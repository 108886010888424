@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-fare-compare-drawer__close-table {
  background: none;
  border: none;
  align-self: baseline;
  margin-left: -10px;
}
.blte-fare-compare-drawer__table-arrow-left {
  margin-left: -11px;
  color: black;
}
.blte-fare-compare-drawer__table-arrow-left::after {
  font-size: 20px;
}
.blte-fare-compare-drawer__table-content {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.blte-fare-compare-drawer__header-drawer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}
.blte-fare-compare-drawer__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px 16px 0;
  align-self: stretch;
  flex: 1;
  text-align: center;
}
.blte-fare-compare-drawer__trip {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  align-items: center;
  flex-grow: 1;
}
@media screen and (min-width: 576px) {
  .blte-fare-compare-drawer__trip {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-fare-compare-drawer__origin {
  margin-right: 10px;
}
.blte-fare-compare-drawer__destination {
  margin-left: 10px;
}
