@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.portal {
  position: static;
}
.blte-desktop-select__list {
  padding: 0;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border: none;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 8px;
}
.blte-desktop-select__listItems {
  flex: 1;
  max-height: 40vh;
  overflow-y: auto;
  padding: 0;
  margin: 0;
}
.blte-desktop-select__list:focus {
  outline: none;
  box-shadow: none;
}
.blte-desktop-select__popover:focus {
  outline: none;
  box-shadow: none;
}
