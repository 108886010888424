@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-overlay-container .blte-title {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .blte-overlay-container .blte-title {
    text-align: left;
  }
}
.blte-overlay-container__description {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  padding: 0 2px 2px;
  margin-top: 16px;
}
@media screen and (min-width: 576px) {
  .blte-overlay-container__description {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-overlay-container__description {
    margin-top: 24px;
  }
}
.blte-overlay-container__buttons {
  margin-top: 24px;
  display: flex;
  flex-direction: column-reverse;
  gap: 12px;
}
.blte-overlay-container__buttons > button {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-overlay-container__buttons {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
    gap: 8px;
  }
  .blte-overlay-container__buttons > button {
    width: auto;
  }
  .blte-overlay-container__buttons > button:last-child {
    margin-left: 8px;
  }
}
.blte-overlay-container__h2-style {
  font-size: 22px;
  font-weight: 500;
}
.blte-overlay-container__error {
  color: #c00;
  width: 100%;
  text-align: center;
}
