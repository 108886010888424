.blte-checkbox__input::after {
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

}
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-checkbox {
  display: flex;
  flex-wrap: wrap;
  max-width: 650px;
  padding: 8px 0;
  border: none;
}
.blte-checkbox__input {
  position: relative;
  width: 18px;
  height: 18px;
  appearance: none;
  cursor: pointer;
  margin: 3px 0 0;
}
.blte-checkbox__input:hover::before {
  border-color: #363636;
}
.blte-checkbox__input::before {
  position: absolute;
  inset: 0;
  content: "";
  user-select: none;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #565656;
  border-radius: 4px;
}
.blte-checkbox__input::after {
  position: absolute;
  content: ICON_FONT_LOADER_IMAGE(IDbc3a9eeac38a8344aa184117cb921092);
  font-size: 11px;
  color: #fff;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  opacity: 0;
  line-height: 17px;
}
.blte-checkbox__input:focus:not(:focus-visible) {
  outline: none;
}
.blte-checkbox__input:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #363636;
  border-radius: 4px;
}
.blte-checkbox__input:checked::before {
  background-color: #2e6a6e;
  border: none;
}
.blte-checkbox__input:checked::after {
  opacity: 1;
}
.blte-checkbox__label {
  margin-left: 8px;
  flex: 1;
}
.blte-checkbox__error {
  color: red;
  flex-basis: 100%;
  padding-left: 26px;
}
.blte-checkbox__label-description {
  font-size: inherit;
  font-weight: inherit;
}
.blte-checkbox--is-disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.blte-checkbox--is-disabled .blte-checkbox__error {
  display: none;
}
.blte-checkbox--is-disabled .blte-checkbox__label {
  color: #e1e1e1;
}
.blte-checkbox--is-disabled .blte-checkbox__input::before {
  border-color: #e1e1e1;
}
.blte-checkbox--is-disabled .blte-checkbox__input:checked::before {
  background-color: #e1e1e1;
}
.blte-checkbox--is-disabled.blte-checkbox--has-error .blte-checkbox__input::before {
  border-color: #e1e1e1;
}
.blte-checkbox--is-disabled.blte-checkbox--has-error .blte-checkbox__input:checked::before {
  background-color: #e1e1e1;
}
.blte-checkbox--has-error .blte-checkbox__input::before {
  border-color: #c00;
}
.blte-checkbox--has-error .blte-checkbox__input:checked::before {
  background-color: #c00;
}
