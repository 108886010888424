@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-payment-card-item__add {
  width: 48px;
  height: 32px;
  background-color: #e1e1e1;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a3a3a3;
  margin-right: 16px;
  font-size: 16px;
}
.blte-payment-card-item__image,
.blte-payment-card-item__card-type-number {
  display: flex;
}
.blte-payment-card-item__image {
  width: 36px;
  height: 24px;
  margin-right: 16px;
}
@media screen and (min-width: 768px) {
  .blte-payment-card-item__image {
    width: 48px;
    height: 32px;
  }
}
.blte-payment-card-item__label {
  flex-direction: column;
  color: #363636;
  align-items: flex-start;
}
.blte-payment-card-item__card-type-number {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  width: 100%;
}
@media screen and (min-width: 576px) {
  .blte-payment-card-item__card-type-number {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-payment-card-item__card-type-number--disabled {
  font-size: 17px;
  color: #8d8d8d;
}
.blte-payment-card-item__card-expiration {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
  display: flex;
  align-items: flex-start;
}
.blte-payment-card-item--active {
  background-color: #e8f2f1;
}
.blte-payment-card-item--focus {
  outline: none;
  background-color: #e8f2f1;
}
