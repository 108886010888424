@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-celebration-banner {
  width: 100%;
  display: flex;
  color: var(--celebration-banner-text-color);
  background-color: var(--celebration-banner-background-color);
  padding: 0 24px;
}
@media screen and (min-width: 576px) {
  .blte-celebration-banner {
    padding: 0 64px;
    text-align: start;
  }
}
@media screen and (min-width: 768px) {
  .blte-celebration-banner {
    padding: 0 40px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-celebration-banner {
    padding: 0;
  }
}
.blte-celebration-banner__banner-no-image {
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--celebration-banner-text-color);
  background-color: var(--celebration-banner-background-color);
  padding: 0 24px;
}
.blte-celebration-banner__details {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  flex: 1;
}
@media screen and (min-width: 768px) {
  .blte-celebration-banner__details {
    text-align: start;
  }
}
@media screen and (min-width: 1024px) {
  .blte-celebration-banner__details {
    padding: 40px;
  }
}
.blte-celebration-banner__details-no-image {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.blte-celebration-banner__title {
  font-size: 28px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-celebration-banner__title {
    font-size: 42px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-celebration-banner__title {
    font-size: 44px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-celebration-banner__booking-label {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  padding: 5px 0;
}
.blte-celebration-banner__booking-number {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 28px !important;
}
.blte-celebration-banner__image {
  display: none;
}
@media (min-width: 1025px) {
  .blte-celebration-banner__image {
    display: flex;
    width: 100%;
    max-width: 440px;
    margin-left: 40px;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
  }
  .blte-celebration-banner__image img,
  .blte-celebration-banner__image picture {
    max-width: 440px;
    aspect-ratio: 16/9;
    width: fit-content;
    max-height: 250px;
  }
}
.blte-celebration-banner__booking {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 4px;
}
@media screen and (min-width: 576px) {
  .blte-celebration-banner__booking {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-celebration-banner__buttons {
  display: flex;
  flex-flow: row wrap;
  padding: 24px 0;
  row-gap: 16px;
  column-gap: 12px;
}
.blte-celebration-banner__buttons:last-child {
  margin-right: 0;
}
.blte-celebration-banner__buttons .blte-btn {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-celebration-banner__buttons .blte-btn {
    width: auto;
  }
}
.blte-celebration-banner__primary-email {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-celebration-banner__primary-email {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-celebration-banner__emails {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  min-height: 24px;
}
@media screen and (min-width: 576px) {
  .blte-celebration-banner__emails {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-celebration-banner__emails {
    min-height: 28px;
  }
}
.blte-celebration-banner__emails span {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-celebration-banner__emails span {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-celebration-banner__expand,
.blte-celebration-banner__collapse {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  outline: none;
  color: #094273;
  text-decoration: underline;
}
.blte-celebration-banner__expand:focus-visible,
.blte-celebration-banner__collapse:focus-visible {
  border-radius: 4px;
  box-shadow: 0 0 0 2px #363636;
}
.blte-celebration-banner__collapse {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-celebration-banner__collapse {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-celebration-banner__expanded {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .blte-celebration-banner__expanded {
    align-items: flex-start;
    text-align: start;
  }
}
@media screen and (min-width: 768px) {
  .blte-celebration-banner__expanded--no-image {
    align-items: center;
    text-align: center;
  }
}
.blte-celebration-banner__all-emails {
  padding: 8px 0;
}
.blte-celebration-banner__bottom {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-celebration-banner__bottom a {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  font-weight: bold;
  padding: 0 2px;
  color: #363636;
}
.blte-celebration-banner__primary-passenger-email {
  font-size: 20px !important;
  line-height: 28px;
  font-weight: 500 !important;
  font-family: "Futura PT", sans-serif;
}
.blte-celebration-banner__kiosk-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 48px;
  margin-bottom: 80px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.blte-celebration-banner__kiosk-buttons .blte-btn {
  display: flex;
  width: 281px;
  padding: 24px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
}
.blte-celebration-banner__kiosk-buttons .blte-btn .blte-icon {
  font-size: 24px;
  margin-right: 0;
}
.blte-celebration-banner__rewards {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  padding: 24px 0;
  max-width: 750px;
  margin: 0 auto;
  width: 100%;
}
.blte-celebration-banner__rewards-divider {
  width: 100%;
  margin: 0;
  border-top: 1px solid #e1e1e1;
}
.blte-celebration-banner__rewards-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-content: center;
  padding: 0;
  width: 90%;
}
@media screen and (min-width: 768px) {
  .blte-celebration-banner__rewards-content {
    flex-direction: row;
    padding: 0 40px;
  }
}
.blte-celebration-banner__rewards-bubble-img {
  position: relative;
  display: flex;
  align-items: start;
  align-self: center;
  width: fit-content;
  max-width: 250px;
}
@media screen and (min-width: 768px) {
  .blte-celebration-banner__rewards-bubble-img {
    max-width: none;
  }
}
.blte-celebration-banner__rewards-bubble-img > picture > img {
  height: 100%;
  object-fit: contain;
}
.blte-celebration-banner__rewards-bubble-img > img {
  height: 100%;
  object-fit: contain;
}
.blte-celebration-banner__rewards-bubble-overlay {
  display: flex;
  justify-content: end;
  position: absolute;
  top: 0;
  right: -10px;
  width: 100%;
}
.blte-celebration-banner__rewards-bubble-text {
  padding: 4px 20px 4px 4px;
  border-radius: 10px 0 0 10px;
  margin-right: -16px;
  background-color: var(--celebration-banner-couldHaveEarnedBackground-color);
  color: var(--celebration-banner-couldHaveEarnedText-color);
  font-size: 16px;
  font-weight: 450;
  height: min-content;
  margin-top: 8px;
  width: 100%;
}
.blte-celebration-banner__rewards-bubble-points {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 4px solid var(--celebration-banner-bubbleBorder-color);
  border-radius: 100%;
  background-color: var(--celebration-banner-bubbleBackground-color);
  padding: 0 10px;
  aspect-ratio: 1/1;
  box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.1411764706);
  min-width: 60px;
}
.blte-celebration-banner__rewards-bubble-points-point {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}
.blte-celebration-banner__rewards-bubble-points-text {
  font-size: 8px;
  font-weight: 450;
  line-height: 11.2px;
}
.blte-celebration-banner__rewards-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-celebration-banner__rewards-column {
    align-items: start;
  }
}
.blte-celebration-banner__rewards-branding-img {
  display: flex;
}
.blte-celebration-banner__rewards-branding-img > picture > img {
  height: 50px;
  object-fit: contain;
  width: auto;
}
.blte-celebration-banner__rewards-branding-img > img {
  height: 50px;
  object-fit: contain;
  width: auto;
}
.blte-celebration-banner__rewards-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .blte-celebration-banner__rewards-details {
    align-items: start;
    text-align: start;
  }
}
.blte-celebration-banner__rewards-rn {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-celebration-banner__rewards-rn {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-celebration-banner__rewards-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  text-align: center;
}
@media screen and (min-width: 576px) {
  .blte-celebration-banner__rewards-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-celebration-banner__rewards-cards {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 24px;
  padding: 24px;
  border-radius: 16px;
  background-color: var(--celebration-banner-cardsBackground-color);
}
@media screen and (min-width: 768px) {
  .blte-celebration-banner__rewards-cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media screen and (min-width: 1024px) {
  .blte-celebration-banner__rewards-cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.blte-celebration-banner__rewards-card {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  text-align: start;
}
.blte-celebration-banner__rewards-card img {
  width: 100%;
  height: 140px;
  object-fit: cover;
  border-radius: 8px;
}
.blte-celebration-banner__rewards-card-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-celebration-banner__rewards-card-title {
    font-size: 22px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-celebration-banner__rewards-card-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-celebration-banner__rewards-card-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-celebration-banner__rewards-buttons {
  margin: -24px 0;
}
