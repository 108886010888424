@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-booking-search__trip-selection {
  display: flex;
  align-items: center;
}
.blte-booking-search__trip-selection > span {
  margin: 0 16px;
}
