@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-profileV2__profile-banner {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  background: #fff;
  border-radius: 8px;
  gap: 16px;
  flex: 1;
}
.blte-profileV2__profile-banner:hover {
  cursor: pointer;
}
.blte-profileV2__user-avatar {
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  width: 54px;
  height: 54px;
  flex: 0 0 auto;
  background: #363636;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-profileV2__user-avatar {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-profileV2__user-avatar {
    width: 48px;
    height: 48px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-profileV2__user-avatar {
    width: 54px;
    height: 54px;
  }
}
.blte-profileV2__user-name {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  word-break: break-word;
  display: flex;
}
.blte-profileV2__membership {
  margin-block-start: 0;
  margin-block-end: 0;
}
.blte-profileV2__date {
  color: #8d8d8d;
  white-space: nowrap;
}
.blte-profileV2__icon {
  margin-left: auto;
}
.blte-profileV2-placeholders__profile-banner {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  background: #fff;
  border-radius: 8px;
  gap: 16px;
  flex: 1;
}
.blte-profileV2-placeholders__user-avatar {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
}
.blte-profileV2-placeholders__user-avatar::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #fff, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-profileV2-placeholders__user-info {
  width: 80%;
}
.blte-profileV2-placeholders__user-name,
.blte-profileV2-placeholders__membership,
.blte-profileV2-placeholders__date {
  border-radius: 2px;
  height: 20px;
  width: 40%;
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
}
.blte-profileV2-placeholders__user-name::after,
.blte-profileV2-placeholders__membership::after,
.blte-profileV2-placeholders__date::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #fff, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-profileV2-placeholders__user-name {
  margin-bottom: 8px;
}
