@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-user-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
}
.blte-user-avatar__emblem {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-transform: none;
  border-radius: 50%;
  background-color: #2e6a6e;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
@media screen and (min-width: 576px) {
  .blte-user-avatar__emblem {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: none;
  }
}
.blte-user-avatar__emblem span {
  display: flex;
  align-items: center;
}
.blte-user-avatar__name {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  margin-left: 8px;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-width: 576px) {
  .blte-user-avatar__name {
    margin-left: 16px;
    margin-right: 16px;
  }
}
.blte-user-avatar__small {
  min-width: 32px;
  min-height: 32px;
}
.blte-user-avatar__large {
  min-width: 36px;
  min-height: 36px;
}
@media screen and (min-width: 576px) {
  .blte-user-avatar__large {
    min-width: 40px;
    min-height: 40px;
  }
}
