@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-inlinecontainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.blte-inlinecontainer .cq-Editable-dom {
  min-width: 50px;
}
.blte-inlinecontainer .cq-Overlay {
  flex-basis: 100%;
}
.blte-inlinecontainer .blte-divider__wrapper {
  align-self: stretch;
}
