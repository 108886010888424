@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-details-passengers {
  margin-top: 16px;
  width: 100%;
  background: #fff;
  padding: 16px 24px;
  box-sizing: border-box;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-passengers {
    padding: 24px 64px;
    margin-top: 24px;
  }
}
@media screen and (min-width: 768px) {
  .blte-trip-details-passengers {
    margin-top: 60px;
    padding: 0;
    background: none;
  }
}
.blte-trip-details-passengers__placeholders {
  display: flex;
  flex-direction: column;
}
.blte-trip-details-passengers__placeholders > div:first-child {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  width: 130px;
  height: 24px;
  border-radius: 2px;
  margin-bottom: 8px;
}
.blte-trip-details-passengers__placeholders > div:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-passengers__placeholders > div:first-child {
    height: 30px;
  }
}
.blte-trip-details-passengers__placeholders > div:nth-child(2),
.blte-trip-details-passengers__placeholders > div:nth-child(3) {
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-passengers__placeholders > div:nth-child(2),
  .blte-trip-details-passengers__placeholders > div:nth-child(3) {
    padding: 16px;
  }
}
.blte-trip-details-passengers__placeholders > div:nth-child(2) > div:first-child,
.blte-trip-details-passengers__placeholders > div:nth-child(3) > div:first-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.blte-trip-details-passengers__placeholders > div:nth-child(2) > div:first-child > *,
.blte-trip-details-passengers__placeholders > div:nth-child(3) > div:first-child > * {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
}
.blte-trip-details-passengers__placeholders > div:nth-child(2) > div:first-child > *::after,
.blte-trip-details-passengers__placeholders > div:nth-child(3) > div:first-child > *::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-trip-details-passengers__placeholders > div:nth-child(2) > div:first-child > div:first-child,
.blte-trip-details-passengers__placeholders > div:nth-child(3) > div:first-child > div:first-child {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.blte-trip-details-passengers__placeholders > div:nth-child(2) > div:first-child > div:last-child,
.blte-trip-details-passengers__placeholders > div:nth-child(3) > div:first-child > div:last-child {
  width: 246px;
  height: 24px;
  margin-top: 0;
  margin-left: 16px;
  border-radius: 2px;
}
.blte-trip-details-passengers__placeholders > div:nth-child(2) > div:last-child,
.blte-trip-details-passengers__placeholders > div:nth-child(3) > div:last-child {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 8px 0 16px;
}
@media screen and (min-width: 1024px) {
  .blte-trip-details-passengers__placeholders > div:nth-child(2) > div:last-child,
  .blte-trip-details-passengers__placeholders > div:nth-child(3) > div:last-child {
    margin-left: 48px;
  }
}
.blte-trip-details-passengers__placeholders > div:nth-child(2) > div:last-child > *,
.blte-trip-details-passengers__placeholders > div:nth-child(3) > div:last-child > * {
  margin-top: 8px;
}
.blte-trip-details-passengers__placeholders > div:nth-child(2) > div:last-child > div,
.blte-trip-details-passengers__placeholders > div:nth-child(3) > div:last-child > div {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  margin-top: 8px;
  height: 16px;
}
.blte-trip-details-passengers__placeholders > div:nth-child(2) > div:last-child > div::after,
.blte-trip-details-passengers__placeholders > div:nth-child(3) > div:last-child > div::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-trip-details-passengers__placeholders > div:nth-child(2) > div:last-child > div:first-child,
.blte-trip-details-passengers__placeholders > div:nth-child(3) > div:last-child > div:first-child {
  width: 80px;
  height: 16px;
  margin-top: 0;
}
.blte-trip-details-passengers__placeholders > div:nth-child(2) > div:last-child > div:nth-child(2),
.blte-trip-details-passengers__placeholders > div:nth-child(2) > div:last-child > div:nth-child(3),
.blte-trip-details-passengers__placeholders > div:nth-child(3) > div:last-child > div:nth-child(2),
.blte-trip-details-passengers__placeholders > div:nth-child(3) > div:last-child > div:nth-child(3) {
  width: 120px;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-passengers__placeholders > div:nth-child(2) > div:last-child > div:last-child,
  .blte-trip-details-passengers__placeholders > div:nth-child(3) > div:last-child > div:last-child {
    margin-bottom: 8px;
  }
}
@media screen and (min-width: 768px) {
  .blte-trip-details-passengers__placeholders > div:nth-child(2) > div:last-child > div:last-child,
  .blte-trip-details-passengers__placeholders > div:nth-child(3) > div:last-child > div:last-child {
    margin-bottom: 0;
  }
}
.blte-trip-details-passengers__items > div:not(:first-child) {
  margin-top: 16px;
}
