@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-departure-trip-summary {
  display: flex;
  align-items: center;
  padding: 12px 0;
  background-color: #fff;
  flex-flow: row wrap;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-departure-trip-summary {
    padding: 16px 18px;
    border-radius: 8px;
  }
}
.blte-departure-trip-summary__left {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .blte-departure-trip-summary__left {
    flex-direction: row;
    align-items: center;
  }
}
.blte-departure-trip-summary__details {
  display: flex;
  align-items: center;
  margin-top: 4px;
  width: 100%;
  order: 1;
}
@media screen and (min-width: 768px) {
  .blte-departure-trip-summary__details {
    width: auto;
    flex-grow: 1;
    margin-top: 0;
    order: 0;
    align-self: auto;
  }
}
.blte-departure-trip-summary__departure {
  display: flex;
  color: #137e00;
}
.blte-departure-trip-summary__label {
  margin-left: 4px;
}
.blte-departure-trip-summary__departureDate,
.blte-departure-trip-summary__departureHour,
.blte-departure-trip-summary__arrivalHour {
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  white-space: nowrap;
}
@media screen and (min-width: 576px) {
  .blte-departure-trip-summary__departureDate,
  .blte-departure-trip-summary__departureHour,
  .blte-departure-trip-summary__arrivalHour {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-departure-trip-summary__departureDate {
  margin: 0 24px 0 0;
}
@media screen and (min-width: 768px) {
  .blte-departure-trip-summary__departureDate {
    margin: 0 24px;
  }
}
.blte-departure-trip-summary__duration-border {
  border: 2px solid #e1e1e1;
  height: 2px;
  width: 35px;
  margin: 0 8px;
}
.blte-departure-trip-summary--class-smart_saver {
  background-color: #ffef8c;
  color: #363636;
}
.blte-departure-trip-summary--class-smart_standard {
  background-color: #ffdb00;
  color: #363636;
}
.blte-departure-trip-summary--class-premium_standard {
  background-color: #363636;
  color: #fff;
}
.blte-departure-trip-summary--class-smart_saver,
.blte-departure-trip-summary--class-smart_standard,
.blte-departure-trip-summary--class-premium_standard {
  display: none;
}
@media screen and (min-width: 768px) {
  .blte-departure-trip-summary--class-smart_saver,
  .blte-departure-trip-summary--class-smart_standard,
  .blte-departure-trip-summary--class-premium_standard {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-transform: none;
    display: flex;
    border-radius: 4px;
    padding: 0 4px;
    margin: 0 8px 0 24px;
    width: fit-content;
  }
}
.blte-departure-trip-summary__price {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  display: none;
  color: #094273;
  margin-right: 8px;
}
@media screen and (min-width: 576px) {
  .blte-departure-trip-summary__price {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-departure-trip-summary__passenger {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-departure-trip-summary__price,
.blte-departure-trip-summary__passenger {
  display: none;
}
@media screen and (min-width: 768px) {
  .blte-departure-trip-summary__price,
  .blte-departure-trip-summary__passenger {
    display: flex;
  }
}
.blte-departure-trip-summary__modify {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  align-self: flex-start;
  color: #094273;
  text-decoration: underline;
  outline: none;
  flex: 1;
  justify-content: flex-end;
}
@media screen and (min-width: 768px) {
  .blte-departure-trip-summary__modify {
    flex: unset;
  }
}
.blte-departure-trip-summary__modify:focus-visible {
  border-radius: 4px;
  box-shadow: 0 0 0 2px #363636;
}
