@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-wallet {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f2f2f4;
}
