@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-seat-map-item {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  outline: none;
  position: absolute;
  overflow: inherit;
  transition: all 0.25s ease;
}
.blte-seat-map-item-content {
  position: relative;
  width: fit-content;
  height: fit-content;
}
.blte-seat-map-item__number {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #363636;
}
.blte-seat-map-item--active::before {
  position: absolute;
  content: " ";
  inset: -4px;
  box-shadow: 0 0 0 2px #094273;
  border-radius: 4px;
}
.blte-seat-map-item--facingDirection-forwards .blte-seat-map-item__icon {
  transform: rotate(180deg);
}
.blte-seat-map-item:hover {
  cursor: pointer;
}
.blte-seat-map-item:focus-visible {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #094273;
  border-radius: 4px;
}
.blte-seat-map-item--isAccountFlow:hover {
  cursor: default;
}
