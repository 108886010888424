@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-safety-pledge-form-success-message {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 250px;
}
.blte-safety-pledge-form-success-message__form-submission-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  bottom: 40px;
}
@media (min-width: 1025px) {
  .blte-safety-pledge-form-success-message__form-submission-message {
    width: 500px;
    padding: 130px 50px;
    max-height: 372px;
    bottom: 170px;
  }
}
.blte-safety-pledge-form-success-message__title {
  font-size: 25px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
}
.blte-safety-pledge-form-success-message__subtitle {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
}
.blte-safety-pledge-form-success-message__text-wrapper {
  display: flex;
  justify-content: center;
}
@media (min-width: 1025px) {
  .blte-safety-pledge-form-success-message__text-wrapper {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 1024px) {
  .blte-safety-pledge-form-success-message__text-wrapper {
    margin-left: 70px;
    margin-right: 70px;
  }
}
@media (max-width: 800px) {
  .blte-safety-pledge-form-success-message__text-wrapper {
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media (max-width: 431px) {
  .blte-safety-pledge-form-success-message__text-wrapper {
    margin-left: 40px;
    margin-right: 40px;
  }
}
