@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-details-error-view {
  max-width: 1400px;
  margin: 0 auto;
  width: calc(100% - 48px);
  box-sizing: content-box;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-error-view {
    width: calc(100% - 128px);
  }
}
@media screen and (min-width: 768px) {
  .blte-trip-details-error-view {
    width: calc(100% - 80px);
  }
}
@media screen and (min-width: 1024px) {
  .blte-trip-details-error-view {
    width: calc(100% - 128px);
  }
}
.blte-trip-details-error-view__exclamation {
  width: 25px;
  height: 25px;
  background: #c00;
  border-radius: 50%;
  color: #fff;
  font-size: 22px;
  line-height: 25px;
}
.blte-trip-details-error-view__title {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  margin-top: 12px;
}
.blte-trip-details-error-view__description {
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
  margin-top: 4px;
}
.blte-trip-details-error-view__button {
  margin-top: 24px;
}
.blte-trip-details-error-view__link {
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  text-decoration: none;
  color: #094273;
  margin-top: 16px;
}
