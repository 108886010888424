@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-dashboard-wallet {
  display: flex;
  width: 100%;
  position: relative;
  height: 212px;
  overflow: hidden;
}
.blte-dashboard-wallet .blte-pass-card,
.blte-dashboard-wallet .blte-credit-pass {
  width: 82%;
  max-width: 340px;
}
@media screen and (min-width: 1024px) {
  .blte-dashboard-wallet .blte-pass-card,
  .blte-dashboard-wallet .blte-credit-pass {
    width: 77%;
  }
}
@media screen and (min-width: 1200px) {
  .blte-dashboard-wallet .blte-pass-card,
  .blte-dashboard-wallet .blte-credit-pass {
    width: 82%;
  }
}
.blte-dashboard-wallet__card {
  overflow: hidden;
}
.blte-dashboard-wallet__passes {
  display: flex;
  width: 100%;
  overflow: hidden;
}
.blte-dashboard-wallet__action {
  margin-top: 16px;
}
.blte-dashboard-wallet > * {
  position: absolute;
  inset: 0;
}
.blte-dashboard-wallet--items-2 :first-child {
  z-index: 0;
}
.blte-dashboard-wallet--items-2 :nth-child(2) {
  z-index: 1;
  left: 54px;
}
.blte-dashboard-wallet--items-3 :first-child {
  z-index: 0;
}
.blte-dashboard-wallet--items-3 :nth-child(2) {
  z-index: 1;
  left: 27px;
}
.blte-dashboard-wallet--items-3 :nth-child(3) {
  z-index: 2;
  left: 54px;
}
.blte-dashboard-wallet--items-2,
.blte-dashboard-wallet--items-3 {
  width: 100%;
}
