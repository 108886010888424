@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-route-item {
  display: flex;
  width: 100%;
  min-height: 100px;
  outline: none;
  margin-top: 24px;
  list-style: none;
}
.blte-route-item:first-child {
  margin-top: 0;
}
.blte-route-item:last-child {
  margin-bottom: 24px;
}
@media screen and (min-width: 768px) {
  .blte-route-item:first-child {
    margin-top: 24px;
  }
}
@media screen and (min-width: 576px) {
  .blte-route-item {
    min-height: 116px;
  }
}
@media screen and (min-width: 768px) {
  .blte-route-item {
    min-height: 134px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-route-item {
    min-height: 122px;
  }
}
.blte-route-item:focus-visible {
  border-radius: 16px;
  box-shadow: 0 0 0 2px var(--pill-button-border-focus-color, #363636);
}
.blte-route-item__time {
  display: flex;
  background-color: white;
  border-radius: 16px 0 0 16px;
  width: 75%;
}
@media screen and (min-width: 576px) {
  .blte-route-item__time {
    width: 83.3333333333%;
  }
}
@media screen and (min-width: 768px) {
  .blte-route-item__time {
    width: 50%;
    border-radius: 16px;
    margin-right: 2px;
  }
}
.blte-route-item__prices {
  display: flex;
  min-width: 108px;
  width: 25%;
}
.blte-route-item__prices > :first-child {
  border-radius: 0 16px 16px 0;
}
@media screen and (min-width: 576px) {
  .blte-route-item__prices {
    width: 16.6666666667%;
    min-width: 120px;
  }
}
@media screen and (min-width: 768px) {
  .blte-route-item__prices {
    width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .blte-route-item__prices > :first-child {
    width: 50%;
    border-radius: 16px 0 0 16px;
    margin-right: 2px;
  }
  .blte-route-item__prices > :last-child {
    display: flex;
    width: 50%;
    border-radius: 0 16px 16px 0;
  }
}
.blte-route-item--unavailable {
  pointer-events: none;
}
.blte-route-item--unavailable .blte-route-item__time,
.blte-route-item--unavailable .blte-route-item__prices {
  background-color: #fff;
  opacity: 0.2;
}
.blte-kiosk .blte-route-item {
  background: #fff;
  border-radius: 8px;
}
.blte-kiosk .blte-route-item__prices {
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.blte-kiosk .blte-route-item__prices > button {
  border-radius: 16px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  margin-right: 0;
  width: calc(50% - 8px);
  min-height: 122px;
}
.blte-kiosk .blte-route-item__prices > button:hover {
  border-radius: 16px;
}
