@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-logged-out-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-logged-out-modal {
    width: 488px;
  }
}
.blte-logged-out-modal__header-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
}
.blte-logged-out-modal__title {
  text-align: center;
  margin: 0 24px;
  max-width: 206px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 768px) {
  .blte-logged-out-modal__title {
    max-width: 100%;
  }
}
@media screen and (min-width: 576px) {
  .blte-logged-out-modal__title {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-logged-out-modal__icon-wrapper {
  position: absolute;
  right: 2px;
  top: 2px;
  background: none;
  border-block: none;
  border-inline: none;
  border-color: transparent;
  padding: 0;
}
.blte-logged-out-modal__icon-wrapper:hover {
  cursor: pointer;
}
.blte-logged-out-modal__icon {
  color: #363636;
  font-size: 24px;
  line-height: 24px;
}
.blte-logged-out-modal__button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
