@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-pill-button {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  height: 36px;
  padding: 6px 16px;
  outline: none;
  text-decoration: none;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  border-radius: 30px;
  background: var(--pill-button-background-color, #f2f2f4);
  color: var(--pill-button-text-color, #094273);
  transition: all 0.3s;
  position: relative;
}
.blte-pill-button::before {
  box-shadow: 0 0 4px var(--pill-button-shadow-color, rgba(0, 0, 0, 0.25));
  border-radius: 30px;
  inset: 0;
  position: absolute;
  content: "";
  display: var(--pill-button-shadow-visibility, none);
}
.blte-pill-button:focus-visible {
  background: var(--pill-button-background-focus-color, #f2f2f4);
  color: var(--pill-button-text-focus-color, #094273);
  box-shadow: 0 0 0 2px var(--pill-button-border-focus-color, #363636);
}
.blte-pill-button:focus-visible::before {
  box-shadow: 0 0 0 2px var(--pill-button-border-focus-color, #363636), 0 0 6px var(--pill-button-shadow-color, rgba(0, 0, 0, 0.25));
}
.blte-pill-button:hover {
  background: var(--pill-button-background-hover-color, #094273);
  color: var(--pill-button-text-hover-color, #fff);
}
.blte-pill-button__external-icon {
  margin-left: 8px;
  font-size: 12px;
  display: var(--pill-button-icon-visibility, none);
}
.blte-pill-button--disabled,
.blte-pill-button--disabled:hover,
.blte-pill-button--disabled:focus-visible {
  background: var(--pill-button-background-disabled-color, #e1e1e1);
  color: var(--pill-button-text-disabled-color, #a3a3a3);
  cursor: default;
  pointer-events: none;
}
.blte-pill-button--disabled::before,
.blte-pill-button--disabled:hover::before,
.blte-pill-button--disabled:focus-visible::before {
  box-shadow: none;
}
.blte-pill-button__icon-title {
  font-size: inherit;
  font-weight: inherit;
}
