@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-privacy-request-success-modal {
  display: flex;
  flex-direction: column;
  align-content: center;
  row-gap: 28px;
  padding: 30px 40px;
}
.blte-privacy-request-success-modal__background-color-blue .blte-modal__body {
  background-color: #e8f2f1;
  color: #094273;
}
@media screen and (min-width: 1024px) {
  .blte-privacy-request-success-modal__modal {
    padding-left: calc(50vw - 270px);
    padding-right: calc(50vw - 270px);
  }
}
@media screen and (min-width: 768px) {
  .blte-privacy-request-success-modal__modal {
    padding-left: calc(50vw - 270px);
    padding-right: calc(50vw - 270px);
  }
}
.blte-privacy-request-success-modal__modal .blte-modal__body {
  width: 100%;
  height: auto;
}
.blte-privacy-request-success-modal__title {
  display: flex;
  justify-content: center;
  padding: 30px 0;
}
.blte-privacy-request-success-modal__header {
  margin-top: 10px;
  text-align: center;
  position: relative;
}
.blte-privacy-request-success-modal__text {
  font-size: 17px;
}
.blte-privacy-request-success-modal__icon-wrapper {
  position: absolute;
  right: 1px;
  top: 0;
  background: none;
  border-block: none;
  border-inline: none;
  border-color: transparent;
  padding: 0;
}
.blte-privacy-request-success-modal__icon-wrapper:hover {
  cursor: pointer;
}
.blte-privacy-request-success-modal__icon {
  color: #094273;
  font-size: 24px;
  line-height: 24px;
}
.blte-privacy-request-success-modal__check-icon {
  color: #094273;
  font-size: 100px;
  line-height: 24px;
  padding-bottom: 25px;
}
.blte-privacy-request-success-modal__content {
  margin: 50px 0;
}
.blte-privacy-request-success-modal__success-message {
  margin: 20px 0;
}
.blte-privacy-request-success-modal__blue-icon {
  display: flex;
  justify-content: center;
}
.blte-privacy-request-success-modal__button-row {
  display: flex;
  justify-content: center;
}
