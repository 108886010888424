@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-od-desktop-select {
  position: relative;
  transition: transform linear 0.3s;
  width: 100%;
}
.blte-od-desktop-select__listItems {
  flex: 1;
  max-height: 348px;
  width: 100%;
  overflow-y: auto;
  padding: 24px 4px 4px;
  list-style-type: unset;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
}
.blte-od-desktop-select:last-child .blte-widget-input {
  padding-left: 16px;
}
.blte-od-desktop-select:last-child .blte-widget-input__label {
  left: 32px;
}
.blte-od-desktop-select:last-child .blte-od-desktop-select__list {
  left: unset;
  right: -16px;
}
@media screen and (min-width: 1024px) {
  .blte-od-desktop-select:last-child .blte-od-desktop-select__list {
    right: unset;
    left: -16px;
  }
}
.blte-od-desktop-select .blte-widget-input {
  min-width: 50%;
}
.blte-od-desktop-select .blte-widget-input__input {
  color: #363636;
}
.blte-od-desktop-select .blte-widget-input__input--disabled {
  color: #e1e1e1;
}
.blte-od-desktop-select .blte-widget-input__clear {
  padding-left: 1px;
}
.blte-od-desktop-select__list {
  display: none;
  z-index: 2;
  position: absolute;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border: none;
  flex-direction: column;
  width: 512px;
  padding: 16px 16px 0;
  left: -16px;
  top: -16px;
}
.blte-od-desktop-select__list--active {
  display: flex;
  z-index: 3;
}
