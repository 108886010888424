@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-fare-compare-selection-item {
  border-radius: 11px;
  min-height: 44px;
  width: 100%;
  display: flex;
  cursor: pointer;
}
.blte-fare-compare-selection-item + .blte-fare-compare-selection-item {
  margin-top: 20px;
}
.blte-fare-compare-selection-item--expanded .blte-fare-compare-selection-item__fareClassOptions {
  display: block;
}
.blte-fare-compare-selection-item__originalPrice {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  margin-left: 3px;
  color: #565656;
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  margin-top: -4px;
}
.blte-fare-compare-selection-item__originalPrice--isPremium {
  color: #fff;
}
.blte-fare-compare-selection-item__item {
  border-radius: 12px 0 0 12px;
  width: 30%;
  padding: 10px 0;
}
.blte-fare-compare-selection-item__item--isPremium {
  color: #fff;
}
.blte-fare-compare-selection-item__cost {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;
}
.blte-fare-compare-selection-item__fare-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
}
.blte-fare-compare-selection-item__fare-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  text-transform: none;
  text-align: center;
  line-height: 1.2;
}
@media screen and (min-width: 576px) {
  .blte-fare-compare-selection-item__fare-title {
    font-size: 22px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-fare-compare-selection-item__fare-title--isNotAvailable {
  color: #565656;
}
.blte-fare-compare-selection-item__price {
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-fare-compare-selection-item__price {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-fare-compare-selection-item__per-guest {
  font-size: 13px;
  font-weight: 400;
  margin-top: -4px;
}
.blte-fare-compare-selection-item__content {
  background-color: #fff;
  width: 70%;
  border-radius: 0 12px 12px 0;
  padding: 10px;
}
.blte-fare-compare-selection-item__title {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-fare-compare-selection-item__horizontal-line {
  width: 100%;
  margin: 3px;
  height: 1px;
  background-color: #f2f2f4;
}
.blte-fare-compare-selection-item__sold-out-message {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-fare-compare-selection-item__sold-out-message--isSmartSaver {
  line-height: 3;
}
.blte-fare-compare-selection-item__SMART_SAVER--isFareSelected {
  border: 4px solid #007981;
}
.blte-fare-compare-selection-item__SMART_SAVER--isFareSelected .blte-fare-compare-selection-item__item {
  border-radius: 7px 0 0 7px;
}
.blte-fare-compare-selection-item__SMART_SAVER--isFareSelected + .blte-fare-compare-selection-item {
  margin-top: 12px;
}
.blte-fare-compare-selection-item__SMART_STANDARD--isFareSelected {
  border: 4px solid #007981;
}
.blte-fare-compare-selection-item__SMART_STANDARD--isFareSelected .blte-fare-compare-selection-item__item {
  border-radius: 7px 0 0 7px;
}
.blte-fare-compare-selection-item__SMART_STANDARD--isFareSelected + .blte-fare-compare-selection-item {
  margin-top: 12px;
}
.blte-fare-compare-selection-item__PREMIUM_STANDARD--isFareSelected {
  border: 4px solid #007981;
  margin-top: 16px !important;
}
.blte-fare-compare-selection-item__PREMIUM_STANDARD--isFareSelected .blte-fare-compare-selection-item__item {
  border-radius: 7px 0 0 7px;
}
