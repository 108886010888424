@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
body.account .blte-xf-container__wrapper,
body.account .blte-sidebar-sectioncontainer__wrapper .blte-account-layout-with-sidebar {
  display: none;
}
body.account.hasVisibleXFContainers .blte-xf-container__wrapper,
body.account.hasVisibleCMSContent .blte-sidebar-sectioncontainer__wrapper .blte-account-layout-with-sidebar,
body.account.edit-mode .blte-xf-container__wrapper,
body.account.edit-mode .blte-sidebar-sectioncontainer__wrapper .blte-account-layout-with-sidebar {
  display: block;
}
body.account {
  display: flex;
  flex-direction: column;
}
body.account.hasVisibleCMSContent > .blte-sectioncontainer__wrapper {
  flex: 1;
}
body.account > .QSIFeedbackButton {
  z-index: 100 !important;
}
@media screen and (min-width: 768px) {
  body.account > .QSIFeedbackButton {
    z-index: inherit;
  }
}
