@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-expired-session-modal__title {
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  text-align: center;
  margin-bottom: 25px;
}
.blte-expired-session-modal__button-wrapper {
  display: flex;
  justify-content: center;
}
