@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-gift-card {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  overflow: unset;
}
.blte-gift-card__applied,
.blte-gift-card__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6px 12px;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
}
.blte-gift-card__applied--active,
.blte-gift-card__content--active {
  box-shadow: 0 0 0 1px #094273;
  background: rgba(242, 242, 244, 0.4);
}
.blte-gift-card__applied:focus-visible,
.blte-gift-card__content:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px #363636;
}
.blte-gift-card__content-wrapper--gray {
  opacity: 0.5;
}
.blte-gift-card__applied {
  margin-bottom: 12px;
  background: rgba(242, 242, 244, 0.4);
  border: 1px solid #094273;
}
.blte-gift-card__content > div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.blte-gift-card__form {
  width: 100%;
  margin-left: 0;
  margin-top: 8px;
}
@media screen and (min-width: 1024px) {
  .blte-gift-card__form {
    width: calc(100% - 42px);
    margin-left: 42px;
  }
}
.blte-gift-card__top_content {
  display: flex;
}
.blte-gift-card__bottom_content {
  display: flex;
  justify-content: space-between;
  padding-left: 45px;
  flex-flow: row nowrap;
}
.blte-gift-card__bottom_content .blte-gift-card__description {
  text-align: left;
  padding-right: 20px;
}
.blte-gift-card__inputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (min-width: 1024px) {
  .blte-gift-card__inputs {
    flex-wrap: nowrap;
  }
}
.blte-gift-card__inputs .blte-input__fieldset:first-of-type {
  width: 100%;
  margin-bottom: 8px;
}
@media screen and (min-width: 1024px) {
  .blte-gift-card__inputs .blte-input__fieldset:first-of-type {
    margin-right: 8px;
    width: 60%;
    margin-bottom: 0;
  }
}
.blte-gift-card__inputs .blte-input__fieldset:nth-of-type(2) {
  width: calc(50% - 4px);
}
@media screen and (min-width: 1024px) {
  .blte-gift-card__inputs .blte-input__fieldset:nth-of-type(2) {
    margin-right: 8px;
    width: 23%;
  }
}
.blte-gift-card__inputs input {
  height: 42px;
}
.blte-gift-card__inputs button {
  width: calc(50% - 4px);
  height: 42px;
  max-height: 42px;
  white-space: nowrap;
}
@media screen and (min-width: 1024px) {
  .blte-gift-card__inputs button {
    width: 17%;
  }
}
.blte-gift-card__inputs .blte-input input {
  border-color: #a3a3a3;
  border-radius: 8px;
  color: #8d8d8d;
  height: 42px;
}
.blte-gift-card_modal__gift-card_modal-container .blte-text {
  margin-bottom: 15px;
}
.blte-gift-card_modal__buttons {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media screen and (min-width: 1024px) {
  .blte-gift-card_modal__buttons {
    flex-direction: row;
  }
}
.blte-gift-card_modal__buttons button {
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .blte-gift-card_modal__buttons button {
    width: 88px;
  }
}
.blte-gift-card_modal__buttons button:first-child {
  margin-bottom: 15px;
}
@media screen and (min-width: 1024px) {
  .blte-gift-card_modal__buttons button:first-child {
    margin-right: 20px;
    margin-bottom: 0;
  }
}
.blte-gift-card__text {
  text-align: left;
}
.blte-gift-card__image {
  width: 34px;
  min-width: 34px;
  height: 24px;
  margin-right: 16px;
}
.blte-gift-card__description {
  color: #565656;
}
.blte-gift-card__balance {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #137e00;
  text-align: end;
  padding-right: 5px;
}
.blte-gift-card__title {
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
.blte-gift-card__details {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.blte-gift-card__subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
  width: fit-content;
}
.blte-gift-card__data {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.blte-gift-card__info {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  column-gap: 8px;
}
.blte-gift-card__checkbox {
  padding: 0;
}
.blte-gift-card__checkbox > label {
  display: none;
}
.blte-gift-card__checkbox-data {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-end;
}
.blte-gift-card__checkbox-data .blte-checkbox {
  padding: 0;
}
.blte-gift-card__checkbox-data .blte-checkbox__label {
  margin-left: 0;
}
.blte-gift-card__checkbox-data > span {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #137e00;
  text-align: end;
}
.blte-gift-card__error-message {
  font-size: 12px;
  font-weight: 450;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  margin-top: 8px;
  float: left;
  color: #c00;
  padding-left: 10px;
  text-align: left;
}
.blte-gift-card__icon {
  line-height: 0;
}
