@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-sweepstakes-form-content__content-body {
  padding: 20px 0;
}
.blte-sweepstakes-form-content__row {
  display: flex;
  flex-direction: column;
  padding: 0 2px;
}
@media screen and (min-width: 768px) {
  .blte-sweepstakes-form-content__row {
    flex-direction: row;
  }
}
.blte-sweepstakes-form-content__column {
  width: 50%;
}
@media screen and (min-width: 768px) {
  .blte-sweepstakes-form-content__column:nth-child(2) {
    margin-left: 10px;
  }
}
.blte-sweepstakes-form-content__field {
  width: 100%;
  padding: 0;
  margin-bottom: 16px;
}
.blte-sweepstakes-form-content__btn {
  width: 100%;
  margin-top: 20px;
}
.blte-sweepstakes-form-content__disclaimer-wrapper {
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  margin-top: -5px;
}
@media screen and (min-width: 768px) {
  .blte-sweepstakes-form-content__disclaimer-wrapper {
    margin-top: -12px;
  }
}
.blte-sweepstakes-form-content__disclaimer {
  padding: 8px 5px;
  font-family: "Futura PT", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
@media screen and (min-width: 768px) {
  .blte-sweepstakes-form-content__column-state {
    flex: 0 0 65%;
    max-width: 65%;
  }
}
@media screen and (min-width: 768px) {
  .blte-sweepstakes-form-content__column-zip {
    flex: 0 0 34%;
    max-width: 34%;
  }
}
.blte-sweepstakes-form-content__zipCode {
  margin-top: 0;
  margin-top: -15px;
}
@media screen and (min-width: 768px) {
  .blte-sweepstakes-form-content__zipCode {
    margin-top: 0;
  }
}
