@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-edit-parking-pass-error-banner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px 0;
}
.blte-account-edit-parking-pass-error-banner__banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;
  background: rgba(204, 0, 0, 0.1);
  border-radius: 4px;
  width: 100%;
}
.blte-account-edit-parking-pass-error-banner__icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 24px;
  height: 24px;
  font-size: 22px;
  line-height: 24px;
  color: #c00;
}
.blte-account-edit-parking-pass-error-banner__text {
  font-weight: 450;
  font-size: 16px;
  line-height: 24px;
  color: #363636;
}
