@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-promo-code-applied-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blte-promo-code-applied-banner__banner {
  display: flex;
  align-items: center;
  padding: 8px;
  background: #137e00;
  width: 100%;
}
.blte-promo-code-applied-banner__banner-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.blte-promo-code-applied-banner__icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 24px;
  height: 24px;
  font-size: 22px;
  line-height: 24px;
  color: #fff;
}
.blte-promo-code-applied-banner__close-icon:hover {
  cursor: pointer;
}
.blte-promo-code-applied-banner__text {
  font-weight: 450;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}
