@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-empty-pass-mobile {
  display: flex;
  width: 100%;
  padding: 0 40px;
  border: 2px dashed #ffdb00;
  flex-direction: column;
  align-items: center;
  max-width: 334px;
  min-height: 212px;
  justify-content: center;
}
.blte-empty-pass-mobile__description {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  color: #8d8d8d;
  margin-bottom: 16px;
  text-align: center;
}
@media screen and (min-width: 576px) {
  .blte-empty-pass-mobile__description {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-empty-pass-mobile__actions {
  display: flex;
  flex-direction: row;
}
