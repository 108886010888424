@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-wallet-edit-pass {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  border-radius: 2px;
  outline: none;
  color: #094273;
  display: flex;
  align-items: center;
  width: fit-content;
}
.blte-wallet-edit-pass__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}
.blte-wallet-edit-pass span {
  padding: 8px 2px;
}
.blte-wallet-edit-pass:focus-visible {
  box-shadow: 0 0 0 2px #363636;
}
.blte-wallet-edit-pass:disabled {
  pointer-events: none;
  color: #8d8d8d;
}
