@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-payment-tokens-card {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.blte-payment-tokens-card__item {
  background-color: #f2f2f4;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  width: 100%;
}
.blte-payment-tokens-card .blte-payment-card-item__card-type-number {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
