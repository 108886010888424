@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-content--state-finished .blte-trip-segment {
  background-color: #fff;
  margin-bottom: 12px;
  padding: 0 0 0 16px;
}
.blte-trip-content--state-finished .blte-trip-segment__direction {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  padding: 0 0 0 16px;
}
.blte-trip-content--state-finished .blte-trip-segment__direction + .blte-trip-line {
  border-top: none;
}
.blte-trip-content--state-finished .blte-trip-line {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 6px;
  padding: 0 0 0 16px;
}
.blte-trip-content--state-finished .blte-trip-line__description {
  display: none;
}
.blte-trip-content--state-finished .blte-trip-line + .blte-trip-line {
  border-top: none;
}
