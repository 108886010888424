.blte-carousel .slick-prev::after,.blte-carousel .slick-next::after {
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

}
@import "slick-carousel/slick/slick.css";
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-carousel {
  position: relative;
}
.blte-carousel__rotation-button {
  position: absolute;
  opacity: 0;
  height: 45px;
  width: 45px;
  cursor: default;
  pointer-events: none;
  border-radius: 13px;
  top: 8px;
  left: 9px;
  background-color: #ffdb00;
  border: none;
  z-index: 100;
}
.blte-carousel__rotation-button:focus-visible {
  opacity: 1;
  pointer-events: all;
  outline: 0;
}
.blte-carousel__rotation-button:focus-visible::before {
  content: "";
  position: absolute;
  inset: -2px;
  box-shadow: 0 0 0 2px var(--button-border-focus-color, #363636);
  border-radius: 15px;
}
.blte-carousel__resume-container,
.blte-carousel__pause-container {
  display: none;
}
.blte-carousel__resume-container--isVisible,
.blte-carousel__pause-container--isVisible {
  display: block;
}
.blte-carousel__pause-container {
  font-size: 22px;
}
.blte-carousel .slick-prev::after {
  content: ICON_FONT_LOADER_IMAGE(ID4a74a83f9f9725e0371f9574b9c6d726);
}
.blte-carousel .slick-prev::after {
  font-size: 23px;
  line-height: 32px;
}
.blte-carousel .slick-next::after {
  content: ICON_FONT_LOADER_IMAGE(IDdc0f790aa6274da32c0fd98eb2798059);
}
.blte-carousel .slick-next::after {
  font-size: 26px;
  line-height: 32px;
}
.blte-carousel .slick-prev,
.blte-carousel .slick-next {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  font-size: 0;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  z-index: 1;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #094273;
  color: #094273;
}
.blte-carousel .slick-prev::after,
.blte-carousel .slick-next::after {
  width: 32px;
  height: 32px;
}
.blte-carousel .slick-prev:hover,
.blte-carousel .slick-next:hover {
  background: #094273;
  color: #fff;
}
.blte-carousel .slick-prev:focus-visible,
.blte-carousel .slick-next:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px #363636;
  color: #363636;
}
.blte-carousel .slick-prev.slick-disabled,
.blte-carousel .slick-next.slick-disabled {
  box-shadow: none;
  cursor: unset;
  background: #f2f2f4;
  color: #e1e1e1;
}
.blte-carousel .slick-prev.slick-disabled:focus,
.blte-carousel .slick-next.slick-disabled:focus {
  box-shadow: none;
}
.blte-carousel .slick-prev.slick-disabled:hover,
.blte-carousel .slick-next.slick-disabled:hover {
  background: #f2f2f4;
  color: #e1e1e1;
}
.blte-carousel__slider {
  display: flex;
}
.blte-carousel__slide {
  position: relative;
}
.blte-carousel__card-image {
  height: var(--height-mobile) !important;
}
@media screen and (min-width: 576px) {
  .blte-carousel__card-image {
    height: var(--height-tablet-sm) !important;
  }
}
@media screen and (min-width: 768px) {
  .blte-carousel__card-image {
    height: var(--height-tablet-lg) !important;
  }
}
@media screen and (min-width: 1024px) {
  .blte-carousel__card-image {
    height: var(--height-desktop) !important;
  }
}
.blte-carousel__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  padding-left: 12px;
}
.blte-carousel__header h2 {
  font-family: "Futura PT", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-carousel__header h2 {
    font-size: 30px;
    line-height: 44px;
  }
}
@media screen and (min-width: 768px) {
  .blte-carousel__header h2 {
    font-size: 32px;
    line-height: 44px;
  }
}
.blte-carousel__header > div {
  display: flex;
}
.blte-carousel__header > div .slick-next,
.blte-carousel__header > div .slick-prev {
  position: relative;
}
.blte-carousel__header > div .slick-prev {
  margin-left: 24px;
  margin-right: 16px;
}
.blte-carousel--variant-full .blte-carousel__slider-wrapper {
  height: var(--height-mobile);
  overflow: hidden;
}
@media screen and (min-width: 576px) {
  .blte-carousel--variant-full .blte-carousel__slider-wrapper {
    height: var(--height-tablet-sm);
  }
}
@media screen and (min-width: 768px) {
  .blte-carousel--variant-full .blte-carousel__slider-wrapper {
    height: var(--height-tablet-lg);
  }
}
@media screen and (min-width: 1024px) {
  .blte-carousel--variant-full .blte-carousel__slider-wrapper {
    height: var(--height-desktop);
  }
}
.blte-carousel--variant-full .slick-slider,
.blte-carousel--variant-full .slick-list,
.blte-carousel--variant-full .slick-track,
.blte-carousel--variant-full .slick-slide,
.blte-carousel--variant-full .slick-slide > div,
.blte-carousel--variant-full .blte-carousel__slider,
.blte-carousel--variant-full .blte-carousel__slide {
  height: 100%;
  width: 100%;
}
.blte-carousel--variant-full .blte-carousel__slide {
  flex: 1 0 auto;
}
.blte-carousel--variant-full .slick-prev {
  left: 8px;
}
@media screen and (min-width: 576px) {
  .blte-carousel--variant-full .slick-prev {
    left: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-carousel--variant-full .slick-prev {
    left: 40px;
  }
}
.blte-carousel--variant-full .slick-next {
  right: 8px;
}
@media screen and (min-width: 576px) {
  .blte-carousel--variant-full .slick-next {
    right: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-carousel--variant-full .slick-next {
    right: 40px;
  }
}
.blte-carousel--variant-full .blte-hero__text {
  padding: 40px 64px;
}
@media screen and (min-width: 576px) {
  .blte-carousel--variant-full .blte-hero__text {
    padding: 40px 96px;
  }
}
@media screen and (min-width: 768px) {
  .blte-carousel--variant-full .blte-hero__text {
    padding: 40px 80px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-carousel--variant-full .blte-hero__text {
    padding: 40px 152px;
  }
}
.blte-carousel--variant-cards {
  max-width: 1400px;
  margin: 0 auto;
  margin: 40px auto;
}
.blte-carousel--variant-cards .slick-track {
  display: flex;
}
.blte-carousel--variant-cards .blte-carousel__slider-wrapper {
  min-height: calc(var(--height-mobile) + 60px);
}
@media screen and (min-width: 576px) {
  .blte-carousel--variant-cards .blte-carousel__slider-wrapper {
    min-height: calc(var(--height-tablet-sm) + 60px);
  }
}
@media screen and (min-width: 768px) {
  .blte-carousel--variant-cards .blte-carousel__slider-wrapper {
    min-height: calc(var(--height-tablet-lg) + 60px);
  }
}
@media screen and (min-width: 1024px) {
  .blte-carousel--variant-cards .blte-carousel__slider-wrapper {
    min-height: calc(var(--height-desktop) + 60px);
  }
}
.blte-carousel--variant-cards .blte-carousel__slider .slick-prev,
.blte-carousel--variant-cards .blte-carousel__slider .slick-next {
  display: none !important;
}
.blte-carousel--variant-cards .blte-carousel__slide {
  padding: 0 12px;
}
.blte-carousel--variant-cards .blte-carousel__slide img,
.blte-carousel--variant-cards .blte-carousel__slide picture {
  width: 80vw;
  height: 106.6666666667vw;
  object-fit: cover;
}
@media screen and (min-width: 576px) {
  .blte-carousel--variant-cards .blte-carousel__slide img,
  .blte-carousel--variant-cards .blte-carousel__slide picture {
    width: 396px;
    height: 529px;
  }
}
.blte-carousel--variant-cards .blte-carousel__slide-title {
  font-family: "Futura PT", sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 26px;
  padding-top: 16px;
}
