@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-seat-legend-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.blte-seat-legend-item__color {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  border-radius: 2px;
}
@media screen and (min-width: 1024px) {
  .blte-seat-legend-item__color {
    margin-right: 6px;
  }
}
.blte-seat-legend-item__label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
.blte-seat-legend-item--color-yellow .blte-seat-legend-item__color {
  background-color: #ffdb00;
}
.blte-seat-legend-item--color-black .blte-seat-legend-item__color {
  background-color: #363636;
}
.blte-seat-legend-item--color-silver .blte-seat-legend-item__color {
  background-color: #d8d8d8;
}
.blte-seat-legend-item--color-maxBlue .blte-seat-legend-item__color {
  background-color: #44b7cc;
}
