@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-table__table-header-container {
  width: 100%;
  top: 0;
}
.blte-table__table-header-container--isStickyHeader {
  position: sticky;
  top: 0;
  z-index: 2;
}
.blte-table__table-header-container--isStickyHeader th:first-of-type {
  z-index: 3;
}
.blte-table__table-header-container--isStickyHeader tr:first-of-type th:first-of-type > div {
  box-shadow: -10px 0 0 0 #fff;
}
.blte-table__table-header-container--isStickyCol tr:first-of-type th:first-of-type > div {
  box-shadow: -10px 0 0 0 #fff;
}
.blte-table__table-header-container--modalVariant-default {
  top: -32px;
}
@media screen and (min-width: 768px) {
  .blte-table__table-header-container--modalVariant-default {
    top: -40px;
  }
}
.blte-table__table-header-container tr:first-of-type th > div {
  border-top: 1px solid #d9d9d9;
}
.blte-table__table-header-container tr:first-of-type th:first-of-type > div:not(.notBottomLeftElement) {
  border-left: 1px solid #d9d9d9;
  border-top-left-radius: 11px;
}
.blte-table__table-header-container tr:first-of-type th:last-of-type > div:not(.notBottomRightElement) {
  border-right: 1px solid #d9d9d9;
  border-top-right-radius: 11px;
}
.blte-table__table-header-container tr:last-of-type th > div {
  border-bottom: 1px solid #d9d9d9;
}
.blte-table__table-header-container table {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.blte-table__table-body-container {
  width: 100%;
}
.blte-table__table-body-container:not(.blte-table__table-body-container--isStickyCol) tr td > div.bottomLeftElement {
  box-shadow: none;
}
.blte-table__table-body-container--isStickyCol tr td:first-of-type > div:not(.notBottomLeftElement) {
  box-shadow: -10px 0 0 0 #fff;
}
.blte-table__table-body-container tr:first-of-type td > div {
  border-top: 0;
}
.blte-table__table-body-container tr:last-of-type td > div {
  border-bottom: 1px solid #d9d9d9;
}
.blte-table__table-body-container tr:last-of-type td:first-of-type > div:not(.notBottomLeftElement) {
  border-bottom-left-radius: 11px;
}
.blte-table__table-body-container tr:last-of-type td:last-of-type > div:not(.notBottomRightElement) {
  border-bottom-right-radius: 11px;
}
.blte-table__table-body-container tr td:first-of-type > div:not(.notBottomLeftElement) {
  border-left: 1px solid #d9d9d9;
}
.blte-table__table-body-container tr td:last-of-type > div:not(.notBottomRightElement) {
  border-right: 1px solid #d9d9d9;
}
.blte-table__table-body-container table {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.blte-table__table {
  border-collapse: collapse;
  border-style: hidden;
  background-color: #fff;
  width: 100%;
}
.blte-table__table td,
.blte-table__table th {
  border-style: hidden !important;
}
.blte-table__table td > div,
.blte-table__table th > div {
  border: 0.5px solid #d9d9d9;
}
.blte-table__table tr td:first-of-type > div,
.blte-table__table tr th:first-of-type > div {
  border-right: 1px solid #d9d9d9;
}
.blte-table__table tr td:nth-of-type(2) > div,
.blte-table__table tr th:nth-of-type(2) > div {
  border-left: 0;
}
.blte-table__row {
  height: 1px;
}
.blte-table__cell {
  padding: 0;
  height: 1px;
  font-size: 16px;
  line-height: 24px;
}
@-moz-document url-prefix() {
  .blte-table__cell {
    height: 100%;
  }
}
.blte-table__cell--isStickyCol {
  position: sticky;
  left: 0;
  z-index: 1;
}
.blte-table__cell--modalVariant-default {
  left: -24px;
}
@media screen and (min-width: 576px) {
  .blte-table__cell--modalVariant-default {
    left: -64px;
  }
}
@media screen and (min-width: 768px) {
  .blte-table__cell--modalVariant-default {
    left: -40px;
  }
}
.blte-table__cell--modalVariant-mini {
  left: -2px;
}
.blte-table__cell-header {
  font-weight: 500;
  letter-spacing: 4%;
}
.blte-table__cell-header > b,
.blte-table__cell-header th {
  font-weight: inherit;
  letter-spacing: inherit;
}
.blte-table__cell-first-column {
  font-weight: 450;
}
.blte-table__cell-first-column > b,
.blte-table__cell-first-column th {
  font-weight: inherit;
}
