@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-drawer__background {
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  pointer-events: none;
  position: fixed;
  inset: 0;
  z-index: 100;
}
.blte-drawer__content {
  background: #fff;
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 24px;
  max-height: 0;
  display: flex;
  flex-direction: column;
  transition: max-height 0.3s;
  overflow: hidden;
  border-radius: 24px 24px 0 0;
}
@media screen and (min-width: 576px) {
  .blte-drawer__content {
    padding: 0 64px;
  }
}
.blte-drawer__content--isBackgroundGrey {
  background-color: #f2f2f4;
}
.blte-drawer__header {
  height: 40px;
  flex: 1 0 auto;
  cursor: pointer;
}
.blte-drawer__body {
  flex: 1;
  overflow: auto;
  padding: 0 2px 40px;
  scrollbar-width: none;
}
.blte-drawer__body::-webkit-scrollbar {
  display: none;
}
.blte-drawer__handler {
  background: #a3a3a3;
  border-radius: 4px;
  width: 40px;
  height: 4px;
  left: calc(50% - 20px + 0.5px);
  top: 16px;
  position: absolute;
}
.blte-drawer--isVisible .blte-drawer__content {
  max-height: 90%;
}
.blte-drawer--isVisible .blte-drawer__content--hasButton {
  padding-bottom: 72px;
}
.blte-drawer--isVisible .blte-drawer__content--secondButtonProps {
  max-height: calc(90% + 30px);
}
.blte-drawer--isVisible .blte-drawer__content--isMobileRti {
  height: var(--drawewr-height) !important;
}
.blte-drawer--isVisible .blte-drawer__background {
  visibility: visible;
  pointer-events: all;
}
.blte-drawer__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 -5px 14px rgba(0, 0, 0, 0.1);
  padding: 16px 24px;
  align-items: center;
  justify-content: space-around;
  gap: 16px;
}
.blte-drawer--isVisible .blte-drawer__footer--hasSecondButton > button:first-child {
  margin-bottom: 8px;
}
.blte-drawer--isVisible .blte-drawer__footer--hasSecondButton > button:last-child {
  border: 2px solid;
  border-color: #ffdb00;
  background-color: #fff;
}
.blte-drawer__action {
  flex-grow: 1;
}
@media screen and (min-width: 768px) {
  .blte-drawer__action {
    flex-grow: 0;
  }
}
