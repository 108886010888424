@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-date-range-picker__mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.blte-date-range-picker__mobile-header {
  margin-bottom: 24px;
}
