@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-kiosk {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f4;
  position: relative;
}
.blte-kiosk .aem-GridColumn--desktop--10 {
  width: 100% !important;
  margin: 0 !important;
}
.blte-kiosk .aem-GridColumn--offset--desktop--1,
.blte-kiosk .aem-GridColumn--offset--default--1 {
  margin: 0 !important;
}
.blte-kiosk .aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--2 {
  width: 25% !important;
}
.blte-kiosk .aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--5 {
  width: 50% !important;
}
.blte-kiosk .blte-padding--horizontal-contained > * {
  max-width: 100% !important;
}
.blte-kiosk-page {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  overflow: auto;
}
.blte-kiosk-page--overlay {
  background-color: #363636;
  opacity: 0.5;
  pointer-events: none;
}
