@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rti-trip-details {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  margin-top: 16px;
}
