@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-manage-passengers {
  padding: 20px;
}
.blte-manage-passengers__buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.blte-manage-passengers__buttons > :first-child {
  margin-right: 12px;
  background-color: #e1e1e1;
}
