@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-checkbox-group {
  display: flex;
  flex-flow: column wrap;
  max-width: 440px;
  padding: 8px 0;
  outline: none;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-checkbox-group {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 1024px) {
  .blte-checkbox-group {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 1024px) and (min-width: 576px) {
  .blte-checkbox-group {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-checkbox-group__title-container {
  padding: 8px 0;
  display: flex;
  align-items: center;
}
.blte-checkbox-group__title {
  margin-right: 16px;
  max-width: calc(100% - 36px);
}
.blte-checkbox-group__rest {
  transition:
    opacity 0.3s,
    max-height 0.3s,
    padding-left 0.3s,
    padding-top 0.3s,
    padding-bottom 0.3s;
  opacity: 1;
  max-height: 500px;
}
.blte-checkbox-group__hidden {
  overflow: hidden;
  transition:
    opacity 0.3s,
    max-height 0.3s,
    padding-left 0.3s,
    padding-top 0.3s,
    padding-bottom 0.3s;
  padding: 0;
  opacity: 0;
  max-height: 0;
}
.blte-checkbox-group__show_more {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #094273;
  text-decoration: underline;
  cursor: pointer;
}
.blte-checkbox-group__error {
  color: #c00;
  flex-basis: 100%;
}
.blte-checkbox-group--is-disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.blte-checkbox-group--is-disabled .blte-checkbox-group__show_more {
  color: #e1e1e1;
}
.blte-checkbox-group--is-disabled .blte-checkbox-group__error {
  display: none;
}
