@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-trip-item {
  background-color: #fff;
  border-radius: 8px;
}
.blte-account-trip-item:not(:last-child) {
  margin-bottom: 40px;
}
.blte-account-trip-item__direction {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.blte-account-trip-item__from {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
  letter-spacing: 0;
  margin-right: 8px;
}
@media screen and (min-width: 576px) {
  .blte-account-trip-item__from {
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-account-trip-item__from {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-account-trip-item__to {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
  letter-spacing: 0;
  margin-left: 8px;
}
@media screen and (min-width: 576px) {
  .blte-account-trip-item__to {
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-account-trip-item__to {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-account-trip-item__direction-icon--type-arrow-double::after {
  font-size: 28px;
}
.blte-account-trip-item__direction-icon--type-arrow-from-to::after {
  font-size: 28px;
}
.blte-account-trip-item__content {
  padding: 16px 16px 24px;
}
@media screen and (min-width: 576px) {
  .blte-account-trip-item__content {
    padding: 24px;
  }
}
.blte-account-trip-item__routes {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 0 0;
  flex-direction: column;
  align-items: stretch;
  padding: 16px 0 0;
}
@media screen and (min-width: 768px) {
  .blte-account-trip-item__routes {
    flex-direction: row;
    padding: 24px 0 0;
  }
}
.blte-account-trip-item__footer {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  align-items: stretch;
}
@media screen and (min-width: 576px) {
  .blte-account-trip-item__footer {
    padding: 24px;
  }
}
@media screen and (min-width: 768px) {
  .blte-account-trip-item__footer {
    flex-direction: row;
    align-items: center;
  }
}
.blte-account-trip-item__actions {
  display: flex;
  align-items: center;
  align-self: flex-end;
}
.blte-account-trip-item__action + .blte-account-trip-item__action {
  margin-left: 16px;
}
