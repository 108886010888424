@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rewards-tracker {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-image: var(--rewards-background-image);
  background-color: #f9f9f9;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
}
.blte-rewards-tracker--placeholder {
  background-color: #fff;
}
.blte-rewards-tracker__progress-bar {
  position: relative;
}
.blte-rewards-tracker__points-container {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.blte-rewards-tracker__spendable-points {
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  color: #363636;
}
.blte-rewards-tracker__spendable-label {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
  color: var(--available-points-label-color, #c669f6);
}
.blte-rewards-tracker__pending-points {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  text-align: center;
  color: var(--pending-points-color, #4297ba);
}
.blte-rewards-tracker__pending-label {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;
  color: var(--pending-points-label-color, #8d8d8d);
}
.blte-rewards-tracker__expiry-date,
.blte-rewards-tracker__bottom-right-rows {
  position: absolute;
  bottom: 8px;
  right: 8px;
}
@media screen and (min-width: 768px) {
  .blte-rewards-tracker__expiry-date,
  .blte-rewards-tracker__bottom-right-rows {
    bottom: 16px;
    right: 16px;
  }
}
.blte-rewards-tracker__info-text {
  display: flex;
  gap: 4px;
  white-space: nowrap;
}
.blte-rewards-tracker__label {
  color: #8d8d8d;
}
.blte-rewards-tracker__progress-bar-rows {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.blte-rewards-tracker__progress-bar-row {
  height: 18px;
  width: 60px;
  border-radius: 2px;
  background: #f2f2f4;
  position: relative;
  overflow: hidden;
}
.blte-rewards-tracker__progress-bar-row::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #f2f2f4, #fff, #f2f2f4);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-rewards-tracker__bottom-right-rows {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.blte-rewards-tracker__bottom-right-row {
  height: 18px;
  width: 80px;
  border-radius: 2px;
  background: #f2f2f4;
  position: relative;
  overflow: hidden;
}
.blte-rewards-tracker__bottom-right-row::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #f2f2f4, #fff, #f2f2f4);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
