@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-train-flag {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  width: fit-content;
  padding: 0 6px;
  border-radius: 4px;
  color: var(--special-train-flag-text-color, #fff);
  background-color: var(--special-train-flag-background-color, #094273);
}
.blte-train-flag--type-informative {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  color: var(--informative-train-flag-text-color, #fff);
  background-color: var(--informative-train-flag-background-color, #094273);
}
.blte-train-flag--type-trend,
.blte-train-flag--type-warning {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-train-flag--type-trend {
  font-style: italic;
  color: var(--trend-train-flag-text-color, #2e6a6e);
  background-color: var(--warining-trend-flag-background-color, none);
}
.blte-train-flag--type-warning {
  color: var(--warning-train-flag-text-color, #c00);
  background-color: var(--warining-train-flag-background-color, none);
}
