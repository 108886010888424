@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-train-status__description {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  margin-top: 0;
  color: #094273;
}
.blte-train-status__buttons {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1024px) {
  .blte-train-status__buttons {
    flex-direction: row;
  }
}
.blte-train-status__button {
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  width: 60%;
  font-size: 18px;
  justify-content: space-between;
}
@media screen and (min-width: 1024px) {
  .blte-train-status__button {
    width: unset;
  }
}
.blte-train-status__button:first-of-type {
  margin-bottom: 10px;
}
@media screen and (min-width: 1024px) {
  .blte-train-status__button:first-of-type {
    margin-right: 15px;
    margin-bottom: 0;
  }
}
.blte-train-status__button .blte-icon {
  font-size: 20px;
  display: flex;
  align-items: center;
}
