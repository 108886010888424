@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-events-browser-filters-desktop__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
}
.blte-events-browser-filters-desktop__filters-wrapper {
  display: flex;
  align-items: center;
  column-gap: 16px;
  row-gap: 30px;
}
.blte-events-browser-filters-desktop__filter-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 4px;
  width: 85px;
  height: 85px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #fff;
}
.blte-events-browser-filters-desktop__filter-item:hover {
  cursor: pointer;
}
.blte-events-browser-filters-desktop__filter-item--selected {
  background-color: #def0ff;
  border: 1px solid #094273;
}
@media (max-width: 1053px) and (min-width: 576px) {
  .blte-events-browser-filters-desktop__filter-item {
    width: 80px;
    height: 80px;
  }
}
.blte-events-browser-filters-desktop__slick-prev {
  display: inline-flex;
  -webkit-tap-highlight-color: transparent;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
}
.blte-events-browser-filters-desktop__slick-prev:active {
  outline: none;
  background-color: #def0ff;
  border: 1px solid #094273;
}
.blte-events-browser-filters-desktop__chevron-icon:hover,
.blte-events-browser-filters-desktop__chevron-icon:active,
.blte-events-browser-filters-desktop__chevron-icon:focus {
  outline: none;
}
.blte-events-browser-filters-desktop__slick-next {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.blte-events-browser-filters-desktop__slick-next:active {
  outline: none;
  background-color: #def0ff;
  border: 1px solid #094273;
}
.blte-events-browser-filters-desktop__filter-icon {
  width: 28px;
  height: 28px;
  font-size: 24px;
  line-height: 24px;
}
.blte-events-browser-filters-desktop__icons {
  width: 30px;
  height: 30px;
}
.blte-events-browser-filters-desktop__filter-label {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-events-browser-filters-desktop__filter-label--selected {
  color: #094273;
}
.blte-events-browser-filters-desktop__city-filter {
  display: flex;
  align-items: center;
  height: 72px;
  justify-content: center;
  padding: 24px 8px 24px 16px;
  border-radius: 100px;
  gap: 8px;
  border: 1px solid #ddd;
  background-color: #fff;
}
.blte-events-browser-filters-desktop__city-filter:hover {
  cursor: pointer;
}
.blte-events-browser-filters-desktop__city-filter--selected {
  background-color: #def0ff;
}
.blte-events-browser-filters-desktop__city-filter-label {
  display: flex;
  align-items: center;
  gap: 4px;
}
.blte-events-browser-filters-desktop__selected-city-filters {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  row-gap: 16px;
  flex-wrap: wrap;
}
.blte-events-browser-filters-desktop__selected-city-pill {
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: 40px;
  padding: 8px 12px;
  border-radius: 50px;
  border: 1px solid #094273;
  background-color: #def0ff;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #094273;
}
@media screen and (min-width: 576px) {
  .blte-events-browser-filters-desktop__selected-city-pill {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-events-browser-filters-desktop__selected-city-pill-icon {
  color: #094273;
  font-size: 12px;
}
.blte-events-browser-filters-desktop__selected-city-pill-icon:hover {
  cursor: pointer;
}
.blte-events-browser-filters-desktop__city-popover-wrapper {
  display: none;
  max-width: 100vw;
  overflow-x: auto;
  flex-direction: column;
  gap: 42px;
  padding: 32px 16px 16px 32px;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2509803922);
}
@media screen and (min-width: 576px) {
  .blte-events-browser-filters-desktop__city-popover-wrapper {
    display: flex;
  }
}
.blte-events-browser-filters-desktop__stations-with-train-wrapper {
  padding-right: 16px;
  position: relative;
}
.blte-events-browser-filters-desktop__stations-wrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-right: 70px;
}
.blte-events-browser-filters-desktop__station {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 8px;
  text-align: center;
  width: 70px;
  z-index: 10;
}
.blte-events-browser-filters-desktop__station:hover {
  cursor: pointer;
}
.blte-events-browser-filters-desktop__station-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid #e1e1e1;
  background-color: #fff;
}
.blte-events-browser-filters-desktop__station-icon--selected {
  background-color: #def0ff;
}
.blte-events-browser-filters-desktop__line {
  width: calc(100% - 66px);
  min-height: 1px;
  position: absolute;
  top: 40px;
  left: 35px;
  border-bottom: 2px solid #000;
}
.blte-events-browser-filters-desktop__train-icon {
  position: absolute;
  top: 22px;
  right: 16px;
}
.blte-events-browser-filters-desktop__city-popover-buttons {
  display: flex;
  justify-content: end;
  gap: 8px;
}
