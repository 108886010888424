@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-display-trains__separator {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 24px;
}
.blte-display-trains__separator > div {
  position: relative;
  display: inline-block;
}
.blte-display-trains__separator > div::before,
.blte-display-trains__separator > div::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #d1d1d6;
}
.blte-display-trains__separator > div::before {
  right: 100%;
  margin-right: 15px;
}
.blte-display-trains__separator > div::after {
  left: 100%;
  margin-left: 15px;
}
.blte-display-trains__separator > div > span {
  display: block;
}
.blte-display-trains__separator > div > span:first-child {
  color: #636366;
}
.blte-display-trains__separator > div > span:last-child {
  font-size: 19px;
  color: #363636;
  font-weight: 700;
}
.blte-display-trains__view-next-button {
  float: right;
  margin: 24px 0;
  background-color: white !important;
}
