@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passenger-selection {
  margin-right: 24px;
  position: relative;
  display: inline-block;
}
@media (max-width: 1132px) {
  .blte-passenger-selection {
    margin-right: 2px;
  }
}
.blte-passenger-selection > button {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  color: #363636;
  transition: all 0.3s;
  white-space: nowrap;
}
.blte-passenger-selection > button:hover {
  background: #f2f2f4;
}
.blte-passenger-selection > button:focus-visible {
  box-shadow: 0 0 0 2px #363636;
}
.blte-passenger-selection__profile-icon {
  margin-right: 8px;
  font-size: 24px;
}
.blte-passenger-selection__arrow-down-icon {
  margin-left: 8px;
  font-size: 21px;
}
.blte-passenger-selection__body {
  padding-top: 8px;
  flex-direction: column;
  overflow-x: hidden;
  list-style: none;
  margin: 0;
}
.blte-passenger-selection__body .blte-divider--alignment-horizontal {
  margin: 0;
  margin: 16px 0;
}
.blte-passenger-selection .blte-passenger-selection__body {
  position: absolute;
  background: #fff;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  top: 48px;
  border-radius: 16px;
  padding: 16px;
  width: 256px;
}
.blte-passenger-selection .blte-passenger-selection__body--position-right {
  right: 0;
}
.blte-passenger-selection .blte-passenger-selection__body--position-left {
  left: 0;
}
.blte-passenger-selection--isOpen > button {
  background: #f2f2f4;
}
.blte-passenger-selection--isOpen .blte-passenger-selection__body {
  z-index: 100;
  display: flex;
}
.blte-passenger-selection__item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.blte-passenger-selection__item-wheelchair {
  display: flex;
  justify-content: space-between;
}
.blte-passenger-selection__item-wheelchair .blte-passenger-selection__title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  margin-left: 8px;
  margin-right: 16px;
  color: #565656;
}
.blte-passenger-selection__item-wheelchair .blte-passenger-selection__right button {
  background-color: #094273;
}
.blte-passenger-selection__item-wheelchair-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 16px;
}
.blte-passenger-selection__left {
  flex: 1 0 auto;
}
.blte-passenger-selection__right {
  display: flex;
  align-items: center;
}
.blte-passenger-selection__right span {
  width: 20px;
  margin: 0 8px;
  text-align: center;
}
.blte-passenger-selection__right button {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background: #094273;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}
.blte-passenger-selection__right button::before {
  height: 2px;
  width: 12px;
  background: white;
  content: "";
}
.blte-passenger-selection__right button:last-child::after {
  content: "";
  height: 12px;
  width: 2px;
  background: white;
  position: absolute;
}
.blte-passenger-selection__right button:focus-visible {
  box-shadow: 0 0 0 1px white, 0 0 0 3px #363636;
}
.blte-passenger-selection__right button:disabled {
  box-shadow: none;
  background: #f2f2f4;
  cursor: auto;
}
.blte-passenger-selection__right button:disabled::before,
.blte-passenger-selection__right button:disabled::after {
  background: #363636;
}
.blte-passenger-selection__title {
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-passenger-selection__title {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-passenger-selection__description {
  font-size: 12px;
  font-weight: 450;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
}
.blte-passenger-selection__footer {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.blte-passenger-selection__message {
  font-size: 12px;
  font-weight: 450;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  color: #094273;
  padding-right: 32px;
}
.blte-passenger-selection__passenger-selection {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}
.blockScroll .blte-passenger-selection__body {
  display: inline;
  max-height: 500px;
  overflow-y: auto;
}
