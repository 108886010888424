@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-content-header {
  display: flex;
  align-items: center;
  color: #363636;
  margin-bottom: 24px;
}
@media screen and (min-width: 768px) {
  .blte-content-header {
    margin-bottom: 40px;
  }
}
.blte-content-header__title {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
  text-transform: none;
  margin: 0 auto;
}
@media screen and (min-width: 576px) {
  .blte-content-header__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-content-header__title {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-content-header__title {
    margin: 0;
  }
}
.blte-content-header__content {
  width: 100%;
  display: flex;
  align-items: center;
}
.blte-content-header__back {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  font-size: 38px;
}
@media screen and (min-width: 768px) {
  .blte-content-header__back {
    display: none;
  }
}
.blte-content-header__actions {
  display: flex;
}
@media screen and (min-width: 768px) {
  .blte-content-header__actions {
    display: none;
  }
}
.blte-content-header__placeholders .blte-content-header__content {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 200px;
  height: 36px;
}
.blte-content-header__placeholders .blte-content-header__content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
@media screen and (min-width: 576px) {
  .blte-content-header__placeholders .blte-content-header__content {
    width: 246px;
    height: 44px;
  }
}
@media screen and (min-width: 768px) {
  .blte-content-header__placeholders .blte-content-header__content {
    margin: 0;
    width: 277px;
    height: 44px;
  }
}
.blte-content-header__placeholders .blte-content-header__back {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  width: 26px;
  height: 44px;
  margin-left: 0;
}
.blte-content-header__placeholders .blte-content-header__back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
@media screen and (min-width: 768px) {
  .blte-content-header__placeholders .blte-content-header__back {
    display: none;
  }
}
