@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-train-status-messages {
  display: flex;
  align-items: center;
  padding: 8px;
  padding-left: 12px;
  border-radius: 8px;
  justify-content: space-between;
  margin-bottom: 10px;
  color: var(--alert-description-color, #363636);
}
.blte-train-status-messages__content {
  display: flex;
  gap: 10px;
}
.blte-train-status-messages__button {
  background-color: var(--alert-background-color, #ffdb00);
  border: none;
}
.blte-train-status-messages__description {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-train-status-messages__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-train-status-messages__icon {
  margin-right: 8px;
  margin-top: 2px;
  display: flex;
}
.blte-train-status-messages__message-icon {
  height: 22px;
  width: 22px;
  margin: auto;
}
.blte-train-status-messages--variant-delayed {
  background-color: #fffbe6 !important;
}
.blte-train-status-messages--variant-delayed .blte-train-status-messages__button {
  background-color: #fffbe6 !important;
  margin: 0;
}
.blte-train-status-messages--variant-updated {
  background-color: #fffbe6 !important;
}
.blte-train-status-messages--variant-updated .blte-train-status-messages__button {
  background-color: #fffbe6 !important;
  margin: 0;
}
.blte-train-status-messages--variant-cancelled {
  background-color: #ffe5e5 !important;
}
.blte-train-status-messages--variant-cancelled .blte-train-status-messages__button {
  background-color: #ffe5e5 !important;
  margin: 0;
}
