@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-privacy-request-form-content {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  width: 100%;
}
.blte-privacy-request-form-content__button-row {
  display: flex;
  justify-content: center;
}
.blte-privacy-request-form-content__phone-number-input .blte-input__fieldset {
  width: 100%;
  margin-left: 16px;
}
