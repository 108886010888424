@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-train-passes-option__text-icon-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  justify-content: center;
}
@media screen and (min-width: 1024px) {
  .blte-train-passes-option__text-icon-container {
    justify-content: flex-start;
  }
}
.blte-train-passes-option__pass-title {
  font-size: 18px;
  font-weight: 500;
  margin-top: 16px;
}
.blte-train-passes-option__info-icon {
  display: inline-block;
  vertical-align: text-bottom;
}
