@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-sweepstakes-form__body {
  padding: 20px 50px;
  background-color: #e8f2f1;
  padding: 20px 30px;
}
@media screen and (min-width: 768px) {
  .blte-sweepstakes-form__body {
    padding: 20px 40px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-sweepstakes-form__body {
    padding: 20px 50px;
  }
}
.blte-sweepstakes-form__image {
  height: 450px;
  max-height: 330px;
}
@media screen and (min-width: 768px) {
  .blte-sweepstakes-form__image {
    max-height: 380px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-sweepstakes-form__image {
    max-height: 430px;
  }
}
.blte-sweepstakes-form__form-titles {
  text-align: center;
  padding: 20px;
}
@media screen and (min-width: 1024px) {
  .blte-sweepstakes-form__form-titles {
    padding: 20px 50px;
  }
}
.blte-sweepstakes-form__title {
  font-size: 30px;
  font-weight: 500;
  line-height: 30px;
}
.blte-sweepstakes-form__subtitle {
  font-size: 20px;
  font-weight: 450;
  line-height: 26px;
  padding-top: 10px;
}
