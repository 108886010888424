@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passes-card-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 16px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  width: 100%;
}
.blte-passes-card-item--placeholder {
  border: 0;
}
.blte-passes-card-item--savedCard {
  background: rgba(242, 242, 244, 0.4);
}
.blte-passes-card-item--disabled {
  background: rgba(242, 242, 244, 0.4);
}
.blte-passes-card-item--selected {
  border: 1px solid #094273;
}
.blte-passes-card-item :hover {
  cursor: pointer;
}
.blte-passes-card-item__card-image {
  width: 38px;
  height: 24px;
}
.blte-passes-card-item__card-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.blte-passes-card-item__card-details-text {
  width: 100%;
  font-weight: 450;
  font-size: 18px;
  line-height: 26px;
  color: #363636;
}
.blte-passes-card-item__card-details-text--disabled {
  font-size: 17px;
  color: #8d8d8d;
}
.blte-passes-card-item__card-details-checkbox--disabled > input {
  border-color: rgba(118, 118, 118, 0.3);
}
.blte-passes-card-item__card-add {
  width: 48px;
  height: 32px;
  background-color: #e1e1e1;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a3a3a3;
  margin-right: 16px;
  font-size: 16px;
}
