@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-loyalty-optin-modal__loyalty-optin {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.blte-loyalty-optin-modal__loyalty {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.blte-loyalty-optin-modal__loyalty-modal-title {
  width: 100%;
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  margin-bottom: 8px;
}
.blte-loyalty-optin-modal__loyalty-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
  margin: 8px 0;
}
.blte-loyalty-optin-modal__loyalty-divider {
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
}
.blte-loyalty-optin-modal__loyalty-title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-loyalty-optin-modal__loyalty-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-loyalty-optin-modal__loyalty-benefits {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: var(--loyalty-benefits-color);
  padding: 16px;
  border-radius: 8px;
  width: 100%;
}
.blte-loyalty-optin-modal__loyalty-benefit {
  display: flex;
  gap: 8px;
  width: 100%;
}
.blte-loyalty-optin-modal__loyalty-benefit-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: #137e00;
}
.blte-loyalty-optin-modal__loyalty-benefit-body {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.blte-loyalty-optin-modal__loyalty-benefit-title {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-loyalty-optin-modal__loyalty-benefit-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-loyalty-optin-modal__loyalty-tos {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-loyalty-optin-modal__loyalty-tos {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-loyalty-optin-modal__loyalty-tos a {
  color: #2e6a6e;
}
.blte-loyalty-optin-modal__loyalty-decline {
  margin: 0 auto;
}
.blte-loyalty-optin-modal__error {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #c00;
}
.blte-loyalty-optin-modal__welcome-loyalty {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.blte-loyalty-optin-modal__welcome-loyalty-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.blte-loyalty-optin-modal__welcome-loyalty-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
}
.blte-loyalty-optin-modal__welcome-loyalty-subtitle {
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
.blte-loyalty-optin-modal__welcome-loyalty-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
}
