@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-img-btn {
  display: flex;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.blte-img-btn--image {
  height: 40px;
  width: max-content;
  border-radius: 4px;
  padding: 2px;
  margin-top: -2px;
  margin-left: -2px;
  box-sizing: content-box;
}
.blte-img-btn--image .blte-img-btn__img {
  width: auto;
  height: 40px;
}
.blte-img-btn--image:focus:not(:focus-visible) {
  outline: none;
}
.blte-img-btn--image:focus-visible:not(.blte-img-btn--disabled) {
  outline: none;
  box-shadow: 0 0 0 2px var(--border-focus-color, #363636);
}
.blte-img-btn--social {
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid var(--border-color, #565656);
  color: var(--icon-color, #565656);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.blte-img-btn--social:hover {
  border: 1px solid var(--border-hover-color, #094273);
  color: var(--icon-hover-color, #094273);
}
.blte-img-btn--social:focus:not(:focus-visible) {
  outline: none;
}
.blte-img-btn--social:focus-visible:not(.blte-img-btn--disabled) {
  border: none;
  box-shadow: 0 0 0 2px var(--border-focus-color, #363636);
  color: var(--icon-focus-color, #363636);
}
.blte-img-btn--disabled {
  outline: none;
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}
.blte-img-btn--disabled:not(.blte-img-btn--image) {
  border: 1px solid var(--border-disabled-color, #e1e1e1);
  color: var(--icon-disabled-color, #e1e1e1);
}
.blte-img-btn--imageSocialMedia {
  height: 32px !important;
}
.blte-img-btn--socialMedia {
  padding: 0;
  margin: 0;
  height: 32px;
}
