@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-navigation__avatar {
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  width: 54px;
  height: 54px;
  flex: 0 0 auto;
  background: #363636;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-account-navigation__avatar {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-account-navigation__avatar {
    width: 48px;
    height: 48px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-account-navigation__avatar {
    width: 54px;
    height: 54px;
  }
}
.blte-account-navigation__name {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0;
  text-transform: none;
  margin-left: 12px;
  color: #363636;
  word-break: break-word;
  display: flex;
}
.blte-account-navigation__profile {
  display: flex;
  align-items: center;
  padding: 8px 24px 16px;
  background: #fff;
}
@media screen and (min-width: 576px) {
  .blte-account-navigation__profile {
    padding: 24px 64px 16px;
  }
}
@media screen and (min-width: 768px) {
  .blte-account-navigation__profile {
    padding: 0 8px;
  }
}
.blte-account-navigation__profile--placeholders .blte-account-navigation__avatar {
  background: #f2f2f4;
  position: relative;
  overflow: hidden;
}
.blte-account-navigation__profile--placeholders .blte-account-navigation__avatar::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #f2f2f4, #fff, #f2f2f4);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-account-navigation__profile--placeholders .blte-account-navigation__name {
  background: #f2f2f4;
  position: relative;
  overflow: hidden;
  font-size: 0;
  width: 70%;
  height: 24px;
  border-radius: 2px;
}
.blte-account-navigation__profile--placeholders .blte-account-navigation__name::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #f2f2f4, #fff, #f2f2f4);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
