@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-events-date-range-picker__mobile {
  display: flex;
  width: 100%;
}
.blte-events-date-range-picker__mobile-header {
  margin-bottom: 24px;
}
.blte-events-mobile-calendar__active-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 10px;
}
.blte-events-mobile-calendar__active-button {
  background: none;
  color: black;
  border: 1px solid #ddd;
  padding: 6px 16px;
  border-radius: 50px;
  font-weight: 450;
  cursor: pointer;
  width: calc(33.333% - 10px);
  margin-bottom: 10px;
}
@media (max-width: 430px) {
  .blte-events-mobile-calendar__active-button {
    padding: 0;
  }
}
.blte-events-mobile-calendar__active-button--isActive {
  border-color: #094273;
  color: #094273;
  background-color: #def0ff;
}
