@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-details {
  background: #f2f2f4;
}
.blte-trip-details__kiosk {
  width: 1160px;
  margin: 0 auto;
  padding: 60px 0;
}
