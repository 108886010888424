@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passes-payment-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.blte-passes-payment-form .payment-container {
  padding: 16px 0;
}
.blte-passes-payment-form__form {
  width: 100%;
}
.blte-passes-payment-form__form .blte-checkbox {
  padding: 0;
  margin-top: 24px;
}
.blte-passes-payment-form__row {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 576px) {
  .blte-passes-payment-form__row {
    flex-direction: row;
  }
}
.blte-passes-payment-form__row--lastCardRow {
  flex-direction: row;
}
.blte-passes-payment-form__row--lastCardRow .blte-passes-payment-form__input {
  margin-bottom: 0;
}
.blte-passes-payment-form__row--lastCardRow .blte-passes-payment-form__input:nth-child(2) {
  margin-left: 16px;
}
.blte-passes-payment-form__input {
  width: 100%;
  margin-bottom: 16px;
}
@media screen and (min-width: 576px) {
  .blte-passes-payment-form__input:nth-child(2) {
    margin-left: 12px;
  }
}
@media screen and (min-width: 768px) {
  .blte-passes-payment-form__input--lastRow {
    margin-bottom: 0;
  }
}
.blte-passes-payment-form__sections-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
}
.blte-passes-payment-form__section:last-child {
  margin-top: 24px;
}
.blte-passes-payment-form__section-header {
  margin-bottom: 16px;
}
.blte-passes-payment-form__section-header--cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 0;
}
.blte-passes-payment-form__section-header-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-passes-payment-form__section-header-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-passes-payment-form__cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.blte-passes-payment-form__card-image {
  max-width: 36px;
}
.blte-passes-payment-form__saved-cards {
  display: flex;
  width: 100%;
}
.blte-passes-payment-form__saved-cards > div {
  display: flex;
  width: 100%;
}
.blte-passes-payment-form__separator {
  width: 100%;
  height: 1px;
  background: #e1e1e1;
}
.blte-passes-payment-form__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px 0;
  gap: 16px;
  border-radius: 8px;
}
.blte-passes-payment-form__tos {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.blte-passes-payment-form__tos-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #363636;
}
