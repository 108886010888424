@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-kiosk-error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.blte-kiosk-error-page__exclamation {
  margin-top: 200px;
  width: 25px;
  height: 25px;
  background: #c00;
  border-radius: 50%;
  color: #fff;
  font-size: 22px;
  line-height: 25px;
}
.blte-kiosk-error-page__title {
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  margin-top: 8px;
}
.blte-kiosk-error-page__description {
  font-size: 20px;
  font-weight: 450;
  line-height: 28px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
  margin-top: 4px;
}
.blte-kiosk-error-page__button {
  margin-top: 32px;
}
