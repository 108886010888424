@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-seat-selection-map {
  display: flex;
  position: relative;
}
.blte-seat-selection-map__map {
  position: relative;
  background-image: var(--seat-map-background-image);
  width: var(--seat-map-width);
  height: var(--seat-map-height);
  background-repeat: no-repeat;
  background-size: contain;
}
