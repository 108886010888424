@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trips-list-past__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 0;
  margin-bottom: 24px;
}
.blte-trips-list-past__header:not(:first-child) {
  margin-top: 164px;
}
.blte-trips-list-past__title {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
  letter-spacing: 0;
}
@media screen and (min-width: 576px) {
  .blte-trips-list-past__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-trips-list-past__title {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-trips-list-past__title--variant-long {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-trips-list-past__title--variant-long {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-trips-list-past__title--variant-long {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-trips-list-past__see-all-past {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-trips-list-past__paging {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}
.blte-trips-list-past__paging-text {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
}
.blte-trips-list-past__list {
  margin-bottom: 24px;
}
.blte-trips-list-past__placeholders-title--variant-long {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 30px;
  border-radius: 2px;
  margin-bottom: 24px;
}
.blte-trips-list-past__placeholders-title--variant-long::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
@media screen and (min-width: 576px) {
  .blte-trips-list-past__placeholders-title--variant-long {
    width: 240px;
    height: 38px;
  }
}
.blte-trips-list-past__placeholders-title--variant-short {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 28px;
  border-radius: 2px;
  margin-bottom: 24px;
}
.blte-trips-list-past__placeholders-title--variant-short::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
@media screen and (min-width: 576px) {
  .blte-trips-list-past__placeholders-title--variant-short {
    width: 130px;
    height: 28px;
  }
}
@media screen and (min-width: 768px) {
  .blte-trips-list-past__placeholders-title--variant-short {
    width: 200px;
    height: 28px;
  }
}
.blte-trips-list-past__placeholders-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background: #fff;
  border-radius: 8px;
  width: 100%;
}
.blte-trips-list-past__placeholders-item + .blte-trips-list-past__placeholders-item {
  margin-top: 16px;
}
.blte-trips-list-past__placeholders-item > div:first-child {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 24px;
  margin-bottom: 8px;
}
.blte-trips-list-past__placeholders-item > div:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
@media screen and (min-width: 768px) {
  .blte-trips-list-past__placeholders-item > div:first-child {
    width: 397px;
    height: 24px;
  }
}
.blte-trips-list-past__placeholders-item > div:nth-child(2) {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  width: 80px;
  height: 16px;
}
.blte-trips-list-past__placeholders-item > div:nth-child(2)::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
@media screen and (min-width: 576px) {
  .blte-trips-list-past__placeholders-item > div:nth-child(2) {
    width: 250px;
    height: 16px;
  }
}
