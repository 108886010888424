@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-tooltip {
  display: inline-flex;
  width: fit-content;
  outline: none;
}
.blte-tooltip__popover {
  margin-bottom: 8px;
  fill: var(--tooltip-info-background-color, #fff);
}
.blte-tooltip__content {
  width: 20px;
  height: 20px;
  color: var(--tooltip-content-text-color, #fff);
  background-color: var(--tooltip-content-background-color, #fff);
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
  gap: 10px;
  outline: none;
}
.blte-tooltip__info {
  transition: all 0.3s ease-in-out;
  max-width: 320px;
  font-size: 14px;
  border-radius: 4px;
  color: var(--tooltip-info-text-color, #363636);
  background: var(--tooltip-info-background-color, #fff);
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.18));
  padding: 8px;
}
.blte-tooltip__arrow {
  z-index: 1;
  filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.18));
}
.blte-tooltip:focus-visible {
  outline: none;
}
.blte-tooltip:focus-visible::before {
  content: "";
  position: absolute;
  inset: -2px;
  box-shadow: 0 0 0 2px var(--tooltip-content-border-focus-color, #363636);
  border-radius: 18px;
}
