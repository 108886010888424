@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-icon-label {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 0;
  min-height: 24px;
  outline: none;
  text-decoration: none;
  color: var(--text-color, #363636);
  border-radius: 2px;
}
.blte-icon-label:hover {
  color: var(--text-hover-color, #363636);
}
.blte-icon-label:hover .blte-icon-label__label {
  text-decoration: underline;
}
.blte-icon-label:focus-visible {
  color: var(--text-focus-color, #363636);
  box-shadow: 0 0 0 2px var(--border-focus-color, #363636), 0 0 6px rgba(0, 0, 0, 0.25);
}
.blte-icon-label--content-position-left {
  display: flex;
  justify-content: left;
}
.blte-icon-label--content-position-right {
  display: flex;
  justify-content: right;
}
.blte-icon-label--content-position-center {
  display: flex;
  justify-content: center;
}
.blte-icon-label--position-right {
  flex-direction: row-reverse;
}
.blte-icon-label--position-right .blte-icon-label__icon {
  margin-left: 8px;
  margin-right: 0;
}
.blte-icon-label__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 24px;
  margin-right: 8px;
}
.blte-icon-label__label {
  max-width: 392px;
}
