@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-menu-item {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  background-color: var(--menu-item-background-color, #e8f2f1);
  display: flex;
  list-style: none;
  transition: all 0.3s ease-in-out;
  outline: none;
  align-items: center;
}
.blte-menu-item__link {
  width: 100%;
  display: flex;
  padding: 8px 16px;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  border: none;
}
.blte-menu-item__link:focus-visible {
  border-radius: 8px;
}
.blte-menu-item__button {
  display: flex;
  padding: 8px 16px;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  border: none;
  outline: none;
}
.blte-menu-item__button:focus-visible {
  border-radius: 8px;
}
.blte-menu-item__link,
.blte-menu-item__button {
  background-color: var(--menu-item-background-color, #fff);
}
.blte-menu-item__link:hover,
.blte-menu-item__button:hover {
  background-color: var(--menu-item-background-hover-color, #e8f2f1);
}
.blte-menu-item__link:focus-visible,
.blte-menu-item__button:focus-visible {
  background-color: var(--menu-item-background-focus-color, #fff);
}
.blte-menu-item__link:focus-visible .blte-menu-item__label,
.blte-menu-item__button:focus-visible .blte-menu-item__label {
  color: var(--menu-item-text-focus-color, #363636);
}
.blte-menu-item__label {
  color: var(--menu-item-text-color, #363636);
  text-align: left;
}
.blte-menu-item:hover .blte-menu-item__label {
  color: var(--menu-item-text-hover-color, #094273);
}
.blte-menu-item--active.blte-menu-item__button,
.blte-menu-item--active .blte-menu-item__link {
  background-color: var(--menu-item-background-active-color, #e8f2f1);
}
.blte-menu-item--active .blte-menu-item__label {
  color: var(--menu-item-text-active-color, #094273);
}
.blte-menu-item--focus {
  outline: none;
  background-color: var(--menu-item-background-focus-color, #e8f2f1);
}
.blte-menu-item--focus .blte-menu-item__button {
  background-color: var(--menu-item-background-hover-color, #e8f2f1);
}
.blte-menu-item--focus .blte-menu-item__label {
  color: var(--menu-item-text-focus-color, #094273);
}
.blte-menu-item--is-disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.blte-menu-item--is-disabled.blte-menu-item__button,
.blte-menu-item--is-disabled .blte-menu-item__link {
  background-color: var(--menu-item-background-disabled-color, #f2f2f4);
}
.blte-menu-item--is-disabled .blte-menu-item__label {
  color: var(--menu-item-text-disabled-color, #e1e1e1);
}
