@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-details-card {
  padding: 16px 24px;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-card {
    padding: 24px 64px;
  }
}
@media screen and (min-width: 576px) {
  .blte-trip-details-card {
    padding: 0;
  }
}
.blte-trip-details-card__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  margin-bottom: 24px;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-card__title {
    font-size: 22px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-trip-details-card__title--noMargin {
  margin-bottom: 0;
}
