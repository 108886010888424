@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-wifi-splash-form-formik {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 26px;
}
.blte-wifi-splash-form-formik__formik-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  width: 100%;
}
.blte-wifi-splash-form-formik__input {
  width: 100%;
}
