@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-payment-processing-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blte-payment-processing-modal__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 0.5s linear infinite;
  width: 64px;
  height: 64px;
}
.blte-payment-processing-modal__title {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
  margin-top: 8px;
}
