@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-train-passes-summerize {
  width: 100%;
}
.blte-train-passes-summerize__fields {
  display: flex;
  justify-content: space-between;
}
.blte-train-passes-summerize__passesLink {
  display: flex;
}
.blte-train-passes-summerize__details {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.blte-train-passes-summerize__auto-renew {
  margin-top: 24px;
}
.blte-train-passes-summerize__auto-renew-text {
  margin-top: 8px;
}
