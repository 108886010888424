@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passes {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f4;
}
.blte-passes-page {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f2f2f4;
}
