.blte-trip-details-navigation-tickets-and-receipts__items button::after {
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

}
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-details-navigation-tickets-and-receipts {
  padding: 20px 0;
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  align-items: flex-start;
  color: #363636;
}
.blte-trip-details-navigation-tickets-and-receipts:first-child {
  padding-top: 0;
}
.blte-trip-details-navigation-tickets-and-receipts button {
  color: #363636;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-navigation-tickets-and-receipts {
    border-bottom: none;
  }
}
.blte-trip-details-navigation-tickets-and-receipts__content {
  flex: 1 0;
}
.blte-trip-details-navigation-tickets-and-receipts__title {
  display: none;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-navigation-tickets-and-receipts__title {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
    display: block;
    margin-bottom: 16px;
    color: #094273;
  }
}
.blte-trip-details-navigation-tickets-and-receipts__icon {
  display: none;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-navigation-tickets-and-receipts__icon {
    font-size: 20px;
    flex: 0 0 auto;
    display: block;
    margin-right: 12px;
    color: #094273;
  }
}
.blte-trip-details-navigation-tickets-and-receipts__items button {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 16px;
  text-align: left;
}
.blte-trip-details-navigation-tickets-and-receipts__items button::after {
  content: ICON_FONT_LOADER_IMAGE(IDdc0f790aa6274da32c0fd98eb2798059);
}
.blte-trip-details-navigation-tickets-and-receipts__items button:focus-visible {
  box-shadow: 0 0 0 2px #094273;
  outline: none;
  border-radius: 4px;
}
.blte-trip-details-navigation-tickets-and-receipts__items button:hover {
  text-decoration: underline;
}
.blte-trip-details-navigation-tickets-and-receipts__items button:hover::after {
  text-decoration: none;
}
.blte-trip-details-navigation-tickets-and-receipts__items button::after {
  float: right;
  font-size: 20px;
}
.blte-trip-details-navigation-tickets-and-receipts__items button:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-navigation-tickets-and-receipts__items button {
    margin-bottom: 20px;
  }
}
.blte-trip-details-navigation-tickets-and-receipts__receipt-error p {
  font-size: 20px;
}
.blte-trip-details-navigation-tickets-and-receipts__receipt-error .blte-modal__body {
  width: 40%;
}
.blte-trip-details-navigation-tickets-and-receipts__receipt-error-button {
  float: right;
}
