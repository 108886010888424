.blte-date-range-picker__calendar .DayPicker .CalendarDay__selected .blte-date-range-picker__day--sameDay::before {
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

}
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-date-range-picker {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: white;
}
.blte-date-range-picker__calendar .DayPicker {
  margin-left: -40px;
}
.blte-date-range-picker__calendar .DayPicker > div {
  transform: translate(20px, 20px);
}
@media screen and (min-width: 768px) {
  .blte-date-range-picker__calendar .DayPicker {
    margin-left: 0;
  }
  .blte-date-range-picker__calendar .DayPicker > div {
    transform: translate(0, 0);
  }
}
.blte-date-range-picker__calendar .DayPicker_weekHeader {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 768px) {
  .blte-date-range-picker__calendar .DayPicker_weekHeader {
    top: 60px;
  }
}
.blte-date-range-picker__calendar .DayPicker_weekHeader_li small {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
}
.blte-date-range-picker__calendar .DayPicker__withBorder {
  border: none;
  box-shadow: none;
  border-radius: 0;
}
.blte-date-range-picker__calendar .DayPicker .CalendarDay {
  font-size: 16px;
  line-height: 26px;
}
.blte-date-range-picker__calendar .DayPicker .CalendarDay__default {
  border: 0;
  outline: none;
}
.blte-date-range-picker__calendar .DayPicker .CalendarDay__default:hover {
  background: none;
}
.blte-date-range-picker__calendar .DayPicker .CalendarDay__default:hover .blte-date-range-picker__day {
  box-shadow: 0 0 0 1px #094273;
}
.blte-date-range-picker__calendar .DayPicker .CalendarDay:focus-visible {
  border-radius: 50%;
  outline: none;
  box-shadow: inset 0 0 0 2px #363636;
}
.blte-date-range-picker__calendar .DayPicker .CalendarDay__hovered_span,
.blte-date-range-picker__calendar .DayPicker .CalendarDay__selected_span,
.blte-date-range-picker__calendar .DayPicker .CalendarDay__selected_span:hover {
  background: #f2f2f4;
  color: #363636;
}
.blte-date-range-picker__calendar .DayPicker .CalendarDay__hovered_span:hover {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background: none;
}
.blte-date-range-picker__calendar .DayPicker .CalendarDay__hovered_span:hover .blte-date-range-picker__day {
  background: #094273;
  color: white;
  box-shadow: none;
}
.blte-date-range-picker__calendar .DayPicker .CalendarDay__selected {
  position: relative;
  background: none;
}
.blte-date-range-picker__calendar .DayPicker .CalendarDay__selected .blte-date-range-picker__day {
  background: #094273;
}
.blte-date-range-picker__calendar .DayPicker .CalendarDay__selected .blte-date-range-picker__day--sameDay::before {
  position: absolute;
  font-size: 37px;
  line-height: 41px;
  inset: 0;
  color: #fff;
  content: ICON_FONT_LOADER_IMAGE(ID6477b70589f0723028462374ef210aff);
}
.blte-date-range-picker__calendar .DayPicker .CalendarDay__selected_start,
.blte-date-range-picker__calendar .DayPicker .CalendarDay__selected_start:active,
.blte-date-range-picker__calendar .DayPicker .CalendarDay__selected_start:hover {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.blte-date-range-picker__calendar .DayPicker .CalendarDay__selected_end,
.blte-date-range-picker__calendar .DayPicker .CalendarDay__selected_end:active,
.blte-date-range-picker__calendar .DayPicker .CalendarDay__selected_end:hover {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.blte-date-range-picker__calendar .DayPicker .CalendarDay__blocked_out_of_range,
.blte-date-range-picker__calendar .DayPicker .CalendarDay__blocked_out_of_range:active,
.blte-date-range-picker__calendar .DayPicker .CalendarDay__blocked_out_of_range:hover,
.blte-date-range-picker__calendar .DayPicker .CalendarDay__blocked_out_of_range:focus-visible {
  box-shadow: none;
  color: #a3a3a3;
}
.blte-date-range-picker__calendar .DayPicker .CalendarDay__blocked_out_of_range .blte-date-range-picker__day,
.blte-date-range-picker__calendar .DayPicker .CalendarDay__blocked_out_of_range:active .blte-date-range-picker__day,
.blte-date-range-picker__calendar .DayPicker .CalendarDay__blocked_out_of_range:hover .blte-date-range-picker__day,
.blte-date-range-picker__calendar .DayPicker .CalendarDay__blocked_out_of_range:focus-visible .blte-date-range-picker__day {
  background: none;
  box-shadow: none;
}
.blte-date-range-picker__calendar .DayPicker .CalendarMonth_caption {
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
}
@media screen and (min-width: 576px) {
  .blte-date-range-picker__calendar .DayPicker .CalendarMonth_caption {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-date-range-picker__calendar .DayPicker .CalendarMonth_caption {
    font-size: 16px;
    font-weight: 450;
    line-height: 24px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) and (min-width: 576px) {
  .blte-date-range-picker__calendar .DayPicker .CalendarMonth_caption {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-date-range-picker__calendar .DayPicker .CalendarMonth_table tbody tr {
  padding-top: 8px;
}
.blte-date-range-picker__calendar .DayPicker .DayPickerNavigation_prevButton__verticalScrollableDefault {
  display: none;
}
.blte-date-range-picker__calendar .DayPicker .DayPickerNavigation_button {
  border: none;
}
.blte-date-range-picker__calendar .DayPicker .DayPickerNavigation_button__disabled .blte-date-range-picker__navButton {
  color: #a3a3a3;
}
.blte-date-range-picker__calendar .DayPicker .DayPicker_weekHeaders__horizontal {
  margin-left: 0;
}
.blte-date-range-picker__calendar .DayPicker .CalendarMonthGrid__horizontal {
  left: 0;
}
.blte-date-range-picker__day {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
@media screen and (min-width: 576px) {
  .blte-date-range-picker__day {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-date-range-picker__inputs {
  display: flex;
  flex-direction: row;
  width: 100%;
  z-index: 2;
}
@media screen and (min-width: 1024px) {
  .blte-date-range-picker__inputs {
    padding: 0;
  }
}
.blte-date-range-picker__inputs .blte-date-range-picker__input-left,
.blte-date-range-picker__inputs .blte-date-range-picker__input-right {
  width: 50%;
}
.blte-date-range-picker__inputs--oneWay .blte-date-range-picker__input-left {
  width: 100%;
}
.blte-date-range-picker__navButton--pos-prev,
.blte-date-range-picker__navButton--pos-next {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  color: #094273;
  position: absolute;
  top: 20px;
  left: 10px;
  width: 24px;
  height: 24px;
  text-align: start;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blte-date-range-picker__navButton--pos-prev:focus-visible,
.blte-date-range-picker__navButton--pos-next:focus-visible {
  border-radius: 50%;
  box-shadow: 0 0 0 2px #363636;
  outline: none;
}
.blte-date-range-picker__navButton--pos-next {
  left: unset;
  right: 20px;
  text-align: end;
  transform: rotate(180deg);
}
.blte-date-range-picker__error {
  font-size: 12px;
  font-weight: 450;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  color: #c00;
  padding: 4px 16px;
}
.blte-date-range-picker__mobile {
  margin-top: 8px;
}
.blte-date-range-picker__mobile .DayPicker .CalendarDay .blte-date-range-picker__day--sameDay::before {
  font-size: var(--font-size);
  line-height: var(--line-height);
}
.blte-date-range-picker .blte-widget-input__input {
  color: #363636;
}
.blte-date-range-picker .blte-widget-input__input--disabled {
  color: #e1e1e1;
}
.blte-date-range-picker .blte-widget-input__clear {
  padding-left: 1px;
}
