.blte-fare-compare-modal__body ul li::before,.blte-fare-compare-modal__body ol li::before,.blte-fare-compare-modal__body .fare-option-check-circle::before,.blte-fare-compare-modal__body .fare-option-money::before {
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

}
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-fare-compare-modal {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1024px) {
  .blte-fare-compare-modal {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }
}
.blte-fare-compare-modal__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}
.blte-fare-compare-modal__subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-fare-compare-modal__header {
  padding: 8px 16px;
  border-radius: 8px 8px 0 0;
}
.blte-fare-compare-modal__body {
  padding: 16px;
}
.blte-fare-compare-modal__body ul,
.blte-fare-compare-modal__body ol {
  margin: 0;
  padding-left: 30px;
  list-style: none;
}
.blte-fare-compare-modal__body li {
  margin-bottom: 16px;
  position: relative;
}
.blte-fare-compare-modal__body ul li::before {
  color: #137e00;
  content: ICON_FONT_LOADER_IMAGE(IDa4bfa326ce5d9ee1a619cf8d8545a410);
  position: absolute;
  left: -30px;
  font-size: 23px;
  line-height: 23px;
  top: 0;
}
.blte-fare-compare-modal__body ol li {
  color: #565656;
}
.blte-fare-compare-modal__body ol li::before {
  content: ICON_FONT_LOADER_IMAGE(ID989933a0b4109a9227eba69d728ba3fc);
  position: absolute;
  left: -23px;
  font-size: 18px;
  top: 0;
}
.blte-fare-compare-modal__body ul:last-child > li:last-child,
.blte-fare-compare-modal__body ol:last-child > li:last-child {
  margin-bottom: 0;
}
.blte-fare-compare-modal__body .fare-option-check-circle::before {
  color: #137e00 !important;
  content: ICON_FONT_LOADER_IMAGE(IDa4bfa326ce5d9ee1a619cf8d8545a410) !important;
  left: -30px !important;
  font-size: 23px !important;
  line-height: 23px !important;
}
.blte-fare-compare-modal__body .fare-option-money {
  color: #565656 !important;
}
.blte-fare-compare-modal__body .fare-option-money::before {
  color: #565656 !important;
  content: ICON_FONT_LOADER_IMAGE(ID989933a0b4109a9227eba69d728ba3fc) !important;
  left: -23px !important;
  font-size: 18px !important;
}
.blte-fare-compare-modal__body p {
  margin: 0;
}
.blte-fare-compare-modal__section {
  width: 100%;
  background: #f2f2f4;
  border-radius: 8px;
}
.blte-fare-compare-modal__section:last-child {
  margin-top: 24px;
}
@media screen and (min-width: 1024px) {
  .blte-fare-compare-modal__section {
    width: calc(50% - 12px);
  }
  .blte-fare-compare-modal__section:last-child {
    margin-top: 0;
  }
}
.blte-fare-compare-modal__section--variant-smart .blte-fare-compare-modal__header {
  background: #ffdb00;
  color: #363636;
}
.blte-fare-compare-modal__section--variant-premium .blte-fare-compare-modal__header {
  background: #363636;
  color: #fff;
}
