@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-fare-selection-drawer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blte-fare-selection-drawer__header {
  display: flex;
  padding-bottom: 16px;
  padding-right: 16px;
  align-self: stretch;
}
.blte-fare-selection-drawer__header-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 70%;
}
.blte-fare-selection-drawer__trip {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  align-items: center;
  flex-grow: 1;
}
@media screen and (min-width: 576px) {
  .blte-fare-selection-drawer__trip {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-fare-selection-drawer__origin {
  margin-right: 10px;
}
.blte-fare-selection-drawer__destination {
  margin-left: 10px;
}
.blte-fare-selection-drawer__wrapper {
  width: 100%;
}
.blte-fare-selection-drawer__fareCompare {
  color: #094273;
  text-decoration: underline;
  margin-top: 18px;
  font-size: 16px;
  font-weight: 450;
  margin-bottom: 50px;
}
.blte-fare-selection-drawer__fareCompare button {
  background: none;
  border: none;
  color: #094273;
  text-decoration: underline;
  margin-top: 18px;
  font-size: 16px;
  font-weight: 450;
}
.blte-fare-selection-drawer__go-back-button {
  background-color: transparent !important;
  align-items: baseline;
  padding: 0 30px 0 0;
}
.blte-fare-selection-drawer__drawer .blte-drawer__content {
  height: 85%;
}
.blte-fare-selection-drawer__drawer .blte-drawer__header {
  flex: none;
}
