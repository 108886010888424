@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
  height: 100%;
}
.blte-events-browser {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding: 0;
}
@media screen and (min-width: 576px) {
  .blte-events-browser {
    padding: 40px 64px;
  }
}
.blte-events-browser__headerXfs {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background-color: #fff;
  padding: 40px 24px;
}
@media screen and (min-width: 576px) {
  .blte-events-browser__headerXfs {
    row-gap: 40px;
    background-color: unset;
    padding: 0;
  }
}
.blte-events-browser__title {
  width: 100%;
  text-align: left;
}
@media screen and (min-width: 768px) {
  .blte-events-browser__title {
    text-align: center;
  }
}
.blte-events-browser__carousel {
  width: 100%;
}
.blte-events-browser__carousel .blte-carousel__slider-wrapper {
  height: 240px !important;
}
.blte-events-browser__carousel .blte-hero {
  height: 240px !important;
}
@media screen and (min-width: 768px) {
  .blte-events-browser__carousel .blte-carousel__slider-wrapper {
    height: 320px !important;
  }
  .blte-events-browser__carousel .blte-hero {
    height: 320px !important;
  }
}
.blte-events-browser__newsletter {
  width: 100%;
}
.blte-events-browser__body-wrapper {
  display: flex;
  flex-direction: column;
}
.blte-events-browser__filters {
  display: none;
  margin-bottom: 0;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #f2f2f4;
  padding-top: 15px;
  padding-bottom: 15px;
}
@media screen and (min-width: 576px) {
  .blte-events-browser__filters {
    display: flex;
    justify-content: center;
    margin-bottom: 64px;
  }
}
.blte-events-browser__events-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
  text-transform: none;
  text-align: left;
  margin-bottom: 24px;
}
@media screen and (min-width: 576px) {
  .blte-events-browser__events-title {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-events-browser__events-title {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-events-browser__events-section {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 24px;
  padding-bottom: 40px;
}
@media screen and (min-width: 576px) {
  .blte-events-browser__events-section {
    padding: 0;
  }
}
.blte-events-browser__top-picks-items {
  display: flex;
  width: 100%;
  padding-left: 0;
}
.blte-events-browser__full-width {
  grid-column: 1/-1;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  gap: 5px;
}
@media (max-width: 750px) {
  .blte-events-browser__full-width {
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .blte-events-browser__full-width {
    padding: 20px 0;
  }
  .blte-events-browser__full-width > * {
    margin-bottom: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-events-browser__full-width > * {
    margin-bottom: 40px;
  }
}
.blte-events-browser__top-pick-card-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 20px;
  justify-content: space-between;
  z-index: 100;
}
.blte-events-browser__top-picks-header {
  min-width: 280px;
}
@media (min-width: 1350px) {
  .blte-events-browser__top-picks-header {
    width: 20%;
  }
}
@media (min-width: 1000px) and (max-width: 1350px) {
  .blte-events-browser__top-picks-header {
    width: 25%;
  }
}
@media (min-width: 751px) and (max-width: 1000px) {
  .blte-events-browser__top-picks-header {
    width: 33%;
  }
}
.blte-events-browser__top-picks-description {
  margin-top: 16px;
}
.blte-events-browser__top-picks-carousel-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}
@media (max-width: 750px) {
  .blte-events-browser__top-picks-carousel-container {
    padding-bottom: 25px;
  }
}
.blte-events-browser__carousel-wrapper {
  display: flex;
  flex-direction: row;
  width: calc(100% - 280px);
}
@media (max-width: 1500px) {
  .blte-events-browser__carousel-wrapper {
    width: calc(100% - 230px);
  }
}
@media (max-width: 750px) {
  .blte-events-browser__carousel-wrapper {
    width: calc(100vw - 48px);
    margin-top: 20px;
  }
}
.blte-events-browser__carousel-wrapper .react-multi-carousel-dot > button {
  width: 6px;
  height: 6px;
  background: #aeaeb2;
  border: none;
  border-radius: 100%;
}
.blte-events-browser__carousel-wrapper .react-multi-carousel-dot--active > button {
  background: #363636 !important;
}
.blte-events-browser__carousel-slider-left {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.blte-events-browser__carousel-slider-left--hidden {
  opacity: 0;
}
.blte-events-browser__carousel-slider-left--button {
  height: 40px;
  width: 40px;
  border: 1px solid #094273;
  color: #094273;
  background: none;
  border-radius: 21px;
}
.blte-events-browser__carousel-slider-left--button:hover {
  cursor: pointer;
}
.blte-events-browser__carousel-slider-right {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}
.blte-events-browser__carousel-slider-right--hidden {
  opacity: 0;
}
.blte-events-browser__carousel-slider-right--button {
  height: 40px;
  width: 40px;
  border: 1px solid #094273;
  color: #094273;
  background: none;
  border-radius: 21px;
}
.blte-events-browser__carousel-slider-right--button:hover {
  cursor: pointer;
}
.blte-events-browser__top-pick-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: calc(100% - 32px);
  border-radius: 8px;
  position: relative;
  text-decoration: none;
  color: inherit;
  overflow: hidden;
  aspect-ratio: 23/34;
}
.blte-events-browser__top-pick-card-image {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  aspect-ratio: var(--top-pick-image-aspect-ratio, "auto");
}
.blte-events-browser__top-pick-card-image picture {
  height: 100%;
}
.blte-events-browser__top-pick-card-image img {
  object-fit: cover;
  object-position: center;
  height: 100%;
}
.blte-events-browser__top-pick-card-overlay {
  background-color: var(--top-pick-bg-color);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  opacity: var(--top-pick-opacity);
}
.blte-events-browser__top-pick-card-title {
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
}
.blte-events-browser__top-pick-card-description,
.blte-events-browser__top-pick-card-description > *:first-child {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media screen and (min-width: 576px) {
  .blte-events-browser__top-pick-card-description,
  .blte-events-browser__top-pick-card-description > *:first-child {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-events-browser__top-pick-card-time {
  font-size: 16px;
  font-weight: 600;
}
.blte-events-browser__top-pick-card-location {
  font-size: 16px;
  font-weight: 400;
}
.blte-events-browser__events-wrapper {
  width: 100%;
  display: grid;
  row-gap: 48px;
  grid-template-columns: 1fr;
  column-gap: 0;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 24px;
}
@media (max-width: 769px) and (min-width: 452px) {
  .blte-events-browser__events-wrapper {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
  }
}
@media (max-width: 1025px) and (min-width: 769px) {
  .blte-events-browser__events-wrapper {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 24px;
  }
}
@media (min-width: 1025px) {
  .blte-events-browser__events-wrapper {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 24px;
  }
}
.blte-events-browser__event-card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.blte-events-browser__event-card-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: fit-content;
}
.blte-events-browser__pagination-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}
@media screen and (min-width: 576px) {
  .blte-events-browser__pagination-wrapper {
    margin-top: 64px;
  }
}
.blte-events-browser__events-empty {
  display: flex;
  width: 100%;
  min-height: 350px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-events-browser__icon-parent {
  display: flex;
  justify-content: center;
}
.blte-events-browser__icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 54px;
  height: 54px;
  font-size: 22px;
  line-height: 24px;
  color: #fff;
}
.blte-events-browser__no-results-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  justify-content: center;
}
.blte-events-browser__no-results-description {
  margin-bottom: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
}
.blte-events-browser__offersCards {
  padding-top: 40px;
}
