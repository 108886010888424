@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-seat-selection-desktop {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: sticky;
  inset: 0;
  height: 100%;
}
.blte-seat-selection-desktop__left {
  display: flex;
  width: 40%;
  height: 100%;
  flex-direction: column;
  background: #fff;
  overflow: auto;
  padding: 48px 24px;
}
@media screen and (min-width: 1024px) {
  .blte-seat-selection-desktop__left {
    padding: 48px 40px;
  }
}
.blte-seat-selection-desktop__left::-webkit-scrollbar {
  display: none;
}
.blte-seat-selection-desktop__left ::-webkit-scrollbar {
  display: none;
}
.blte-seat-selection-desktop__right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 60%;
  padding: 16px 24px;
  background-color: #f2f2f4;
}
@media screen and (min-width: 1024px) {
  .blte-seat-selection-desktop__right {
    padding: 16px 40px 24px;
  }
}
.blte-seat-selection-desktop__right-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  overflow: hidden;
  flex: 1 1 0;
}
.blte-seat-selection-desktop__cancel-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
.blte-seat-selection-desktop__disabled-seat-icon {
  opacity: 0.5;
  font-size: 100px;
}
.blte-seat-selection-desktop__disabled-seat-header {
  font-weight: 500;
  font-size: 22px;
  margin: 8px;
}
.blte-seat-selection-desktop__disabled-seat-text {
  font-size: 22px;
  margin: 0;
}
.blte-seat-selection-desktop__update-error {
  position: absolute;
  padding: 8px 12px 8px 8px;
  display: flex;
  bottom: 100px;
  left: 0;
  right: 0;
  width: fit-content;
  justify-content: center;
  align-items: center;
  background: #fae5e5;
  gap: 8px;
  border-radius: 4px;
  margin: 0 auto;
}
.blte-seat-selection-desktop__update-error-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 22px;
  color: #c00;
}
.blte-seat-selection-desktop__update-error-message {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
.blte-seat-selection-desktop__close-modal {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  position: absolute;
  right: 24px;
  top: 16px;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  outline: none;
  color: #363636;
}
.blte-seat-selection-desktop__close-modal:focus-visible {
  box-shadow: 0 0 0 2px #363636;
  border-radius: 4px;
}
.blte-seat-selection-desktop__title {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  margin-bottom: 8px;
}
@media screen and (min-width: 576px) {
  .blte-seat-selection-desktop__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-seat-selection-desktop__title {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-seat-selection-desktop__subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  margin-bottom: 24px;
}
@media screen and (min-width: 576px) {
  .blte-seat-selection-desktop__subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-seat-selection-desktop__seats {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.blte-seat-selection-desktop__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  width: 100%;
}
.blte-seat-selection-desktop__travel {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
  display: flex;
  flex-direction: row;
  column-gap: 12px;
}
.blte-seat-selection-desktop__direction-icon {
  transform: rotate(-90deg);
}
.blte-seat-selection-desktop__coach-select {
  display: flex;
  margin-bottom: 16px;
}
.blte-seat-selection-desktop__coach-select > div {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.blte-seat-selection-desktop__seat-map {
  display: flex;
  overflow: auto;
  margin-bottom: 75px;
}
.blte-seat-selection-desktop__seat-map::-webkit-scrollbar {
  display: none;
}
.blte-seat-selection-desktop__seat-map ::-webkit-scrollbar {
  display: none;
}
.blte-seat-selection-desktop__sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.blte-seat-selection-desktop__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 16px 40px;
  border-top: 1px solid #e1e1e1;
  position: sticky;
  inset: 0;
  background-color: #fff;
}
.blte-seat-selection-desktop__legend {
  display: flex;
  gap: 12px;
  flex-flow: row wrap;
}
@media screen and (min-width: 1024px) {
  .blte-seat-selection-desktop__legend {
    column-gap: 24px;
  }
}
.blte-seat-selection-desktop__actions {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
}
