@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-events-browser-filters-mobile__wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background-color: #f2f2f4;
}
.blte-events-browser-filters-mobile__modal-heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px 24px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 50;
}
.blte-events-browser-filters-mobile__modal-heading-title {
  display: flex;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
}
.blte-events-browser-filters-mobile__modal-heading-icon {
  width: 24px;
  height: 24px;
  font-size: 20px;
  line-height: 20px;
}
.blte-events-browser-filters-mobile__modal-heading-label {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}
.blte-events-browser-filters-mobile__selected-filters {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 16px;
}
.blte-events-browser-filters-mobile__selected-filter-pill {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  padding: 8px;
  background-color: #def0ff;
  border-radius: 50px;
  border: 1px solid #094273;
  font-size: 12px;
  font-weight: 450;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  color: #094273;
}
.blte-events-browser-filters-mobile__selected-filter-pill-icon {
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
}
.blte-events-browser-filters-mobile__filters-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 24px;
  background-color: #fff;
}
.blte-events-browser-filters-mobile__filter-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}
.blte-events-browser-filters-mobile__filter-items {
  display: grid;
  row-gap: 16px;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 24px;
}
.blte-events-browser-filters-mobile__calendar-filter {
  display: flex;
  width: 100%;
}
.blte-events-browser-filters-mobile__filter-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
}
.blte-events-browser-filters-mobile__filter-item--selected {
  border: 1px solid #094273;
  background-color: #def0ff;
}
.blte-events-browser-filters-mobile__filter-icon {
  width: 30px;
  height: 30px;
}
.blte-events-browser-filters-mobile__station-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
  text-align: center;
  cursor: pointer;
}
.blte-events-browser-filters-mobile__station-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  font-size: 24px;
  line-height: 24px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background-color: #fff;
  cursor: pointer;
}
.blte-events-browser-filters-mobile__station-icon--selected {
  border: 1px solid #094273;
  background-color: #def0ff;
}
.blte-events-browser-filters-mobile__icons {
  max-width: 30px;
  max-height: 30px;
}
.blte-events-browser-filters-mobile__filter-label {
  font-weight: 450;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
}
.blte-events-browser-filters-mobile__filter-button {
  width: 100%;
  display: flex;
  justify-content: end;
}
@media screen and (min-width: 576px) {
  .blte-events-browser-filters-mobile__modal {
    display: none;
  }
}
.blte-events-browser-filters-mobile__modal .blte-modal__content {
  min-height: 100vh;
}
