@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-od-mobile-select {
  position: unset;
  width: 100%;
  transition: transform linear 0.3s;
}
.blte-od-mobile-select__listItems {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: unset;
  width: 100%;
  overflow-y: auto;
  padding: 14px 4px 4px;
}
.blte-od-mobile-select .blte-widget-input__input {
  color: #363636;
}
.blte-od-mobile-select .blte-widget-input__clear {
  padding-left: 1px;
}
.blte-od-mobile-select .blte-widget-input__error {
  position: absolute;
}
.blte-od-mobile-select__list {
  display: none;
  z-index: 2;
  position: absolute;
  border-radius: 6px;
  background: #fff;
  box-shadow: none;
  border: none;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin-top: 2px;
  left: 0;
  top: 100%;
}
.blte-od-mobile-select__list:first-child .blte-widget-input,
.blte-od-mobile-select__list:last-child .blte-widget-input {
  border-radius: 8px;
}
.blte-od-mobile-select__list--active {
  display: flex;
  z-index: 3;
}
