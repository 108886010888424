@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passenger-info__wrapper {
  overflow: hidden;
  padding: 4px;
}
.blte-passenger-info__wrapper .blte-divider--alignment-horizontal {
  margin: 16px 0;
}
.blte-passenger-info__header {
  padding-bottom: 16px;
  display: flex;
  align-items: center;
}
.blte-passenger-info__header--no-top {
  padding-top: 0;
}
.blte-passenger-info__desc {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-passenger-info__desc {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-passenger-info__primary {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  padding-left: 8px;
}
.blte-passenger-info__row {
  display: flex;
}
@media screen and (min-width: 768px) {
  .blte-passenger-info__row {
    flex-direction: row;
  }
}
.blte-passenger-info__column {
  width: 50%;
}
@media screen and (min-width: 768px) {
  .blte-passenger-info__column:nth-child(2) {
    margin-left: 16px;
  }
}
.blte-passenger-info__field {
  width: 100%;
  padding: 0;
  margin-bottom: 16px;
}
.blte-passenger-info__field--no-bottom {
  margin-bottom: 0;
}
.blte-passenger-info__field .blte-phone-number__country-code {
  margin-right: 0;
}
.blte-passenger-info__field.blte-checkbox {
  width: auto;
  padding-right: 8px;
}
.blte-passenger-info__assistance {
  display: flex;
  align-items: center;
  color: #094273;
  font-size: 24px;
}
.blte-passenger-info__text,
.blte-passenger-info__change {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  border: none;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 4px;
}
.blte-passenger-info__actions-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  padding-top: 16px;
}
.blte-passenger-info__actions {
  width: 100%;
}
@media screen and (min-width: 576px) {
  .blte-passenger-info__actions {
    width: auto;
  }
}
.blte-passenger-info__ckeck-icon {
  margin: 4px;
  font-size: 14px;
  padding: 2px 6px;
  border-radius: 50%;
  color: #fff;
  background-color: #137e00;
}
.blte-passenger-info__readonly {
  border-radius: 4px;
  background-color: #e8f2f1;
  display: flex;
  padding: 8px 16px 8px 8px;
  margin-bottom: 16px;
}
.blte-passenger-info__info {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
}
@media screen and (min-width: 576px) {
  .blte-passenger-info__info {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-passenger-info__info-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-passenger-info__info-name {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-passenger-info__complete {
  display: flex;
  flex-wrap: wrap;
  padding-left: 32px;
}
.blte-passenger-info__complete .blte-passenger-info__preview {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-passenger-info__complete .blte-passenger-info__preview {
    width: 50%;
  }
}
.blte-passenger-info__complete .blte-passenger-info__preview--second-column {
  padding-left: 0;
}
@media screen and (min-width: 768px) {
  .blte-passenger-info__complete .blte-passenger-info__preview--second-column {
    padding-left: 24px;
  }
}
.blte-passenger-info__complete .blte-passenger-info__preview,
.blte-passenger-info__complete .blte-passenger-info__desc {
  padding-bottom: 8px;
}
.blte-passenger-info__complete .blte-passenger-info__desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-passenger-info__complete .blte-passenger-info__simple {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blte-passenger-info__server-error {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: #fae6e6;
}
.blte-passenger-info__server-error i {
  font-size: 20px;
  color: #c00;
}
.blte-passenger-info__server-error--variant-modified {
  background-color: #ffdb00;
}
.blte-passenger-info__server-error--variant-modified i {
  color: #000;
}
.blte-passenger-info__error-description {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  padding-left: 10px;
}
.blte-passenger-info__fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
.blte-passenger-info__passenger-info-guest,
.blte-passenger-info__passenger-info-primary-guest,
.blte-passenger-info__passenger-info-wheelchair {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}
.blte-newsletter-confirm {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  flex-direction: column;
}
.blte-newsletter-confirm p {
  margin: 0;
}
.blte-newsletter-confirm .blte-checkbox {
  max-width: none;
  margin-top: 4px;
}
