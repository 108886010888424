@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-events-desktop-calendar {
  background: white;
  border-radius: 20px;
  overflow: hidden;
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  max-width: 100vw;
}
.blte-events-desktop-calendar .DayPicker_1 {
  max-width: 100vw;
}
@media (min-width: 575px) and (max-width: 705px) {
  .blte-events-desktop-calendar .CalendarMonthGrid_month__horizontal_1 {
    max-width: 50vw;
  }
}
.blte-events-desktop-calendar__footer {
  display: flex;
  justify-content: end;
  padding: 16px;
  width: auto;
  align-items: center;
}
.blte-events-desktop-calendar__footer-right {
  display: flex;
}
.blte-events-desktop-calendar__button-clear,
.blte-events-desktop-calendar__button-close {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-events-desktop-calendar__button-clear {
  color: #094273;
  padding: 4px 8px;
  outline: none;
  margin-right: 16px;
}
.blte-events-desktop-calendar__calendar {
  z-index: 2;
  padding: 16px 0 0 5px;
  width: fit-content;
  background: #fff;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  outline: none;
  max-width: 100vw;
}
.blte-events-desktop-calendar__calendar:focus-visible {
  box-shadow: 0 0 0 2px #363636;
}
.blte-events-desktop-calendar__active-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 12px;
  margin: 0 12px;
}
.blte-events-desktop-calendar__active-button {
  background: none;
  color: black;
  border: 1px solid #ddd;
  padding: 6px 16px;
  border-radius: 50px;
  font-weight: 450;
  cursor: pointer;
}
.blte-events-desktop-calendar__active-button--isActive {
  border-color: #094273;
  color: #094273;
  background-color: #def0ff;
}
