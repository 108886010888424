@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-events-browser-filters-drawer {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  width: 100%;
  position: sticky;
  bottom: 0;
  padding: 12px 24px;
  background-color: #fff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.0784313725);
  cursor: pointer;
}
@media screen and (min-width: 576px) {
  .blte-events-browser-filters-drawer {
    display: none;
  }
}
.blte-events-browser-filters-drawer__filter {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center;
  padding: 8px;
  border-radius: 100px;
  gap: 4px;
  border: 1px solid #ddd;
  background-color: #fff;
}
.blte-events-browser-filters-drawer__filter-label-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}
.blte-events-browser-filters-drawer__filter-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
  line-height: 18px;
}
.blte-events-browser-filters-drawer__filter-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
}
