@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-safety-pledge-counter-circle {
  display: flex;
  justify-content: center;
}
.blte-safety-pledge-counter-circle__pledge-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -55px;
}
.blte-safety-pledge-counter-circle__pledge-counter--isFormSubmitted {
  top: -115px;
  flex-direction: column;
}
.blte-safety-pledge-counter-circle__circle {
  width: 250px;
  height: 170px;
  background-image: url(assets/images/png/safety-badge.png);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.blte-safety-pledge-counter-circle__circle--isFormSubmitted {
  bottom: 235px;
}
.blte-safety-pledge-counter-circle__number {
  color: white;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 20px;
}
.blte-safety-pledge-counter-circle__confetti {
  width: 450px;
  height: 300px;
  background-image: url(assets/images/png/counter-confetti.png);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
