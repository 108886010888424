@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-seat-map-placeholder {
  display: flex;
  flex-direction: column;
  width: 358px;
  align-items: center;
  overflow: hidden;
  height: 100%;
}
.blte-seat-map-placeholder__title {
  width: 164px;
  height: 24px;
  border-radius: 2px;
  background-color: #e1e1e1;
  margin-bottom: 8px;
  margin-top: 20px;
}
.blte-seat-map-placeholder__subtitle {
  width: 154px;
  height: 36px;
  border-radius: 4px;
  background-color: #e1e1e1;
  margin-bottom: 16px;
}
.blte-seat-map-placeholder__content {
  width: 358px;
  height: calc(100% - 104px);
  flex-shrink: 0;
  border-radius: 2px;
  background-color: #e1e1e1;
}
