@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-cardV2 {
  background-color: #f9f9f9;
  background-image: var(--card-background-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  padding: 16px 16px 16px 0;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  color: var(--card-text-color, #363636);
}
.blte-cardV2__card-title_img {
  width: 140px;
}
.blte-cardV2--placeholders {
  background-color: #fff;
}
.blte-cardV2--isNotSneakPeak {
  min-height: 212px;
}
.blte-cardV2__content {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
}
.blte-cardV2__content--placeholders {
  flex-wrap: nowrap;
}
.blte-cardV2__row {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.blte-cardV2__title {
  font-size: 18px;
  font-weight: 500;
}
@media screen and (min-width: 576px) {
  .blte-cardV2__title {
    font-size: 22px;
  }
}
.blte-cardV2__card-info-text {
  font-size: 24px;
  font-weight: 700;
}
.blte-cardV2__description-label,
.blte-cardV2__card-id,
.blte-cardV2__country-abbreviation {
  opacity: 0.7;
}
.blte-cardV2__country-abbreviation {
  font-size: 10px;
  vertical-align: super;
}
.blte-cardV2__icon-wrapper picture,
.blte-cardV2__icon-wrapper img {
  max-width: none;
  width: 32px;
  height: 32px;
}
.blte-cardV2__date-container {
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.blte-cardV2__date-description {
  display: flex;
  gap: 4px;
  white-space: nowrap;
}
.blte-cardV2__qr-code-container {
  display: flex;
  align-items: center;
  mix-blend-mode: multiply;
  margin-left: auto;
}
.blte-cardV2__qr-code-container img,
.blte-cardV2__qr-code-container picture {
  max-width: none;
}
.blte-cardV2__divider-placeholder {
  width: 2px;
  align-items: stretch;
  margin: 0 16px;
  background: #f2f2f4;
  position: relative;
  overflow: hidden;
}
.blte-cardV2__divider-placeholder::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #f2f2f4, #fff, #f2f2f4);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-cardV2__row-items {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}
.blte-cardV2__row-item-placeholder {
  width: 40%;
  height: 20px;
  border-radius: 2px;
  background: #f2f2f4;
  position: relative;
  overflow: hidden;
}
.blte-cardV2__row-item-placeholder::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #f2f2f4, #fff, #f2f2f4);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-cardV2__row-item-placeholder--title {
  width: 70%;
}
.blte-cardV2__icon-placeholder {
  width: 32px;
  height: 32px;
  border-radius: 2px;
  background: #f2f2f4;
  position: relative;
  overflow: hidden;
}
.blte-cardV2__icon-placeholder::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #f2f2f4, #fff, #f2f2f4);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-cardV2__qr-code-placeholder {
  width: 56px;
  height: 56px;
  border-radius: 2px;
  background: #f2f2f4;
  position: relative;
  overflow: hidden;
}
.blte-cardV2__qr-code-placeholder::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #f2f2f4, #fff, #f2f2f4);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
