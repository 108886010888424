@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-no-results {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 40px 16px;
  background: #fff;
  border-radius: 8px;
}
.blte-no-results__icon {
  width: 58px;
  height: 40px;
  margin-bottom: 24px;
}
.blte-no-results__title {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-transform: none;
  color: #363636;
  text-transform: uppercase;
  margin-bottom: 2px;
}
@media screen and (min-width: 576px) {
  .blte-no-results__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: none;
  }
}
.blte-no-results__description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  margin-bottom: 24px;
}
@media screen and (min-width: 576px) {
  .blte-no-results__description {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
