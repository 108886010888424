@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-upcoming-trips-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  background: #fff;
  border-radius: 8px;
}
.blte-upcoming-trips-card__content-wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
}
.blte-upcoming-trips-card__content-wrapper > div {
  margin-right: 16px;
}
@media screen and (min-width: 1024px) {
  .blte-upcoming-trips-card__content-wrapper > div {
    margin-right: 24px;
  }
}
.blte-upcoming-trips-card__trip-list-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  background: #f2f2f4;
  border-radius: 8px;
}
@media screen and (min-width: 1024px) {
  .blte-upcoming-trips-card__trip-list-item {
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }
}
.blte-upcoming-trips-card__direction {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.blte-upcoming-trips-card__direction-icon {
  color: #094273;
}
.blte-upcoming-trips-card__direction-icon--type-arrow-double::after {
  font-size: 20px;
}
.blte-upcoming-trips-card__direction-icon--type-arrow-from-to::after {
  font-size: 20px;
}
.blte-upcoming-trips-card__from,
.blte-upcoming-trips-card__to {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-upcoming-trips-card__from {
  margin-right: 4px;
}
@media screen and (min-width: 1024px) {
  .blte-upcoming-trips-card__from {
    margin-right: 8px;
  }
}
.blte-upcoming-trips-card__to {
  margin-left: 4px;
}
@media screen and (min-width: 1024px) {
  .blte-upcoming-trips-card__to {
    margin-left: 8px;
  }
}
.blte-upcoming-trips-card__actions {
  display: flex;
  align-items: start;
  margin-top: 6px;
}
@media screen and (min-width: 1024px) {
  .blte-upcoming-trips-card__actions {
    margin-top: 0;
  }
}
.blte-upcoming-trips-card__action + .blte-upcoming-trips-card__action {
  margin-left: 16px;
}
.blte-upcoming-trips-card__status-tag {
  margin-left: 24px;
}
