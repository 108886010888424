@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-global-header__top-nav {
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.blte-global-header__top-nav--isDesktop {
  margin: 0;
  border-top: none;
  flex-direction: row;
  padding: 0;
  width: unset;
}
.blte-global-header__top-nav--isDesktop .blte-global-header__language .blte-nav-item__submenu {
  min-width: calc(100% + 20px);
  right: 0;
  left: auto;
}
@media screen and (min-width: 768px) {
  .blte-global-header__top-nav--isResponsive {
    margin: 0;
    border-top: none;
    flex-direction: row;
    padding: 0;
    width: unset;
  }
  .blte-global-header__top-nav--isResponsive .blte-global-header__language .blte-nav-item__submenu {
    min-width: calc(100% + 20px);
    right: 0;
    left: auto;
  }
}
.blte-global-header__top-nav :last-child .blte-nav-item--size-small {
  margin-right: 0;
  margin-bottom: 0;
}
@media (hover: hover) and (pointer: fine) {
  .blte-global-header__top-nav .blte-global-header__login--auth .blte-nav-item-header__label:hover {
    text-decoration: underline;
  }
}
