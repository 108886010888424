.blte-safety-pledge__description ul li::before {
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

}
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-safety-pledge {
  background-color: var(--safety-pledge-background-color);
  margin-top: 70px;
}
.blte-safety-pledge__content {
  padding-bottom: 70px;
}
@media (min-width: 1025px) and (max-width: 1080px) {
  .blte-safety-pledge__content {
    margin: 0 40px;
  }
}
.blte-safety-pledge__description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: var(--text-and-media-description-color, #363636);
}
.blte-safety-pledge__description ul {
  margin-left: 0;
  padding-left: 30px;
  list-style: none;
}
.blte-safety-pledge__description ul li {
  margin-top: 8px;
  position: relative;
  padding: 8px;
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
}
.blte-safety-pledge__description ul li::before {
  content: ICON_FONT_LOADER_IMAGE(IDbc3a9eeac38a8344aa184117cb921092);
  position: absolute;
  padding: 8px;
  left: -30px;
  font-size: 18px;
  top: 0;
}
.blte-safety-pledge__description ol {
  margin-left: 0;
  padding-left: 30px;
}
.blte-safety-pledge__row {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 1025px) {
  .blte-safety-pledge__row {
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 1024px) {
  .blte-safety-pledge__row {
    margin-left: 70px;
    margin-right: 70px;
  }
}
@media (max-width: 800px) {
  .blte-safety-pledge__row {
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media (max-width: 431px) {
  .blte-safety-pledge__row {
    margin-left: 40px;
    margin-right: 40px;
  }
}
.blte-safety-pledge__form-row {
  display: flex;
  justify-content: left;
  flex-direction: row;
}
.blte-safety-pledge__input-field {
  width: 100%;
}
.blte-safety-pledge__column {
  width: 100%;
}
@media screen and (min-width: 576px) {
  .blte-safety-pledge__column {
    width: 100%;
    padding-left: 0;
  }
}
@media (min-width: 1025px) {
  .blte-safety-pledge__column {
    width: 455px;
    padding-left: 24px;
  }
}
.blte-safety-pledge__field {
  width: 100%;
  padding: 0;
  margin-bottom: 16px;
}
.blte-safety-pledge__field--postal-code {
  width: 50%;
}
.blte-safety-pledge__title {
  font-size: 30px;
  font-weight: 500;
  line-height: 44px;
  text-align: center;
  margin-bottom: 40px;
}
@media (max-width: 800px) {
  .blte-safety-pledge__title {
    font-size: 27px;
  }
}
@media (max-width: 431px) {
  .blte-safety-pledge__title {
    font-size: 22px;
  }
}
.blte-safety-pledge__subtitle {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}
@media (min-width: 1024px) {
  .blte-safety-pledge__subtitle {
    margin: 10px 0;
  }
}
@media (max-width: 1023px) {
  .blte-safety-pledge__subtitle {
    margin: 20px 0;
  }
}
@media (max-width: 431px) {
  .blte-safety-pledge__subtitle {
    font-size: 18px;
    line-height: 25px;
  }
}
@media (max-width: 281px) {
  .blte-safety-pledge__subtitle {
    font-size: 16px;
    line-height: 20px;
  }
}
.blte-safety-pledge__disclaimer-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}
@media screen and (min-width: 768px) {
  .blte-safety-pledge__disclaimer-wrapper {
    margin-top: -12px;
  }
}
.blte-safety-pledge__disclaimer {
  padding: 8px 0;
  font-family: "Futura PT", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
@media (min-width: 1025px) {
  .blte-safety-pledge__disclaimer {
    margin-left: 8px;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .blte-safety-pledge__disclaimer {
    padding: 10px 0;
  }
}
@media (max-width: 768px) {
  .blte-safety-pledge__disclaimer {
    padding: 8px 7px;
  }
}
.blte-safety-pledge__disclaimer-row {
  display: flex;
  flex-direction: column;
  padding: 0 2px;
}
@media screen and (min-width: 768px) {
  .blte-safety-pledge__disclaimer-row {
    flex-direction: row;
  }
}
