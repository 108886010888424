@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-forced-login-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.blte-forced-login-modal__modal .blte-modal__body {
  height: fit-content;
  width: 800px;
  margin-top: auto;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
@media screen and (min-width: 576px) {
  .blte-forced-login-modal__modal .blte-modal__body {
    border-radius: 16px;
    margin-top: unset;
  }
}
.blte-forced-login-modal__close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  max-width: 28px;
  height: 28px;
  max-height: 28px;
  border-radius: 100%;
  background: #fff;
}
.blte-forced-login-modal__close-button:hover {
  cursor: pointer;
}
.blte-forced-login-modal__close-icon {
  line-height: 24px;
  color: #363636;
}
.blte-forced-login-modal__image-container {
  height: 170px;
  max-height: 170px;
  width: 100%;
  overflow: hidden;
}
@media screen and (min-width: 576px) {
  .blte-forced-login-modal__image-container {
    height: 300px;
    max-height: 300px;
  }
}
.blte-forced-login-modal__image {
  object-fit: cover;
}
.blte-forced-login-modal__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
  padding: 16px 24px 32px;
}
.blte-forced-login-modal__logo {
  height: 40px;
}
.blte-forced-login-modal__text {
  color: #363636;
  text-align: center;
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) and (min-width: 576px) {
  .blte-forced-login-modal__text {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-forced-login-modal__buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  column-gap: 16px;
  row-gap: 24px;
  width: 100%;
}
@media screen and (min-width: 576px) {
  .blte-forced-login-modal__buttons {
    flex-direction: row;
  }
}
.blte-forced-login-modal__buttons button {
  width: 100%;
}
@media screen and (min-width: 576px) {
  .blte-forced-login-modal__buttons button {
    width: 185px;
  }
}
.blte-forced-login-modal__register-button:hover {
  color: white;
}
