@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passes-cost-summary-item__line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.blte-passes-cost-summary-item__name {
  color: #363636;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .blte-passes-cost-summary-item__name {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
  }
}
.blte-passes-cost-summary-item__name--isDiscount {
  color: #137e00;
}
.blte-passes-cost-summary-item__name--isTotal {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}
@media screen and (min-width: 768px) {
  .blte-passes-cost-summary-item__name--isTotal {
    font-size: 20px;
    line-height: 28px;
  }
}
.blte-passes-cost-summary-item__price {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #363636;
}
.blte-passes-cost-summary-item__price--isDiscount {
  color: #137e00;
}
.blte-passes-cost-summary-item__price--isTotal {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}
@media screen and (min-width: 768px) {
  .blte-passes-cost-summary-item__price--isTotal {
    font-size: 20px;
    line-height: 28px;
  }
}
