@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-confirm-cancellation__buttons {
  margin: 0;
}
.blte-confirm-cancellation__buttons > :first-child {
  margin-bottom: 16px;
}
.blte-confirm-cancellation__buttons > button {
  width: 100%;
}
.blte-confirm-cancellation__buttons > :nth-child(2):hover {
  background-color: #000;
  color: #fff;
}
.blte-confirm-cancellation__mobile-buttons {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  gap: 12px;
  background-color: #fff;
  position: sticky;
  bottom: 0;
  width: 100%;
  margin-top: 24px;
  box-shadow: 0 0 10px #e1e1e1;
}
.blte-confirm-cancellation__mobile-buttons > button {
  width: 50%;
}
