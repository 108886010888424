@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-seat-footer-placeholder {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  position: sticky;
  inset: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
@media screen and (min-width: 1024px) {
  .blte-seat-footer-placeholder {
    padding: 16px 40px;
  }
}
.blte-seat-footer-placeholder__legend-section {
  display: flex;
  column-gap: 24px;
}
.blte-seat-footer-placeholder__legend {
  width: 91px;
  height: 20px;
  border-radius: 2px;
  background-color: #e1e1e1;
}
.blte-seat-footer-placeholder__action {
  width: 157px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: #e1e1e1;
}
