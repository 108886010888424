@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-dashboard-wallet-mobile {
  padding: 16px 0 0;
}
@media screen and (min-width: 576px) {
  .blte-dashboard-wallet-mobile {
    padding: 24px 0 0;
  }
}
.blte-dashboard-wallet-mobile__dots {
  margin: 16px;
}
.blte-dashboard-wallet-mobile .blte-passes-carousel {
  padding: 0 12px;
  background-color: transparent;
}
@media screen and (min-width: 576px) {
  .blte-dashboard-wallet-mobile .blte-passes-carousel {
    padding: 0;
  }
}
