@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-seat-map-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 8px;
}
.blte-seat-map-error__icon {
  display: flex;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: #c00;
}
.blte-seat-map-error__messages {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blte-seat-map-error__title {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  margin-bottom: 4px;
}
@media screen and (min-width: 576px) {
  .blte-seat-map-error__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-seat-map-error__title {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-seat-map-error__subtitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
  margin-bottom: 8px;
}
@media screen and (min-width: 576px) {
  .blte-seat-map-error__subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
