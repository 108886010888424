@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-booking-header {
  background: #fff;
}
.blte-booking-header__skip-link {
  position: absolute;
  top: 5px;
  left: 5px;
  opacity: 0;
  cursor: default;
  pointer-events: none;
}
.blte-booking-header__skip-link:focus-visible {
  opacity: 1;
  pointer-events: all;
}
.blte-booking-header__navigation--absolute {
  z-index: 101;
}
.blte-booking-header__container {
  display: flex;
  justify-content: space-between;
  height: 56px;
  align-items: center;
}
@media screen and (min-width: 576px) {
  .blte-booking-header__container {
    height: 88px;
  }
}
.blte-booking-header__tickets-container {
  position: relative;
}
.blte-booking-header__tickets-items {
  z-index: 100;
  background: #fff;
}
.blte-booking-header__tickets-items--absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
@media screen and (min-width: 768px) {
  .blte-booking-header__form-container {
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
.blte-booking-header .blte-logo {
  width: 116px;
  flex-shrink: 0;
}
.blte-booking-header .blte-logo img {
  height: 28px;
}
@media screen and (min-width: 576px) {
  .blte-booking-header .blte-logo {
    width: 140px;
  }
  .blte-booking-header .blte-logo img {
    height: 34px;
  }
}
.blte-booking-header__trip-summary {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  display: block;
  border-top: 1px solid #e1e1e1;
  background-color: #fff;
  width: 100%;
  animation: slide-up 1s;
}
@media screen and (min-width: 768px) {
  .blte-booking-header__trip-summary {
    display: none;
  }
}
