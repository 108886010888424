@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-details {
  background: #f2f2f4;
}
.blte-trip-details .blte-account-layout-with-sidebar__content {
  padding: 0 0 42px;
}
@media screen and (min-width: 576px) {
  .blte-trip-details .blte-account-layout-with-sidebar__content {
    padding: 0 0 40px;
  }
}
@media screen and (min-width: 768px) {
  .blte-trip-details .blte-account-layout-with-sidebar__content {
    padding: 48px 0 48px 40px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-trip-details .blte-account-layout-with-sidebar__content {
    padding: 48px 0 48px 64px;
  }
}
.blte-trip-details__mobility {
  margin-top: 60px;
  background: none;
  padding: 0;
}
