@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-tickets-form-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 999999;
  height: 100%;
}
.blte-tickets-form-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 24px;
}
.blte-tickets-form-modal__button {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  width: 28px;
  height: 28px;
  font-size: 0;
  border: 1px solid #a3a3a3;
  border-radius: 50%;
  position: relative;
}
.blte-tickets-form-modal__button::before,
.blte-tickets-form-modal__button::after {
  width: 16px;
  height: 2px;
  background: #363636;
  content: "";
  position: absolute;
  transform: rotate(45deg);
  left: calc(50% - 8px);
}
.blte-tickets-form-modal__button::after {
  transform: rotate(-45deg);
}
