@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-details-share-trip-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  max-height: 80vh;
  padding: 2px;
}
.blte-trip-details-share-trip-modal--withPadding {
  max-height: 100vh;
  height: 100vh;
  padding: 24px;
}
.blte-trip-details-share-trip-modal__close-button {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
}
.blte-trip-details-share-trip-modal__close-button--isMobile {
  top: 24px;
  right: 24px;
}
.blte-trip-details-share-trip-modal__modal > .blte-modal__body {
  padding: 22px;
}
.blte-trip-details-share-trip-modal__modal-mobile .blte-modal__content {
  height: 100vh;
  max-height: 100vh;
}
.blte-trip-details-share-trip-modal__title {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  margin-bottom: 8px;
}
.blte-trip-details-share-trip-modal__error-banner {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding: 8px;
  background: #fae6e6;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-trip-details-share-trip-modal__error-banner > i {
  font-size: 20px;
  font-weight: 450;
  color: #c00;
}
.blte-trip-details-share-trip-modal__subtitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
.blte-trip-details-share-trip-modal__emails-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.blte-trip-details-share-trip-modal__footer {
  display: flex;
  justify-content: end;
  gap: 8px;
  width: 100%;
  margin-top: 8px;
}
.blte-trip-details-share-trip-modal__footer--isMobile {
  margin-top: auto;
}
.blte-trip-details-share-trip-modal__success-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  gap: 8px;
}
.blte-trip-details-share-trip-modal__success-wrapper--isMobile {
  margin-top: auto;
  margin-bottom: auto;
}
.blte-trip-details-share-trip-modal__icon {
  font-size: 64px;
  line-height: 64px;
  color: #094273;
}
.blte-trip-details-share-trip-modal__success-first-row {
  display: flex;
  gap: 4px;
}
.blte-trip-details-share-trip-modal__success-first-row > button {
  padding: 0;
}
.blte-trip-details-share-trip-modal__success-light-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
.blte-trip-details-share-trip-modal__success-bold-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
.blte-trip-details-share-trip-modal__success-second-row {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
}
.blte-trip-details-share-trip-modal__success-footer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
}
