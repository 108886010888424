@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-footer-date-and-booking-number--variant-upcoming-trips-card .blte-footer-date-and-booking-number__footer-date,
.blte-footer-date-and-booking-number--variant-upcoming-trips-card .blte-footer-date-and-booking-number__referenceNumber {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-footer-date-and-booking-number--variant-upcoming-trips-card .blte-footer-date-and-booking-number__referenceNumber {
  margin-left: 12px;
}
@media screen and (min-width: 1024px) {
  .blte-footer-date-and-booking-number--variant-upcoming-trips-card .blte-footer-date-and-booking-number__referenceNumber {
    margin-left: 16px;
  }
}
.blte-footer-date-and-booking-number--variant-upcoming-trip {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .blte-footer-date-and-booking-number--variant-upcoming-trip {
    margin-bottom: 0;
  }
}
.blte-footer-date-and-booking-number--variant-upcoming-trip .blte-footer-date-and-booking-number__footer-date {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-footer-date-and-booking-number--variant-upcoming-trip .blte-footer-date-and-booking-number__footer-date {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-footer-date-and-booking-number--variant-upcoming-trip .blte-footer-date-and-booking-number__referenceNumber {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  letter-spacing: 0;
  margin-left: 16px;
}
@media screen and (min-width: 576px) {
  .blte-footer-date-and-booking-number--variant-upcoming-trip .blte-footer-date-and-booking-number__referenceNumber {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-footer-date-and-booking-number--variant-past-trip {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.blte-footer-date-and-booking-number--variant-past-trip:not(:first-child) {
  margin-top: 8px;
}
.blte-footer-date-and-booking-number--variant-past-trip .blte-footer-date-and-booking-number__referenceNumber {
  letter-spacing: 0;
  margin-left: 16px;
}
