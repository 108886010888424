@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-details-route__content {
  display: flex;
  width: 100%;
  flex-direction: row;
  border-radius: 8px;
  background-color: #fff;
}
.blte-trip-details-route__content--disabledClass {
  opacity: 0.7;
}
.blte-trip-details-route__canceled {
  height: 40px;
  width: 100%;
  background-color: #fffbe6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 15px;
  margin-top: 13px;
  position: relative;
  font-size: 14px;
}
@media screen and (min-width: 1024px) {
  .blte-trip-details-route__canceled {
    font-size: 15px;
  }
}
.blte-trip-details-route__canceled::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 10%;
  transform: translateX(-50%);
  border-width: 12px;
  border-style: solid;
  border-color: transparent transparent #fffbe6;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-route {
    box-shadow: none;
  }
}
.blte-trip-details-route__date {
  min-width: 68px;
  background-color: #2e6a6e;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px 0 0 8px;
  padding: 30px 20px;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-route__date {
    min-width: 130px;
    padding: 35px 44px;
  }
}
@media screen and (min-width: 768px) {
  .blte-trip-details-route__date {
    min-width: 130px;
    padding: 38px 44px;
  }
}
.blte-trip-details-route__day {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-route__day {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-trip-details-route__day {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-trip-details-route__month {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: none;
}
.blte-trip-details-route__details {
  display: flex;
  flex-direction: column;
  max-width: 525px;
  width: 100%;
  padding: 0 30px;
}
.blte-trip-details-route__hours,
.blte-trip-details-route__stations {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.blte-trip-details-route__stations {
  justify-content: space-between;
  margin-bottom: 8px;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-route__stations {
    margin-bottom: 16px;
  }
}
.blte-trip-details-route__departure,
.blte-trip-details-route__arrival {
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-route__departure,
  .blte-trip-details-route__arrival {
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-trip-details-route__departure,
  .blte-trip-details-route__arrival {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 1024px) {
  .blte-trip-details-route__departure,
  .blte-trip-details-route__arrival {
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-trip-details-route__departure {
  margin-right: 8px;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-route__departure {
    margin-right: 24px;
  }
}
.blte-trip-details-route__arrival {
  margin-left: 8px;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-route__arrival {
    margin-left: 24px;
  }
}
.blte-trip-details-route__train {
  min-height: 18px;
  margin-bottom: 4px;
}
.blte-trip-details-route__train--status {
  text-transform: capitalize;
}
.blte-trip-details-route__train--status-ontime {
  padding: 0 6px;
  width: fit-content;
  color: #fff;
  background-color: #137e00;
  border-radius: 4px;
}
.blte-trip-details-route__duration {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  justify-content: center;
  max-width: 292px;
}
.blte-trip-details-route__duration-value {
  display: none;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-route__duration-value {
    position: absolute;
    display: flex;
    padding: 0 8px;
    background-color: #fff;
  }
}
.blte-trip-details-route__border {
  flex: 1;
  border: 2px solid #f2f2f4;
  height: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.blte-trip-details-route__border::before {
  content: " ";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #f2f2f4;
  background-color: #fff;
}
.blte-trip-details-route__border::after {
  content: " ";
  width: 7px;
  height: 7px;
  background-color: #f2f2f4;
  box-shadow: 0 0 0 2px #f2f2f4;
}
.blte-trip-details-route__station {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
.blte-trip-details-route__boarding-info {
  display: flex;
  margin-bottom: 10px;
}
.blte-trip-details-route--class-smart_standard {
  background-color: #ffdb00;
  color: #363636;
}
.blte-trip-details-route--class-smart_saver {
  background-color: #ffef8c;
  color: #363636;
}
.blte-trip-details-route--class-premium_standard {
  background-color: #363636;
  color: #fff;
}
.blte-trip-details-route--class-smart_standard,
.blte-trip-details-route--class-smart_saver,
.blte-trip-details-route--class-premium_standard {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  width: fit-content;
  border-radius: 4px;
  padding: 0 6px;
  align-items: center;
}
.blte-trip-details-route__boarding-close {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  margin-left: 8px;
}
.blte-trip-details-route__previous-times {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  justify-content: space-between;
  color: #565656;
  text-decoration: line-through;
}
.blte-trip-details-route__status-tag {
  width: fit-content;
  margin-bottom: 4px;
}
