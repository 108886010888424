@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-odselect {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 280px;
  width: 100%;
  padding: 12px 16px;
}
.blte-odselect:focus {
  outline: none;
}
.blte-odselect__label {
  color: #094273;
  background-color: #fff;
  overflow: hidden;
  max-width: calc(100% - 80px);
  white-space: nowrap;
  text-overflow: ellipsis;
}
.blte-odselect__label-required {
  color: #c00;
}
.blte-odselect__select {
  padding: 0;
  background: none;
  width: 100%;
  border-radius: 4px;
  border: none;
  outline: none;
  color: gray;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  line-height: 26px;
  text-align: start;
}
.blte-odselect--accessibility-focus,
.blte-odselect--has-focus {
  color: #363636;
  box-shadow: 0 0 0 2px #094273;
  border-radius: 8px;
}
.blte-odselect--accessibility-focus .blte-odselect__label,
.blte-odselect--has-focus .blte-odselect__label {
  color: #094273;
}
.blte-odselect--has-error:not(.blte-odselect--disabled) .blte-odselect__label {
  color: #c00;
  font-weight: 500;
  transform: translateY(-50%) scale(1);
  top: 0;
  font-size: 12px;
  opacity: 1;
  left: 8px !important;
}
.blte-odselect--has-error:not(.blte-odselect--disabled) .blte-odselect__select {
  box-shadow: 0 0 0 1px #c00;
  color: #363636;
}
.blte-odselect--has-error:not(.blte-odselect--disabled).blte-odselect--has-focus .blte-odselect__select,
.blte-odselect--has-error:not(.blte-odselect--disabled).blte-odselect--accessibility-focus .blte-odselect__select {
  box-shadow: 0 0 0 2px #c00;
}
.blte-odselect--disabled {
  pointer-events: none;
  cursor: not-allowed;
  color: #e1e1e1;
}
.blte-odselect--disabled .blte-odselect__label,
.blte-odselect--disabled .blte-odselect__select,
.blte-odselect--disabled .blte-odselect__error,
.blte-odselect--disabled .blte-odselect__label-required,
.blte-odselect--disabled .blte-odselect__helper-container {
  color: #e1e1e1;
}
.blte-odselect--disabled .blte-odselect__select {
  box-shadow: 0 0 0 1px #e1e1e1;
}
.blte-odselect__error {
  position: absolute;
  padding: 0 8px;
  width: 100%;
  gap: 16px;
  color: #c00;
}
