@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-select__label::before {
  background-color: transparent;
}
.blte-monthly-parking-passes-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
@media screen and (min-width: 1024px) {
  .blte-monthly-parking-passes-form {
    align-items: flex-start;
  }
}
.blte-monthly-parking-passes-form__body {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .blte-monthly-parking-passes-form__body {
    width: 450px;
    align-items: center;
  }
}
@media screen and (min-width: 1024px) {
  .blte-monthly-parking-passes-form__body {
    width: 100%;
    align-items: flex-start;
  }
}
.blte-monthly-parking-passes-form__title {
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .blte-monthly-parking-passes-form__title {
    font-weight: 500;
    font-size: 44px;
    line-height: 54px;
    text-align: unset;
  }
}
.blte-monthly-parking-passes-form__subtitle {
  margin-top: 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .blte-monthly-parking-passes-form__subtitle {
    margin-top: 24px;
    font-weight: 500;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
  }
}
@media screen and (min-width: 1024px) {
  .blte-monthly-parking-passes-form__subtitle {
    margin-top: 26px;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    text-align: unset;
  }
}
.blte-monthly-parking-passes-form__text {
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .blte-monthly-parking-passes-form__text {
    text-align: unset;
  }
}
.blte-monthly-parking-passes-form__text--garageNote {
  width: fit-content;
  margin-top: 8px;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}
.blte-monthly-parking-passes-form__text--renewNote {
  width: fit-content;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin-top: 16px;
}
@media screen and (min-width: 1024px) {
  .blte-monthly-parking-passes-form__text--renewNote {
    margin-top: 8px;
  }
}
.blte-monthly-parking-passes-form__input {
  padding-top: 16px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-monthly-parking-passes-form__input {
    width: 450px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-monthly-parking-passes-form__input {
    width: 220px;
  }
}
.blte-monthly-parking-passes-form__total {
  font-size: 26px;
  font-weight: 500;
}
.blte-monthly-parking-passes-form__total--discount {
  text-decoration: line-through;
  margin-right: 10px;
}
.blte-monthly-parking-passes-form__autoRenew {
  margin-top: 16px;
}
@media screen and (min-width: 1024px) {
  .blte-monthly-parking-passes-form__autoRenew {
    margin-top: 24px;
  }
}
.blte-monthly-parking-passes-form__row {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: 16px;
}
@media screen and (min-width: 1024px) {
  .blte-monthly-parking-passes-form__row {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
    margin-top: 40px;
  }
}
.blte-monthly-parking-passes-form__price-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
  order: 1;
}
@media screen and (min-width: 1024px) {
  .blte-monthly-parking-passes-form__price-row {
    order: 2;
  }
}
.blte-monthly-parking-passes-form__btn-wrapper {
  display: flex;
  width: 100%;
  order: 2;
}
@media screen and (min-width: 1024px) {
  .blte-monthly-parking-passes-form__btn-wrapper {
    width: unset;
    order: 1;
  }
}
.blte-monthly-parking-passes-form__btn {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-monthly-parking-passes-form__btn {
    max-width: 450px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-monthly-parking-passes-form__btn {
    width: unset;
  }
}
