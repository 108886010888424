@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-newsletter-form {
  position: relative;
  background: linear-gradient(180deg, #e8f2f1 0%, rgba(232, 242, 241, 0) 100%);
  padding: 24px 0;
}
.blte-newsletter-form__content {
  max-width: 1400px;
  margin: 0 auto;
  width: calc(100% - 48px);
  box-sizing: content-box;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 576px) {
  .blte-newsletter-form__content {
    width: calc(100% - 128px);
  }
}
@media screen and (min-width: 768px) {
  .blte-newsletter-form__content {
    width: calc(100% - 80px);
  }
}
@media screen and (min-width: 1024px) {
  .blte-newsletter-form__content {
    width: calc(100% - 128px);
  }
}
@media screen and (min-width: 768px) {
  .blte-newsletter-form__content {
    flex-wrap: nowrap;
    align-items: center;
  }
}
.blte-newsletter-form__title {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-newsletter-form__title {
    width: 33.3333333333%;
  }
}
@media screen and (min-width: 1024px) {
  .blte-newsletter-form__title {
    width: 25%;
  }
}
.blte-newsletter-form__title .blte-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0;
  text-transform: none;
  color: #094273;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0;
  text-transform: none;
  padding-left: 10px;
}
@media screen and (min-width: 576px) {
  .blte-newsletter-form__title .blte-title {
    padding-top: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-newsletter-form__title .blte-title {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-newsletter-form__required-field-label {
  margin: 40px 0 -15px 5px;
}
.blte-newsletter-form__form {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-newsletter-form__form {
    width: 66.6666666667%;
    padding-left: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-newsletter-form__form {
    width: 75%;
    padding-left: 40px;
  }
}
.blte-newsletter-form__fields {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
@media screen and (min-width: 768px) {
  .blte-newsletter-form__fields {
    flex-direction: row;
  }
}
@media screen and (min-width: 1024px) {
  .blte-newsletter-form__fields {
    flex-direction: row;
  }
}
.blte-newsletter-form__fields > button {
  margin-left: 8px;
  white-space: nowrap;
  height: 48px;
  margin-top: 15px;
}
@media screen and (min-width: 576px) {
  .blte-newsletter-form__fields > button {
    margin-top: 15px;
  }
}
.blte-newsletter-form .blte-input__fieldset {
  margin-left: 8px;
  margin-top: 15px;
}
.blte-newsletter-form .blte-input__label {
  top: 26px;
}
.blte-newsletter-form .blte-input--has-value .blte-input__label {
  top: 0;
}
.blte-newsletter-form .blte-input__helper-container {
  font-weight: 450;
  font-size: 12px;
  line-height: 18px;
  color: #094273;
  margin-top: 4px;
  position: static;
}
.blte-newsletter-form .blte-input__helper-container a {
  color: #094273;
  outline: none;
}
.blte-newsletter-form .blte-input__helper-container a:focus-visible {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #363636;
  border-radius: 2px;
}
.blte-newsletter-form__emailField {
  width: 100%;
}
@media screen and (min-width: 576px) {
  .blte-newsletter-form__emailField {
    min-width: 140px;
  }
}
.blte-newsletter-form__disclaimerText {
  margin-left: 9px;
  font-weight: 450;
  line-height: 18px;
  color: #094273;
  font-size: 12px;
  margin-top: 5px;
}
@media screen and (min-width: 1024px) {
  .blte-newsletter-form__disclaimerText {
    font-size: 14px;
  }
}
.blte-newsletter-form--hasImage .blte-newsletter-form__title {
  order: 1;
  width: 58.3333333333%;
}
@media screen and (min-width: 768px) {
  .blte-newsletter-form--hasImage .blte-newsletter-form__title {
    width: 33.3333333333%;
  }
}
@media screen and (min-width: 1024px) {
  .blte-newsletter-form--hasImage .blte-newsletter-form__title {
    width: 25%;
  }
}
.blte-newsletter-form--hasImage .blte-newsletter-form__form {
  order: 3;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-newsletter-form--hasImage .blte-newsletter-form__form {
    order: 2;
    width: 100%;
    padding-left: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-newsletter-form--hasImage .blte-newsletter-form__form {
    width: 50%;
    padding-left: 40px;
  }
}
.blte-newsletter-form--hasImage .blte-newsletter-form__image {
  order: 2;
  width: 41.6666666667%;
  padding-left: 24px;
}
@media screen and (min-width: 768px) {
  .blte-newsletter-form--hasImage .blte-newsletter-form__image {
    order: 3;
    width: 25%;
  }
}
@media screen and (min-width: 1024px) {
  .blte-newsletter-form--hasImage .blte-newsletter-form__image {
    padding-left: 40px;
  }
}
.blte-newsletter-form--status-success .blte-input__helper-container,
.blte-newsletter-form--status-success .blte-input__helper-container a {
  color: #137e00;
}
