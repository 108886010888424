@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-feature-item {
  display: flex;
  flex-direction: column;
  position: relative;
}
.blte-feature-item--background {
  background-color: var(--feature-item-background-color);
  padding: 40px;
  border-radius: 4px;
  padding: 40px 24px;
}
@media screen and (min-width: 576px) {
  .blte-feature-item--background {
    padding: 40px;
  }
}
.blte-feature-item--variant-textOnly .blte-feature-item__checkmark,
.blte-feature-item--variant-icon .blte-feature-item__checkmark {
  display: none;
}
.blte-feature-item--variant-textOnly .blte-feature-item__icon,
.blte-feature-item--variant-checkmark .blte-feature-item__icon {
  display: none;
}
.blte-feature-item--variant-checkmark .blte-feature-item__checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
}
.blte-feature-item--variant-checkmark .blte-feature-item__checkmark > * {
  background-color: var(--feature-item-checkmark-background-color, #ffdb00);
  color: var(--feature-item-checkmark-color, #363636);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  font-size: 10px;
  border-radius: 50%;
  margin: auto;
}
.blte-feature-item--variant-checkmark.blte-feature-item--alignment-left .blte-feature-item__description,
.blte-feature-item--variant-checkmark.blte-feature-item--alignment-left .blte-feature-item__cta,
.blte-feature-item--variant-checkmark.blte-feature-item--alignment-left .blte-feature-item__flag {
  margin-left: 36px;
}
.blte-feature-item--variant-checkmark .blte-feature-item__title {
  margin-left: 8px;
  width: 100%;
}
.blte-feature-item--variant-icon .blte-feature-item__icon {
  width: 48px;
  height: 48px;
  font-size: 24px;
  margin-bottom: 12px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--feature-item-icon-color, #363636);
  background-color: var(--feature-item-icon-background-color, #fff);
}
.blte-feature-item--alignment-left .blte-feature-item__content {
  align-items: start;
  text-align: left;
}
.blte-feature-item--alignment-left.blte-feature-item--background .blte-feature-item__flag {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 0;
}
.blte-feature-item--alignment-center .blte-feature-item__content {
  align-items: center;
  text-align: center;
}
.blte-feature-item--alignment-center .blte-feature-item__flag {
  display: flex;
  align-self: center;
}
.blte-feature-item--alignment-center.blte-feature-item--background .blte-feature-item__flag {
  position: absolute;
  top: 0;
  display: flex;
  align-self: center;
}
.blte-feature-item__flag {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  width: fit-content;
  padding: 0 4px;
  border-radius: 4px;
  color: var(--feature-item-flag-text-color, #fff);
  background-color: var(--feature-item-flag-background-color, #363636);
  margin-bottom: 12px;
}
.blte-feature-item__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.blte-feature-item__title-content {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 12px;
  color: var(--feature-item-title-color, #363636);
}
@media screen and (min-width: 576px) {
  .blte-feature-item__title-content {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-feature-item__description {
  margin-bottom: 12px;
  margin-right: 12px;
  color: var(--feature-item-description-color, #363636);
}
.blte-feature-item__description ul {
  padding-inline-start: 0;
}
.blte-feature-item__description li {
  list-style: none;
  display: flex;
}
.blte-feature-item__description a {
  color: #094273;
  outline: none;
}
.blte-feature-item__description a:focus-visible {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #363636;
  border-radius: 2px;
}
.blte-feature-item__cta {
  margin-top: auto;
}
