@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-wallet-desktop__title {
  display: none;
}
@media screen and (min-width: 768px) {
  .blte-account-wallet-desktop__title {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
    display: flex;
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 768px) and (min-width: 576px) {
  .blte-account-wallet-desktop__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .blte-account-wallet-desktop__title {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-account-wallet-desktop__content > * {
  margin-bottom: 16px;
}
.blte-account-wallet-desktop__content > *:last-child {
  margin-bottom: 0;
}
.blte-account-wallet-desktop__footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  column-gap: 16px;
  margin-top: 16px;
}
.blte-account-wallet-desktop__empty-actions {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
}
.blte-account-wallet-desktop__wallet-title {
  font-size: inherit;
  font-weight: inherit;
}
