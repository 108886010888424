@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-pass-card {
  display: flex;
  padding: 12px;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  flex-direction: column;
  max-width: 374px;
  justify-content: space-between;
  height: 100% !important;
}
@media screen and (min-width: 576px) {
  .blte-pass-card--isBlWallet {
    height: 195px !important;
  }
}
.blte-pass-card--type-train .blte-pass-card__expires-label {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-pass-card--type-train .blte-pass-card__expires-date {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-pass-card--type-parking {
  background: radial-gradient(87.45% 120.57% at 19.27% 108.91%, #2e6a6e 42.71%, #388d99 100%);
  color: #fff;
}
.blte-pass-card--type-parking .blte-pass-card__stations {
  font-size: 22px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-pass-card--type-credit {
  background-color: #ffdb00;
}
.blte-pass-card--passType-redeemable_rides {
  background: radial-gradient(87.45% 120.57% at 19.27% 108.91%, #363636 39.34%, #636366 100%);
  color: #fff;
}
.blte-pass-card--passType-redeemable_rides .blte-pass-card__expires-label,
.blte-pass-card--passType-redeemable_rides .blte-pass-card__expires-date {
  color: #fff;
}
.blte-pass-card__header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.blte-pass-card__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  flex-flow: row wrap;
  margin-right: 24px;
  flex: 1;
}
@media screen and (min-width: 576px) {
  .blte-pass-card__title {
    font-size: 22px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-pass-card__renewal-wrapper {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 8px 0 2px;
  background: #fff;
  color: #c00;
  border-radius: 12px;
  min-width: 54px;
  min-height: 24px;
  width: fit-content;
  height: max-content;
}
.blte-pass-card__renewal-wrapper--active {
  color: #137e00;
}
.blte-pass-card__renewal-icon {
  font-size: 20px;
}
.blte-pass-card__details {
  display: flex;
  flex-direction: column;
}
.blte-pass-card__class {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  padding: 0 4px;
  text-transform: capitalize;
  background-color: #fff;
  width: fit-content;
  color: #363636;
  border-radius: 4px;
}
.blte-pass-card__stations {
  display: flex;
  align-items: center;
}
.blte-pass-card__station {
  font-size: 22px;
  font-weight: 450;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-pass-card__trip-icon {
  display: flex;
  align-items: center;
  font-size: 28px;
  width: 28px;
  height: 28px;
  margin: 0 4px;
}
.blte-pass-card__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.blte-pass-card__footer-left,
.blte-pass-card__footer-right {
  display: flex;
  flex-direction: column;
}
.blte-pass-card__footer-left--lastDate,
.blte-pass-card__footer-right--lastDate {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: none;
  padding: 5px 9px;
  background: #fff;
  color: #c00;
  border-radius: 12px;
  min-width: 54px;
  min-height: 24px;
  height: max-content;
}
.blte-pass-card__footer-right {
  align-items: flex-end;
}
.blte-pass-card__footer-right-label {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-pass-card__footer-right-data {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-pass-card__footer-right-icon {
  width: 26px;
  height: 24px;
  font-size: 24px;
  display: flex;
  align-items: center;
  margin-left: 16px;
}
.blte-pass-card__expires-label {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-pass-card__expires-date {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
