@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-details-extras {
  margin-top: 16px;
  width: 100%;
  padding: 16px 24px;
  background: #fff;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-extras {
    padding: 24px 64px;
    margin-top: 24px;
  }
}
@media screen and (min-width: 768px) {
  .blte-trip-details-extras {
    margin-top: 60px;
    padding: 0;
    background: none;
  }
}
.blte-trip-details-extras__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  text-transform: none;
  padding-bottom: 24px;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-extras__title {
    font-size: 22px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-trip-details-extras__item-card {
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-extras__item-card {
    padding: 16px;
  }
}
.blte-trip-details-extras__item-title-wrapper {
  display: flex;
  align-items: center;
}
.blte-trip-details-extras__item-info-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-extras__item-info-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-trip-details-extras__item-edit {
  margin-left: 16px;
  cursor: pointer;
  text-decoration: underline;
  color: #094273;
}
.blte-trip-details-extras__parking-item-edit {
  margin-left: 16px;
  cursor: pointer;
  text-decoration: underline;
  color: #094273;
  display: flex;
  column-gap: 16px;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-extras__parking-item-edit {
    column-gap: 24px;
  }
}
@media screen and (min-width: 768px) {
  .blte-trip-details-extras__parking-item-edit {
    display: flex;
    column-gap: 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
.blte-trip-details-extras__parking-item-edit > * {
  min-height: 36px;
}
.blte-trip-details-extras__item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blte-trip-details-extras__item-header .blte-icon {
  background-color: #2e6a6e;
  border-radius: 50%;
  padding: 0 4px 0 5px;
  font-size: 22px;
  color: #fff;
  width: 32px;
  height: 32px;
  margin-right: 12px;
}
.blte-trip-details-extras__item-header .blte-icon.blte-icon__car {
  line-height: 1.2;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-extras__item-header .blte-icon {
    margin-right: 16px;
  }
}
.blte-trip-details-extras__item-content {
  display: flex;
  margin-top: 8px;
}
@media screen and (min-width: 1024px) {
  .blte-trip-details-extras__item-content {
    padding-left: 48px;
  }
}
.blte-trip-details-extras__parking-item-content {
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
}
@media screen and (min-width: 1024px) {
  .blte-trip-details-extras__parking-item-content {
    padding-left: 48px;
  }
}
.blte-trip-details-extras__item-content-direction {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  flex-direction: column;
  min-width: 25%;
  max-width: 50%;
}
.blte-trip-details-extras__item-content-direction:first-child {
  padding-right: 16px;
}
.blte-trip-details-extras__item-content-direction-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: none;
  color: #094273;
  padding-bottom: 4px;
}
.blte-trip-details-extras__item-content-parking-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  padding-bottom: 8px;
}
@media screen and (min-width: 1024px) {
  .blte-trip-details-extras__item-content-parking-title {
    padding-bottom: 4px;
  }
}
.blte-trip-details-extras__item-content-parking-driver span {
  padding-right: 24px;
}
