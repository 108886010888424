@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-seat-selection-mobile {
  display: flex;
  position: absolute;
  inset: 0;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.blte-seat-selection-mobile__cancel-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
.blte-seat-selection-mobile__disabled-seat-icon {
  opacity: 0.5;
  font-size: 100px;
}
.blte-seat-selection-mobile__disabled-seat-header {
  font-weight: 500;
  font-size: 22px;
  margin: 8px;
}
.blte-seat-selection-mobile__disabled-seat-text {
  font-size: 22px;
  margin: 0;
}
.blte-seat-selection-mobile__top {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.blte-seat-selection-mobile__header,
.blte-seat-selection-mobile__routes-wrapper,
.blte-seat-selection-mobile__select-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 24px;
  align-items: center;
}
@media screen and (min-width: 576px) {
  .blte-seat-selection-mobile__header,
  .blte-seat-selection-mobile__routes-wrapper,
  .blte-seat-selection-mobile__select-wrapper {
    padding: 8px 64px;
  }
}
.blte-seat-selection-mobile__header {
  color: #fff;
  background-color: #2e6a6e;
}
.blte-seat-selection-mobile__back {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 28px;
  transform: rotate(180deg);
  outline: none;
  color: #fff;
}
.blte-seat-selection-mobile__data {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
}
.blte-seat-selection-mobile__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-seat-selection-mobile__title {
    font-size: 22px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-seat-selection-mobile__stations {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  gap: 4px;
}
.blte-seat-selection-mobile__from-to {
  width: 24px;
  height: 24px;
  font-size: 22px;
}
.blte-seat-selection-mobile__routes-wrapper {
  border-bottom: 1px solid #f2f2f4;
}
.blte-seat-selection-mobile__routes {
  display: flex;
  width: 100%;
  padding: 4px;
  border-radius: 8px;
  background-color: #f2f2f4;
}
.blte-seat-selection-mobile__route {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  width: 50%;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  color: #363636;
  outline: none;
  background-color: transparent;
}
@media screen and (min-width: 576px) {
  .blte-seat-selection-mobile__route {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-seat-selection-mobile__route--active {
  border-radius: 8px;
  background-color: #ffdb00;
}
.blte-seat-selection-mobile__route--disabled {
  color: #8d8d8d;
}
.blte-seat-selection-mobile__route--disabled:hover {
  cursor: default;
}
.blte-seat-selection-mobile__select-wrapper {
  z-index: 1;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
}
.blte-seat-selection-mobile__select-wrapper > div {
  display: flex;
  width: 100%;
}
.blte-seat-selection-mobile__seat-map {
  display: flex;
  height: 100%;
  overflow: auto;
  justify-content: center;
  margin-bottom: 140px;
}
@media screen and (min-width: 576px) {
  .blte-seat-selection-mobile__seat-map {
    margin-bottom: 180px;
  }
}
.blte-seat-selection-mobile__seat-map--carousel {
  margin-bottom: 160px;
}
@media screen and (min-width: 576px) {
  .blte-seat-selection-mobile__seat-map--carousel {
    margin-bottom: 190px;
  }
}
.blte-seat-selection-mobile__seat-map::-webkit-scrollbar {
  display: none;
}
.blte-seat-selection-mobile__seat-map ::-webkit-scrollbar {
  display: none;
}
.blte-seat-selection-mobile__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 16px 16px 0 0;
  background-color: #fff;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.15);
}
.blte-seat-selection-mobile__passenger-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  border-radius: 16px 16px 0 0;
  padding: 16px 24px 8px;
}
@media screen and (min-width: 576px) {
  .blte-seat-selection-mobile__passenger-details {
    padding: 16px 64px 12px;
  }
}
.blte-seat-selection-mobile__btn-container {
  display: flex;
  width: 100%;
  padding: 12px 24px;
  border-top: 1px solid #e1e1e1;
}
@media screen and (min-width: 576px) {
  .blte-seat-selection-mobile__btn-container {
    padding: 24px 64px;
  }
}
.blte-seat-selection-mobile__update-error {
  position: absolute;
  padding: 8px 12px 8px 8px;
  display: flex;
  bottom: 185px;
  left: 0;
  right: 0;
  width: fit-content;
  justify-content: center;
  align-items: center;
  background: #fae5e5;
  gap: 8px;
  border-radius: 4px;
  margin: 0 auto;
  z-index: 1;
}
@media screen and (min-width: 576px) {
  .blte-seat-selection-mobile__update-error {
    bottom: 210px;
  }
}
.blte-seat-selection-mobile__update-error-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 22px;
  color: #c00;
}
.blte-seat-selection-mobile__update-error-message {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
