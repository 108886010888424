@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-seat {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  color: #363636;
  background-color: #c9edf9;
  padding: 2px 6px;
  border-radius: 4px;
}
.blte-trip-seat__number {
  margin-right: 4px;
}
.blte-trip-seat .blte-icon {
  width: 20px;
  height: 20px;
}
