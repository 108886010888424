@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-cancel-trip-modal__buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.blte-cancel-trip-modal__buttons > :first-child {
  margin-right: 12px;
  background-color: #e1e1e1;
}
.blte-cancel-trip-modal__tripCancelledButton > :first-child {
  width: 100%;
}
.blte-cancel-trip-modal__buttons-unstyled {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.blte-cancel-trip-modal__buttons-unstyled > :first-child {
  margin-right: 12px;
}
.blte-cancel-trip-modal__text {
  margin-bottom: 16px;
  font-size: 18px;
}
.blte-cancel-trip-modal__text-group {
  margin-bottom: 16px;
  font-size: 18px;
  word-wrap: break-word;
}
.blte-cancel-trip-modal__icon {
  margin-bottom: 20px;
}
.blte-cancel-trip-modal__icon > :first-child {
  display: flex;
  margin: auto;
  color: #fefefe;
  background-color: #094273;
  outline: #094273;
}
