@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-profile-settings__section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.blte-profile-settings__section-action {
  padding: 0;
}
.blte-profile-settings__alert-section {
  margin-bottom: 16px;
}
.blte-profile-settings__has-top-margin {
  margin-top: 40px;
}
.blte-profile-settings__section-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-profile-settings__section-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 576px) {
  .blte-profile-settings__section-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 576px) and (min-width: 576px) {
  .blte-profile-settings__section-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-profile-settings__row {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .blte-profile-settings__row {
    flex-direction: row;
  }
}
.blte-profile-settings__column {
  width: 50%;
}
@media screen and (min-width: 768px) {
  .blte-profile-settings__column:nth-child(2) {
    margin-left: 16px;
  }
}
.blte-profile-settings__field {
  width: 100%;
  padding: 0;
  margin-bottom: 16px;
}
.blte-profile-settings__field--no-bottom {
  margin-bottom: 0;
}
.blte-profile-settings__field .blte-phone-number__country-code {
  margin-right: 16px;
}
.blte-profile-settings__field.blte-checkbox {
  width: auto;
  padding-right: 8px;
}
.blte-profile-settings__assistance {
  display: flex;
  align-items: center;
  color: #094273;
  font-size: 24px;
}
.blte-profile-settings__text,
.blte-profile-settings__change {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 4px;
}
.blte-profile-settings__ckeck-icon {
  margin: 4px;
  font-size: 14px;
  padding: 2px 6px;
  border-radius: 50%;
  color: #fff;
  background-color: #137e00;
}
.blte-profile-settings__readonly {
  border-radius: 4px;
  background-color: #e8f2f1;
  display: flex;
  padding: 8px 16px 8px 8px;
}
.blte-profile-settings__info {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
}
@media screen and (min-width: 576px) {
  .blte-profile-settings__info {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-profile-settings__delete-account-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 24px;
}
.blte-profile-settings__delete-account-input {
  margin-bottom: 24px;
}
.blte-profile-settings__delete-account-actions {
  display: flex;
  flex-direction: column-reverse;
  gap: 12px;
}
.blte-profile-settings__delete-account-actions .blte-btn {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-profile-settings__delete-account-actions {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
    gap: 8px;
  }
  .blte-profile-settings__delete-account-actions .blte-btn {
    width: auto;
  }
}
.blte-profile-settings__delete-account-submit-action {
  box-shadow: 0 0 0 2px var(--button-background-or-border-color, #c00);
}
.blte-profile-settings__user-account {
  padding: 8px 0 0;
}
@media screen and (min-width: 576px) {
  .blte-profile-settings__user-account {
    padding: 16px 0 0;
  }
}
.blte-overlay-container.blte-delete-account-overlay .blte-overlay-container__description {
  margin-top: 16px;
}
@media screen and (min-width: 768px) {
  .blte-overlay-container.blte-delete-account-overlay .blte-overlay-container__description {
    margin-top: 4px;
  }
}
