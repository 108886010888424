@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rewards__container {
  background-image: var(--rewards-background-image);
  background-color: #fff;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 576px) {
  .blte-rewards__container {
    padding: 16px;
    min-width: 200px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-rewards__container {
    min-width: 280px;
  }
}
.blte-rewards__container:hover {
  cursor: pointer;
}
.blte-rewards__info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.blte-rewards__points {
  display: flex;
  align-items: center;
}
.blte-rewards__title,
.blte-rewards__available-pts {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}
.blte-rewards__available-pts-label {
  color: var(--available-points-label-color, #c669f6);
  font-weight: 700;
  font-size: 14px;
  margin-block-start: 0;
  margin-block-end: 0;
}
.blte-rewards__pending-pts {
  color: var(--pending-points-color, #4297ba);
  white-space: nowrap;
  font-weight: 500;
}
.blte-rewards__pending-pts-label {
  margin-block-start: 0;
  margin-block-end: 0;
  color: var(--pending-points-label-color, #8d8d8d);
  font-size: 16px;
}
.blte-rewards__icon {
  margin-left: auto;
}
.blte-rewards-placeholders__container {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 24px;
}
@media screen and (min-width: 576px) {
  .blte-rewards-placeholders__container {
    min-width: 200px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-rewards-placeholders__container {
    min-width: 280px;
  }
}
.blte-rewards-placeholders__title {
  width: 50%;
  height: 20px;
  border-radius: 2px;
  margin-bottom: 12px;
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
}
.blte-rewards-placeholders__title::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #fff, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-rewards-placeholders__points {
  display: flex;
  align-items: center;
}
.blte-rewards-placeholders__available-pts,
.blte-rewards-placeholders__pending-pts {
  width: 20%;
  height: 30px;
  border-radius: 2px;
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
}
.blte-rewards-placeholders__available-pts::after,
.blte-rewards-placeholders__pending-pts::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #fff, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
