@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-wifi-splash-form-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.blte-wifi-splash-form-header__logo {
  width: 220px;
  max-width: 220px;
}
