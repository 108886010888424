@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-edit-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  padding: 12px 8px 12px 16px;
  margin: 8px 0;
  background-color: #f2f2f4;
  color: #363636;
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-edit-section {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-edit-section__button {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  padding: 0 8px;
  color: #094273;
}
.blte-edit-section__button:focus {
  outline: none;
}
.blte-edit-section__button:focus-visible {
  outline: 2px solid #363636;
}
.blte-edit-section__button i::after {
  font-size: 15px;
}
