@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-divider {
  display: block;
  background-color: var(--background-color, #f2f2f4);
}
.blte-divider__wrapper {
  display: flex !important;
  justify-content: center;
  overflow: hidden;
}
.blte-divider__wrapper[class*=blte-padding--left] > div {
  margin-left: 0;
}
.blte-divider__wrapper[class*=blte-padding--right] > div {
  margin-right: 0;
}
.blte-divider__wrapper[class*=blte-padding--top] > div {
  margin-top: 0;
}
.blte-divider__wrapper[class*=blte-padding--bottom] > div {
  margin-bottom: 0;
}
.blte-divider--alignment-vertical {
  width: var(--width);
  margin: 0 var(--margin);
  min-height: 5px;
  align-self: stretch;
}
.blte-divider--alignment-horizontal {
  width: 100%;
  height: var(--width);
  margin: var(--margin) 0;
}
