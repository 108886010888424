@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-train-passes-footer {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  width: 100%;
  flex-direction: column-reverse;
  align-items: center;
  gap: 20px;
}
@media screen and (min-width: 1024px) {
  .blte-train-passes-footer {
    justify-content: space-between;
    margin-top: 40px;
    flex-direction: row;
    align-items: unset;
    gap: unset;
  }
}
.blte-train-passes-footer__total {
  font-size: 26px;
  font-weight: 500;
  display: inline-block;
}
.blte-train-passes-footer__total.strike {
  text-decoration: line-through;
  margin-right: 10px;
}
.blte-train-passes-footer__continueButton {
  width: 100%;
}
.blte-train-passes-footer__continueButton button {
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .blte-train-passes-footer__continueButton {
    width: auto;
  }
  .blte-train-passes-footer__continueButton button {
    width: auto;
  }
}
