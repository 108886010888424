@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-line {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  padding: 8px 16px;
  outline: none;
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-trip-line {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-trip-line:focus-visible {
  border-radius: 4px;
  box-shadow: 0 0 0 2px #363636;
  z-index: 1;
}
.blte-trip-line + .blte-trip-line {
  border-top: 1px solid #e1e1e1;
}
.blte-trip-line__seating-unavailable-text {
  color: #363636;
}
@media screen and (min-width: 768px) {
  .blte-trip-line__seating-unavailable-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-trip-line__seat {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.blte-trip-line__location {
  display: flex;
  align-items: center;
}
.blte-trip-line__description {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
