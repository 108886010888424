@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-wallet-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blte-account-wallet-mobile__content {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  width: 100%;
  background-color: #fff;
}
@media screen and (min-width: 576px) {
  .blte-account-wallet-mobile__content {
    padding: 16px 64px 24px;
  }
}
.blte-account-wallet-mobile__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.blte-account-wallet-mobile__book-action {
  margin-bottom: 16px;
}
.blte-account-wallet-mobile__slides-indicators {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
}
.blte-account-wallet-mobile__slide-indicator {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
}
.blte-account-wallet-mobile__renew {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 16px 24px;
  width: 100%;
  background-color: #fff;
  height: fit-content;
}
@media screen and (min-width: 576px) {
  .blte-account-wallet-mobile__renew {
    padding: 16px 64px;
  }
}
.blte-account-wallet-mobile__divider {
  border-top: 1px solid #e1e1e1;
  margin: 8px 0;
}
.blte-account-wallet-mobile--empty {
  color: #8d8d8d;
}
.blte-account-wallet-mobile__user-account {
  padding: 8px 24px 0;
}
@media screen and (min-width: 576px) {
  .blte-account-wallet-mobile__user-account {
    padding: 16px 64px 0;
  }
}
