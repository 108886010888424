@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-cardslist {
  font-style: normal;
  margin: 0 auto;
  padding: 0 20px 20px;
  font-family: Arial, sans-serif;
  position: relative;
}
.blte-cardslist__vertical-line-container {
  display: none;
}
@media screen and (min-width: 768px) {
  .blte-cardslist__vertical-line-container {
    display: var(--display-line);
    position: relative;
    align-self: stretch;
  }
}
.blte-cardslist__vertical-line {
  width: 2px;
  background-color: #ccc;
}
.blte-cardslist__horizontal-dot-container {
  display: flex;
  align-items: center;
}
.blte-cardslist__corner-dot {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
}
.blte-cardslist__horizontal-line {
  width: 38px;
  height: 2px;
  background-color: #ccc;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .blte-cardslist__horizontal-line {
    width: 25px;
  }
}
.blte-cardslist__title {
  position: relative;
  z-index: 1;
  color: var(--cardslist-title-color, #363636);
  text-align: var(--cardslist-title-alignment);
}
.blte-cardslist__title--showTitle {
  margin-bottom: 10px;
}
.blte-cardslist__description {
  font-size: 16px;
  position: relative;
  z-index: 1;
  color: var(--cardslist-description-color, #363636);
  text-align: var(--cardslist-description-alignment);
}
.blte-cardslist__card {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 1;
  height: auto;
  min-height: 229px;
  max-width: 1160px;
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .blte-cardslist__card {
    min-height: none;
    flex-direction: column;
    align-items: inherit;
    margin-bottom: 24px;
  }
}
@media screen and (min-width: 0) and (max-width: 575px) {
  .blte-cardslist__card {
    min-height: none;
    flex-direction: column;
    align-items: inherit;
    margin-bottom: 24px;
  }
}
.blte-cardslist__card:nth-of-type(1) .blte-cardslist__vertical-line {
  height: 50%;
  align-self: end;
}
.blte-cardslist__card:last-child .blte-cardslist__vertical-line {
  height: 50%;
}
.blte-cardslist__card-image {
  width: 328px;
  height: 120px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  margin-left: 12px;
  flex-shrink: 0;
}
.blte-cardslist__card-image img {
  aspect-ratio: var(--card-image-aspect-ratio);
  object-fit: cover;
  object-position: center;
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .blte-cardslist__card-image--add-margin-mobile {
    margin-top: 40px;
  }
}
@media screen and (min-width: 0) and (max-width: 575px) {
  .blte-cardslist__card-image--add-margin-mobile {
    margin-top: 40px;
  }
}
@media screen and (min-width: 768px) {
  .blte-cardslist__card-image {
    width: 208px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-cardslist__card-image {
    width: 328px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .blte-cardslist__card-image {
    width: 100%;
    margin-left: 0;
  }
}
@media screen and (min-width: 0) and (max-width: 575px) {
  .blte-cardslist__card-image {
    width: 100%;
    margin-left: 0;
  }
}
.blte-cardslist__card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  gap: 8px;
  width: 100%;
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .blte-cardslist__card-content {
    padding: 16px 24px;
  }
}
@media screen and (min-width: 0) and (max-width: 575px) {
  .blte-cardslist__card-content {
    padding: 16px 24px;
  }
}
.blte-cardslist__card-title {
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  color: var(--card-title-color, #363636);
  text-align: var(--card-title-alignment);
}
.blte-cardslist__card-description {
  text-align: var(--card-description-alignment);
  color: var(--card-description-color, #363636);
  line-height: 28px;
}
.blte-cardslist__card-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 16px;
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .blte-cardslist__card-buttons {
    margin-top: 12px;
  }
}
@media screen and (min-width: 0) and (max-width: 575px) {
  .blte-cardslist__card-buttons {
    margin-top: 12px;
  }
}
.blte-cardslist__card-button {
  color: #094273;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  border: 1px solid #4297ba;
  border-radius: 8px;
}
