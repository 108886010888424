@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.blte-btn {
  border-radius: 8px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  box-sizing: border-box;
  width: max-content;
  position: relative;
}
.blte-btn:focus {
  outline: none;
}
.blte-btn .blte-icon {
  margin-right: 10px;
}
.blte-btn__loading-icon {
  width: 16px;
  height: 16px;
  animation: spin 0.5s linear infinite;
  margin-right: 10px;
}
.blte-btn--size-medium {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  padding: 8px 16px;
  font-size: 18px;
  line-height: 26px;
}
@media screen and (min-width: 576px) {
  .blte-btn--size-medium {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-btn--size-small {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
}
.blte-btn--variant-primary {
  background-color: var(--button-background-or-border-color);
  color: var(--button-text-color);
  fill: var(--button-text-color);
}
.blte-btn--variant-primary.blte-btn--color-yellow {
  background-color: var(--button-background-or-border-color, #ffdb00);
  color: var(--button-text-color, #363636);
  fill: var(--button-text-color, #363636);
}
.blte-btn--variant-primary.blte-btn--color-charcoal {
  background-color: var(--button-background-or-border-color, #363636);
  color: var(--button-text-color, #fff);
  fill: var(--button-text-color, #fff);
}
.blte-btn--variant-primary.blte-btn--color-white {
  background-color: var(--button-background-or-border-color, #fff);
  color: var(--button-text-color, #363636);
  fill: var(--button-text-color, #363636);
}
.blte-btn--variant-primary.blte-btn--color-blue {
  background-color: var(--button-background-or-border-color, #094273);
  color: var(--button-text-color, #fff);
  fill: var(--button-text-color, #fff);
}
.blte-btn--variant-primary.blte-btn--color-black {
  background-color: var(--button-background-or-border-color, #363636);
  color: var(--button-text-color, #fff);
  fill: var(--button-text-color, #fff);
}
.blte-btn--variant-primary.blte-btn--color-green {
  background-color: var(--button-background-or-border-color, #137e00);
  color: var(--button-text-color, #137e00);
  fill: var(--button-text-color, #137e00);
}
.blte-btn--variant-primary.blte-btn--color-red {
  background-color: var(--button-background-or-border-color, #c00);
  color: var(--button-text-color, #fff);
  fill: var(--button-text-color, #c00);
}
.blte-btn--variant-primary.blte-btn--disabled,
.blte-btn--variant-primary.blte-btn--loading {
  background-color: #f2f2f4;
  color: #e1e1e1;
}
.blte-btn--variant-primary:focus-visible {
  outline: none;
}
.blte-btn--variant-primary:focus-visible::before {
  content: "";
  position: absolute;
  inset: -2px;
  box-shadow: 0 0 0 2px var(--button-border-focus-color, #363636);
  border-radius: 8px;
}
.blte-btn--variant-primary:hover {
  opacity: var(--button-background-opacity, 0.85);
}
.blte-btn--variant-secondary {
  background-color: unset;
  box-shadow: 0 0 0 2px var(--button-background-or-border-color);
  color: var(--button-text-color);
  fill: var(--button-text-color);
}
.blte-btn--variant-secondary.blte-btn--color-yellow {
  box-shadow: 0 0 0 2px var(--button-background-or-border-color, #ffdb00);
  color: var(--button-text-color, #363636);
  fill: var(--button-text-color, #363636);
}
.blte-btn--variant-secondary.blte-btn--color-yellow:hover {
  background-color: var(--button-background-or-border-color, #ffdb00);
  color: var(--button-text-color, #363636);
  fill: var(--button-text-color, #363636);
}
.blte-btn--variant-secondary.blte-btn--color-charcoal {
  box-shadow: 0 0 0 2px var(--button-background-or-border-color, #363636);
  color: var(--button-text-color, #363636);
  fill: var(--button-text-color, #363636);
}
.blte-btn--variant-secondary.blte-btn--color-charcoal:hover {
  background-color: var(--button-background-or-border-color, #363636);
  color: var(--button-text-color, #fff);
  fill: var(--button-text-color, #fff);
}
.blte-btn--variant-secondary.blte-btn--color-white {
  box-shadow: 0 0 0 2px var(--button-background-or-border-color, #fff);
  color: var(--button-text-color, #fff);
  fill: var(--button-text-color, #fff);
}
.blte-btn--variant-secondary.blte-btn--color-white:hover {
  background-color: var(--button-background-or-border-color, #fff);
  color: var(--button-text-color, #363636);
  fill: var(--button-text-color, #363636);
}
.blte-btn--variant-secondary.blte-btn--color-blue {
  box-shadow: 0 0 0 2px var(--button-background-or-border-color, #094273);
  color: var(--button-text-color, #094273);
  fill: var(--button-text-color, #094273);
}
.blte-btn--variant-secondary.blte-btn--color-blue:hover {
  background-color: var(--button-background-or-border-color, #094273);
  color: var(--button-text-color, #fff);
  fill: var(--button-text-color, #fff);
}
.blte-btn--variant-secondary.blte-btn--color-green {
  box-shadow: 0 0 0 2px var(--button-background-or-border-color, #137e00);
  color: var(--button-text-color, #137e00);
  fill: var(--button-text-color, #137e00);
}
.blte-btn--variant-secondary.blte-btn--color-green:hover {
  background-color: var(--button-background-or-border-color, #137e00);
  color: var(--button-text-color, #fff);
  fill: var(--button-text-color, #fff);
}
.blte-btn--variant-secondary.blte-btn--color-red {
  box-shadow: 0 0 0 2px var(--button-background-or-border-color, #c00);
  color: var(--button-text-color, #fff);
  fill: var(--button-text-color, #fff);
}
.blte-btn--variant-secondary.blte-btn--color-red:hover {
  background-color: var(--button-background-or-border-color, #c00);
  color: var(--button-text-color, #fff);
  fill: var(--button-text-color, #fff);
}
.blte-btn--variant-secondary:hover {
  background-color: var(--button-background-or-border-color);
  color: var(--button-text-color);
  fill: var(--button-text-color);
  box-shadow: none;
}
.blte-btn--variant-secondary:focus-visible {
  outline: none;
}
.blte-btn--variant-secondary:focus-visible::before {
  content: "";
  position: absolute;
  inset: -4px;
  box-shadow: 0 0 0 2px var(--button-border-focus-color, #363636);
  border-radius: 8px;
}
.blte-btn--variant-secondary.blte-btn--disabled,
.blte-btn--variant-secondary.blte-btn--loading {
  background-color: unset;
  box-shadow: 0 0 0 2px #a3a3a3;
  color: #a3a3a3;
  fill: #a3a3a3;
}
.blte-btn--variant-primary.blte-btn--disabled,
.blte-btn--variant-primary.blte-btn--loading,
.blte-btn--variant-secondary.blte-btn--disabled,
.blte-btn--variant-secondary.blte-btn--loading {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}
.blte-btn--variant-primary.blte-btn--disabled:focus-visible::before,
.blte-btn--variant-primary.blte-btn--loading:focus-visible::before,
.blte-btn--variant-secondary.blte-btn--disabled:focus-visible::before,
.blte-btn--variant-secondary.blte-btn--loading:focus-visible::before {
  box-shadow: none;
}
.blte-btn--focusColor-red:focus-visible::before {
  box-shadow: 0 0 0 2px #c00;
}
.blte-btn--icon-right:not(.blte-btn--loading) {
  flex-direction: row-reverse;
}
.blte-btn--icon-right:not(.blte-btn--loading) .blte-icon {
  margin-left: 10px;
  margin-right: 0;
}
.blte-btn--width-full {
  width: 100%;
}
.blte-btn__wrapper {
  display: flex !important;
}
