@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-events-card__events-section {
  margin: 40px 60px;
  display: flex;
  flex-direction: column;
  align-items: left;
}
@media (max-width: 1070px) {
  .blte-events-card__events-section {
    margin: 40px;
  }
}
@media (max-width: 575px) and (min-width: 473px) {
  .blte-events-card__events-section {
    display: flex;
    flex-direction: column;
    margin: 40px 60px;
  }
}
.blte-events-card__event-card-title {
  font-size: 30px;
  font-weight: 500;
  line-height: 44px;
  padding-bottom: 20px;
  text-align: var(--title-alignment, "left");
  font-size: 30px;
}
@media (max-width: 575px) {
  .blte-events-card__event-card-title {
    align-items: center;
  }
}
.blte-events-card__event-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: dense;
  display: flex;
  flex-direction: column;
  grid-row-gap: 60px;
}
@media screen and (min-width: 576px) {
  .blte-events-card__event-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px 60px;
  }
}
@media (max-width: 1025px) and (min-width: 830px) {
  .blte-events-card__event-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px 60px;
    align-items: center;
  }
}
@media (min-width: 1026px) {
  .blte-events-card__event-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-columns: repeat(4, minmax(220px, 1fr));
    min-width: 400px;
    grid-gap: 60px 20px;
  }
}
.blte-events-card__image-container {
  position: relative;
  height: 250px;
  border-radius: 10px;
}
.blte-events-card__image {
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
  border-radius: 10px;
}
.blte-events-card__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.blte-events-card__distanceFromStation {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: white;
  max-width: 320px;
  border-radius: 5px;
  padding: 2px 5px;
}
.blte-events-card__distanceFromStationLabel {
  font-weight: 450;
  line-height: 18px;
  text-transform: none !important;
  font-family: "Futura PT", sans-serif;
  font-size: 13px;
}
@media screen and (min-width: 576px) {
  .blte-events-card__distanceFromStationLabel {
    font-size: 13px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-events-card__distanceFromStationLabel {
    font-size: 15px;
  }
}
.blte-events-card__title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  font-family: "Futura PT", sans-serif;
}
@media screen and (min-width: 576px) {
  .blte-events-card__title {
    font-size: 19px;
  }
}
@media screen and (min-width: 768px) {
  .blte-events-card__title {
    font-size: 19px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-events-card__title {
    font-size: 22px;
  }
}
.blte-events-card__dateAndTime {
  font-size: 18px;
  font-weight: 460;
  font-family: "Futura PT", sans-serif;
  line-height: 24px;
}
.blte-events-card__location {
  font-size: 19px;
  font-family: "Futura PT", sans-serif;
  font-weight: 400;
  margin-top: -10px;
}
@media screen and (min-width: 576px) {
  .blte-events-card__location {
    font-size: 17px;
  }
}
@media screen and (min-width: 768px) {
  .blte-events-card__location {
    padding-top: 10px;
    font-size: 17px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-events-card__location {
    font-size: 19px;
  }
}
.blte-events-card__event-pills {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  gap: 5px;
  top: 10px;
  left: 5px;
  right: 5px;
}
.blte-events-card__event-pills > * {
  margin-bottom: 5px;
}
