@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-upsell-content-description {
  display: flex;
  flex-direction: column;
  background: #f2f2f4;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 20px;
}
.blte-upsell-content-description__title {
  font-size: 18px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
  text-align: center;
  padding-bottom: 16px;
}
.blte-upsell-content-description__description {
  margin-bottom: 16px;
  margin-left: 16px;
}
.blte-upsell-content-description__bullet-point {
  display: flex;
  flex-flow: row;
}
.blte-upsell-content-description__body-content {
  display: grid;
  grid-row-gap: 10px;
}
.blte-upsell-content-description--isMobile {
  background: white;
  margin: 20px 0 40px;
}
.blte-upsell-content-description--isMobile .blte-upsell-content-description__title {
  font-size: 16px;
}
