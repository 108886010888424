@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-no-modification-allowed-modal__button {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.blte-no-modification-allowed-modal__button :first-child {
  margin-right: 12px;
  background-color: #e1e1e1;
}
.blte-no-modification-allowed-modal__description {
  margin-bottom: 16px;
  font-size: 22px;
  font-weight: 500;
  word-wrap: break-word;
}
