@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-seat-exit-confirmation-modal {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.blte-seat-exit-confirmation-modal__header {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-seat-exit-confirmation-modal__header {
    font-size: 22px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-seat-exit-confirmation-modal__description {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-seat-exit-confirmation-modal__description {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-seat-exit-confirmation-modal__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
