@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-promo-flag {
  font-size: 12px;
  font-weight: 450;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  width: fit-content;
  position: relative;
  padding: 0 4px;
  border-radius: 18px;
  box-sizing: content-box;
}
.blte-promo-flag--type-promotional {
  color: var(--promo-flag-text-color, #363636);
  background-color: var(--promo-flag-background-color, #ffdb00);
}
.blte-promo-flag--type-childfare {
  color: var(--promo-flag-text-color, #fff);
  background-color: var(--promo-flag-background-color, #cb2b99);
}
.blte-promo-flag:focus-visible {
  outline: none;
}
.blte-promo-flag:focus-visible::before {
  content: "";
  position: absolute;
  inset: -2px;
  box-shadow: 0 0 0 2px var(--promo-flag-border-focus-color, #363636);
  border-radius: 18px;
}
