@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-setting-entry-travel-agent {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  margin-bottom: 16px;
  background: #fff;
  border-radius: 8px;
}
@media screen and (min-width: 576px) {
  .blte-setting-entry-travel-agent {
    flex-direction: row;
  }
}
.blte-setting-entry-travel-agent__setting-label-travel-agent {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
}
@media screen and (min-width: 576px) {
  .blte-setting-entry-travel-agent__setting-label-travel-agent {
    min-width: 128px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-setting-entry-travel-agent__setting-label-travel-agent {
    min-width: 140px;
  }
}
.blte-setting-entry-travel-agent__setting-value-travel-agent-default {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #a3a3a3;
}
@media screen and (min-width: 576px) {
  .blte-setting-entry-travel-agent__setting-value-travel-agent-default {
    margin-left: 24px;
  }
}
.blte-setting-entry-travel-agent__setting-value-travel-agent {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-setting-entry-travel-agent__setting-value-travel-agent {
    margin-left: 24px;
  }
}
.blte-setting-entry-travel-agent__setting-error-label-travel-agent {
  color: red;
  margin-top: -15px;
}
.blte-setting-entry-travel-agent--border-error {
  border: 1px solid red;
}
