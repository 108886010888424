@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-details-navigation {
  padding-bottom: 64px;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-navigation {
    padding-bottom: 0;
  }
}
.blte-trip-details-navigation__placeholders > div {
  display: flex;
  margin-bottom: 42px;
}
.blte-trip-details-navigation__placeholders > div > div:first-child {
  background: #f2f2f4;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  margin-right: 12px;
}
.blte-trip-details-navigation__placeholders > div > div:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #f2f2f4, #fff, #f2f2f4);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-trip-details-navigation__placeholders > div > div:last-child {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.blte-trip-details-navigation__placeholders > div > div:last-child > div {
  background: #f2f2f4;
  position: relative;
  overflow: hidden;
  height: 24px;
  margin-bottom: 16px;
  width: 80%;
  border-radius: 2px;
}
.blte-trip-details-navigation__placeholders > div > div:last-child > div::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #f2f2f4, #fff, #f2f2f4);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-trip-details-navigation__placeholders > div > div:last-child > div:first-child {
  width: 100%;
}
.blte-trip-details-navigation__modify-trip-section {
  margin-bottom: 24px;
}
.blte-trip-details-navigation__modify-smart-saver[disabled] {
  color: grey !important;
  cursor: auto !important;
  text-decoration: none !important;
}
