.blte-text-and-media__content__description ul li::before {
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

}
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-text-and-media .aem-Grid {
  display: flex;
  align-items: center;
}
.blte-text-and-media__media__attachment {
  position: relative;
  aspect-ratio: var(--text-and-media-aspect-ratio, 1.3333333333);
  width: 100%;
  height: auto;
  overflow: hidden;
}
.blte-text-and-media__media__attachment > img,
.blte-text-and-media__media__attachment > picture > img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blte-text-and-media__media__imageContent {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: end;
  word-wrap: break-word;
  padding: 25px;
}
@media screen and (min-width: 576px) {
  .blte-text-and-media__media__imageContent {
    padding: 35px;
  }
}
@media screen and (min-width: 768px) {
  .blte-text-and-media__media__imageContent {
    padding: 25px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-text-and-media__media__imageContent {
    padding: 30px;
  }
}
.blte-text-and-media__media__imageContent_imageIcon {
  color: var(--text-and-media-image-icon-color, #363636) !important;
}
.blte-text-and-media__media__imageContent_imageIcon--size-small :first-child {
  width: 80px;
  height: 100%;
}
.blte-text-and-media__media__imageContent_imageIcon--size-small :first-child::after {
  font-size: 20px;
}
.blte-text-and-media__media__imageContent_imageIcon--size-medium :first-child {
  width: 110px;
  height: 100%;
}
.blte-text-and-media__media__imageContent_imageIcon--size-medium :first-child::after {
  font-size: 22px;
}
.blte-text-and-media__media__imageContent_imageIcon--size-large :first-child {
  width: 140px;
  height: 100%;
}
.blte-text-and-media__media__imageContent_imageIcon--size-large :first-child::after {
  font-size: 24px;
}
.blte-text-and-media__media__imageContent .blte-imageTitle {
  color: var(--text-and-media-image-title-color, #363636);
  margin-bottom: 16px;
}
.blte-text-and-media__media__imageContent .blte-imageTitle :first-child {
  line-height: 30px;
}
@media screen and (min-width: 576px) {
  .blte-text-and-media__media__imageContent .blte-imageTitle :first-child {
    line-height: 40px;
  }
}
@media screen and (min-width: 768px) {
  .blte-text-and-media__media__imageContent .blte-imageTitle :first-child {
    line-height: 35px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-text-and-media__media__imageContent .blte-imageTitle :first-child {
    line-height: 44px;
  }
}
.blte-text-and-media__media__imageContent_imageDescription {
  color: var(--text-and-media-image-description-color, #363636);
  margin-bottom: 15px;
}
.blte-text-and-media__media__imageContent_imageDescription :first-child {
  line-height: 30px;
}
@media screen and (min-width: 768px) {
  .blte-text-and-media__media__imageContent_imageDescription {
    margin-bottom: 20px;
  }
  .blte-text-and-media__media__imageContent_imageDescription :first-child {
    line-height: 25px;
  }
}
@media screen and (min-width: 768px) {
  .blte-text-and-media__media__imageContent_imageDescription :first-child {
    line-height: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-text-and-media__media__imageContent_imageDescription :first-child {
    line-height: 44px;
  }
}
.blte-text-and-media__content {
  display: flex;
  flex-direction: column;
}
.blte-text-and-media__content__eyebrow {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: none;
  color: var(--text-and-media-eyebrow-color, #363636);
}
@media screen and (min-width: 768px) {
  .blte-text-and-media__content__eyebrow {
    margin-bottom: 8px;
  }
}
.blte-text-and-media__content .blte-title {
  color: var(--text-and-media-title-color, #363636);
  margin-bottom: 16px;
}
.blte-text-and-media__content__description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: var(--text-and-media-description-color, #363636);
  margin-bottom: 16px;
}
.blte-text-and-media__content__description ul {
  margin-left: 0;
  padding-left: 30px;
  list-style: none;
}
.blte-text-and-media__content__description ul li {
  margin-top: 8px;
  position: relative;
}
.blte-text-and-media__content__description ul li::before {
  content: ICON_FONT_LOADER_IMAGE(IDa4bfa326ce5d9ee1a619cf8d8545a410);
  position: absolute;
  left: -30px;
  font-size: 18px;
  top: 0;
}
.blte-text-and-media__content__description ol {
  margin-left: 0;
  padding-left: 30px;
}
@media screen and (min-width: 768px) {
  .blte-text-and-media__content__description {
    margin-bottom: 24px;
  }
}
.blte-text-and-media__content__buttons {
  display: flex;
}
.blte-text-and-media__content__buttons > :first-child:nth-last-child(2) {
  margin-right: 16px;
}
@media screen and (min-width: 768px) {
  .blte-text-and-media__content__buttons {
    margin-bottom: 16px;
  }
}
.blte-text-and-media--textAlignment-center .blte-text-and-media__content {
  text-align: center;
  align-items: center;
}
.blte-text-and-media--imageTextAlignment-center .blte-text-and-media__media__imageContent {
  text-align: center;
  align-items: center;
}
.blte-text-and-media--imageTextAlignment-right .blte-text-and-media__media__imageContent {
  text-align: right;
  align-items: end;
}
@media screen and (min-width: 768px) {
  .blte-text-and-media--alignment-left .blte-text-and-media__content {
    padding-left: 24px;
  }
}
@media screen and (min-width: 768px) {
  .blte-text-and-media--alignment-right .blte-text-and-media__content {
    padding-right: 24px;
  }
}
.blte-text-and-media--alignment-right .aem-Grid > :first-child {
  order: 1;
}
@media screen and (min-width: 768px) {
  .blte-text-and-media--alignment-right .aem-Grid > :first-child {
    order: unset;
  }
}
.blte-text-and-media--variant-default {
  padding: 40px 0;
  background: var(--text-and-media-background-color, #fff);
}
@media screen and (min-width: 576px) {
  .blte-text-and-media--variant-default {
    padding: 80px 0;
  }
}
.blte-text-and-media--variant-default .blte-text-and-media__media__attachment {
  border-radius: 4px;
}
.blte-text-and-media--variant-default .blte-text-and-media__content {
  margin-top: 16px;
}
@media screen and (min-width: 576px) {
  .blte-text-and-media--variant-default .blte-text-and-media__content {
    margin-top: 24px;
  }
}
@media screen and (min-width: 768px) {
  .blte-text-and-media--variant-default .blte-text-and-media__content {
    margin-top: 0;
  }
}
.blte-text-and-media--variant-default .blte-text-and-media__content .blte-title {
  margin-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .blte-text-and-media--variant-default .blte-text-and-media__content .blte-title {
    margin-bottom: 24px;
  }
}
.blte-text-and-media--variant-default.blte-text-and-media--removeDefaultSpacings {
  padding-top: 0;
  padding-bottom: 0;
}
@media screen and (min-width: 576px) {
  .blte-text-and-media--variant-default.blte-text-and-media--removeDefaultSpacings {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.blte-text-and-media--variant-contained .aem-Grid {
  overflow: hidden;
  align-items: stretch;
}
.blte-text-and-media--variant-contained .blte-text-and-media__media__attachment {
  border-radius: 8px 8px 0 0;
  aspect-ratio: 1.3333333333;
}
@media screen and (min-width: 768px) {
  .blte-text-and-media--variant-contained .blte-text-and-media__media__attachment {
    height: 100%;
    border-radius: 8px 0 0 8px;
    aspect-ratio: unset;
  }
  .blte-text-and-media--variant-contained .blte-text-and-media__media__attachment::before {
    content: "";
    padding-top: 100%;
    display: block;
  }
}
@media screen and (min-width: 1024px) {
  .blte-text-and-media--variant-contained .blte-text-and-media__media__attachment::before {
    content: "";
    padding-top: 75%;
    display: block;
  }
}
.blte-text-and-media--variant-contained .blte-text-and-media__content {
  background: var(--text-and-media-background-color, #fff);
  padding: 16px;
  min-height: 100%;
  justify-content: center;
  border-radius: 0 0 8px 8px;
}
@media screen and (min-width: 576px) {
  .blte-text-and-media--variant-contained .blte-text-and-media__content {
    padding: 40px;
  }
}
@media screen and (min-width: 768px) {
  .blte-text-and-media--variant-contained .blte-text-and-media__content {
    padding: 80px;
    border-radius: 0 8px 8px 0;
  }
}
.blte-text-and-media--variant-contained .blte-text-and-media__content .blte-title {
  margin-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .blte-text-and-media--variant-contained .blte-text-and-media__content .blte-title {
    margin-bottom: 24px;
  }
}
@media screen and (min-width: 768px) {
  .blte-text-and-media--variant-contained.blte-text-and-media--alignment-right .blte-text-and-media__media__attachment {
    border-radius: 0 8px 8px 0;
  }
}
.blte-text-and-media--variant-contained.blte-text-and-media--alignment-right .blte-text-and-media__content {
  border-radius: 0 0 8px 8px;
}
@media screen and (min-width: 768px) {
  .blte-text-and-media--variant-contained.blte-text-and-media--alignment-right .blte-text-and-media__content {
    border-radius: 8px 0 0 8px;
  }
}
.blte-text-and-media--variant-banner {
  background: var(--text-and-media-background-color, #fff);
}
.blte-text-and-media--variant-banner .blte-text-and-media__media__attachment {
  aspect-ratio: 1/1;
}
.blte-text-and-media--variant-banner .blte-text-and-media__content {
  padding: 16px 0;
}
@media screen and (min-width: 576px) {
  .blte-text-and-media--variant-banner .blte-text-and-media__content {
    padding: 40px 0;
  }
}
@media screen and (min-width: 1024px) {
  .blte-text-and-media--variant-banner .blte-text-and-media__content {
    padding: 80px 0;
  }
}
