@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-sweepstakes-form-submission-message__form-submission-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 100px 0;
  background-color: #e8f2f1;
}
.blte-sweepstakes-form-submission-message__check-icon {
  width: 46px;
  height: 46px;
}
.blte-sweepstakes-form-submission-message__submitAnotherFormMesssage {
  font-family: "Futura PT", sans-serif;
  font-size: 15px;
  color: #094273;
  font-weight: 450;
}
@media screen and (min-width: 768px) {
  .blte-sweepstakes-form-submission-message__submitAnotherFormMesssage {
    font-size: 20px;
  }
}
@media (max-width: 389px) {
  .blte-sweepstakes-form-submission-message__submitAnotherFormMesssage {
    font-size: 13px;
  }
}
