@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passenger-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  gap: 8px;
  border-radius: 0;
  background: unset;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .blte-passenger-item {
    padding: 8px 8px 8px 12px;
    gap: 24px;
    border-radius: 8px;
    background: #f2f2f4;
    flex-wrap: nowrap;
  }
}
.blte-passenger-item__passenger-name {
  width: 100%;
}
@media screen and (min-width: 576px) {
  .blte-passenger-item__passenger-name {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-passenger-item__dropdown {
  height: 48px;
  min-width: 320px;
}
