@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-avatar {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  color: #fff;
  background-color: #2e6a6e;
}
