@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-modal-component {
  min-width: 400px;
  min-height: 400px;
}
.blte-modal-component__modalHeader {
  height: 80px;
  position: sticky;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 1;
}
.blte-modal-component__modalHeader button {
  position: relative;
  float: right;
}
.blte-modal-component__content {
  padding: 30px;
}
.blte-modal-component__button {
  background-color: #fff;
  border-style: none;
  border-radius: 10px;
  margin: 27px 60px;
}
.blte-modal-component__button:hover {
  cursor: pointer;
  background-color: #f2f2f4;
}
