@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
@media screen and (min-width: 768px) {
  .blte-account-layout-with-sidebar__content {
    width: 71%;
  }
}
#svg-icon-chevron-single-up-down,
#svg-icon-chevron-single-up,
#svg-icon-chevron-single-down {
  display: none;
}
.blte-account-admin-panel {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.blte-account-admin-panel__my-account {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.blte-account-admin-panel__my-account-label {
  font-size: 18px;
  font-weight: 450;
}
@media (max-width: 576px) {
  .blte-account-admin-panel__my-account {
    margin-top: 16px;
  }
}
.blte-account-admin-panel__cross-linking {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 20px;
  gap: 16px;
  margin-top: 24px;
}
@media (max-width: 576px) {
  .blte-account-admin-panel__cross-linking {
    margin-top: 16px;
  }
}
@media (max-width: 576px) {
  .blte-account-admin-panel__cross-linking--displayBannerPerViewport-showDesktopOnly {
    display: none;
  }
}
@media (min-width: 576px) {
  .blte-account-admin-panel__cross-linking--displayBannerPerViewport-showMobileOnly {
    display: none;
  }
}
.blte-account-admin-panel__cross-linking--displayBannerPerViewport-hideAll {
  display: none;
}
.blte-account-admin-panel__cross-linking-title {
  font-weight: 500;
  font-size: 20px;
}
.blte-account-admin-panel__cross-linking-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
}
.blte-account-admin-panel__cross-linking-description {
  display: flex;
  font-weight: 400;
  font-size: 16px;
}
.blte-account-admin-panel__cross-linking-description-second-text {
  color: #8d8d8d;
  margin-left: 10px;
}
.blte-account-admin-panel__right-arrow {
  text-decoration: none;
}
.blte-account-admin-panel__table {
  margin-top: 24px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
  gap: 24px;
}
@media (max-width: 576px) {
  .blte-account-admin-panel__table {
    margin-top: 16px;
  }
}
.blte-account-admin-panel__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.blte-account-admin-panel__table-disclaimer-text {
  font-size: 13px;
  line-height: 18px;
  color: #565656;
  padding-top: 5px;
}
.blte-account-admin-panel__commission-title {
  font-weight: 500;
  font-size: 20px;
}
.blte-account-admin-panel__download {
  border: 1px solid #094273;
  border-radius: 8px;
  background-color: transparent;
  padding: 0 9px;
}
.blte-account-admin-panel__download--disabled {
  border: 2px solid #a3a3a3;
}
.blte-account-admin-panel__download:hover {
  cursor: pointer;
}
.blte-account-admin-panel__downloadIcon {
  color: #094273;
}
.blte-account-admin-panel__downloadIcon--disabled {
  color: #a3a3a3;
}
.blte-account-admin-panel__header-cell {
  font-weight: 500 !important;
  padding: 11px 16px !important;
  border-bottom: 1px solid black !important;
}
.blte-account-admin-panel__table-header-icon-default {
  width: 20px;
  height: 20px;
  background-image: url(assets/images/png/icons/triangle-down-default.png);
  background-size: contain;
}
.blte-account-admin-panel__table-header-icon-default:hover {
  background-image: url(assets/images/png/icons/triangle-down-hover.png);
}
.blte-account-admin-panel__table-header-icon-down {
  width: 20px;
  height: 20px;
  background-image: url(assets/images/png/icons/triangle-selected-down.png);
  background-size: contain;
}
.blte-account-admin-panel__table-header-icon-up {
  width: 20px;
  height: 20px;
  background-image: url(assets/images/png/icons/triangle-selected-up.png);
  background-size: contain;
}
.blte-account-admin-panel__body-cell {
  padding: 12px 16px !important;
  font-weight: 400;
  border-bottom: 1px solid #dde2eb !important;
}
.blte-account-admin-panel__name-cell {
  width: 100%;
}
.blte-account-admin-panel__confirmation-cell {
  color: #2e6a6e;
}
.blte-account-admin-panel__offers-title {
  margin-top: 24px;
  font-weight: 500;
  font-size: 22px;
}
@media (max-width: 576px) {
  .blte-account-admin-panel__offers-title {
    margin-top: 16px;
  }
}
.blte-account-admin-panel__bottom-experience-fragment {
  margin-top: 24px;
}
@media (max-width: 576px) {
  .blte-account-admin-panel__bottom-experience-fragment {
    margin-top: 16px;
  }
}
.blte-account-admin-panel__user-account {
  padding: 8px 0 0;
}
@media screen and (min-width: 576px) {
  .blte-account-admin-panel__user-account {
    padding: 16px 0 0;
  }
}
.blte-account-admin-panel__empty-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;
  text-align: center;
  width: 100%;
  margin-top: 40px;
}
.blte-account-admin-panel__empty-icon {
  font-size: 46px;
  color: #a3a3a3;
}
.blte-account-admin-panel__empty-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.blte-account-admin-panel__empty-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
.blte-account-admin-panel__empty-messaging {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
