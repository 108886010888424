@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
@media screen and (min-width: 768px) {
  .blte-zendesk--zendeskDesktopVisibility-show {
    display: block !important;
  }
  .blte-zendesk--zendeskDesktopVisibility-hide {
    display: none !important;
  }
}
@media screen and (min-width: 0) and (max-width: 575px) {
  .blte-zendesk--zendeskMobileVisibility-show {
    display: block !important;
  }
  .blte-zendesk--zendeskMobileVisibility-hide {
    display: none !important;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .blte-zendesk--zendeskMobileVisibility-show {
    display: block !important;
  }
  .blte-zendesk--zendeskMobileVisibility-hide {
    display: none !important;
  }
}
.blte-zendesk button {
  position: fixed;
  bottom: 18px;
  right: 18px;
  padding: 8px 16px;
  border-radius: 8px;
  z-index: 9999;
  cursor: pointer;
  display: flex;
  background: #ffdb00;
  border: 0;
  box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.0784313725);
  align-items: center;
  gap: 4px;
  font-family: "Futura PT", sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: #363636;
}
.blte-zendesk .blte-icon__zendesk-question {
  color: #363636;
}
.blte-zendesk #close-zendesk-button {
  border-radius: 35px;
  padding: 14px 16px;
  bottom: 15px;
}
.blte-zendesk #close-zendesk-button i {
  color: #363636;
  font-size: 35px;
}
.blte-zendesk #unread-indicator {
  margin-top: -5px;
  color: white;
  text-align: center;
  font-size: 12px;
  line-height: 1.7;
  background: black;
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  font-weight: 600;
  margin-left: 1px;
}
@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.blte-zendesk .tilt-animation {
  animation: tilt-shaking 0.25s;
}
@keyframes fade-out-up {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}
@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.blte-zendesk #open-zendesk-button:active {
  animation: none;
  opacity: 0;
}
.blte-zendesk #open-zendesk-button:active + #close-zendesk-button {
  animation: fadeInUp 0.3s forwards;
  opacity: 1;
}
.blte-zendesk #close-zendesk-button:active {
  animation: none;
  opacity: 0;
}
.blte-zendesk #close-zendesk-button:active + #open-zendesk-button {
  animation: fadeOutUp 0.3s forwards;
  opacity: 1;
}
.blte-zendesk__close-icon {
  display: flex;
  padding: 5px 0;
}
.blte-zendesk__no-label #open-zendesk-button {
  padding: 8px 14px 8px 18px;
}
