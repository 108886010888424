@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-extras-modal-footer-buttons {
  width: auto;
  border-top: unset;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.15);
}
@media screen and (min-width: 768px) {
  .blte-extras-modal-footer-buttons {
    border-top: 1px solid #f2f2f4;
    box-shadow: unset;
  }
}
.blte-extras-modal-footer-buttons__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 16px 24px;
}
@media screen and (min-width: 768px) {
  .blte-extras-modal-footer-buttons__footer {
    padding: 16px 40px;
  }
}
.blte-extras-modal-footer-buttons__footer-total {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
@media screen and (min-width: 768px) {
  .blte-extras-modal-footer-buttons__footer-total {
    gap: 16px;
  }
}
.blte-extras-modal-footer-buttons__footer-total-label {
  color: #363636;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .blte-extras-modal-footer-buttons__footer-total-label {
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
  }
}
.blte-extras-modal-footer-buttons__footer-total-price {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .blte-extras-modal-footer-buttons__footer-total-price {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
  }
}
.blte-extras-modal-footer-buttons__footer-buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
@media screen and (min-width: 768px) {
  .blte-extras-modal-footer-buttons__footer-buttons {
    gap: 12px;
  }
}
