@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.blte-pagination__title {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  padding-bottom: 8px;
}
.blte-pagination__list {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.blte-pagination__item {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #363636;
  border-radius: 30px;
  min-width: 40px;
  height: 40px;
}
.blte-pagination__item .blte-btn {
  width: 100%;
  justify-content: center;
  border-radius: 30px;
  background: none;
}
.blte-pagination__item .blte-btn--disabled .blte-icon {
  color: #a3a3a3;
}
.blte-pagination__item .blte-btn--size-small {
  padding: 8px;
}
.blte-pagination__item .blte-btn .blte-icon {
  margin-right: 0;
  font-size: 24px;
}
.blte-pagination__item__dots {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  justify-content: center;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  padding: 8px;
}
.blte-pagination__item--active .blte-btn {
  background-color: #363636;
  color: #fff;
}
.blte-pagination__item--disabled .blte-icon {
  color: #a3a3a3;
}
.blte-pagination__arrow-button.blte-btn--size-small {
  font-size: 0;
}
