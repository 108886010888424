@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-details-passenger {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-passenger {
    padding: 16px;
    border-radius: 8px;
  }
}
.blte-trip-details-passenger__passenger-info {
  display: flex;
  align-items: center;
}
.blte-trip-details-passenger__avatar {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-passenger__avatar {
    margin-right: 16px;
  }
}
.blte-trip-details-passenger__name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  align-items: center;
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-passenger__name {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-trip-details-passenger__separator {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #565656;
  margin: 0 6px;
}
.blte-trip-details-passenger__passenger-type {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  text-transform: capitalize !important;
  color: #565656;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-passenger__passenger-type {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-trip-details-passenger__details {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-passenger__details {
    flex-direction: row;
  }
}
@media screen and (min-width: 1024px) {
  .blte-trip-details-passenger__details {
    margin-left: 48px;
  }
}
.blte-trip-details-passenger__seats {
  display: flex;
  flex-direction: row;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-passenger__seats {
    width: auto;
  }
}
.blte-trip-details-passenger__seat {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  flex: 1 0;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-passenger__seat {
    min-width: 200px;
  }
}
.blte-trip-details-passenger__seat-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}
.blte-trip-details-passenger__seat-details .blte-trip-coach {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-trip-details-passenger__seat > * {
  min-height: 36px;
}
.blte-trip-details-passenger__route-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #094273;
}
.blte-trip-details-passenger__luggage {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  text-align: start;
  display: flex;
  align-items: center;
}
.blte-trip-details-passenger__actions {
  display: flex;
  column-gap: 16px;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-passenger__actions {
    column-gap: 24px;
  }
}
@media screen and (min-width: 768px) {
  .blte-trip-details-passenger__actions {
    display: flex;
    column-gap: 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
.blte-trip-details-passenger__actions > * {
  min-height: 36px;
}
