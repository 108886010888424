.blte-app-banner__description ul li::before {
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

}
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-app-banner {
  max-width: 1400px;
  margin: 0 auto;
  width: calc(100% - 48px);
  box-sizing: content-box;
  background-color: var(--app-banner-background-color, #2e6a6e);
  display: flex;
  padding: 24px 24px 0;
  flex-direction: column;
  overflow: hidden;
}
@media screen and (min-width: 576px) {
  .blte-app-banner {
    width: calc(100% - 128px);
  }
}
@media screen and (min-width: 768px) {
  .blte-app-banner {
    width: calc(100% - 80px);
  }
}
@media screen and (min-width: 1024px) {
  .blte-app-banner {
    width: calc(100% - 128px);
  }
}
@media screen and (min-width: 576px) {
  .blte-app-banner {
    padding: 40px 64px 0;
  }
}
@media screen and (min-width: 768px) {
  .blte-app-banner {
    padding: 0 40px;
    border-radius: 8px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-app-banner {
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 24px 40px 0;
    width: calc(100% - 80px);
  }
}
.blte-app-banner__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blte-app-banner__actions .blte-img-btn {
  align-self: stretch;
}
.blte-app-banner__actions .blte-img-btn + .blte-img-btn {
  margin-left: 8px;
}
.blte-app-banner__title {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
  color: var(--app-banner-title-color, #fff);
  padding-bottom: 16px;
  letter-spacing: 0;
  text-align: left;
}
@media screen and (min-width: 576px) {
  .blte-app-banner__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-app-banner__title {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-app-banner__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
}
@media screen and (min-width: 576px) {
  .blte-app-banner__content {
    padding-bottom: 24px;
  }
}
@media screen and (min-width: 768px) {
  .blte-app-banner__content {
    padding-top: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-app-banner__content {
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-basis: 33.33%;
    padding-top: 0;
    padding-bottom: 24px;
    margin-right: 16px;
  }
}
.blte-app-banner__qr {
  border: 2px solid var(--app-banner-qr-border-color, #094273);
  border-radius: 26px;
  padding: 24px;
  display: flex;
  align-items: center;
  background-color: var(--app-banner-qr-background-color, #fff);
  color: var(--app-banner-qr-text-color, #000);
  margin-bottom: 24px;
}
.blte-app-banner__qr > div:first-child {
  display: flex;
  flex-direction: column;
  width: 140px;
}
.blte-app-banner__qr > div:first-child > div:first-child {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-app-banner__qr > div:first-child > div:last-child {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0;
  text-transform: none;
  margin-top: 8px;
}
.blte-app-banner__qr svg {
  margin-left: 34px;
}
.blte-app-banner__media {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}
@media screen and (min-width: 576px) {
  .blte-app-banner__media {
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;
    align-self: stretch;
  }
}
@media screen and (min-width: 1024px) {
  .blte-app-banner__media {
    display: flex;
    align-items: flex-end;
    justify-content: stretch;
    align-self: stretch;
    flex-basis: 66.67%;
  }
}
.blte-app-banner__description {
  color: var(--app-banner-title-color, #fff);
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}
.blte-app-banner__description ul {
  margin-left: 0;
  padding-left: 30px;
  list-style: none;
}
.blte-app-banner__description ul li {
  margin-top: 8px;
  position: relative;
}
.blte-app-banner__description ul li::before {
  content: ICON_FONT_LOADER_IMAGE(IDa4bfa326ce5d9ee1a619cf8d8545a410);
  position: absolute;
  left: -30px;
  font-size: 18px;
  top: 0;
}
.blte-app-banner__description ol {
  margin-left: 0;
  padding-left: 30px;
}
.blte-app-banner__description ul,
.blte-app-banner__description ol {
  margin: 0;
}
@media screen and (min-width: 576px) {
  .blte-app-banner__description ul,
  .blte-app-banner__description ol {
    padding-top: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-app-banner__description ul,
  .blte-app-banner__description ol {
    padding-top: 0;
  }
}
.blte-app-banner__description ul li,
.blte-app-banner__description ol li {
  margin-top: 0;
}
.blte-app-banner__description ul li + li,
.blte-app-banner__description ol li + li {
  margin-top: 8px;
}
@media screen and (min-width: 576px) {
  .blte-app-banner__description {
    align-self: stretch;
    padding-bottom: 24px;
    padding-right: 16px;
    flex-basis: 60%;
    justify-content: flex-end;
  }
}
@media screen and (min-width: 768px) {
  .blte-app-banner__description {
    min-height: auto;
    flex-basis: 50%;
  }
}
@media screen and (min-width: 1024px) {
  .blte-app-banner__description {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 24px;
  }
}
.blte-app-banner__img-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  margin: 0;
  height: 135px;
}
@media screen and (min-width: 576px) {
  .blte-app-banner__img-wrapper {
    flex-basis: 40%;
    position: relative;
    align-self: stretch;
    height: auto;
    align-items: flex-end;
  }
}
@media screen and (min-width: 768px) {
  .blte-app-banner__img-wrapper {
    flex-basis: 50%;
    position: relative;
  }
}
.blte-app-banner__img-wrapper picture {
  display: flex;
}
.blte-app-banner__img-wrapper img {
  aspect-ratio: 16/9;
  width: 100%;
  max-width: 240px;
  height: 100%;
  object-fit: contain;
}
@media screen and (min-width: 576px) {
  .blte-app-banner__img-wrapper img {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    aspect-ratio: 4/3;
    width: 212px;
    height: auto;
  }
}
@media screen and (min-width: 768px) {
  .blte-app-banner__img-wrapper img {
    position: static;
  }
}
@media screen and (min-width: 1024px) {
  .blte-app-banner__img-wrapper img {
    width: 260px;
  }
}
