@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-teaser {
  display: flex;
  flex-direction: column;
}
.blte-teaser__image {
  display: flex;
  margin-bottom: 24px;
  object-fit: cover;
  width: auto;
  height: auto;
  max-height: 440px;
  overflow: hidden;
}
.blte-teaser__image > div {
  width: 100%;
}
.blte-teaser__video {
  width: 100%;
  margin-bottom: 24px;
}
.blte-teaser__video .blte-videoplayer__wrapper {
  display: flex;
  width: 100%;
}
.blte-teaser__title .blte-title,
.blte-teaser__title .blte-text,
.blte-teaser__desc .blte-title,
.blte-teaser__desc .blte-text {
  margin-bottom: 16px;
}
.blte-teaser .blte-btn__wrapper,
.blte-teaser .blte-title__wrapper,
.blte-teaser .blte-text__wrapper,
.blte-teaser .cmp-image__wrapper,
.blte-teaser .blte-video-player__wrapper {
  width: 100%;
}
.blte-alignment-center .blte-teaser__image,
.blte-alignment-center .blte-teaser__video,
.blte-alignment-center .blte-teaser__title,
.blte-alignment-center .blte-teaser__desc,
.blte-alignment-center .blte-teaser__button,
.blte-alignment-center .blte-teaser .blte-btn__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.blte-alignment-left .blte-teaser__image,
.blte-alignment-left .blte-teaser__video,
.blte-alignment-left .blte-teaser__title,
.blte-alignment-left .blte-teaser__desc,
.blte-alignment-left .blte-teaser__button,
.blte-alignment-left .blte-teaser .blte-btn__wrapper {
  display: flex;
  justify-content: start;
  align-items: start;
  text-align: left;
}
.blte-alignment-right .blte-teaser__image,
.blte-alignment-right .blte-teaser__video,
.blte-alignment-right .blte-teaser__title,
.blte-alignment-right .blte-teaser__desc,
.blte-alignment-right .blte-teaser__button,
.blte-alignment-right .blte-teaser .blte-btn__wrapper {
  display: flex;
  justify-content: end;
  align-items: end;
  text-align: right;
}
