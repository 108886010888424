@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-extras-modal-card {
  display: flex;
  flex-grow: 1;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid #e1e1e1;
  padding: 8px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}
@media screen and (min-width: 768px) {
  .blte-extras-modal-card {
    padding: 0;
    max-width: 33.3333333333%;
    flex-direction: column;
    justify-content: unset;
    align-items: start;
    align-self: unset;
  }
}
.blte-extras-modal-card--twoColumnWidth {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-extras-modal-card--twoColumnWidth {
    max-width: 50%;
  }
}
.blte-extras-modal-card__image-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 8px;
  gap: 10px;
  width: 100px;
  background: #f2f2f4;
}
@media screen and (min-width: 768px) {
  .blte-extras-modal-card__image-card {
    width: 100%;
    background: #e8f2f1;
  }
}
.blte-extras-modal-card__image {
  height: unset;
}
@media screen and (min-width: 768px) {
  .blte-extras-modal-card__image {
    height: 84px;
  }
}
.blte-extras-modal-card__details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  justify-content: center;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .blte-extras-modal-card__details {
    justify-content: start;
    padding: 24px;
  }
}
.blte-extras-modal-card__details-title {
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-extras-modal-card__details-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-extras-modal-card__details-text {
  color: #363636;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
