@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-route-item {
  position: relative;
  flex-basis: 100%;
}
.blte-account-route-item--isCompleted {
  opacity: 0.5;
}
.blte-account-route-item + .blte-account-route-item {
  padding-top: 16px;
}
.blte-account-route-item:not(:last-child) {
  padding-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .blte-account-route-item {
    flex-basis: 50%;
  }
  .blte-account-route-item + .blte-account-route-item {
    padding-top: 0;
  }
  .blte-account-route-item:not(:last-child) {
    border-right: 2px solid #e1e1e1;
    padding-right: 40px;
    padding-bottom: 0;
  }
  .blte-account-route-item:not(:first-child):last-child {
    padding-left: 40px;
  }
}
.blte-account-route-item__duration {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  background-color: #fff;
  position: absolute;
  width: 65px;
  height: 42px;
  left: calc(50% - 32.5px + 1px);
  top: calc(50% - 21px);
  flex: none;
  order: 1;
  flex-grow: 0;
}
.blte-account-route-item__time {
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  color: #363636;
  background-color: #fff;
}
@media screen and (min-width: 576px) {
  .blte-account-route-item__time {
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-account-route-item__time {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 1024px) {
  .blte-account-route-item__time {
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-account-route-item__divider {
  border-top: 2px solid #e1e1e1;
  height: 0;
  flex-grow: 1;
  margin: 0 12px;
}
.blte-account-route-item__schedule {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blte-account-route-item__origin,
.blte-account-route-item__destination {
  display: flex;
  flex-direction: column;
  position: relative;
}
.blte-account-route-item__origin {
  align-items: flex-start;
}
.blte-account-route-item__destination {
  align-items: flex-end;
}
.blte-account-route-item__stations {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-account-route-item__stations:not(:last-child) {
    margin-bottom: 4px;
  }
}
@media screen and (min-width: 768px) {
  .blte-account-route-item__stations:not(:last-child) {
    margin-bottom: 8px;
  }
}
.blte-account-route-item__date {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  color: #094273;
}
@media screen and (min-width: 576px) {
  .blte-account-route-item__date {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-account-route-item__date:not(:last-child) {
  margin-right: 8px;
}
.blte-account-route-item__departure-date {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4px;
}
@media screen and (min-width: 768px) {
  .blte-account-route-item__departure-date {
    margin-bottom: 8px;
  }
}
.blte-account-route-item__old {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #565656;
  text-decoration-line: line-through;
}
.blte-account-route-item__departure {
  text-decoration: line-through;
}
.blte-account-route-item__arrival {
  text-decoration: line-through;
}
.blte-account-route-item__class-tag {
  display: none;
}
@media screen and (min-width: 576px) {
  .blte-account-route-item__class-tag {
    display: inline;
  }
}
