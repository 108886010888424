@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-error-codes-browser {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 24px;
}
.blte-error-codes-browser__search-panel {
  display: flex;
  justify-content: center;
  column-gap: 16px;
}
.blte-error-codes-browser__results-panel {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.blte-error-codes-browser__results-header {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  font-weight: 550;
  border-bottom: 1px solid #e1e1e1;
}
.blte-error-codes-browser__results-header > div {
  text-align: center;
}
.blte-error-codes-browser__result {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  border-bottom: 1px solid #f2f2f4;
}
.blte-error-codes-browser__result > div {
  text-align: center;
}
.blte-error-codes-browser__no-results {
  width: 100%;
  text-align: center;
  padding-top: 16px;
}
.blte-error-codes-browser__view-in-publish {
  width: 100%;
  text-align: center;
  padding: 16px 0;
  background-color: #ffdb00;
  border-radius: 8px;
  border: 1px solid #f2f2f4;
  font-weight: 700;
}
