@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-details-navigation-drawer {
  padding: 16px 24px;
  background: #fff;
  box-shadow: 0 -5px 14px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
