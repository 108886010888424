@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.portal {
  position: static;
}
.blte-coach-select {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 8px 24px;
  align-items: center;
  background-color: #fff;
  color: #363636;
  cursor: pointer;
}
@media screen and (min-width: 576px) {
  .blte-coach-select {
    padding: 16px 24px;
    border-radius: 31px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
}
@media screen and (min-width: 768px) {
  .blte-coach-select {
    padding: 6px 8px 6px 12px;
    border-radius: 31px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
}
.blte-coach-select--disabled:hover {
  cursor: default;
}
.blte-coach-select__container {
  display: flex;
  width: 100%;
}
.blte-coach-select__coach {
  display: flex;
  width: 100%;
}
.blte-coach-select__coach-number {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-coach-select__divider {
  display: flex;
  margin: 0 8px;
}
.blte-coach-select__class {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  text-transform: capitalize;
}
.blte-coach-select:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px #363636;
}
.blte-coach-select:focus-within {
  outline: none;
  box-shadow: 0 0 0 2px #363636;
}
.blte-coach-select__trailing-icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
}
