@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-event-pills__pills {
  margin-right: 10px;
  border-radius: 5px;
  padding: 5px 8px;
  line-height: 18px;
  font-weight: 450;
  font-family: "Futura PT", sans-serif;
  font-size: 14px;
}
