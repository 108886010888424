@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-seating-not-available {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  padding: 12px 32px;
  gap: 10px;
  border-radius: 4px;
  background: #e8f2f1;
}
.blte-trip-seating-not-available--state-finished {
  background-color: #fff;
  margin-bottom: 12px;
  padding: 0 0 0 16px;
}
.blte-trip-seating-not-available__text {
  color: #363636;
}
@media screen and (min-width: 768px) {
  .blte-trip-seating-not-available__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-transform: none;
  }
}
