@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-promo-code {
  width: fit-content;
}
.blte-promo-code__apply {
  height: 40px;
}
@media screen and (min-width: 768px) {
  .blte-promo-code__apply {
    font-size: 16px;
    font-weight: 450;
    line-height: 24px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) and (min-width: 576px) {
  .blte-promo-code__apply {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-promo-code--input {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  gap: 8px;
  justify-content: space-between;
  padding: 0 0 0 1px;
}
@media (min-width: 579px) and (max-width: 1132px) {
  .blte-promo-code--input {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.blte-promo-code .blte-input__input {
  height: 40px;
}
.blte-promo-code__input > input {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: none;
  text-transform: none;
  padding: 12px 36px 12px 12px;
  min-width: unset;
}
@media (min-width: 1132px) {
  .blte-promo-code__input > input {
    min-width: 115px;
  }
}
.blte-promo-code__promo-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 12px;
  height: 42px;
  border: 2px solid #137e00;
  border-radius: 8px;
}
@media (max-width: 767px) {
  .blte-promo-code__promo-btn {
    max-width: 180px;
  }
}
@media (min-width: 768px) and (max-width: 880px) {
  .blte-promo-code__promo-btn {
    max-width: 130px;
  }
}
.blte-promo-code__promo-btn-code {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
@media (max-width: 1132px) {
  .blte-promo-code__promo-btn-code {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.blte-promo-code__promo-btn-icon-left {
  line-height: 17.5px;
  font-size: 17.5px;
  color: #137e00;
}
.blte-promo-code__promo-btn-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #137e00;
}
.blte-promo-code__promo-btn-remove {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
.blte-promo-code__promo-btn-remove:hover {
  cursor: pointer;
}
.blte-promo-code__promo-btn-apply {
  height: fit-content;
}
@media (max-width: 767px) {
  .blte-promo-code .blte-input__error {
    max-width: 160px;
  }
}
.blte-promo-code .blte-input__trailingIcon {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  position: absolute;
  font-size: 24px;
  width: 24px;
  height: 24px;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  color: #8d8d8d;
}
@media (max-width: 1132px) {
  .blte-promo-code--status-success .blte-input {
    margin-left: -30px;
  }
}
.blte-promo-code--status-success .blte-input:hover:not(.blte-input--has-value):not(.blte-input--has-error) .blte-input__leadingIcon {
  color: #fff;
}
.blte-promo-code--status-success .blte-input:hover:not(.blte-input--has-value):not(.blte-input--has-error) .blte-input__input:not(:focus-visible) {
  box-shadow: 0 0 0 2px #137e00;
}
.blte-promo-code--status-success .blte-input__input {
  box-shadow: 0 0 0 2px #137e00;
  padding-left: 34px;
}
.blte-promo-code--status-success .blte-input:focus-within .blte-input__leadingIcon,
.blte-promo-code--status-success .blte-input:hover .blte-input__leadingIcon {
  color: #fff;
}
.blte-promo-code--status-success .blte-input__leadingIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 12px;
  color: #fff;
  background-color: #137e00;
}
.blte-promo-code--status-success .blte-input__trailingIcon {
  cursor: pointer;
  color: #363636;
  font-size: 16px;
  width: 16px;
  height: 16px;
  background-color: transparent;
  padding: 0;
}
.blte-promo-code--status-error .blte-input__trailingIcon {
  background-color: #c00;
}
.blte-promo-code--status-error .blte-input__input {
  color: #c00;
}
.blte-promo-code__button {
  white-space: nowrap;
}
