@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-dashboard {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.blte-account-dashboard__title {
  display: none;
}
@media screen and (min-width: 768px) {
  .blte-account-dashboard__title {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
    display: flex;
  }
}
@media screen and (min-width: 768px) and (min-width: 576px) {
  .blte-account-dashboard__title {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .blte-account-dashboard__title {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-account-dashboard {
    gap: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-account-dashboard {
    gap: 40px;
  }
}
.blte-account-dashboard__row {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}
@media screen and (min-width: 1024px) {
  .blte-account-dashboard__row {
    flex-direction: row;
    justify-content: space-between;
    gap: unset;
  }
}
.blte-account-dashboard__row .blte-account-dashboard__column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 24px;
}
@media screen and (min-width: 1024px) {
  .blte-account-dashboard__row .blte-account-dashboard__column {
    width: 50%;
    max-width: 436px;
    min-width: 260px;
    gap: 40px;
  }
  .blte-account-dashboard__row .blte-account-dashboard__column:nth-child(2) {
    margin-left: 40px;
  }
}
.blte-account-dashboard__row .blte-account-dashboard__column-single {
  width: 100%;
}
.blte-account-dashboard__row .blte-account-dashboard__column-single--no-margin {
  margin-bottom: 0;
}
.blte-account-dashboard__create {
  background-color: #ffdb00;
  color: #fff;
}
.blte-account-dashboard__delayed-message {
  margin: 0 !important;
}
