.blte-rti-step__wrapper--state-finished .blte-rti-step__step::after {
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

}
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rti-step__card {
  margin-bottom: 24px;
  width: 100%;
  padding: 16px 24px;
  background: #fff;
  border-radius: 8px;
  padding: 16px 24px;
  border-radius: 0;
}
@media screen and (min-width: 576px) {
  .blte-rti-step__card {
    padding: 16px 64px;
    border-radius: 8px;
  }
}
@media screen and (min-width: 768px) {
  .blte-rti-step__card {
    padding: 16px 24px;
  }
}
.blte-rti-step__card--isRtiDrawer {
  padding: 0 !important;
}
.blte-rti-step__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.blte-rti-step__wrapper--state-active .blte-rti-step__step {
  background-color: #094273;
}
.blte-rti-step__wrapper--state-passive {
  margin-bottom: 0;
}
.blte-rti-step__wrapper--state-passive .blte-rti-step__step {
  background-color: #565656;
}
.blte-rti-step__wrapper--state-passive .blte-rti-step__text {
  color: #565656;
}
.blte-rti-step__wrapper--state-finished {
  margin-bottom: 8px;
}
.blte-rti-step__wrapper--state-finished .blte-rti-step__step::after {
  content: ICON_FONT_LOADER_IMAGE(IDa4bfa326ce5d9ee1a619cf8d8545a410);
  font-size: 30px;
  background-color: #fff;
  color: #137e00;
}
.blte-rti-step__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
}
@media screen and (min-width: 576px) {
  .blte-rti-step__title {
    font-size: 22px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-rti-step__step {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  width: 24px;
  height: 24px;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.blte-rti-step__server-error {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: #fae6e6;
}
.blte-rti-step__server-error i {
  font-size: 20px;
  color: #c00;
}
.blte-rti-step__error-description {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  padding-left: 10px;
}
.blte-rti-step__card-edit {
  margin-left: 8px;
}
