@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-wallet-edit-pass-modal-mobile__title {
  display: flex;
  justify-content: center;
}
.blte-wallet-edit-pass-modal-mobile__form {
  margin-top: 16px;
}
.blte-wallet-edit-pass-modal-mobile__row {
  display: flex;
  margin-top: 16px;
  gap: 12px;
  flex-direction: column-reverse;
}
.blte-wallet-edit-pass-modal-mobile__row button {
  width: 100%;
}
.blte-wallet-edit-pass-modal-mobile__drawer .blte-drawer__content {
  height: unset;
}
