@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passes-checkout {
  background: #f2f2f4;
  min-height: 100%;
  padding-bottom: 50px;
}
@media screen and (min-width: 768px) {
  .blte-passes-checkout {
    padding: 40px 0;
  }
}
.blte-passes-checkout__wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.blte-passes-checkout__left-column {
  padding-right: 20px;
}
.blte-passes-checkout__right-column {
  padding-left: 20px;
}
