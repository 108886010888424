@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-time-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
}
@media screen and (min-width: 576px) {
  .blte-time-item {
    padding: 12px 24px 12px 12px;
  }
}
@media screen and (min-width: 768px) {
  .blte-time-item {
    padding: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-time-item {
    padding: 16px 24px 16px 16px;
  }
}
.blte-time-item__header {
  display: flex;
  margin-bottom: 8px;
  flex-wrap: wrap;
}
.blte-time-item__header-tag {
  padding-right: 8px;
}
@media screen and (min-width: 1024px) {
  .blte-time-item__header {
    margin-bottom: 6px;
  }
}
.blte-time-item__content {
  position: relative;
  animation: fade-in 1s;
}
@media screen and (min-width: 768px) {
  .blte-time-item__content {
    margin-bottom: 8px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-time-item__content {
    margin-bottom: 6px;
  }
}
.blte-time-item__origin,
.blte-time-item__destination {
  display: flex;
  flex-direction: column;
}
.blte-time-item__origin {
  padding-right: 8px;
}
.blte-time-item__destination {
  padding-left: 8px;
  justify-content: end;
  align-items: end;
}
.blte-time-item__destination .blte-time-item__hour {
  display: flex;
  justify-content: end;
}
.blte-time-item__duration-wrapper {
  flex: 1;
  align-items: center;
  display: flex;
}
.blte-time-item__details {
  display: flex;
  justify-content: space-around;
}
.blte-time-item__duration {
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
}
.blte-time-item__duration-border {
  flex: 1;
  border: 2px solid #e1e1e1;
  height: 2px;
}
.blte-time-item__duration-value,
.blte-time-item__duration-type {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
}
.blte-time-item__duration-type {
  text-decoration-line: underline;
}
.blte-time-item__duration-separator {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #363636;
  margin: 0 4px;
}
@media screen and (min-width: 768px) {
  .blte-time-item__duration {
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    margin: auto;
    padding: 0 8px;
  }
  .blte-time-item__duration-value,
  .blte-time-item__duration-type {
    font-size: 14px;
    font-weight: 450;
    line-height: 20px;
    letter-spacing: 0;
    text-transform: none;
    color: #565656;
  }
  .blte-time-item__duration-type {
    text-decoration-line: underline;
  }
  .blte-time-item__duration-separator {
    display: none;
  }
}
.blte-time-item__hour {
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-time-item__hour {
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-time-item__hour {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 1024px) {
  .blte-time-item__hour {
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 576px) {
  .blte-time-item__hour {
    min-width: 90px;
  }
}
@media screen and (min-width: 768px) {
  .blte-time-item__hour {
    min-width: 100px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-time-item__hour {
    min-width: 110px;
  }
}
.blte-time-item__station {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  display: none;
}
@media screen and (min-width: 768px) {
  .blte-time-item__station {
    display: flex;
  }
}
.blte-time-item__midnight-train {
  display: flex;
  font-size: 12px;
  color: #007981;
}
.blte-time-item__class-tags {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0 4px;
}
@media screen and (min-width: 576px) {
  .blte-time-item__class-tags {
    margin: 8px 0 12px;
  }
}
@media screen and (min-width: 768px) {
  .blte-time-item__class-tags {
    display: none;
  }
}
.blte-time-item__class-tags > :first-child:nth-last-child(2) {
  margin-right: 4px;
}
.blte-time-item__data {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 576px) {
  .blte-time-item__data {
    flex-direction: row;
    justify-content: space-between;
  }
}
.blte-time-item__seats {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #c00;
}
@media screen and (min-width: 768px) {
  .blte-time-item__seats {
    display: none;
  }
}
.blte-time-item__footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: auto;
}
.blte-time-item__footer-tag {
  padding-right: 4px;
}
@media screen and (min-width: 576px) {
  .blte-time-item__footer-tag {
    max-height: 18px;
  }
}
@media screen and (min-width: 768px) {
  .blte-time-item__footer-tag {
    padding-right: 16px;
    max-height: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-time-item__footer-tag {
    max-height: 26px;
  }
}
.blte-time-item__footer-left {
  display: flex;
}
.blte-time-item__footer-right {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}
.blte-time-item__footer-markdown {
  font-size: 12px;
  font-weight: 450;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  text-align: right;
  color: #363636;
}
.blte-time-item__footer-markdown a {
  color: #094273;
}
.blte-time-item__event-train {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;
}
.blte-time-item__event-train-image {
  max-width: 55px;
}
@media screen and (min-width: 768px) {
  .blte-time-item__event-train-image {
    max-width: 73px;
  }
}
.blte-time-item__event-train-description {
  font-size: 12px;
  font-weight: 450;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  text-align: end;
}
.blte-time-item__event-train-description a {
  color: #094273;
}
