@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-parking-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.blte-parking-modal__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
  gap: 24px;
}
@media screen and (min-width: 768px) {
  .blte-parking-modal__content {
    padding: 32px 40px 40px;
  }
}
.blte-parking-modal__content-main {
  margin-top: 8px;
}
.blte-parking-modal__separator {
  border-top: 1px solid #e1e1e1;
}
.blte-parking-modal__icons {
  padding-top: 10px;
  display: flex;
  align-items: start;
  gap: 15px;
}
.blte-parking-modal__icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 64px;
  max-width: 64px;
  padding: 10px 0;
}
.blte-parking-modal__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  line-height: 32px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  color: #363636;
  background-color: #f2f2f4;
}
.blte-parking-modal__icon-label {
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 590;
  line-height: normal;
  color: #363636;
}
.blte-parking-modal__disclaimer {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .blte-parking-modal__disclaimer {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
}
.blte-parking-modal__server-error {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #fae6e6;
}
.blte-parking-modal__server-error i {
  font-size: 20px;
  color: #c00;
}
.blte-parking-modal__error-description {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  padding-left: 10px;
}
