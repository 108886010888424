@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-wallet-pass-item-desktop {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  border-radius: 8px;
}
.blte-wallet-pass-item-desktop__pass-content {
  padding: 16px;
  display: flex;
}
@media screen and (min-width: 768px) {
  .blte-wallet-pass-item-desktop__pass-content {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 1024px) {
  .blte-wallet-pass-item-desktop__pass-content {
    flex-direction: row;
  }
}
.blte-wallet-pass-item-desktop__pass-details {
  display: flex;
  flex-direction: row;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-wallet-pass-item-desktop__pass-details .blte-pass-card,
  .blte-wallet-pass-item-desktop__pass-details .blte-credit-pass {
    min-width: 296px;
    max-width: 296px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-wallet-pass-item-desktop__pass-details .blte-pass-card,
  .blte-wallet-pass-item-desktop__pass-details .blte-credit-pass {
    min-width: 312px;
    max-width: 312px;
  }
}
@media screen and (min-width: 768px) {
  .blte-wallet-pass-item-desktop__action {
    margin-top: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-wallet-pass-item-desktop__action {
    margin-top: 0;
  }
}
.blte-wallet-pass-item-desktop__content {
  display: flex;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-wallet-pass-item-desktop__content {
    padding: 0 16px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-wallet-pass-item-desktop__content {
    padding: 0 0 0 24px;
  }
}
.blte-wallet-pass-item-desktop__label {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
.blte-wallet-pass-item-desktop__value {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
.blte-wallet-pass-item-desktop__info {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.blte-wallet-pass-item-desktop__info > * {
  margin-bottom: 6px;
}
.blte-wallet-pass-item-desktop__info > *:last-child {
  margin-bottom: 0;
}
.blte-wallet-pass-item-desktop__manage-pass {
  border-top: 1px solid #e1e1e1;
  padding: 8px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.blte-wallet-pass-item-desktop__manage-pass-button {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  outline: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: #094273;
}
.blte-wallet-pass-item-desktop__manage-pass-icon {
  transition: all 0.3s;
  font-size: 24px;
}
.blte-wallet-pass-item-desktop__manage-pass--expanded .blte-wallet-pass-item-desktop__manage-pass-icon {
  transform: rotate(180deg);
}
.blte-wallet-pass-item-desktop__manage-pass--expanded .blte-wallet-pass-item-desktop__manage-pass-button {
  margin-bottom: 12px;
}
.blte-wallet-pass-item-desktop__manage-pass:focus-visible {
  box-shadow: 0 0 0 2px #363636;
}
.blte-wallet-pass-item-desktop__renew-content {
  margin-left: 16px;
}
.blte-wallet-pass-item-desktop__renew-content:first-child {
  margin-bottom: 16px;
}
.blte-wallet-pass-item-desktop__auto-renew-wrapper {
  margin-bottom: 16px;
}
