@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-pdf-loader {
  border: 0;
  width: 100%;
  padding: 0;
  outline: none;
}
