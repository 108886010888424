@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-travel-pass-eligible {
  display: flex;
  align-items: center;
  background-color: #137e00;
  border-radius: 18px;
  column-gap: 4px;
  padding: 0 4px 0 3px;
  width: fit-content;
}
.blte-travel-pass-eligible--isTableHeader {
  border-radius: 0 0 4px;
}
.blte-travel-pass-eligible__pass-eligible-label {
  font-size: 12px;
  font-weight: 450;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  color: #fff;
}
.blte-travel-pass-eligible__check-icon {
  display: flex;
  min-width: 13px;
  min-height: 13px;
  max-width: 13px;
  max-height: 13px;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  color: #137e00;
  border-radius: 100%;
  background: #fff;
}
