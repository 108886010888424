@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-newsletter-v2 {
  background-color: var(--newsletter-form-v2-background-color);
}
.blte-newsletter-v2__content {
  padding-top: 50px;
  padding-bottom: 70px;
}
@media (min-width: 1025px) and (max-width: 1080px) {
  .blte-newsletter-v2__content {
    margin: 0 40px;
  }
}
.blte-newsletter-v2__row {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 1025px) {
  .blte-newsletter-v2__row {
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 1024px) {
  .blte-newsletter-v2__row {
    margin-left: 70px;
    margin-right: 70px;
  }
}
@media (max-width: 800px) {
  .blte-newsletter-v2__row {
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media (max-width: 431px) {
  .blte-newsletter-v2__row {
    margin-left: 40px;
    margin-right: 40px;
  }
}
.blte-newsletter-v2__form-row {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.blte-newsletter-v2__input-field {
  width: 100%;
}
.blte-newsletter-v2__column {
  width: 100%;
}
@media screen and (min-width: 576px) {
  .blte-newsletter-v2__column {
    width: 100%;
    padding-left: 0;
  }
}
@media (min-width: 1025px) {
  .blte-newsletter-v2__column {
    width: 455px;
    padding-left: 24px;
  }
}
.blte-newsletter-v2__form-column {
  width: 90%;
  padding-left: 24px;
  display: flex;
  justify-content: center;
}
.blte-newsletter-v2__form-column:nth-child(2) {
  margin-left: 16px;
}
@media (min-width: 1025px) {
  .blte-newsletter-v2__form-column {
    width: 455px;
  }
}
.blte-newsletter-v2__field {
  width: 100%;
  padding: 0;
  margin-bottom: 16px;
}
.blte-newsletter-v2__field--no-bottom {
  margin-bottom: 0;
}
.blte-newsletter-v2__field .blte-phone-number__country-code {
  margin-right: 0;
}
.blte-newsletter-v2__field.blte-checkbox {
  width: auto;
  padding-right: 8px;
}
.blte-newsletter-v2__image {
  display: flex;
  justify-content: center;
  min-height: 350px;
}
@media (min-width: 1025px) {
  .blte-newsletter-v2__image {
    max-width: 455px;
    padding-left: 0;
  }
}
.blte-newsletter-v2__image-border {
  border-radius: 20px;
  height: -webkit-fill-available;
}
.blte-newsletter-v2__title {
  font-size: 30px;
  font-weight: 500;
  line-height: 44px;
  text-align: center;
  margin-bottom: 40px;
}
@media (max-width: 800px) {
  .blte-newsletter-v2__title {
    font-size: 27px;
  }
}
@media (max-width: 431px) {
  .blte-newsletter-v2__title {
    font-size: 22px;
  }
}
.blte-newsletter-v2__subtitle {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}
@media (min-width: 1024px) {
  .blte-newsletter-v2__subtitle {
    margin: 10px 0;
  }
}
@media (max-width: 1023px) {
  .blte-newsletter-v2__subtitle {
    margin: 20px 0;
  }
}
@media (max-width: 431px) {
  .blte-newsletter-v2__subtitle {
    font-size: 18px;
    line-height: 25px;
  }
}
@media (max-width: 281px) {
  .blte-newsletter-v2__subtitle {
    font-size: 16px;
    line-height: 20px;
  }
}
.blte-newsletter-v2__disclaimer-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}
@media screen and (min-width: 768px) {
  .blte-newsletter-v2__disclaimer-wrapper {
    margin-top: -12px;
  }
}
.blte-newsletter-v2__disclaimer {
  padding: 8px 0;
  font-family: "Futura PT", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
@media (min-width: 1025px) {
  .blte-newsletter-v2__disclaimer {
    margin-left: 8px;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .blte-newsletter-v2__disclaimer {
    padding: 10px 0;
  }
}
@media (max-width: 768px) {
  .blte-newsletter-v2__disclaimer {
    padding: 8px 7px;
  }
}
.blte-newsletter-v2__disclaimer-row {
  display: flex;
  flex-direction: column;
  padding: 0 2px;
}
@media screen and (min-width: 768px) {
  .blte-newsletter-v2__disclaimer-row {
    flex-direction: row;
  }
}
.blte-newsletter-confirm {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  flex-direction: column;
}
.blte-newsletter-confirm p {
  margin: 0;
}
.blte-newsletter-confirm .blte-checkbox {
  max-width: none;
  margin-top: 4px;
}
