@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-xf-container {
  display: block;
  height: 100%;
  position: relative;
}
.blte-xf-container .blte-xf-container__element--position-relative {
  position: relative;
}
.blte-xf-container .blte-xf-container__element--hidden {
  display: none;
}
.blte-xf-container__inner-wrapper .blte-banners-container {
  transition: transform 0.3s;
}
.blte-xf-container__inner-wrapper--header .blte-alert-banner--sticky {
  position: fixed;
  z-index: 101;
  top: 0;
}
.blte-xf-container__inner-wrapper--footer .blte-alert-banner--sticky {
  z-index: 101;
  position: fixed;
  bottom: 0;
}
.blte-xf-container__outer-wrapper .blte-xf-container__inner-wrapper--is-sticky {
  transition: transform 0.3s;
  position: fixed;
  z-index: 100;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 10px 12px rgba(0, 0, 0, 0.078);
}
.blte-xf-container__outer-wrapper .blte-xf-container__inner-wrapper--is-hidden {
  transform: translateY(-100%);
}
