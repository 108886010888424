@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-kiosk-home-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--kiosk-homepage-background-color, #2e6a6e);
  align-items: center;
}
.blte-kiosk-home-page__content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1160px;
}
.blte-kiosk-home-page__welcome {
  font-size: 44px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: 0;
  text-transform: none;
  color: var(--kiosk-homepage-welcome-message-text-color, #2e6a6e);
  background: var(--kiosk-homepage-welcome-message-background-color, rgba(46, 106, 110, 0.9));
  border-radius: 4px;
  padding: 0 12px;
  display: inline-flex;
  margin: 80px auto;
}
.blte-kiosk-home-page__widget,
.blte-kiosk-home-page__banner {
  width: 100%;
}
.blte-kiosk-home-page__banner {
  margin-top: 80px;
}
.blte-kiosk-home-page__banner .blte-app-banner {
  max-width: 100%;
}
.blte-kiosk-home-page__banner .blte-app-banner__media {
  justify-content: space-between;
}
.blte-kiosk-home-page__banner .blte-app-banner__description {
  align-items: center;
}
