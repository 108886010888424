@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-modify-trip-origin-and-destination {
  position: relative;
  display: flex;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .blte-modify-trip-origin-and-destination {
    width: 50%;
  }
}
.blte-modify-trip-origin-and-destination__swap-container {
  position: absolute;
  left: 50%;
  top: 36px;
  z-index: 2;
}
.blte-modify-trip-origin-and-destination__swap-container .blte-modify-trip-origin-and-destination__swap-btn {
  transform: rotate(0deg);
  position: absolute;
  top: 0;
  left: -16px;
  padding: 0;
  border-radius: 50%;
  line-height: normal;
  background-color: #fff;
  border: 1px #e1e1e1 solid;
  margin-top: -16px;
  font-size: 0;
}
.blte-modify-trip-origin-and-destination__swap-container .blte-modify-trip-origin-and-destination__swap-btn .blte-icon {
  width: 32px;
  height: 32px;
  font-size: 26px;
  margin: 0;
  color: #094273;
}
.blte-modify-trip-origin-and-destination--is-mobile {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
}
.blte-modify-trip-origin-and-destination--is-mobile .blte-modify-trip-origin-and-destination__swap-container {
  position: relative;
  z-index: 2;
  left: unset;
  top: unset;
}
.blte-modify-trip-origin-and-destination--is-mobile .blte-modify-trip-origin-and-destination__swap-btn {
  transform: rotate(90deg);
  margin-top: -16px;
}
.blte-modify-trip-origin-and-destination--is-mobile .reverse-left {
  transform: translateY(-50%);
}
.blte-modify-trip-origin-and-destination--is-mobile .reverse-right {
  transform: translateY(50%);
}
.reverse-left {
  transform: translateX(-50%);
}
.reverse-right {
  transform: translateX(50%);
}
