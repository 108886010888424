@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-parking-nativeSelect {
  position: absolute;
  inset: 0;
  width: 100%;
  opacity: 0;
}
.blte-parking-nativeSelect__fieldset {
  border: none;
  margin: 10px 0 0;
  padding: 0;
}
.blte-parking-nativeSelect__legend {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.blte-parking-nativeSelect__item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-left: -95px;
}
.blte-parking-nativeSelect__left {
  flex: 1 0 auto;
}
.blte-parking-nativeSelect__right {
  display: flex;
  align-items: center;
  padding: 0 15px;
  justify-content: end;
}
.blte-parking-nativeSelect__right span {
  width: 20px;
  margin: 0 8px;
  text-align: center;
}
.blte-parking-nativeSelect__right button {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background: none;
  color: #363636;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 1px solid #363636;
}
.blte-parking-nativeSelect__right button::before {
  height: 2px;
  width: 12px;
  background: #363636;
  content: "";
}
.blte-parking-nativeSelect__right button:last-child::after {
  content: "";
  height: 12px;
  width: 2px;
  background: #363636;
  position: absolute;
}
.blte-parking-nativeSelect__right button:focus-visible {
  box-shadow: 0 0 0 1px #363636, 0 0 0 3px #363636;
}
.blte-parking-nativeSelect__right button:disabled {
  box-shadow: none;
  background: none;
  cursor: auto;
}
.blte-parking-nativeSelect__right button:disabled::before,
.blte-parking-nativeSelect__right button:disabled::after {
  background: none;
}
.blte-parking-nativeSelect__numberOfDays {
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 12px 30px;
  background-color: #fff;
  margin: 0 5px;
}
.blte-parking-nativeSelect__title {
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-parking-nativeSelect__title {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
