@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-error__title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.blte-error__icon {
  font-size: 32px;
  margin-right: 5px;
}
