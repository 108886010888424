@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-link-button-list--variant-horizontal {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 576px) {
  .blte-link-button-list--variant-horizontal {
    flex-direction: row;
    align-items: center;
  }
  .blte-link-button-list--variant-horizontal .blte-link-button-list__title {
    padding: 8px 8px 8px 0;
  }
}
.blte-link-button-list--variant-horizontal .blte-link-button {
  padding: 8px 0;
}
.blte-link-button-list--variant-horizontal .blte-link-button-list__item {
  padding-right: 8px;
}
.blte-link-button-list--variant-horizontal .blte-link-button-list__item:last-child {
  padding-right: 0;
}
.blte-link-button-list--variant-vertical {
  display: flex;
  flex-direction: column;
  max-width: 440px;
}
.blte-link-button-list__title {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: var(--text-color);
  padding: 8px 0;
}
@media screen and (min-width: 576px) {
  .blte-link-button-list__title {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
