@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-cancellation-modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.blte-cancellation-modal__select-cancellation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.blte-cancellation-modal__title {
  font-size: 25px;
  font-weight: 500;
  margin: 0;
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .blte-cancellation-modal__title {
    padding: 0;
  }
}
.blte-cancellation-modal__partial-cancellation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .blte-cancellation-modal__partial-cancellation {
    flex-direction: row;
    padding: 0;
  }
}
.blte-cancellation-modal__buttons {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 25px 0;
  padding-top: 20px;
  border-top: solid 1px #e1e1e1;
}
.blte-cancellation-modal__buttons button {
  width: 40%;
}
.blte-cancellation-modal__buttons button:disabled {
  color: #636366;
  background-color: #e1e1e1;
}
@media screen and (min-width: 768px) {
  .blte-cancellation-modal__buttons {
    justify-content: end;
    border-top: none;
    margin: 0;
  }
  .blte-cancellation-modal__buttons button {
    width: fit-content;
  }
}
.blte-cancellation-modal .blte-modal__body {
  background-color: #fff;
}
.blte-cancellation-modal--details .blte-modal__body {
  background-color: #f2f2f4;
}
.blte-cancellation-modal--isMobile .blte-modal__body .blte-modal__content {
  height: 100%;
}
.blte-cancellation-modal--isMobile .blte-modal__content {
  padding: 24px 0 0;
  max-height: 100%;
}
.blte-cancellation-modal__discard-cancellation .blte-modal__body {
  border-radius: 20px;
  width: 70%;
  height: fit-content;
}
@media screen and (min-width: 768px) {
  .blte-cancellation-modal__discard-cancellation .blte-modal__body {
    width: 650px;
  }
}
.blte-cancellation-modal__discard-buttons {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
}
@media screen and (min-width: 1024px) {
  .blte-cancellation-modal > div {
    width: 50%;
    max-height: 538px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-cancellation-modal {
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .blte-cancellation-modal {
    flex-direction: row;
  }
}
.blte-cancellation-modal__summary {
  background-color: #fff;
}
@media screen and (min-width: 1024px) {
  .blte-cancellation-modal__summary {
    border-radius: 8px;
    width: 50%;
    height: 538px;
  }
}
.blte-cancellation-modal .blte-rti-step__card {
  margin-bottom: 0;
  overflow: auto;
  height: 100%;
}
.blte-cancellation-modal .blte-cost-summary-sections__cancelation-fee > div {
  display: flex;
  align-items: center;
}
