@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-empty-view {
  color: #363636;
}
.blte-empty-view__page-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-empty-view__page-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-empty-view__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
.blte-empty-view__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  text-transform: none;
  margin-top: 24px;
}
@media screen and (min-width: 576px) {
  .blte-empty-view__title {
    font-size: 22px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-empty-view__subtitle {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  text-align: center;
  color: #565656;
  margin-top: 4px;
}
@media screen and (min-width: 576px) {
  .blte-empty-view__subtitle {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-empty-view .blte-btn {
  margin-top: 24px;
}
