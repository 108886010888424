@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-toggle {
  display: inline-flex;
  align-items: center;
  background: #f2f2f4;
  border-radius: 18px;
  height: 36px;
  color: #094273;
}
.blte-toggle__item {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  font-family: "Futura PT", sans-serif;
  padding: 6px 16px 6px 32px;
  cursor: pointer;
  position: relative;
  border: none;
  border-radius: 18px;
  min-height: 100%;
  margin-left: 3px;
  flex-shrink: 0;
  background: none;
  color: #094273;
  transition: box-shadow 300ms, background 300ms;
}
.blte-toggle__item::before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px #094273;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
}
.blte-toggle__item:first-child {
  margin-left: 0;
}
.blte-toggle__item:focus {
  outline: none;
}
.blte-toggle__item:focus-visible {
  box-shadow: 0 0 0 2px #363636;
}
.blte-toggle__item--checked {
  background: #094273;
  color: white;
}
.blte-toggle__item--checked::before {
  box-shadow: inset 0 0 0 4px #fff;
}
.blte-toggle__item--checked:focus-visible {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #363636;
}
