@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-video-modal__background {
  inset: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #363636;
  opacity: 0.8;
  z-index: 2;
}
.blte-video-modal__play {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  inset: 0;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--video-modal-player-icon-color, #094273);
  color: #fff;
  margin: auto;
  outline: none;
  width: 80px;
  height: 80px;
}
.blte-video-modal__play > * {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 40px;
}
@media screen and (min-width: 576px) {
  .blte-video-modal__play {
    width: 128px;
    height: 128px;
  }
  .blte-video-modal__play > * {
    width: 64px;
    height: 64px;
    font-size: 64px;
  }
}
.blte-video-modal__play:hover {
  cursor: pointer;
}
.blte-video-modal__play:focus-visible {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #363636;
}
.blte-video-modal__player {
  inset: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  z-index: 2;
  padding: 24px;
}
@media screen and (min-width: 576px) {
  .blte-video-modal__player {
    padding: 40px;
  }
}
.blte-video-modal__player-close {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  position: absolute;
  background-color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  align-self: flex-end;
  outline: none;
}
.blte-video-modal__player-close:focus,
.blte-video-modal__player-close:focus-visible {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #363636;
}
.blte-video-modal__player-content {
  max-width: 1400px;
  margin: auto;
  aspect-ratio: 16/9;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-video-modal__player-content {
    width: 70vw;
  }
}
@media screen and (min-width: 1024px) {
  .blte-video-modal__player-content {
    width: 60vw;
  }
}
