.blte-fare-selection-item__fareClassOptions ul li::before,.blte-fare-selection-item__fareClassOptions ol li::before {
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

}
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-fare-selection-item {
  border-radius: 12px;
  padding: 16px 16px 8px;
  gap: 4px;
  border: 2px solid #f2f2f4;
  background-color: #f2f2f4;
  cursor: pointer;
}
.blte-fare-selection-item + .blte-fare-selection-item {
  margin-top: 16px;
}
.blte-fare-selection-item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blte-fare-selection-item__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  padding: 0 5px;
  border-radius: 4px;
}
.blte-fare-selection-item--fareVariant-smart .blte-fare-selection-item__title {
  background-color: #ffdb00;
}
.blte-fare-selection-item--fareVariant-premium .blte-fare-selection-item__title {
  color: #fff;
  background-color: #363636;
}
.blte-fare-selection-item--selected.blte-fare-selection-item--fareVariant-smart {
  border-color: #ffdb00;
}
.blte-fare-selection-item--selected.blte-fare-selection-item--fareVariant-premium {
  border-color: #363636;
}
.blte-fare-selection-item--unavailable {
  cursor: auto;
}
.blte-fare-selection-item--unavailable .blte-fare-selection-item__radio input {
  border-color: #e1e1e1;
}
.blte-fare-selection-item--unavailable .blte-fare-selection-item__radio input::before {
  background-color: #f2f2f4;
}
.blte-fare-selection-item--unavailable .blte-fare-selection-item__description {
  color: #565656;
}
.blte-fare-selection-item--expanded .blte-fare-selection-item__fareClassOptions {
  display: block;
}
.blte-fare-selection-item__description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  margin: 4px 0 0;
}
@media screen and (min-width: 576px) {
  .blte-fare-selection-item__description {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-fare-selection-item__cost {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.blte-fare-selection-item__originalPrice {
  margin-left: 3px;
  color: #565656;
  text-decoration: line-through;
  text-decoration-thickness: 1px;
}
.blte-fare-selection-item__radio input {
  border-width: 2px;
}
.blte-fare-selection-item__passenger {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  margin-left: 4px;
}
@media screen and (min-width: 576px) {
  .blte-fare-selection-item__passenger {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-fare-selection-item__more {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-fare-selection-item__fareClassOptions {
  display: none;
  padding: 12px 0 8px;
}
.blte-fare-selection-item__fareClassOptions ul,
.blte-fare-selection-item__fareClassOptions ol {
  margin: 0;
  padding-left: 30px;
  list-style: none;
}
.blte-fare-selection-item__fareClassOptions li {
  margin-bottom: 16px;
  position: relative;
}
.blte-fare-selection-item__fareClassOptions ul li::before {
  color: #137e00;
  content: ICON_FONT_LOADER_IMAGE(IDa4bfa326ce5d9ee1a619cf8d8545a410);
  position: absolute;
  left: -30px;
  font-size: 23px;
  line-height: 23px;
  top: 0;
}
.blte-fare-selection-item__fareClassOptions ol li {
  color: #565656;
}
.blte-fare-selection-item__fareClassOptions ol li::before {
  content: ICON_FONT_LOADER_IMAGE(ID989933a0b4109a9227eba69d728ba3fc);
  position: absolute;
  left: -23px;
  font-size: 18px;
  top: 0;
}
.blte-fare-selection-item__fareClassOptions ul:last-child > li:last-child,
.blte-fare-selection-item__fareClassOptions ol:last-child > li:last-child {
  margin-bottom: 0;
}
.blte-fare-selection-item__fareClassOptions li {
  margin-bottom: 8px;
}
