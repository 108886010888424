@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-input {
  position: relative;
  width: 100%;
  color: #8d8d8d;
}
.blte-input__fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
.blte-input__fieldset--phone-number {
  width: 100%;
}
.blte-input__fieldset--parking {
  width: 85%;
  margin-left: -55px;
}
.blte-input__fieldset--promo-code {
  width: inherit;
}
.blte-input__coupon {
  position: absolute;
  font-size: 24px;
  top: 50%;
  left: 8px;
  display: flex;
  transform: translateY(-50%);
}
.blte-input__legend {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.blte-input__leadingIcon {
  position: absolute;
  font-size: 24px;
  color: #000;
  top: 50%;
  left: 8px;
  display: flex;
  transform: translateY(-50%);
}
.blte-input__leadingIcon--external {
  max-width: 36px;
}
.blte-input__trailingIcon {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  position: absolute;
  font-size: 24px;
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  padding: 0;
  margin: 0;
  background: none;
  outline: none;
  border: none;
  color: #8d8d8d;
}
.blte-input__trailingIcon--error {
  color: #c00;
}
.blte-input__trailingIcon:focus:not(:focus-visible) {
  outline: none;
}
.blte-input__trailingIcon:focus-visible {
  outline: none;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #363636;
}
.blte-input__helper-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  padding: 0 8px;
  gap: 16px;
  justify-content: space-between;
  color: #565656;
}
.blte-input__helper-container--error-inline {
  position: relative;
}
.blte-input__helper-container--applied-promo-code {
  padding: 2px 0;
}
.blte-input__error {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  color: #c00;
}
.blte-input__label {
  position: absolute;
  color: #8d8d8d;
  padding: 0 2px;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
  width: calc(100% - 16px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.blte-input__label--promo-code {
  margin-left: 28px;
  width: calc(100% - 66px);
}
.blte-input__label-required {
  color: #c00;
}
.blte-input__label--hasLeadingIcon {
  left: 40px;
  width: calc(100% - 80px);
}
.blte-input__input {
  appearance: none;
  width: 100%;
  padding: 8px;
  gap: 8px;
  outline: none;
  border-radius: 4px;
  border: none;
  box-shadow: 0 0 0 1px #e1e1e1;
  transition: 0.1s ease-out;
  display: flex;
  flex-wrap: wrap;
  line-height: 26px;
  height: 48px;
  text-overflow: ellipsis;
}
.blte-input__input--promo-code {
  padding-left: 36px !important;
}
.blte-input__input--travel-agent-error {
  border-color: #c00;
}
.blte-input__input--hasExternalLeadingIcon {
  padding-left: 50px;
}
.blte-input__input--hasLeadingIcon:not(.blte-input__input--hasExternalLeadingIcon) {
  padding-left: 40px;
}
.blte-input__input--hasTrailingIcon {
  padding-right: 40px;
}
.blte-input__input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #fff inset;
}
.blte-input:focus-within .blte-input__label {
  font-weight: 500;
  transform: translateY(-50%) scale(1);
  top: 0;
  font-size: 12px;
  opacity: 1;
  left: 8px !important;
  width: auto;
  max-width: calc(100% - 40px);
  z-index: 1;
  color: #094273;
  line-height: 1;
}
.blte-input:focus-within .blte-input__leadingIcon {
  color: #000;
}
.blte-input:focus-within .blte-input__input {
  box-shadow: 0 0 0 2px #094273;
  color: #363636;
}
.blte-input--has-value .blte-input__leadingIcon {
  color: #000;
}
.blte-input--has-value .blte-input__label {
  font-weight: 500;
  transform: translateY(-50%) scale(1);
  color: #a3a3a3;
  top: 0;
  font-size: 12px;
  opacity: 1;
  left: 8px !important;
  width: auto;
  max-width: calc(100% - 40px);
  line-height: 1;
}
.blte-input--has-value .blte-input__input {
  color: #363636;
  box-shadow: 0 0 0 1px #e1e1e1;
}
.blte-input--has-error .blte-input__label {
  color: #c00;
  width: auto;
  max-width: calc(100% - 40px);
  line-height: 1;
}
.blte-input--has-error .blte-input__label:not(.blte-input--has-value) {
  color: #c00;
  font-weight: 500;
  transform: translateY(-50%) scale(1);
  top: 0;
  font-size: 12px;
  opacity: 1;
  left: 8px !important;
  z-index: 1;
}
.blte-input--has-error .blte-input__input {
  box-shadow: 0 0 0 1px #c00;
  color: #363636;
}
.blte-input--has-error .blte-input__leadingIcon {
  color: #363636;
}
.blte-input--has-error.blte-input--disabled .blte-input__label {
  color: #e1e1e1;
}
.blte-input--has-error.blte-input:focus-within .blte-input__input {
  box-shadow: 0 0 0 2px #c00;
}
.blte-input--disabled {
  background-color: #fefefe;
  pointer-events: none;
  cursor: not-allowed;
  color: #e1e1e1;
}
.blte-input--disabled .blte-input__label,
.blte-input--disabled .blte-input__input,
.blte-input--disabled .blte-input__leadingIcon,
.blte-input--disabled .blte-input__trailingIcon,
.blte-input--disabled .blte-input__error,
.blte-input--disabled .blte-input__label-required,
.blte-input--disabled .blte-input__helper-container {
  border-color: #e1e1e1;
  color: #a3a3a3;
  background-color: inherit;
  -webkit-touch-callout: none;
  user-select: none;
}
.blte-input--disabled .blte-input__label::placeholder,
.blte-input--disabled .blte-input__input::placeholder,
.blte-input--disabled .blte-input__leadingIcon::placeholder,
.blte-input--disabled .blte-input__trailingIcon::placeholder,
.blte-input--disabled .blte-input__error::placeholder,
.blte-input--disabled .blte-input__label-required::placeholder,
.blte-input--disabled .blte-input__helper-container::placeholder {
  color: transparent;
}
.blte-input--disabled .blte-input__helper-container {
  background: none;
}
.blte-input__helper-text--promo-code {
  color: green;
}
.blte-input:hover:not(.blte-input--has-value):not(.blte-input--has-error) .blte-input__leadingIcon {
  color: #000;
}
.blte-input:hover:not(.blte-input--has-value):not(.blte-input--has-error) .blte-input__input:not(:focus-visible) {
  box-shadow: 0 0 0 1px #094273;
}
.blte-input input::-webkit-contacts-auto-fill-button {
  margin-right: 22px;
}
