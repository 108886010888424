@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rti-seat-route {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f2f2f4;
  border-radius: 8px;
  padding: 16px;
}
.blte-rti-seat-route__direction {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: none;
  color: #094273;
}
.blte-rti-seat-route__stations {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  display: flex;
  align-items: center;
  column-gap: 4px;
}
.blte-rti-seat-route__icon {
  color: #094273;
  width: 24px;
  height: 24px;
  font-size: 20px;
}
.blte-rti-seat-route__passengers {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 16px;
}
.blte-rti-seat-route__seating-unavailable-text {
  color: #363636;
}
@media screen and (min-width: 768px) {
  .blte-rti-seat-route__seating-unavailable-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-transform: none;
  }
}
