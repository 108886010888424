@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-ticket-break {
  height: 1px;
  background-image: linear-gradient(to right, #565656 33%, white 0%);
  background-position: bottom;
  background-size: 8px 1px;
  background-repeat: repeat-x;
  width: 100%;
  position: relative;
}
.blte-ticket-break::before {
  content: "";
  position: absolute;
  left: -10px;
  top: -10px;
  height: 20px;
  width: 20px;
  border-radius: 0 20px 20px 0;
  background: #f2f2f4;
  z-index: 1;
}
.blte-ticket-break::after {
  content: "";
  z-index: 1;
  position: absolute;
  right: -10px;
  top: -10px;
  height: 20px;
  width: 20px;
  border-radius: 20px 0 0 20px;
  background: #f2f2f4;
}
