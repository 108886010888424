@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passes-review {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 8px;
  background: #fff;
  margin-top: 24px;
  border-radius: 0;
}
@media screen and (min-width: 768px) {
  .blte-passes-review {
    margin-top: 0;
    border-radius: 8px;
  }
}
.blte-passes-review__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.blte-passes-review__title-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  color: #137e00;
}
.blte-passes-review__title-text {
  width: 100%;
  color: #363636;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .blte-passes-review__title-text {
    font-size: 22px;
    line-height: 34px;
    font-weight: 500;
  }
}
.blte-passes-review__title-placeholder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.blte-passes-review__summary {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}
@media screen and (min-width: 768px) {
  .blte-passes-review__summary {
    gap: 24px;
  }
}
.blte-passes-review__summary-travel-pass {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 32px;
  gap: 0;
}
@media screen and (min-width: 768px) {
  .blte-passes-review__summary-travel-pass {
    gap: 6px;
  }
}
.blte-passes-review__summary-subtitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.blte-passes-review__summary-subtitle-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #363636;
}
.blte-passes-review__summary-subtitle-price {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #363636;
}
.blte-passes-review__summary-details {
  display: flex;
  flex-direction: column;
  gap: 0;
}
@media screen and (min-width: 768px) {
  .blte-passes-review__summary-details {
    gap: 6px;
  }
}
.blte-passes-review__summary-details-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #363636;
}
.blte-passes-review__summary--isParkingPass {
  flex-direction: row;
  padding: 8px 0 0 32px;
}
.blte-passes-review__summary-parking-labels {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}
@media screen and (min-width: 768px) {
  .blte-passes-review__summary-parking-labels {
    gap: 0;
  }
}
.blte-passes-review__summary-parking-labels--isRight {
  width: fit-content;
}
.blte-passes-review__summary-parking-labels-text {
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  color: #363636;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .blte-passes-review__summary-parking-labels-text {
    font-weight: 400;
  }
}
