@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-privacy-request-form {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 30px 40px;
}
.blte-privacy-request-form__input {
  padding-bottom: 20px;
}
.blte-privacy-request-form__header {
  margin-top: 10px;
  text-align: center;
}
.blte-privacy-request-form__button {
  display: flex;
  justify-content: center;
}
.blte-privacy-request-form__text {
  font-size: 17px;
}
