@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-carousel-dots {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-flow: row wrap;
  gap: 6px;
}
.blte-carousel-dots__dot {
  width: 7px;
  height: 7px;
  background-color: #8d8d8d;
  border-radius: 50%;
  opacity: 0.3;
}
.blte-carousel-dots__dot:last-child {
  margin-right: 0;
}
.blte-carousel-dots__dot--active {
  opacity: 1;
}
