@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-payment-info-entry {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.blte-payment-info-entry__form {
  width: 100%;
}
.blte-payment-info-entry__row {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 576px) {
  .blte-payment-info-entry__row {
    flex-direction: row;
  }
}
.blte-payment-info-entry__row--lastCardRow {
  flex-direction: row;
}
.blte-payment-info-entry__row--lastCardRow .blte-payment-info-entry__input {
  margin-bottom: 0;
}
.blte-payment-info-entry__row--lastCardRow .blte-payment-info-entry__input:nth-child(2) {
  margin-left: 16px;
}
.blte-payment-info-entry__input {
  width: 100%;
  margin-bottom: 16px;
}
@media screen and (min-width: 576px) {
  .blte-payment-info-entry__input:nth-child(2) {
    margin-left: 12px;
  }
}
@media screen and (min-width: 768px) {
  .blte-payment-info-entry__input--lastRow {
    margin-bottom: 0;
  }
}
.blte-payment-info-entry__section:last-child {
  margin-top: 24px;
}
.blte-payment-info-entry__section-header {
  margin-bottom: 8px;
}
.blte-payment-info-entry__section-header--cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 0;
}
.blte-payment-info-entry__section-header-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-payment-info-entry__section-header-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-payment-info-entry__card-image {
  max-width: 36px;
  margin-right: 10px;
}
.blte-payment-info-entry__card-image:last-child {
  margin-right: 0;
}
.blte-payment-info-entry__saved-cards {
  display: flex;
  width: 100%;
}
.blte-payment-info-entry__saved-cards > div {
  display: flex;
  width: 100%;
}
.blte-payment-info-entry__divider {
  width: 100%;
  overflow: hidden;
}
.blte-payment-info-entry__divider .blte-divider {
  margin: 16px 0;
}
.blte-payment-info-entry__save-card {
  margin-top: 16px;
}
.blte-payment-info-entry__kiosk-notification {
  display: flex;
  padding: 8px 24px 8px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: rgba(9, 66, 115, 0.1);
}
.blte-payment-info-entry__kiosk-notification .blte-icon {
  font-size: 24px;
  color: #094273;
}
.blte-payment-info-entry__kiosk-notification-text {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
