@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rti-policy-item {
  color: #363636;
}
.blte-rti-policy-item__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-rti-policy-item__description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #636366;
  margin-top: 5px;
}
.blte-rti-policy-item__description-tag {
  display: flex;
  flex-direction: row;
}
.blte-rti-policy-item__description-tag > p {
  font-weight: 500;
  color: #363636;
}
.blte-rti-policy-item__description a {
  color: #094273;
}
.blte-rti-policy-item__description ul {
  margin-top: 5px;
  padding-left: 19px;
}
.blte-rti-policy-item__divider {
  border-top: 1px solid #f2f2f4;
  height: 0;
  flex-grow: 1;
  margin: 12px 0;
}
.blte-rti-policy-item__images {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  list-style: none;
  padding: 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
}
.blte-rti-policy-item__images li {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 8px;
  margin-right: 16px;
}
@media screen and (min-width: 768px) {
  .blte-rti-policy-item__images li {
    margin-right: 24px;
  }
}
.blte-rti-policy-item__images li:last-child {
  margin-right: 0;
}
.blte-rti-policy-item__images img {
  width: 24px;
  height: auto;
  margin-right: 8px;
  align-self: center;
}
.blte-rti-policy-item__icon {
  margin-top: 5px;
  max-width: 30%;
  height: 20px;
}
.blte-rti-policy-item__icon img {
  max-width: 24px;
  height: 24px;
}
.blte-rti-policy-item__policy-item {
  display: flex;
  gap: 16px;
}
.blte-rti-policy-item__policy-item--isTermsOfService {
  margin-bottom: 16px;
  background-color: #f2f2f4;
  padding: 8px;
  border-radius: 10px;
}
.blte-rti-policy-item__terms {
  text-decoration: underline;
  display: inline;
  color: #094273;
  cursor: pointer;
}
.blte-rti-policy-item__policy {
  display: inline;
}
.blte-rti-policy-item__policy :first-child {
  display: inline;
}
.blte-rti-policy-item__sub-title {
  display: flex;
}
.blte-rti-policy-item__stations {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-rti-policy-item__stations > i {
  color: #094273;
}
.blte-rti-policy-item__modalHeader {
  padding: 24px 30px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}
.blte-rti-policy-item__modalHeader button {
  position: relative;
  float: right;
}
