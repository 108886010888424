@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px 16px;
  background: #fff;
  border-radius: 8px;
  width: 100%;
  min-height: 124px;
  height: 100%;
}
.blte-account-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  margin-bottom: 16px;
  flex-flow: row wrap;
  width: 100%;
}
.blte-account-card__title {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  padding-top: 4px;
  flex: 1;
}
@media screen and (min-width: 576px) {
  .blte-account-card__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-account-card__action {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  padding: 0;
}
.blte-account-card__placeholders {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px 16px;
  background: #fff;
  border-radius: 8px;
  height: 100%;
}
.blte-account-card__placeholders > div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.blte-account-card__placeholders > div:first-child:not(:last-child) {
  margin-bottom: 22px;
}
.blte-account-card__placeholders > div:first-child > div:first-child {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  width: 150px;
  height: 24px;
  border-radius: 2px;
}
.blte-account-card__placeholders > div:first-child > div:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-account-card__placeholders > div:first-child > div:nth-child(2) {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  width: 60px;
  height: 16px;
  border-radius: 2px;
}
.blte-account-card__placeholders > div:first-child > div:nth-child(2)::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-account-card__placeholders-content {
  display: flex;
  width: 100%;
}
.blte-account-card__placeholders-content--variant-large {
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blte-account-card__placeholders-content--variant-large > div:first-child {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  display: flex;
  max-width: 340px;
  height: 212px;
  width: 100%;
  border-radius: 16px;
}
.blte-account-card__placeholders-content--variant-large > div:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-account-card__placeholders-content--variant-small {
  display: flex;
  align-items: center;
  justify-content: center;
}
.blte-account-card__placeholders-content--variant-small > div:first-child {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  display: flex;
  height: 48px;
  width: 100%;
  border-radius: 8px;
}
.blte-account-card__placeholders-content--variant-small > div:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-account-card__placeholders-content--variant-medium {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.blte-account-card__placeholders-content--variant-medium > div:first-child,
.blte-account-card__placeholders-content--variant-medium > div:nth-child(2) {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  display: flex;
  height: 64px;
  width: 100%;
  border-radius: 8px;
}
.blte-account-card__placeholders-content--variant-medium > div:first-child::after,
.blte-account-card__placeholders-content--variant-medium > div:nth-child(2)::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-account-card__placeholders-content--variant-medium > div:not(:first-child) {
  margin-top: 16px;
}
.blte-account-card__placeholders-button {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  width: 30%;
  height: 32px;
  border-radius: 2px;
  margin-top: 22px;
}
.blte-account-card__placeholders-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
