@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-parking-review__placeholder {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.blte-parking-review__form {
  display: flex;
  gap: 12px;
  flex-direction: column;
}
.blte-parking-review__fieldset {
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 12px;
  flex-direction: column;
}
.blte-parking-review__legend {
  font-size: 1em;
  padding: 0;
  margin-bottom: 5px;
}
