@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-timeout-modal__time {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  background: #e8f2f1;
  border-radius: 8px;
  padding: 8px 16px;
  display: inline-flex;
}
.blte-timeout-modal__title {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
  margin-top: 24px;
}
.blte-timeout-modal__description {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  margin-top: 8px;
}
.blte-timeout-modal__buttons {
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
}
.blte-timeout-modal__buttons > button:last-child {
  margin-left: 12px;
}
