@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-passengers__user {
  overflow: hidden;
}
.blte-account-passengers__list {
  flex: auto;
}
@media screen and (min-width: 768px) {
  .blte-account-passengers__list {
    flex: none;
  }
}
.blte-account-passengers__list-row {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 12px 8px 12px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
}
@media screen and (min-width: 576px) {
  .blte-account-passengers__list-row {
    padding: 12px 12px 12px 16px;
  }
}
.blte-account-passengers__list-row .blte-account-passengers__edit {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  color: #000;
  font-size: 28px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 6px 8px;
  height: 20px;
  margin-left: 8px;
}
.blte-account-passengers__list-row .blte-account-passengers__edit i::after {
  display: flex;
  line-height: 0;
  align-items: start;
  height: 16px;
}
.blte-account-passengers__list-row .blte-account-passengers__edit--opened {
  background-color: #e8f2f1;
  box-shadow: 0 0 0 2px #363636;
  border-radius: 4px;
}
@media screen and (min-width: 576px) {
  .blte-account-passengers__list-row .blte-account-passengers__edit {
    margin-left: 16px;
  }
}
.blte-account-passengers__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 40px;
}
@media screen and (min-width: 576px) {
  .blte-account-passengers__title {
    font-size: 22px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-account-passengers__actions-container {
  position: relative;
}
.blte-account-passengers__actions {
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border: 1px solid #f2f2f4;
  border-radius: 4px;
  width: max-content;
}
.blte-account-passengers__actions--visible {
  display: flex;
  transform: translate(0, 102%);
  z-index: 1;
}
.blte-account-passengers__actions-btn {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  padding: 8px 16px;
}
.blte-account-passengers__actions-btn:focus {
  outline: none;
}
.blte-account-passengers__actions-btn:focus-visible {
  background-color: #e8f2f1;
  box-shadow: 0 0 0 2px #363636;
  border-radius: 4px;
}
.blte-account-passengers__delete {
  color: #c00;
}
.blte-account-passengers__add-to-list {
  display: flex;
  position: sticky;
  bottom: 0;
}
@media screen and (min-width: 768px) {
  .blte-account-passengers__add-to-list {
    position: relative;
    padding-bottom: 16px;
    justify-content: end;
    width: 100%;
  }
}
.blte-account-passengers__user-account {
  padding: 8px 0 0;
}
@media screen and (min-width: 576px) {
  .blte-account-passengers__user-account {
    padding: 16px 0 0;
  }
}
