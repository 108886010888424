@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-circular-progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.blte-circular-progress-bar__container {
  transform: rotate(90deg);
  display: flex;
  justify-content: center;
}
.blte-circular-progress-bar__progress-border,
.blte-circular-progress-bar__current-progress-border {
  fill: none;
  stroke: white;
  stroke-width: 20px;
  stroke-linecap: round;
}
.blte-circular-progress-bar__progress-background,
.blte-circular-progress-bar__current-progress-background {
  fill: none;
  stroke-width: 14px;
  stroke-linecap: round;
}
.blte-circular-progress-bar__current-progress-border,
.blte-circular-progress-bar__current-progress-background {
  animation: load-progress-bar 1s ease-in;
}
.blte-circular-progress-bar__wrapper {
  position: relative;
}
.blte-circular-progress-bar__tooltip {
  position: absolute;
  bottom: 44px;
  right: 43px;
}
.blte-circular-progress-bar__text {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}
.blte-circular-progress-bar__total {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #363636;
}
.blte-circular-progress-bar__total-icon {
  font-size: 10px;
}
.blte-circular-progress-bar__progress-background-placeholder {
  fill: none;
  stroke: #f2f2f4;
  stroke-width: 20px;
  stroke-linecap: round;
}
