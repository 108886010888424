@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-saved-cards {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
}
