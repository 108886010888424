@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rewards-points {
  background-color: #e6f2f2;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 4px 8px;
  gap: 4px;
  font-size: 15px;
}
@media screen and (min-width: 576px) {
  .blte-rewards-points {
    padding: 8px;
    font-size: 18px;
  }
}
@media (min-width: 1025px) {
  .blte-rewards-points {
    padding: 8px 16px;
  }
}
.blte-rewards-points__rewards-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.blte-rewards-points__rewards-icon-wrapper picture,
.blte-rewards-points__rewards-icon-wrapper img {
  width: 24px;
  height: 24px;
}
@media (min-width: 1025px) {
  .blte-rewards-points__rewards-icon-wrapper picture,
  .blte-rewards-points__rewards-icon-wrapper img {
    width: 32px;
    height: 32px;
  }
}
.blte-rewards-points__content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.blte-rewards-points__heading {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}
.blte-rewards-points__title-tooltip-container {
  display: flex;
  gap: 4px;
}
@media screen and (min-width: 576px) {
  .blte-rewards-points__tooltip-passes i {
    padding-top: 4px;
  }
}
.blte-rewards-points__rewards {
  margin-left: auto;
  white-space: nowrap;
  text-align: right;
}
.blte-rewards-points__subtext {
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: 12px;
  line-height: 20px;
  color: #565656;
}
@media screen and (min-width: 576px) {
  .blte-rewards-points__subtext {
    font-size: 14px;
  }
}
.blte-rewards-points__bolder {
  font-weight: 700;
}
