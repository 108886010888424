@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-location-item {
  min-height: 60px;
  border-radius: 8px;
  padding: 4px 8px;
  margin: 4px 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.blte-location-item:focus {
  outline: none;
}
.blte-location-item:focus-visible {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #363636;
}
.blte-location-item__description {
  display: flex;
  flex-direction: column;
}
.blte-location-item__label {
  color: #363636;
}
.blte-location-item__info,
.blte-location-item__units {
  min-height: 18px;
  color: #565656;
}
.blte-location-item:hover,
.blte-location-item--selected {
  background-color: #f2f2f4;
}
