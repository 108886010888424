@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-merchandising-banner {
  background-color: var(--merchandising-banner-background-color, #fff);
  display: flex;
  padding: 16px;
  border-radius: 8px;
  margin-top: 40px;
  justify-content: space-between;
}
.blte-merchandising-banner__description {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
