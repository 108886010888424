@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-parking-driver-details__card {
  width: 100%;
  padding: 12px 16px 12px 12px;
  background: #f2f2f4;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .blte-parking-driver-details__card {
    grid-template-columns: 1fr 2fr;
    padding: 8px 12px;
  }
}
.blte-parking-driver-details__card--selected {
  background: #e8f2f1;
}
.blte-parking-driver-details__driver-details {
  display: grid;
  grid-template-columns: 1fr;
  grid-column: span 2;
  align-items: center;
  gap: 16px;
  margin-top: 12px;
}
.blte-parking-driver-details__driver-details-desktop {
  margin-left: -70px;
  display: grid;
  grid-template-columns: 1fr;
  grid-column: span 2;
  align-items: center;
  gap: 16px;
  margin-top: 12px;
}
@media screen and (min-width: 768px) {
  .blte-parking-driver-details__driver-details-desktop {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column: unset;
    gap: 12px;
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) {
  .blte-parking-driver-details__driver-details {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column: unset;
    gap: 12px;
    margin-top: 0;
  }
}
.blte-parking-driver-details__driver-name label {
  font-size: 18px;
}
.blte-parking-driver-details__driver-days {
  font-size: 18px;
}
.blte-parking-driver-details__driver-days--error {
  padding-bottom: 16px;
}
.blte-parking-driver-details__driver-total {
  font-weight: 500;
}
.blte-parking-driver-details__item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-left: -95px;
}
.blte-parking-driver-details__numberOfDays {
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 12px 30px;
  background-color: #fff;
  margin: 0 5px;
}
.blte-parking-driver-details__left {
  flex: 1 0 auto;
}
.blte-parking-driver-details__right {
  display: flex;
  align-items: center;
  padding: 0 15px;
}
.blte-parking-driver-details__right span {
  width: 20px;
  margin: 0 8px;
  text-align: center;
}
.blte-parking-driver-details__right button {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background: none;
  color: #363636;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 1px solid #363636;
}
.blte-parking-driver-details__right button::before {
  height: 2px;
  width: 12px;
  background: #363636;
  content: "";
}
.blte-parking-driver-details__right button:last-child::after {
  content: "";
  height: 12px;
  width: 2px;
  background: #363636;
  position: absolute;
}
.blte-parking-driver-details__right button:focus-visible {
  box-shadow: 0 0 0 1px #363636, 0 0 0 3px #363636;
}
.blte-parking-driver-details__right button:disabled {
  box-shadow: none;
  background: none;
  cursor: auto;
}
.blte-parking-driver-details__right button:disabled::before,
.blte-parking-driver-details__right button:disabled::after {
  background: none;
}
.blte-parking-driver-details__title {
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-parking-driver-details__title {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
