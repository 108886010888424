@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-kiosk-header {
  padding: 24px 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
}
.blte-kiosk-header img {
  height: 42px;
}
.blte-kiosk-header button {
  white-space: nowrap;
}
