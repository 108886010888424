@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passes-saved-cards {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  gap: 8px;
}
.blte-passes-saved-cards__title {
  color: #363636;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}
@media screen and (min-width: 768px) {
  .blte-passes-saved-cards__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
}
.blte-passes-saved-cards__cards {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
