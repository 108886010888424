.blte-breadcrumbs__expand-wrapper::after,.blte-breadcrumbs__item::after {
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

}
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-breadcrumbs {
  max-width: 1400px;
  margin: 0 auto;
  width: calc(100% - 48px);
  box-sizing: content-box;
  padding: 8px 0;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 576px) {
  .blte-breadcrumbs {
    width: calc(100% - 128px);
  }
}
@media screen and (min-width: 768px) {
  .blte-breadcrumbs {
    width: calc(100% - 80px);
  }
}
@media screen and (min-width: 1024px) {
  .blte-breadcrumbs {
    width: calc(100% - 128px);
  }
}
.blte-breadcrumbs--hidden {
  display: none;
}
.blte-breadcrumbs__list {
  list-style: none;
  display: flex;
  padding: 0;
}
.blte-breadcrumbs__more-breadcrumbs {
  background-color: transparent;
  padding: 8px 4px;
  border: none;
  cursor: pointer;
  position: relative;
}
.blte-breadcrumbs__more-breadcrumbs:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--border-focus-color, #363636);
  border-radius: 2px;
}
.blte-breadcrumbs__expand-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.blte-breadcrumbs__expand-wrapper::after {
  color: var(--icon-color, #094273);
  padding: 0 2px;
  width: 24px;
  content: ICON_FONT_LOADER_IMAGE(IDdc0f790aa6274da32c0fd98eb2798059);
  font-size: 24px;
}
.blte-breadcrumbs__item {
  display: flex;
  align-items: center;
}
.blte-breadcrumbs__item::after {
  color: var(--icon-color, #094273);
  padding: 0 2px;
  width: 24px;
  content: ICON_FONT_LOADER_IMAGE(IDdc0f790aa6274da32c0fd98eb2798059);
  font-size: 24px;
}
.blte-breadcrumbs__item:last-of-type::before,
.blte-breadcrumbs__item:last-of-type::after {
  display: none;
}
.blte-breadcrumbs__item--active {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: var(--text-color-active, #565656);
}
@media screen and (min-width: 576px) {
  .blte-breadcrumbs__item--active {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-breadcrumbs__item--active span {
  padding: 8px 2px;
}
.blte-breadcrumbs__item--active,
.blte-breadcrumbs__item .blte-link-button {
  padding: 0;
  max-width: 110px;
}
@media screen and (min-width: 768px) {
  .blte-breadcrumbs__item--active,
  .blte-breadcrumbs__item .blte-link-button {
    max-width: 220px;
  }
}
.blte-breadcrumbs__item span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  padding: 0 2px;
}
.blte-breadcrumbs__popover {
  left: 0 !important;
  top: 100% !important;
  width: fit-content;
}
.blte-breadcrumbs__popover-body {
  position: absolute;
  top: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.blte-breadcrumbs__popover-body .blte-breadcrumbs__item {
  padding: 8px 16px;
}
.blte-breadcrumbs__popover-body .blte-breadcrumbs__item::before,
.blte-breadcrumbs__popover-body .blte-breadcrumbs__item::after {
  display: none;
}
.blte-breadcrumbs__popover-body--hidden {
  display: none;
}
@media screen and (min-width: 0) and (max-width: 575px) {
  .blte-breadcrumbs--hidden_mobile {
    display: none;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .blte-breadcrumbs--hidden_tablet {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .blte-breadcrumbs--hidden_tablet_large {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .blte-breadcrumbs--hidden_desktop {
    display: none;
  }
}
@media screen and (min-width: 0) and (max-width: 575px) {
  .blte-breadcrumbs-edit-mode-visible.blte-breadcrumbs--hidden_mobile {
    display: block;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .blte-breadcrumbs-edit-mode-visible.blte-breadcrumbs--hidden_tablet {
    display: block;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .blte-breadcrumbs-edit-mode-visible.blte-breadcrumbs--hidden_tablet_large {
    display: block;
  }
}
@media screen and (min-width: 1024px) {
  .blte-breadcrumbs-edit-mode-visible.blte-breadcrumbs--hidden_desktop {
    display: block;
  }
}
