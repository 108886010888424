@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passengers-card {
  display: flex;
  width: 100%;
}
.blte-passengers-card > div:nth-child(2) {
  margin-left: 16px;
}
.blte-passengers-card__item {
  background-color: #f2f2f4;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  width: calc(50% - 8px);
}
.blte-passengers-card__item--single {
  width: 100%;
}
