@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-widget-input {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0 0 0 1px #e1e1e1;
  background-color: #fff;
  height: auto;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}
.blte-widget-input--position-left {
  border-radius: 8px 0 0 8px;
}
.blte-widget-input--position-left::before {
  border-radius: 8px 0 0 8px;
}
.blte-widget-input--position-right {
  border-radius: 0 8px 8px 0;
  margin-left: 1px;
}
.blte-widget-input--position-right::before {
  border-left: none;
  border-radius: 0 8px 8px 0;
}
.blte-widget-input--position-right.blte-widget-input--has-error {
  margin-left: 2px;
}
.blte-widget-input--position-top {
  border-radius: 8px 8px 0 0;
}
.blte-widget-input--position-top::before {
  border-radius: 8px 8px 0 0;
}
.blte-widget-input--position-bottom {
  border-radius: 0 0 8px 8px;
  margin-top: 1px;
}
.blte-widget-input--position-bottom::before {
  border-radius: 0 0 8px 8px;
}
.blte-widget-input--position-bottom.blte-widget-input--has-error {
  margin-top: 2px;
}
.blte-widget-input__label {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  left: 16px;
  top: 12px;
  position: absolute;
  color: #094273;
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.blte-widget-input__input-container {
  display: flex;
  flex-direction: column;
  padding-right: 8px;
}
.blte-widget-input__input {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  padding: 32px 8px 12px 16px;
  border-radius: 8px;
  height: 70px;
  width: 100%;
  outline: none;
  border: none;
  transition: 0.1s ease-out;
  display: flex;
  flex-wrap: wrap;
  line-height: 26px;
  text-overflow: ellipsis;
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-widget-input__input {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-widget-input__input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #fff inset;
}
.blte-widget-input__input:disabled {
  background-color: transparent;
  color: #e1e1e1 !important;
}
.blte-widget-input__input--button {
  background-color: inherit;
  color: #363636;
}
.blte-widget-input__input--buttonIsPlaceHolder {
  color: #757575 !important;
}
.blte-widget-input__clear {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  outline: none;
  width: 20px;
  height: 20px;
  font-size: 18px;
  color: #8d8d8d;
  padding-right: 8px;
  border-radius: 4px;
}
.blte-widget-input__clear:focus-within,
.blte-widget-input__clear:focus-visible,
.blte-widget-input__clear:focus {
  outline: none;
  box-shadow: 0 0 0 2px #094273;
  z-index: 1;
}
.blte-widget-input__clear:focus-within::before,
.blte-widget-input__clear:focus-visible::before,
.blte-widget-input__clear:focus::before {
  border: none;
}
.blte-widget-input--active,
.blte-widget-input:hover,
.blte-widget-input:focus-within,
.blte-widget-input:focus-visible,
.blte-widget-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #094273;
  z-index: 1;
}
.blte-widget-input--active::before,
.blte-widget-input:hover::before,
.blte-widget-input:focus-within::before,
.blte-widget-input:focus-visible::before,
.blte-widget-input:focus::before {
  border: none;
}
.blte-widget-input--has-error {
  box-shadow: 0 0 0 2px #c00;
  z-index: 1;
}
.blte-widget-input--has-error .blte-widget-input__label,
.blte-widget-input--has-error .blte-widget-input__clear,
.blte-widget-input--has-error .blte-widget-input__input {
  color: #c00;
}
.blte-widget-input--has-error .blte-widget-input__label::placeholder,
.blte-widget-input--has-error .blte-widget-input__clear::placeholder,
.blte-widget-input--has-error .blte-widget-input__input::placeholder {
  color: #a3a3a3;
}
.blte-widget-input__error {
  font-size: 12px;
  font-weight: 450;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  color: #c00;
  padding: 4px 16px;
}
.blte-widget-input--disabled {
  background-color: #fefefe;
  pointer-events: none;
  cursor: not-allowed;
  color: #e1e1e1;
}
.blte-widget-input--disabled .blte-widget-input__input,
.blte-widget-input--disabled .blte-widget-input__error,
.blte-widget-input--disabled .blte-widget-input__clear,
.blte-widget-input--disabled .blte-widget-input__helper-container {
  border-color: #e1e1e1;
  color: #e1e1e1;
}
.blte-widget-input--disabled .blte-widget-input__input::placeholder {
  color: #e1e1e1;
}
