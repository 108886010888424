@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-booking {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f4;
  position: relative;
}
.blte-booking__grid {
  max-width: 1400px;
  margin: 0 auto;
  width: calc(100% - 48px);
  box-sizing: content-box;
  flex: 1;
}
@media screen and (min-width: 576px) {
  .blte-booking__grid {
    width: calc(100% - 128px);
  }
}
@media screen and (min-width: 768px) {
  .blte-booking__grid {
    width: calc(100% - 80px);
  }
}
@media screen and (min-width: 1024px) {
  .blte-booking__grid {
    width: calc(100% - 128px);
  }
}
.blte-booking-columns {
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 1024px) {
  .blte-booking-columns {
    width: 83.3333333333%;
  }
}
.blte-booking-page {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.blte-booking-page--overlay {
  background-color: #363636;
  opacity: 0.5;
  pointer-events: none;
}
