@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-modal {
  position: fixed;
  inset: 0;
  background: rgba(54, 54, 54, 0.5);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  top: var(--top);
}
@media screen and (min-width: 768px) {
  .blte-modal {
    padding: 80px 40px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-modal {
    padding: 80px 64px;
  }
}
.blte-modal__body {
  background: #fff;
}
.blte-modal__content {
  max-height: 90vh;
  overflow: auto;
  padding: 0 2px 2px;
}
.blte-modal--variant-mini {
  padding: 80px 24px;
}
@media screen and (min-width: 576px) {
  .blte-modal--variant-mini {
    padding: 80px 64px;
  }
}
.blte-modal--variant-mini .blte-modal__body {
  padding: 24px;
  border-radius: 20px;
  width: 100%;
}
@media screen and (min-width: 576px) {
  .blte-modal--variant-mini .blte-modal__body {
    max-width: 446px;
  }
}
@media screen and (min-width: 768px) {
  .blte-modal--variant-mini .blte-modal__body {
    max-width: 540px;
    border-radius: 16px;
  }
}
.blte-modal--variant-upsell {
  padding: 80px 24px;
}
@media screen and (min-width: 576px) {
  .blte-modal--variant-upsell {
    padding: 80px 64px;
  }
}
.blte-modal--variant-upsell .blte-modal__body {
  padding: 24px;
  border-radius: 20px;
  width: 100%;
}
@media screen and (min-width: 576px) {
  .blte-modal--variant-upsell .blte-modal__body {
    max-width: 446px;
  }
}
@media screen and (min-width: 768px) {
  .blte-modal--variant-upsell .blte-modal__body {
    max-width: 630px;
    border-radius: 16px;
  }
}
.blte-modal--variant-default .blte-modal__body {
  width: 100%;
  height: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .blte-modal--variant-default .blte-modal__body {
    border-radius: 16px;
    height: auto;
    max-height: 100%;
  }
}
.blte-modal--variant-default .blte-modal__header {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-transform: none;
  height: 56px;
  background: #2e6a6e;
  color: #fff;
  width: 100%;
  padding: 8px 16px 8px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (min-width: 576px) {
  .blte-modal--variant-default .blte-modal__header {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: none;
  }
}
@media screen and (min-width: 576px) {
  .blte-modal--variant-default .blte-modal__header {
    padding: 8px 40px 8px 64px;
  }
}
@media screen and (min-width: 768px) {
  .blte-modal--variant-default .blte-modal__header {
    padding: 8px 32px 8px 40px;
    border-radius: 16px 16px 0 0;
  }
}
.blte-modal--variant-default .blte-modal__header button {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #fff;
  outline: none;
  border: none;
  margin-left: 24px;
}
.blte-modal--variant-default .blte-modal__header button:hover {
  background: rgba(255, 255, 255, 0.15);
}
.blte-modal--variant-default .blte-modal__header button:focus {
  outline-offset: 5px;
  outline: 3px solid #363636;
}
.blte-modal--variant-default .blte-modal__content {
  padding: 32px 24px 40px;
  overflow: auto;
}
@media screen and (min-width: 576px) {
  .blte-modal--variant-default .blte-modal__content {
    padding: 32px 64px;
  }
}
@media screen and (min-width: 768px) {
  .blte-modal--variant-default .blte-modal__content {
    padding: 40px;
  }
}
.blte-modal--variant-default-no-space .blte-modal__body {
  width: 100%;
  height: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .blte-modal--variant-default-no-space .blte-modal__body {
    border-radius: 16px;
    height: auto;
    max-height: 100%;
  }
}
.blte-modal--variant-default-no-space .blte-modal__header {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-transform: none;
  background: #2e6a6e;
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-radius: 0;
}
@media screen and (min-width: 576px) {
  .blte-modal--variant-default-no-space .blte-modal__header {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-modal--variant-default-no-space .blte-modal__header {
    padding: 16px 32px;
    border-radius: 16px 16px 0 0;
  }
}
.blte-modal--variant-default-no-space .blte-modal__header button {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  margin-left: 24px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  color: #fff;
  background: unset;
}
.blte-modal--variant-default-no-space .blte-modal__header button:focus {
  outline-offset: 5px;
  outline: 3px solid #c00;
}
.blte-modal--variant-default-no-space .blte-modal__header button:hover {
  background: rgba(255, 255, 255, 0.15);
}
@media screen and (min-width: 1024px) {
  .blte-modal--variant-default-no-space .blte-modal__header button {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    color: #363636;
    background: #fff;
  }
  .blte-modal--variant-default-no-space .blte-modal__header button:hover {
    background: #fff;
  }
}
.blte-modal--variant-default-no-space .blte-modal__content {
  overflow: auto;
  height: 100%;
}
.blte-modal--variant-no-space .blte-modal__body {
  padding: 0;
  width: 100%;
  max-width: 1100px;
  overflow: hidden;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .blte-modal--variant-no-space .blte-modal__body {
    border-radius: 16px;
  }
}
.blte-modal--variant-no-space .blte-modal__content {
  padding: 0;
  height: 100%;
  overflow: hidden;
}
.blte-modal--variant-no-space-scroll .blte-modal__body {
  padding: 0;
  height: 100%;
  min-width: 70%;
  overflow: auto;
  display: inline-block;
}
@media screen and (min-width: 768px) {
  .blte-modal--variant-no-space-scroll .blte-modal__body {
    border-radius: 16px;
  }
}
.blte-modal--variant-no-space-scroll .blte-modal__content {
  padding: 0;
  height: 100%;
}
