@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-radio {
  display: flex;
  flex-wrap: wrap;
  max-width: 440px;
  padding: 8px 0;
  outline: none;
}
.blte-radio__input {
  position: relative;
  width: 18px;
  height: 18px;
  appearance: none;
  cursor: pointer;
  margin: 3px 0 0;
  border: 1px solid #565656;
  border-radius: 50%;
}
.blte-radio__input:hover::before {
  border-color: #363636;
}
.blte-radio__input::before {
  position: absolute;
  inset: 2px;
  content: "";
  user-select: none;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 50%;
}
.blte-radio__input:focus-visible {
  outline: none;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #000;
}
.blte-radio__input:checked:not(:disabled) {
  border-color: #2e6a6e;
}
.blte-radio__input:checked:not(:disabled)::before {
  background-color: #2e6a6e;
  border: none;
}
.blte-radio__label {
  color: #363636;
  margin-left: 8px;
  flex: 1;
}
.blte-radio__error {
  color: #c00;
  flex-basis: 100%;
  padding-left: 26px;
}
.blte-radio--is-disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.blte-radio--is-disabled .blte-radio__error {
  display: none;
}
.blte-radio--is-disabled .blte-radio__label {
  color: #e1e1e1;
}
.blte-radio--is-disabled .blte-radio__input::before {
  border-color: #e1e1e1;
}
.blte-radio--is-disabled .blte-radio__input:checked::before {
  background-color: #e1e1e1;
}
.blte-radio--is-disabled.blte-radio--has-error .blte-radio__input {
  border-color: #e1e1e1;
}
.blte-radio--is-disabled.blte-radio--has-error .blte-radio__input::before {
  border-color: #e1e1e1;
}
.blte-radio--is-disabled.blte-radio--has-error .blte-radio__input:checked {
  border-color: #e1e1e1;
}
.blte-radio--is-disabled.blte-radio--has-error .blte-radio__input:checked::before {
  background-color: #e1e1e1;
}
.blte-radio--has-error .blte-radio__input {
  border-color: #c00;
}
.blte-radio--has-error .blte-radio__input::before {
  border-color: #c00;
}
.blte-radio--has-error .blte-radio__input:checked {
  border-color: #c00;
}
.blte-radio--has-error .blte-radio__input:checked::before {
  background-color: #c00;
}
