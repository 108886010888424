@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-cancellation-return-summary {
  background-color: #fff;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (min-width: 1024px) {
  .blte-cancellation-return-summary {
    border-radius: 8px;
    width: 50%;
    height: 538px;
  }
}
.blte-cancellation-return-summary__title {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
  padding-bottom: 20px;
}
@media screen and (min-width: 576px) {
  .blte-cancellation-return-summary__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-cancellation-return-summary__title {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
  }
}
