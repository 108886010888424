@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-footer {
  width: 100%;
  background-color: var(--background-color, #094273);
}
.blte-footer__content {
  max-width: 1400px;
  margin: 0 auto;
  width: calc(100% - 48px);
  box-sizing: content-box;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  height: auto;
}
@media screen and (min-width: 576px) {
  .blte-footer__content {
    width: calc(100% - 128px);
  }
}
@media screen and (min-width: 768px) {
  .blte-footer__content {
    width: calc(100% - 80px);
  }
}
@media screen and (min-width: 1024px) {
  .blte-footer__content {
    width: calc(100% - 128px);
  }
}
@media screen and (min-width: 1024px) {
  .blte-footer__content {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
.blte-footer__first-row {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .blte-footer__first-row {
    flex-direction: row;
    justify-content: space-between;
  }
}
.blte-footer__first-row-left {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}
@media screen and (min-width: 576px) {
  .blte-footer__first-row-left {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen and (min-width: 768px) {
  .blte-footer__first-row-left {
    border: none;
    padding: 0;
  }
}
.blte-footer__first-row-left-column {
  padding: 0 0 40px;
}
.blte-footer__first-row-left-column:last-child {
  padding: 0;
}
@media screen and (min-width: 576px) {
  .blte-footer__first-row-left-column {
    padding: 0;
  }
}
@media screen and (min-width: 768px) {
  .blte-footer__first-row-left-column {
    padding: 0 24px 0 0;
    flex-direction: row;
    width: fit-content;
  }
  .blte-footer__first-row-left-column:last-child {
    padding: 0 24px 0 0;
  }
}
.blte-footer__first-row-right {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .blte-footer__first-row-right {
    padding: 0;
  }
}
.blte-footer__social-media {
  display: flex;
  flex-direction: row;
  padding-bottom: 40px;
}
.blte-footer__social-media-item {
  padding-right: 28px;
}
.blte-footer__social-media-item:last-child {
  padding-right: 0;
}
.blte-footer__application {
  display: flex;
  flex-direction: column;
}
.blte-footer__application-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-transform: none;
  color: var(--text-color, #fff);
}
@media screen and (min-width: 576px) {
  .blte-footer__application-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: none;
  }
}
.blte-footer__application-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: var(--text-color, #fff);
}
@media screen and (min-width: 576px) {
  .blte-footer__application-description {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-footer__application-description a {
  color: var(--text-color, #fff);
}
.blte-footer__application-description a:focus-visible {
  outline: 1px solid var(--border-focus-color, #fff);
}
.blte-footer__application-buttons {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
}
.blte-footer__application-buttons > a:first-child {
  margin-right: 6px;
}
.blte-footer__first-row + .blte-divider + .blte-footer__second-row {
  padding-top: 40px;
}
@media screen and (min-width: 768px) {
  .blte-footer__first-row + .blte-divider + .blte-footer__second-row {
    padding-top: 64px;
  }
}
.blte-footer__second-row {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .blte-footer__second-row {
    padding-left: 0;
    padding-right: 0;
    flex-direction: row;
    align-items: center;
  }
}
.blte-footer__copyright {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: var(--text-color, #fff);
  padding-right: 8px;
}
.blte-footer .blte-divider--alignment-horizontal {
  margin: 0;
  width: calc(100vw - 15px);
  margin-left: -24px;
}
@media screen and (min-width: 576px) {
  .blte-footer .blte-divider--alignment-horizontal {
    margin-left: -64px;
  }
}
@media screen and (min-width: 768px) {
  .blte-footer .blte-divider--alignment-horizontal {
    display: none;
  }
}
