@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-date-selection {
  padding-top: 16px;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .blte-date-selection {
    padding-top: 0;
    padding-left: 16px;
    width: 50%;
  }
}
