@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rti-extras__item-card-image {
  position: relative;
  width: 100px;
}
@media screen and (min-width: 768px) {
  .blte-rti-extras__item-card-image {
    width: 160px;
  }
}
.blte-rti-extras__item-card-image .blte-image {
  display: flex;
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 116px;
  border-radius: 8px 0 0 8px;
}
@media screen and (min-width: 768px) {
  .blte-rti-extras__item-card-image .blte-image {
    min-height: 142px;
  }
}
.blte-rti-extras__item-card {
  position: relative;
  display: flex;
  align-items: stretch;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #fff;
}
.blte-rti-extras__item-card--completed {
  background-color: #e8f2f1;
  border: 1px solid #094273;
}
.blte-rti-extras__item-card--collapsed {
  background-color: #e8f2f1;
  border: unset;
}
.blte-rti-extras__item-card--collapsed.blte-rti-extras__item-card--completed {
  background-color: #fff;
  border: 1px solid #e1e1e1;
}
.blte-rti-extras__item-card--collapsed .blte-rti-extras__item-info {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-rti-extras__item-card--collapsed .blte-rti-extras__item-info {
    padding: 8px 12px;
  }
}
.blte-rti-extras__item-card--collapsed .blte-rti-extras__item-info-title-text {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-rti-extras__item-info {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  align-self: stretch;
  width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 12px;
  gap: 0;
}
@media screen and (min-width: 768px) {
  .blte-rti-extras__item-info {
    width: calc(100% - 160px);
  }
}
.blte-rti-extras__item-info--expanded {
  padding: 12px 16px;
  gap: 12px;
}
.blte-rti-extras__item-info-title {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.blte-rti-extras__item-info-title-text {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-rti-extras__item-info-title-text {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-rti-extras__item-info-title-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.blte-rti-extras__item-info-btn-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.blte-rti-extras__item-offer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 8px;
  padding-top: 8px;
}
@media screen and (min-width: 768px) {
  .blte-rti-extras__item-offer {
    padding-top: 0;
  }
  .blte-rti-extras__item-offer--collapsed {
    padding-top: 8px;
  }
}
.blte-rti-extras__item-offer-price {
  display: flex;
  align-items: baseline;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-rti-extras__item-offer-price-amount {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  padding-left: 6px;
}
@media screen and (min-width: 576px) {
  .blte-rti-extras__item-offer-price-amount {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-rti-extras__item-offer-disclaimer-copy {
  display: flex;
  align-items: baseline;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #c00;
}
.blte-rti-extras__completed-icon {
  position: absolute;
  top: -10px;
  right: -9px;
  font-size: 34px;
  line-height: 34px;
  color: #137e00;
}
.blte-rti-extras__items-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0;
  gap: 8px;
  width: 100%;
}
.blte-rti-extras__items-list.no-gap {
  gap: 0;
}
.blte-rti-extras__extras-items-list {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.blte-rti-extras__item-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.blte-rti-extras__item-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 450;
  color: #363636;
}
.blte-rti-extras__item-details-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  width: 100%;
}
.blte-rti-extras__item-details-row--wrapRow {
  flex-flow: row wrap;
}
.blte-rti-extras__item-details {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  column-gap: 16px;
}
.blte-rti-extras__item-details-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #363636;
  white-space: nowrap;
}
