@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  color: #363636;
  height: auto;
  box-sizing: border-box;
  outline: none;
  width: 100%;
}
.blte-logo:focus-visible {
  border-radius: 2px;
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #363636;
}
