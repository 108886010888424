@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-wallet-edit-pass-modal-desktop__form {
  margin-top: 16px;
}
.blte-wallet-edit-pass-modal-desktop__row {
  display: flex;
  margin-top: 24px;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}
