@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-nativeSelect {
  position: absolute;
  inset: 0;
  width: 100%;
  opacity: 0;
}
.blte-nativeSelect__fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
.blte-nativeSelect__legend {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
