@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-segment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background-color: #e8f2f1;
  border-radius: 4px;
  margin-bottom: 16px;
}
.blte-trip-segment__direction {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  padding: 12px 16px;
  align-self: stretch;
}
@media screen and (min-width: 576px) {
  .blte-trip-segment__direction {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-trip-segment__direction + .blte-trip-line {
  border-top: 1px solid #e1e1e1;
}
.blte-trip-segment__from {
  margin-right: 4px;
}
.blte-trip-segment__to {
  margin-left: 4px;
}
.blte-trip-segment__title {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}
