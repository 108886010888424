@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-event-details {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}
.blte-event-details__title-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.blte-event-details__title-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #094273;
}
.blte-event-details__title {
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
}
.blte-event-details__body {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-bottom: 16px;
}
.blte-event-details__event-pills {
  display: flex;
  flex-wrap: wrap;
}
.blte-event-details__info-wrapper {
  display: flex;
  column-gap: 8px;
}
.blte-event-details__info-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 18px;
  line-height: 18px;
}
.blte-event-details__info-details {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
.blte-event-details__info-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}
.blte-event-details__info-text {
  color: black;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}
.blte-event-details__info-text a {
  color: black;
}
.blte-event-details__station-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 16px 24px;
  border: 1px solid #a3a3a3;
  border-radius: 8px;
}
.blte-event-details__station-list li {
  margin-bottom: 8px;
}
.blte-event-details__station-list li:last-of-type {
  margin-bottom: 0 !important;
}
.blte-event-details__station-text {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
}
.blte-event-details__station-bullet-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
}
.blte-event-details__station-bullet-text {
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
}
.blte-event-details__link-button {
  min-width: fit-content;
  white-space: nowrap;
  padding: 0;
}
.blte-event-details__icons-wrapper {
  display: flex;
  gap: 16px;
}
.blte-event-details__social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 18px;
}
