@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rewards-payment {
  padding: 6px 12px;
  background: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin-bottom: 8px;
}
.blte-rewards-payment:hover {
  cursor: pointer;
}
.blte-rewards-payment--expanded {
  background: rgba(242, 242, 244, 0.4);
  border: 1px solid #094273;
}
.blte-rewards-payment__content {
  display: flex;
  gap: 16px;
}
.blte-rewards-payment__content--disabled {
  opacity: 0.5;
}
.blte-rewards-payment__icon {
  width: 38px;
  max-width: 38px;
  min-width: 38px;
}
.blte-rewards-payment__main-panel {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.blte-rewards-payment__upper-panel {
  display: flex;
  gap: 8px;
}
.blte-rewards-payment__labels-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.blte-rewards-payment__title {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-rewards-payment__title {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-rewards-payment__available-points-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
}
.blte-rewards-payment__checkbox-wrapper {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  align-items: end;
  height: 100%;
  margin-top: 3px;
}
.blte-rewards-payment__checkbox-wrapper > div > .blte-checkbox {
  padding: 0;
  justify-content: end;
}
.blte-rewards-payment__remove-button {
  font-size: 20px;
  line-height: 20px;
  width: 20px;
  height: 20px;
}
.blte-rewards-payment__remove-button:hover {
  cursor: pointer;
}
.blte-rewards-payment__applied-points-text {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  white-space: nowrap;
  color: #137e00;
}
.blte-rewards-payment__bottom-panel {
  display: flex;
  gap: 8px;
  margin-bottom: 6px;
  margin-left: -54px;
  width: calc(100% + 54px);
}
@media screen and (min-width: 768px) {
  .blte-rewards-payment__bottom-panel {
    width: 100%;
    margin-left: 0;
  }
}
.blte-rewards-payment__bottom-panel > .blte-input__fieldset {
  width: 100%;
}
.blte-rewards-payment__input {
  width: 100%;
}
.blte-rewards-payment__input > input {
  height: 42px;
}
.blte-rewards-payment__button {
  min-width: 100px;
  max-height: 42px;
  padding: 4px 16px;
  white-space: nowrap;
}
