@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passes-payment-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px 0;
  gap: 16px;
  border-radius: 8px;
}
.blte-passes-payment-footer__tos {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.blte-passes-payment-footer__tos-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #363636;
}
