@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-phone-number {
  width: 100%;
}
.blte-phone-number__input-wrapper {
  display: flex;
}
.blte-phone-number__country-code {
  width: 100px;
  min-width: 100px;
  margin-right: 0;
}
.blte-phone-number__helper {
  color: #565656;
}
.blte-phone-number__error {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  color: #c00;
}
.blte-phone-number--is-disabled .blte-phone-number__helper,
.blte-phone-number--is-disabled .blte-phone-number__error {
  color: #e1e1e1;
}
