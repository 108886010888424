.blte-global-header__menu-button::after,.blte-global-header__menu.isMenuVisible .blte-global-header__menu-button::after,.blte-global-header__cart-button::before {
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

}
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-global-header {
  padding-bottom: 16px;
  padding-top: 16px;
  display: block;
  position: relative;
  height: 100%;
  background-color: var(--header-container-background-color, #fff);
}
@media screen and (min-width: 768px) {
  .blte-global-header {
    padding-top: 8px;
  }
}
.blte-global-header__skip-link {
  position: absolute;
  top: 5px;
  left: 5px;
  opacity: 0;
  cursor: default;
  pointer-events: none;
}
.blte-global-header__skip-link:focus-visible {
  opacity: 1;
  pointer-events: all;
}
.blte-global-header__container {
  max-width: 1400px;
  margin: 0 auto;
  width: calc(100% - 48px);
  box-sizing: content-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media screen and (min-width: 576px) {
  .blte-global-header__container {
    width: calc(100% - 128px);
  }
}
@media screen and (min-width: 768px) {
  .blte-global-header__container {
    width: calc(100% - 80px);
  }
}
@media screen and (min-width: 1024px) {
  .blte-global-header__container {
    width: calc(100% - 128px);
  }
}
.blte-global-header .blte-global-header__container--empty_top_items {
  align-items: center;
}
.blte-global-header .blte-global-header__container--empty_top_items .blte-logo {
  padding-top: 0;
  padding-bottom: 0;
}
.blte-global-header .blte-logo {
  width: 142px;
  flex-shrink: 0;
  padding-top: 6px;
}
.blte-global-header .blte-logo img {
  height: 34px;
}
@media screen and (min-width: 768px) {
  .blte-global-header .blte-logo {
    padding-top: 40px;
    padding-bottom: 8px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-global-header .blte-logo {
    width: 172px;
    padding-top: 32px;
    padding-bottom: 16px;
  }
  .blte-global-header .blte-logo img {
    height: 42px;
  }
}
.blte-global-header__menu-button {
  font-size: 0;
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  color: #363636;
  margin-left: 16px;
}
.blte-global-header__menu-button::after {
  content: ICON_FONT_LOADER_IMAGE(ID842ebaa5424711b20f2ce6224e33b682);
}
.blte-global-header__menu-button::after {
  font-size: 24px;
}
@media screen and (min-width: 768px) {
  .blte-global-header__menu-button {
    display: none;
  }
}
.blte-global-header__navigation {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--header-container-background-color, #fff);
  height: calc(100vh - 50px);
  overflow-y: auto;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  z-index: 200;
}
.blte-global-header__navigation > nav {
  border-top: 1px solid var(--header-container-border-color, #f2f2f4);
}
@media screen and (min-width: 768px) {
  .blte-global-header__navigation > nav {
    border-top: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-global-header__navigation {
    display: flex;
    border-top: 0;
    position: static;
    background: none;
    align-items: flex-end;
    padding: 0;
    height: auto;
    overflow: visible;
  }
}
.blte-global-header__menu.isMenuVisible .blte-global-header__menu-button::after {
  content: ICON_FONT_LOADER_IMAGE(ID31c0986a29ca6037de27c1872a302aa2);
}
.blte-global-header__menu.isMenuVisible .blte-global-header__navigation {
  display: flex;
}
.blte-global-header__buy-tickets {
  flex-shrink: 0;
}
.blte-global-header__buy-tickets.blte-btn--size-medium {
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
}
@media screen and (min-width: 1024px) {
  .blte-global-header__buy-tickets.blte-btn--size-medium {
    padding: 8px 16px;
    font-size: 18px;
    line-height: 26px;
  }
}
.blte-global-header__cart-button {
  width: 32px;
  height: 32px;
  background: #094273;
  border-radius: 50%;
  position: relative;
  overflow: unset;
  display: flex;
  text-decoration: none;
  outline: none;
}
@media screen and (min-width: 768px) {
  .blte-global-header__cart-button {
    margin-left: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-global-header__cart-button {
    width: 42px;
    height: 42px;
  }
}
.blte-global-header__cart-button::before {
  position: absolute;
  content: ICON_FONT_LOADER_IMAGE(IDd14f4c056d9b71135762cd8d643f3fa9);
  font-size: 22px;
  color: #fff;
  line-height: 32px;
  top: 0;
  left: 4px;
}
@media screen and (min-width: 1024px) {
  .blte-global-header__cart-button::before {
    line-height: 42px;
    top: 0;
    left: 9px;
  }
}
.blte-global-header__cart-button:focus-visible {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #363636;
  outline: none;
}
.blte-global-header__cart-button span {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-transform: none;
  position: absolute;
  top: -4px;
  right: -8px;
  background: #ffdb00;
  color: #000;
  content: "22";
  border-radius: 50%;
  padding: 0 4px;
  height: 16px;
}
@media screen and (min-width: 1024px) {
  .blte-global-header__cart-button span {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-transform: none;
    line-height: 20px;
    height: 20px;
    padding: 0 6px;
  }
}
.blte-global-header__buttons {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .blte-global-header__buttons .blte-global-header__cart-button {
    display: none;
  }
}
.blte-global-header__custom-icon {
  width: 14px;
  height: 14px;
}
