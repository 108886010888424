@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rti-seat-passengers-carousel {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}
.blte-rti-seat-passengers-carousel__pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 8px 0;
}
.blte-rti-seat-passengers-carousel__indicator {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  width: 7px;
  height: 7px;
  background-color: #8d8d8d;
  border-radius: 50%;
  margin-right: 6px;
  opacity: 0.3;
  outline: none;
}
.blte-rti-seat-passengers-carousel__indicator:last-child {
  margin-right: 0;
}
.blte-rti-seat-passengers-carousel__indicator--active {
  opacity: 1;
}
.blte-rti-seat-passengers-carousel-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
}
.blte-rti-seat-passengers-carousel-item__passenger-name {
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  display: flex;
  flex: 1;
  margin-right: 24px;
}
@media screen and (min-width: 576px) {
  .blte-rti-seat-passengers-carousel-item__passenger-name {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-rti-seat-passengers-carousel-item__seat-info {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.blte-rti-seat-passengers-carousel-item__location {
  display: flex;
  flex-direction: row;
  width: fit-content;
}
.blte-rti-seat-passengers-carousel-item__description {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  text-align: end;
}
.blte-rti-seat-passengers-carousel-item .blte-trip-seat {
  background-color: #f2f2f4;
}
