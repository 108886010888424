@charset "UTF-8";
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-header-search-bar {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  border: 1px solid var(--header-search-bar-border-color, #e1e1e1);
  border-radius: 40px;
  padding: 8px 16px;
  color: var(--header-search-bar-text-color, #363636);
  display: none;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .blte-header-search-bar {
    display: flex;
  }
}
.blte-header-search-bar--isPanelVisible {
  border-color: transparent;
}
.blte-header-search-bar__o-d {
  display: none;
}
@media screen and (min-width: 1024px) {
  .blte-header-search-bar__o-d {
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.blte-header-search-bar__passengers {
  display: none;
  align-items: center;
  flex-shrink: 0;
}
@media screen and (min-width: 768px) {
  .blte-header-search-bar__passengers {
    display: flex;
  }
}
.blte-header-search-bar__passengers span {
  margin-right: 4px;
}
.blte-header-search-bar__date-selection {
  flex-shrink: 0;
}
.blte-header-search-bar__date-selection + .blte-header-search-bar__passengers::before {
  content: "\25cf";
  color: var(--header-search-bullet-border-color, #2e6a6e);
  padding: 0 8px;
}
@media screen and (min-width: 1024px) {
  .blte-header-search-bar__o-d + .blte-header-search-bar__date-selection::before,
  .blte-header-search-bar__o-d + .blte-header-search-bar__passengers::before {
    content: "\25cf";
    color: var(--header-search-bullet-border-color, #2e6a6e);
    padding: 0 8px;
  }
}
.blte-header-search-bar__button {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  text-decoration-line: underline;
  color: var(--header-search-bar-link-color, #094273);
  outline: none;
}
.blte-header-search-bar__button:focus-visible {
  border-radius: 2px;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--header-search-bar-border-focus-color, #363636);
}
.blte-header-search-bar__divider {
  width: 1px;
  height: 24px;
  background: var(--header-search-divider-border-color, #a3a3a3);
  margin: 0 8px;
  display: none;
}
@media screen and (min-width: 1024px) {
  .blte-header-search-bar__divider {
    margin: 0 16px;
  }
}
.blte-header-search-bar__o-d .blte-header-search-bar__divider,
.blte-header-search-bar__date-selection + .blte-header-search-bar__passengers + .blte-header-search-bar__divider {
  display: block;
}
@media screen and (min-width: 768px) {
  .blte-header-search-bar__passengers + .blte-header-search-bar__divider {
    display: block;
  }
}
.blte-header-search-bar__trip-icon {
  color: var(--header-search-icon-color, #094273);
  margin: 0 8px;
}
