@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-fare-compare-table {
  position: relative;
  z-index: 2;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  background: transparent;
}
.blte-fare-compare-table__table-container {
  border: 0;
}
.blte-fare-compare-table__top-right-background {
  position: absolute;
  z-index: -1;
  width: 20px;
  height: 20px;
  top: 0;
  right: 0;
  background-color: #565656;
}
.blte-fare-compare-table__cell-content-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 9px;
  padding: 15px;
  width: 100%;
  height: 100%;
}
.blte-fare-compare-table__cell-content-wrapper picture {
  display: block;
  height: 100%;
}
.blte-fare-compare-table__pass-eligibility-wrapper {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
}
.blte-fare-compare-table__image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.blte-fare-compare-table__button {
  margin-top: auto;
}
.blte-fare-compare-table__cell-content {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  width: 100%;
  height: 100%;
}
.blte-fare-compare-table__fare-content {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  width: 100%;
  height: 100%;
  align-items: center;
}
.blte-fare-compare-table__fare-price-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  width: 100%;
  flex-wrap: wrap;
}
.blte-fare-compare-table__fare-price {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
  text-transform: none;
  color: #094273;
}
@media screen and (min-width: 576px) {
  .blte-fare-compare-table__fare-price {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-fare-compare-table__fare-price {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-fare-compare-table__fare-price-strikethrough {
  font-size: 20px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
  text-decoration: line-through;
}
.blte-fare-compare-table__fare-label {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
  white-space: nowrap;
}
.blte-fare-compare-table__fare-price-unavailable {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-fare-compare-table__fare-price-unavailable {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-fare-compare-table__regular-content {
  display: flex;
  column-gap: 8px;
  margin-top: auto;
  margin-bottom: auto;
}
.blte-fare-compare-table__regular-content--isItemUnavailable {
  color: #e1e1e1 !important;
}
.blte-fare-compare-table__regular-content--isItemUnavailable ::before {
  color: #e1e1e1 !important;
}
.blte-fare-compare-table__cell-tooltip-wrapper {
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.blte-fare-compare-table__tooltip {
  border: 1px solid #565656;
  border-radius: 100%;
}
.blte-fare-compare-table__tooltip--isItemUnavailable {
  border: 1px solid #e1e1e1;
}
.blte-fare-compare-table__tooltip .blte-tooltip__content {
  width: 15px;
  height: 15px;
}
.blte-fare-compare-table__collapsable-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}
.blte-fare-compare-table__collapsable-wrapper :hover {
  cursor: pointer;
}
.blte-fare-compare-table__collapsable-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #094273;
}
.blte-fare-compare-table__collapsable-icon {
  display: flex;
  align-items: center;
  font-size: 28px;
  width: 28px;
  height: 28px;
  color: #094273;
}
