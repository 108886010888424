@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-credit-pass {
  display: flex;
  padding: 12px;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  flex-direction: column;
  color: #363636;
  position: relative;
  width: 100%;
  background-color: #ffdb00;
  background-image: url(assets/images/svg/logo/splash.svg);
  background-repeat: no-repeat;
  justify-content: space-between;
  max-width: 374px;
  height: 100% !important;
}
@media screen and (min-width: 576px) {
  .blte-credit-pass {
    min-height: 195px;
  }
}
.blte-credit-pass__header {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  text-transform: none;
  z-index: 0;
}
@media screen and (min-width: 576px) {
  .blte-credit-pass__header {
    font-size: 22px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-credit-pass__footer {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  justify-content: flex-end;
  z-index: 0;
}
@media screen and (min-width: 576px) {
  .blte-credit-pass__footer {
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-credit-pass__footer {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
  }
}
