@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-tickets-form-container__required-field-label {
  margin: 0 0 15px;
}
.blte-tickets-form {
  display: flex;
  width: 100%;
}
.blte-tickets-form > form {
  width: 100%;
}
.blte-tickets-form__required-field-label {
  margin: 0 0 15px;
}
.blte-tickets-form__first-row {
  display: flex;
  padding: 16px 0;
  flex-direction: column;
}
@media screen and (min-width: 1024px) {
  .blte-tickets-form__first-row {
    flex-direction: row;
  }
}
.blte-tickets-form__second-row {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.blte-tickets-form.blte-tickets-form--is-mobile .blte-tickets-form__first-row {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
.blte-tickets-form__separator {
  padding: 0 8px;
}
.blte-tickets-form__content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 156px);
  padding: 0 24px;
  overflow: auto;
}
.blte-tickets-form__content .blte-date-selection {
  padding-left: 0;
}
.blte-tickets-form__promo {
  margin: 0 8px 0 0;
  column-gap: 8px;
}
.blte-tickets-form__promo--mobile {
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 16px 0 0;
}
.blte-tickets-form__actions {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  padding: 16px 24px;
  background: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.15);
}
.blte-tickets-form__actions--edit {
  display: flex;
}
.blte-tickets-form__actions--edit button {
  margin: 0 4px;
}
.blte-tickets-form__selectFromDropDown {
  color: #c00;
  margin-bottom: 0;
}
.blte-tickets-form__button {
  white-space: nowrap;
}
.blte-tickets-form__banner-wrapper-mobile {
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-left: -24px;
  margin-bottom: 10px;
  box-shadow: 0 -35px 60px 1px rgba(0, 0, 0, 0.75);
}
.blte-tickets-form__banner-wrapper-desktop {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-right: 10px;
}
@media (max-width: 1024px) {
  .blte-tickets-form__banner-wrapper-desktop {
    width: 50%;
  }
}
@media (max-width: 794px) {
  .blte-tickets-form__banner-wrapper-desktop {
    max-width: 120px;
  }
}
.blte-tickets-form__second-column-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: flex-start;
  width: 100%;
}
