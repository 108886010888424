@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-layout-with-sidebar {
  background: #f2f2f4;
  border-top: 1px solid #e1e1e1;
}
@media screen and (min-width: 768px) {
  .blte-account-layout-with-sidebar {
    background: linear-gradient(90deg, white 0%, white 30%, #f2f2f4 31%, #f2f2f4 100%);
  }
}
.blte-account-layout-with-sidebar__body {
  max-width: 1400px;
  margin: 0 auto;
  box-sizing: content-box;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-account-layout-with-sidebar__body {
    width: calc(100% - 80px);
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1024px) {
  .blte-account-layout-with-sidebar__body {
    width: calc(100% - 128px);
  }
}
.blte-account-layout-with-sidebar__sidebar {
  overflow: hidden;
  display: flex;
  flex: 0 0 29%;
}
.blte-account-layout-with-sidebar__sidebar > div {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  width: calc(100% - 4px);
  background: #fff;
}
@media screen and (min-width: 768px) {
  .blte-account-layout-with-sidebar__sidebar > div {
    padding: 54px 40px 40px 0;
  }
}
@media screen and (min-width: 1024px) {
  .blte-account-layout-with-sidebar__sidebar > div {
    padding: 60px 64px 40px 0;
  }
}
.blte-account-layout-with-sidebar__content {
  padding: 0 24px 42px;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 576px) {
  .blte-account-layout-with-sidebar__content {
    padding: 0 64px 40px;
  }
}
@media screen and (min-width: 768px) {
  .blte-account-layout-with-sidebar__content {
    background: #f2f2f4;
    display: flex;
    flex-direction: column;
    width: 71%;
    padding: 48px 0 48px 40px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-account-layout-with-sidebar__content {
    padding: 48px 0 48px 64px;
    overflow: hidden;
  }
}
.blte-account-layout-with-sidebar__content .blte-content-header {
  padding: 42px 0 24px;
  margin: 0;
}
@media screen and (min-width: 576px) {
  .blte-account-layout-with-sidebar__content .blte-content-header {
    padding: 40px 0 24px;
  }
}
.blte-account-layout-with-sidebar__content--noHorizontalPaddings {
  padding: 0 0 42px;
}
@media screen and (min-width: 576px) {
  .blte-account-layout-with-sidebar__content--noHorizontalPaddings {
    padding: 0 0 40px;
  }
}
.blte-account-layout-with-sidebar__content--noHorizontalPaddings .blte-content-header {
  padding: 42px 24px 24px;
  margin: 0;
}
@media screen and (min-width: 576px) {
  .blte-account-layout-with-sidebar__content--noHorizontalPaddings .blte-content-header {
    padding: 40px 64px 24px;
  }
}
.blte-sidebar-sectioncontainer__wrapper .blte-account-layout-with-sidebar {
  border-top: none;
}
.hasHiddenSidebar .blte-sidebar-sectioncontainer__wrapper .blte-account-layout-with-sidebar {
  background: #f2f2f4;
}
.hasHiddenSidebar .blte-sidebar-sectioncontainer__wrapper .blte-account-layout-with-sidebar .blte-account-layout-with-sidebar__sidebar {
  display: none;
}
@media screen and (min-width: 768px) {
  .hasHiddenSidebar .blte-sidebar-sectioncontainer__wrapper .blte-account-layout-with-sidebar .blte-account-layout-with-sidebar__content {
    padding: 48px 40px;
  }
}
@media screen and (min-width: 1024px) {
  .hasHiddenSidebar .blte-sidebar-sectioncontainer__wrapper .blte-account-layout-with-sidebar .blte-account-layout-with-sidebar__content {
    padding: 48px 64px;
  }
}
