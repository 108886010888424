@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-upsell-content-desktop {
  display: flex;
  flex-direction: column;
}
.blte-upsell-content-desktop__title {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  letter-spacing: 0.04em;
  padding-bottom: 16px;
}
.blte-upsell-content-desktop__buttons {
  display: flex;
  flex-flow: row;
}
.blte-upsell-content-desktop__buttons > button {
  width: 50%;
  min-width: 220px;
}
.blte-upsell-content-desktop__buttons > button:first-child {
  background-color: #fff;
  border: 2px solid;
}
.blte-upsell-content-desktop__buttons > button:last-child {
  margin-left: 15px;
}
