@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passes-login-prompt {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  background: #fff;
  padding: 16px 24px;
  align-items: flex-start;
}
@media screen and (min-width: 768px) {
  .blte-passes-login-prompt {
    margin-top: 0;
    background: unset;
    padding: 0;
    align-items: center;
  }
}
.blte-passes-login-prompt__title {
  font-weight: 700;
  font-size: 22px;
  line-height: 34px;
  color: #363636;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: unset;
}
@media screen and (min-width: 768px) {
  .blte-passes-login-prompt__title {
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
  }
}
.blte-passes-login-prompt__text {
  color: #565656;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: unset;
}
@media screen and (min-width: 768px) {
  .blte-passes-login-prompt__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
  }
}
