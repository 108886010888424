@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trips-list {
  padding-bottom: 80px;
  padding-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .blte-trips-list {
    padding-bottom: 80px;
  }
}
.blte-trips-list__header {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
  text-transform: none;
  letter-spacing: 0;
  margin-bottom: 24px;
}
@media screen and (min-width: 576px) {
  .blte-trips-list__header {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-trips-list__header {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-trips-list__paging {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}
.blte-trips-list__paging-text {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 8px;
}
.blte-trips-list__list {
  margin-bottom: 24px;
}
.blte-trips-list__placeholders {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .blte-trips-list__placeholders {
    margin-bottom: 80px;
  }
}
.blte-trips-list__placeholders > div:first-child {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 30px;
  border-radius: 2px;
  margin-bottom: 24px;
}
.blte-trips-list__placeholders > div:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
@media screen and (min-width: 576px) {
  .blte-trips-list__placeholders > div:first-child {
    width: 240px;
    height: 38px;
  }
}
.blte-trips-list__placeholders > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  width: 100%;
  padding: 16px 16px 24px;
  border-radius: 8px 8px 0 0;
}
@media screen and (min-width: 576px) {
  .blte-trips-list__placeholders > div:nth-child(2) {
    padding: 24px;
  }
}
.blte-trips-list__placeholders > div:nth-child(2) > div:first-child {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  width: 146px;
  height: 28px;
  border-radius: 2px;
  margin-bottom: 16px;
}
.blte-trips-list__placeholders > div:nth-child(2) > div:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
@media screen and (min-width: 576px) {
  .blte-trips-list__placeholders > div:nth-child(2) > div:first-child {
    width: 302px;
    height: 28px;
  }
}
@media screen and (min-width: 768px) {
  .blte-trips-list__placeholders > div:nth-child(2) > div:first-child {
    margin-bottom: 24px;
  }
}
.blte-trips-list__placeholders > div:nth-child(2) > div:nth-child(2) {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 16px;
  border-radius: 2px;
  margin-bottom: 12px;
}
.blte-trips-list__placeholders > div:nth-child(2) > div:nth-child(2)::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-trips-list__placeholders > div:nth-child(2) > div:nth-child(3) {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 23px;
  border-radius: 2px;
}
.blte-trips-list__placeholders > div:nth-child(2) > div:nth-child(3)::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
@media screen and (min-width: 768px) {
  .blte-trips-list__placeholders > div:nth-child(2) > div:nth-child(3) {
    width: 515px;
  }
}
.blte-trips-list__placeholders > div:nth-child(4) {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  background: #fff;
  width: 100%;
  gap: 16px;
  border-radius: 0 0 8px 8px;
}
@media screen and (min-width: 576px) {
  .blte-trips-list__placeholders > div:nth-child(4) {
    padding: 24px;
  }
}
.blte-trips-list__placeholders > div:nth-child(4) > div:first-child,
.blte-trips-list__placeholders > div:nth-child(4) > div:nth-child(2) {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 16px;
  border-radius: 2px;
}
.blte-trips-list__placeholders > div:nth-child(4) > div:first-child::after,
.blte-trips-list__placeholders > div:nth-child(4) > div:nth-child(2)::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
