@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-coach {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  padding: 2px 6px;
  border-radius: 4px;
  margin-right: 8px;
}
.blte-trip-coach--type-smart {
  color: #363636;
  background-color: #ffdb00;
}
.blte-trip-coach--type-premium {
  color: #fff;
  background-color: #363636;
}
