@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-edit-details-form__row {
  display: flex;
  flex-direction: column;
  padding: 0 2px;
}
@media screen and (min-width: 768px) {
  .blte-edit-details-form__row {
    flex-direction: row;
  }
}
.blte-edit-details-form__column {
  width: 50%;
}
@media screen and (min-width: 768px) {
  .blte-edit-details-form__column:nth-child(2) {
    margin-left: 16px;
  }
}
.blte-edit-details-form__field {
  width: 100%;
  padding: 0;
  margin-bottom: 16px;
}
.blte-edit-details-form__field--no-bottom {
  margin-bottom: 0;
}
.blte-edit-details-form__field .blte-phone-number__country-code {
  margin-right: 16px;
}
.blte-edit-details-form__field.blte-checkbox {
  width: auto;
  padding-right: 8px;
}
.blte-edit-details-form__required-field-label {
  font-size: 16px;
  margin-top: -10px;
  margin-bottom: 16px;
}
