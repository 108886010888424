@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-parking-details {
  display: grid;
  column-gap: 16px;
  row-gap: 8px;
  grid-template-columns: auto;
}
@media screen and (min-width: 768px) {
  .blte-parking-details {
    grid-template-columns: auto 1fr;
  }
}
.blte-parking-details__parkingTitle {
  font-size: 22px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 8px;
}
.blte-parking-details__title {
  font-weight: 500;
}
.blte-parking-details__dates {
  display: flex;
  align-items: center;
}
.blte-parking-details__datesParking {
  display: flex;
  align-items: center;
  color: #8d8d8d;
}
.blte-parking-details__icon {
  margin: 0 8px;
  font-size: 18px;
  line-height: 1;
  color: #094273;
}
.blte-parking-details__button {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  padding: 0 8px;
  color: #094273;
}
