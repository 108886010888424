@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rti-passenger-seat {
  display: flex;
  flex-direction: row;
  padding: 8px;
  width: 100%;
  column-gap: 16px;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  outline: none;
}
.blte-rti-passenger-seat--active {
  background-color: #fff;
}
.blte-rti-passenger-seat--active::before {
  content: " ";
  position: absolute;
  inset: -4px;
  box-shadow: 0 0 0 2px #094273;
  border-radius: 8px;
}
.blte-rti-passenger-seat--updated .blte-rti-passenger-seat__seat::before {
  content: " ";
  position: absolute;
  inset: -2px;
  box-shadow: 0 0 0 2px #094273;
  border-radius: 8px;
}
.blte-rti-passenger-seat__seat {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  min-width: 40px;
  min-height: 40px;
  width: fit-content;
  height: fit-content;
  background-color: #ffdb00;
  position: relative;
}
.blte-rti-passenger-seat__seat-number {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-rti-passenger-seat__seat-number {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-rti-passenger-seat__details {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: start;
}
.blte-rti-passenger-seat__name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
.blte-rti-passenger-seat__revert {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 23px;
  right: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #000;
  background-color: #fff;
  color: #094273;
  outline: none;
}
.blte-rti-passenger-seat__close {
  font-size: 12px;
}
.blte-rti-passenger-seat__coach {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  position: relative;
  align-items: center;
}
.blte-rti-passenger-seat__seat-direction {
  display: flex;
  align-items: center;
}
.blte-rti-passenger-seat__seat-direction::before {
  content: " ";
  width: 4px;
  height: 4px;
  position: inherit;
  border-radius: 50%;
  margin: 0 4px;
  background-color: #565656;
}
.blte-rti-passenger-seat:focus-visible::before {
  content: " ";
  position: absolute;
  inset: -4px;
  box-shadow: 0 0 0 2px #363636, 0 0 0 4px #094273;
  border-radius: 8px;
}
