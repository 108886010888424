@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passes-payment {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  background: #fff;
  width: 100%;
  margin-top: 0;
  margin-top: 24px;
  border-radius: 0;
}
@media screen and (min-width: 768px) {
  .blte-passes-payment {
    margin-top: 0;
    border-radius: 8px;
  }
}
.blte-passes-payment__title {
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  color: #363636;
}
.blte-passes-payment__separator {
  width: 100%;
  height: 1px;
  background: #e1e1e1;
}
