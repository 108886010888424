@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-image-flag {
  max-width: 55px;
  max-height: 16px;
}
@media screen and (min-width: 576px) {
  .blte-image-flag {
    max-height: 18px;
  }
}
@media screen and (min-width: 768px) {
  .blte-image-flag {
    max-width: 73px;
    max-height: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-image-flag {
    max-height: 26px;
  }
}
