@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-registration__container {
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 576px) {
  .blte-account-registration__header {
    height: 82px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f2f2f4;
  }
}
.blte-account-registration__header .blte-logo {
  width: 125px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
}
@media screen and (min-width: 576px) {
  .blte-account-registration__header .blte-logo {
    width: 140px;
    margin-left: 64px;
  }
}
.blte-account-registration__body {
  margin: 0 auto;
  max-width: 340px;
  flex: 1;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
}
.blte-account-registration__body:has(.blte-account-registration__form--currentStep-3) {
  max-width: 575px;
}
@media screen and (min-width: 576px) {
  .blte-account-registration__body:has(.blte-account-registration__form--currentStep-3) {
    margin: 25px auto 0;
    display: block;
  }
}
@media screen and (min-width: 576px) {
  .blte-account-registration__body--done {
    max-width: 560px;
  }
}
@media screen and (min-width: 576px) {
  .blte-account-registration__body {
    margin: 135px auto 0;
    display: block;
  }
}
.blte-account-registration__body .blte-title {
  text-align: center;
}
.blte-account-registration__subheading {
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  color: #636366;
  margin-top: 8px;
}
.blte-account-registration__form {
  padding-top: 40px;
  padding-bottom: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.blte-account-registration__form .blte-input + .blte-input {
  margin-top: 12px;
}
.blte-account-registration__form .blte-input--has-error + .blte-input {
  margin-top: 34px;
}
.blte-account-registration__form .blte-btn {
  margin-top: 16px;
}
.blte-account-registration__disclaimers {
  margin-top: 40px;
}
@media screen and (min-width: 576px) {
  .blte-account-registration__footer {
    margin-top: 40px;
  }
}
.blte-account-registration__names {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.blte-account-registration__names .blte-input__helper-text {
  margin-top: 4px;
}
.blte-account-registration__footer-info {
  font-size: 16px;
  line-height: 24px;
  color: #636366;
  text-align: center;
  font-weight: 400;
}
.blte-account-registration__footer-info a {
  color: #636366;
}
.blte-account-registration__last-step {
  text-align: center;
}
.blte-account-registration__confirmation-image {
  width: 280px;
  margin: 50px auto 30px;
}
@media screen and (min-width: 576px) {
  .blte-account-registration__confirmation-image {
    width: 560px;
  }
}
.blte-account-registration__helper {
  color: #565656;
}
.blte-account-registration__helper-date {
  color: #565656;
  padding: 0 8px;
}
.blte-account-registration__error {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #c00;
}
.blte-account-registration__done {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blte-account-registration__phone-wrapper {
  display: flex;
  max-width: 342px;
}
.blte-account-registration__country {
  width: 88px;
  margin-right: 8px;
}
