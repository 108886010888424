@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-my-account {
  display: flex;
  align-items: center;
  gap: 8px;
}
.blte-my-account:hover {
  cursor: pointer;
}
.blte-my-account__user-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blte-my-account__title {
  font-size: 20px;
  font-weight: 450;
  line-height: 26px;
}
.blte-my-account__nav-modal--isModalOpening {
  animation: slide-in-from-left 1s;
}
.blte-my-account__nav-modal--isModalClosing {
  animation: slide-out-to-left 1s;
}
.blte-my-account__content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.blte-my-account__close-button {
  display: flex;
}
.blte-my-account__close-icon {
  margin-left: auto;
}
.blte-my-account__close-icon:hover {
  cursor: pointer;
}
