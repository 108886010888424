@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-pass-details {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.blte-account-pass-details__info {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.blte-account-pass-details__info > * {
  padding: 8px 0;
  border-bottom: 1px solid #e1e1e1;
}
.blte-account-pass-details__info > *:first-child {
  padding: 0 0 8px;
}
.blte-account-pass-details__info > *:last-child {
  padding: 8px 0 0;
  border-bottom: none;
}
@media screen and (min-width: 768px) {
  .blte-account-pass-details__info > * {
    border-bottom: none;
    padding: 0 0 6px;
  }
  .blte-account-pass-details__info > *:last-child {
    padding: 0;
  }
}
.blte-account-pass-details__label {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
.blte-account-pass-details__value {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
.blte-account-pass-details__info--empty .blte-account-pass-details__label,
.blte-account-pass-details__info--empty .blte-account-pass-details__value {
  color: #8d8d8d;
}
