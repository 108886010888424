@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-card-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  flex-direction: column;
}
@media screen and (min-width: 576px) {
  .blte-card-actions {
    flex-direction: row;
  }
}
.blte-card-actions__action {
  width: 100%;
}
@media screen and (min-width: 576px) {
  .blte-card-actions__action {
    width: auto;
  }
}
