@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-wifi-splash-form {
  display: flex;
  justify-content: center;
  padding: 30px 40px;
  width: 100%;
}
.blte-wifi-splash-form__wrapper {
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 380px;
  max-width: 380px;
}
