@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-accordion {
  display: flex;
  flex-flow: column;
  padding: 8px 0;
  outline: none;
}
.blte-accordion__expand,
.blte-accordion__collapse {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  width: 100%;
  text-align: end;
  color: #094273;
  text-decoration: underline;
  cursor: pointer;
  padding: 16px 0;
  border: none;
  border-bottom: 1px solid #e1e1e1;
  align-self: end;
  background: none;
  font: inherit;
}
.blte-accordion__hidden {
  display: none;
}
.blte-accordion-item {
  padding: 16px 0;
  border-bottom: 1px solid #e1e1e1;
}
.blte-accordion-item:has(:focus-visible) {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #363636;
}
.blte-accordion-item__title {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  text-decoration: none;
  color: inherit;
  width: 100%;
  border: none;
  background: none;
  padding: 0;
  font: inherit;
  text-align: unset;
}
.blte-accordion-item__title__element {
  padding-right: 16px;
}
.blte-accordion-item__title:focus,
.blte-accordion-item__title:focus:not(:focus-visible) {
  outline: 0;
}
.blte-accordion-item__title:hover {
  color: #094273;
}
.blte-accordion-item__trailing-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #094273;
}
.blte-accordion-item__content {
  overflow: hidden;
}
.blte-accordion-item__content:not(.cq-Editable-dom--container) {
  transition:
    opacity 0.3s,
    max-height 0.3s,
    padding-left 0.3s,
    padding-top 0.3s,
    padding-bottom 0.3s;
  padding: 0;
  opacity: 0;
  max-height: 0;
  padding-right: 32px;
  overflow: hidden;
  visibility: hidden;
}
.blte-accordion-item__content--expanded {
  overflow: auto;
}
.blte-accordion-item__content--expanded:not(.cq-Editable-dom--container) {
  transition:
    opacity 0.3s,
    max-height 0.3s,
    padding-left 0.3s,
    padding-top 0.3s,
    padding-bottom 0.3s;
  opacity: 1;
  padding: 8px 32px 0 0;
  max-width: 100%;
  overflow: auto;
  visibility: visible;
}
.blte-accordion-item__auto-height:not(.cq-Editable-dom--container) {
  padding: 8px 48px 0 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  max-height: 100%;
}
