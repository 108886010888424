@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-transactions-table {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  background: #fff;
  overflow-x: auto;
}
@media screen and (min-width: 768px) {
  .blte-account-transactions-table {
    padding: 24px;
    gap: 24px;
  }
}
.blte-account-transactions-table__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-account-transactions-table__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
}
.blte-account-transactions-table__no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;
  border-radius: 8px;
  text-align: center;
  background: #f9f9f9;
}
.blte-account-transactions-table__main-subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
.blte-account-transactions-table__second-subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #a3a3a3;
}
.blte-account-transactions-table__table-wrapper {
  display: grid;
  grid-template-columns: min-content auto min-content;
  column-gap: 24px;
  padding: 12px 16px;
}
.blte-account-transactions-table__table-cell {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
}
.blte-account-transactions-table__table-cell--header {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
.blte-account-transactions-table__table-cell--alignRight {
  justify-self: end;
}
.blte-account-transactions-table__table-divider {
  grid-column: span 3;
  border-bottom: 1px solid #363636;
  width: calc(100% + 32px);
  margin: 0 -16px;
}
.blte-account-transactions-table__table-divider--row {
  border-bottom: 1px solid #e1e1e1;
}
.blte-account-transactions-table__activity {
  display: flex;
  flex-direction: column;
  padding: 11px 0;
}
.blte-account-transactions-table__od {
  display: flex;
  gap: 4px;
  color: #363636;
}
.blte-account-transactions-table__od-item {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-account-transactions-table__date {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: #a3a3a3;
}
.blte-account-transactions-table__confirmation {
  display: flex;
  align-items: center;
  align-self: stretch;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #2e6a6e;
  padding: 11px 0;
}
.blte-account-transactions-table__confirmation:hover {
  cursor: pointer;
}
.blte-account-transactions-table__passes-confirmation:hover {
  cursor: default;
}
.blte-account-transactions-table__rewards {
  display: flex;
  flex-direction: column;
  justify-self: stretch;
  justify-content: center;
  align-items: end;
  padding: 11px 0;
}
.blte-account-transactions-table__pending-awarded {
  display: flex;
  align-items: center;
  gap: 4px;
}
.blte-account-transactions-table__pending {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  color: #8d8d8d;
}
.blte-account-transactions-table__awarded {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #137e00;
  white-space: nowrap;
}
.blte-account-transactions-table__redeemed {
  display: flex;
  align-items: center;
  gap: 4px;
}
.blte-account-transactions-table__redeemed-label {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  color: #8d8d8d;
}
.blte-account-transactions-table__redeemed-pts {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  white-space: nowrap;
}
.blte-account-transactions-table__pagination-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -24px;
}
