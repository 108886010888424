.blte-features-grid-v2__top-description ul li::before {
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

}
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-features-grid-v2 {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  background-color: var(--features-grid-v2-background-color);
  padding-left: 0;
}
.blte-features-grid-v2__content {
  max-width: 1400px;
  margin: 0 auto;
}
.blte-features-grid-v2__content--length-2 {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-features-grid-v2__content--length-2 {
    width: 83.3333333333%;
  }
}
@media screen and (min-width: 1024px) {
  .blte-features-grid-v2__content--length-2 {
    width: 66.6666666667%;
  }
}
.blte-features-grid-v2__content--length-3 {
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .blte-features-grid-v2__content--length-3 {
    width: 83.3333333333%;
  }
}
.blte-features-grid-v2__content--length-4 {
  width: 100%;
}
.blte-features-grid-v2__content--variant-stack .blte-features-grid-v2__items {
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.blte-features-grid-v2__content--variant-stack .blte-features-grid-v2__items > * {
  margin: 40px 0 0;
}
@media screen and (min-width: 768px) {
  .blte-features-grid-v2__content--variant-stack .blte-features-grid-v2__items > * {
    margin: 0 24px 0 0;
  }
  .blte-features-grid-v2__content--variant-stack .blte-features-grid-v2__items > *:last-child {
    margin: 0;
  }
}
@media screen and (min-width: 1024px) {
  .blte-features-grid-v2__content--variant-stack .blte-features-grid-v2__items > * {
    margin: 0 40px 0 0;
  }
}
.blte-features-grid-v2__content--variant-carousel {
  overflow: hidden;
  padding: 0 24px;
}
@media screen and (min-width: 576px) {
  .blte-features-grid-v2__content--variant-carousel {
    padding: 0 64px;
  }
}
@media screen and (min-width: 768px) {
  .blte-features-grid-v2__content--variant-carousel {
    padding: 0;
  }
}
.blte-features-grid-v2__content--variant-carousel .blte-features-grid-v2__items-wrapper {
  padding: 40px 0 0;
}
@media screen and (min-width: 768px) {
  .blte-features-grid-v2__content--variant-carousel .blte-features-grid-v2__items-wrapper {
    padding: 0;
  }
}
.blte-features-grid-v2__content--variant-carousel .blte-features-grid-v2__items {
  position: relative;
  transition: transform 400ms ease 0s;
  user-select: none;
}
.blte-features-grid-v2__content--variant-carousel .blte-features-grid-v2__items > * {
  flex: 1 0 100%;
  margin-right: 16px;
}
.blte-features-grid-v2__content--variant-carousel .blte-features-grid-v2__items > *:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .blte-features-grid-v2__content--variant-carousel .blte-features-grid-v2__items > * {
    flex: none;
    margin: 0 24px 0 0;
  }
  .blte-features-grid-v2__content--variant-carousel .blte-features-grid-v2__items > *:last-child {
    margin: 0;
  }
}
@media screen and (min-width: 1024px) {
  .blte-features-grid-v2__content--variant-carousel .blte-features-grid-v2__items > * {
    margin: 0 40px 0 0;
  }
}
.blte-features-grid-v2__content--alignment-left .blte-features-grid-v2__top,
.blte-features-grid-v2__content--alignment-left .blte-features-grid-v2__top-eyebrow,
.blte-features-grid-v2__content--alignment-left .blte-features-grid-v2__top-title,
.blte-features-grid-v2__content--alignment-left .blte-features-grid-v2__top-description,
.blte-features-grid-v2__content--alignment-left .blte-features-grid-v2__bottom {
  text-align: start;
  align-items: start;
  justify-content: start;
}
.blte-features-grid-v2__content--alignment-center .blte-features-grid-v2__top,
.blte-features-grid-v2__content--alignment-center .blte-features-grid-v2__top-eyebrow,
.blte-features-grid-v2__content--alignment-center .blte-features-grid-v2__top-title,
.blte-features-grid-v2__content--alignment-center .blte-features-grid-v2__top-description,
.blte-features-grid-v2__content--alignment-center .blte-features-grid-v2__bottom {
  text-align: center;
  align-items: center;
  justify-content: center;
}
.blte-features-grid-v2__content--alignment-right .blte-features-grid-v2__top,
.blte-features-grid-v2__content--alignment-right .blte-features-grid-v2__top-eyebrow,
.blte-features-grid-v2__content--alignment-right .blte-features-grid-v2__top-title,
.blte-features-grid-v2__content--alignment-right .blte-features-grid-v2__top-description,
.blte-features-grid-v2__content--alignment-right .blte-features-grid-v2__bottom {
  text-align: end;
  align-items: end;
  justify-content: end;
}
.blte-features-grid-v2__top {
  width: 100%;
  display: flex;
}
.blte-features-grid-v2__top-content {
  display: flex;
  width: 100%;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .blte-features-grid-v2__top-content--length-2 {
    max-width: 83.3333333333%;
  }
}
@media screen and (min-width: 1024px) {
  .blte-features-grid-v2__top-content--length-2 {
    max-width: 66.6666666667%;
  }
}
@media screen and (min-width: 768px) {
  .blte-features-grid-v2__top-content--length-3 {
    max-width: 66.6666666667%;
  }
}
@media screen and (min-width: 768px) {
  .blte-features-grid-v2__top-content--length-4 {
    max-width: 66.6666666667%;
  }
}
@media screen and (min-width: 1024px) {
  .blte-features-grid-v2__top-content--length-4 {
    max-width: 50%;
  }
}
.blte-features-grid-v2__top-eyebrow {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: none;
  margin-bottom: 8px;
  color: var(--features-grid-v2-eyebrow-color, #094273);
}
.blte-features-grid-v2__top-eyebrow--noTitleAndDesc {
  margin-bottom: 0;
}
.blte-features-grid-v2__top-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
  text-transform: none;
  color: var(--features-grid-v2-title-color, #363636);
  padding-top: 4px;
  margin-bottom: 24px;
}
@media screen and (min-width: 576px) {
  .blte-features-grid-v2__top-title {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-features-grid-v2__top-title {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-features-grid-v2__top-title--noDesc {
  margin-bottom: 0;
}
.blte-features-grid-v2__top-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: var(--features-grid-v2-description-color, #363636);
}
@media screen and (min-width: 576px) {
  .blte-features-grid-v2__top-description {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-features-grid-v2__top-description ul {
  margin-left: 0;
  padding-left: 30px;
  list-style: none;
}
.blte-features-grid-v2__top-description ul li {
  margin-top: 8px;
  position: relative;
}
.blte-features-grid-v2__top-description ul li::before {
  content: ICON_FONT_LOADER_IMAGE(IDa4bfa326ce5d9ee1a619cf8d8545a410);
  position: absolute;
  left: -30px;
  font-size: 18px;
  top: 0;
}
.blte-features-grid-v2__top-description ol {
  margin-left: 0;
  padding-left: 30px;
}
.blte-features-grid-v2__top-description ul {
  display: inline-block;
}
.blte-features-grid-v2__top-description ul li {
  display: flex;
}
.blte-features-grid-v2__grid-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  gap: 20px;
}
.blte-features-grid-v2__desktop-carousel-wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.blte-features-grid-v2__desktop-carousel-content {
  position: relative;
  display: flex;
  align-items: center;
}
.blte-features-grid-v2__desktop-carousel-content .blte-features-grid-v2__carrot-wrapper:first-child {
  left: 0;
}
.blte-features-grid-v2__desktop-carousel-content .blte-features-grid-v2__carrot-wrapper:last-child {
  right: 0;
}
.blte-features-grid-v2__items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
  gap: 48px;
}
.blte-features-grid-v2__items-wrapper--isDesktopCarousel {
  flex-direction: row;
  gap: 0;
  transition: transform 400ms ease 0s;
}
.blte-features-grid-v2__items-wrapper--isDesktopCarousel > * {
  flex: 1 0 100%;
}
.blte-features-grid-v2__items-wrapper--isDesktopCarousel:last-child {
  margin-right: 54px;
}
.blte-features-grid-v2__items {
  display: flex;
  width: 100%;
}
.blte-features-grid-v2__items--isDesktopCarousel {
  padding-left: 54px;
  padding-right: 54px;
}
@media screen and (min-width: 768px) {
  .blte-features-grid-v2__items:first-child:not(.blte-features-grid-v2__items--premium) {
    padding-top: 48px;
  }
  .blte-features-grid-v2__items:not(:first-child):not(.blte-features-grid-v2__items--premium) {
    padding-top: 40px;
  }
  .blte-features-grid-v2__items:last-child {
    padding-bottom: 48px;
  }
  .blte-features-grid-v2__items--isDesktopCarousel:last-child {
    padding-bottom: 0;
  }
}
.blte-features-grid-v2__items > .blte-feature-item-v2 {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-features-grid-v2__items--premium {
    padding-top: 80px;
  }
  .blte-features-grid-v2__items--premium .blte-feature-item-v2__flag {
    margin-top: -32px;
  }
}
@media screen and (min-width: 768px) {
  .blte-features-grid-v2__items--premium.blte-features-grid-v2__items--background {
    padding-top: 48px;
  }
  .blte-features-grid-v2__items--premium.blte-features-grid-v2__items--background .blte-feature-item-v2__flag {
    margin-top: 0;
  }
}
.blte-features-grid-v2__items--length-2 {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-features-grid-v2__items--length-2 > .blte-feature-item-v2 {
    width: calc((100% - 24px) / 2);
  }
}
@media screen and (min-width: 1024px) {
  .blte-features-grid-v2__items--length-2 > .blte-feature-item-v2 {
    width: calc((100% - 40px) / 2);
  }
}
@media screen and (min-width: 768px) {
  .blte-features-grid-v2__items--length-3 > .blte-feature-item-v2 {
    width: calc((100% - 48px) / 3);
  }
}
@media screen and (min-width: 1024px) {
  .blte-features-grid-v2__items--length-3 > .blte-feature-item-v2 {
    width: calc((100% - 80px) / 3);
  }
}
@media screen and (min-width: 768px) {
  .blte-features-grid-v2__items--length-4 > .blte-feature-item-v2 {
    width: calc((100% - 80px) / 4);
  }
}
@media screen and (min-width: 1024px) {
  .blte-features-grid-v2__items--length-4 > .blte-feature-item-v2 {
    width: calc((100% - 120px) / 4);
  }
}
.blte-features-grid-v2__bottom {
  display: flex;
  padding-top: 40px;
}
@media screen and (min-width: 768px) {
  .blte-features-grid-v2__bottom--isStack {
    padding-top: 0;
  }
}
.blte-features-grid-v2__carrot-wrapper {
  position: absolute;
  top: 50%;
  z-index: 2;
}
.blte-features-grid-v2__carrot-wrapper:focus .blte-features-grid-v2__carrot {
  border: 2px solid #363636;
  color: #363636;
}
.blte-features-grid-v2__carrot-wrapper:hover .blte-features-grid-v2__carrot {
  cursor: pointer;
  background-color: #094273;
  color: #fff;
}
.blte-features-grid-v2__carrot {
  display: flex;
  width: 34px;
  height: 34px;
  font-size: 24px;
  line-height: 24px;
  justify-content: center;
  align-items: center;
  border: 1px solid #094273;
  border-radius: 100%;
  background-color: #fff;
}
.blte-features-grid-v2__dots-wrapper {
  display: flex;
  justify-content: center;
  gap: 8px;
}
.blte-features-grid-v2__dot {
  display: flex;
  min-width: 10px;
  min-height: 10px;
  border-radius: 100%;
  background-color: #d1d1d6;
}
.blte-features-grid-v2__dot--selected {
  background-color: #636366;
}
