@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-link-button {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  display: flex;
  flex-direction: row;
  max-width: 440px;
  width: fit-content;
  outline: none;
  text-decoration: none;
  color: var(--text-color, #094273);
  padding: 8px 2px;
  border: var(--border-style, unset);
}
.blte-link-button--variant-small {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-link-button--variant-medium {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-link-button--variant-large,
.blte-link-button--variant-big {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-link-button--variant-large,
  .blte-link-button--variant-big {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-link-button--variant-extra-large {
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-link-button--variant-extra-large {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-link-button--underline .blte-link-button__label {
  text-decoration: underline;
}
.blte-link-button__label--isMobileRti {
  font-size: 16px;
}
.blte-link-button:hover {
  color: var(--text-hover-color, #094273);
  cursor: pointer;
}
.blte-link-button:hover .blte-link-button__icon {
  color: var(--text-hover-color, #094273);
}
.blte-link-button:focus-visible {
  outline: none;
  border-radius: 2px;
  box-shadow: 0 0 0 2px var(--border-focus-color, #363636);
}
.blte-link-button__icon {
  color: var(--text-color, #094273);
  padding-left: 14px;
  text-decoration: none;
}
