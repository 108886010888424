@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passes-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}
.blte-passes-error__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.blte-passes-error__icon {
  font-size: 32px;
}
