@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-dropdown {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  user-select: none;
  position: relative;
  cursor: pointer;
}
.blte-dropdown__item-wrap {
  width: 100%;
  border-radius: 19px;
  overflow: hidden;
  background-color: var(--dropdown-background-color, #fff);
}
@media screen and (min-width: 768px) {
  .blte-dropdown__item-wrap .blte-dropdown__item {
    font-size: 16px;
    font-weight: 450;
    line-height: 24px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) and (min-width: 576px) {
  .blte-dropdown__item-wrap .blte-dropdown__item {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-dropdown__item {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  outline: none;
  display: flex;
  cursor: pointer;
  border: none;
  padding: 8px 12px;
  justify-content: space-between;
  width: 100%;
  border-radius: 18px;
  color: var(--dropdown-text-color, #363636);
  background-color: var(--dropdown-background-color, #fff);
  text-align: left;
}
.blte-dropdown__item:hover {
  color: var(--dropdown-text-hover-color, #363636);
  background-color: var(--dropdown-background-hover-color, #fff);
  box-shadow: inset 0 0 0 1px var(--dropdown-border-hover-color, #363636);
}
.blte-dropdown__item:focus-visible {
  color: var(--dropdown-text-focus-color, #363636);
  background-color: var(--dropdown-background-focus-color, #fff);
  box-shadow: inset 0 0 0 2px var(--dropdown-border-focus-color, #363636);
}
.blte-dropdown__menu {
  scrollbar-color: #c1c1c1 transparent;
  background: var(--dropdown-background-color, #fff);
  list-style-type: none;
  display: none;
  z-index: 1;
  position: absolute;
  overflow: hidden;
  border-radius: 6px;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
  left: 0;
  top: 100%;
}
.blte-dropdown__menu button {
  padding: 8px 16px;
}
.blte-dropdown--open .blte-dropdown__item-wrap {
  border-radius: 19px 19px 0 0;
}
.blte-dropdown--open .blte-dropdown__menu {
  max-height: 40vh;
  overflow-y: auto;
  display: flex;
  border-radius: 0 0 19px 19px;
}
