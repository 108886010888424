@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-add-parking-mobile-passenger-selection {
  position: relative;
  width: 100%;
}
.blte-add-parking-mobile-passenger-selection__leadingIcon {
  position: absolute;
  font-size: 24px;
  color: #000;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}
.blte-add-parking-mobile-passenger-selection__trailingIcon {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  color: #565656;
  position: absolute;
  font-size: 24px;
  width: 24px;
  height: 24px;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  border: none;
  background: none;
  outline: none;
  pointer-events: none;
}
.blte-add-parking-mobile-passenger-selection__trailingIcon--error {
  color: #c00;
}
.blte-add-parking-mobile-passenger-selection__label {
  position: absolute;
  color: #8d8d8d;
  padding: 2px;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  transition: 0.3s ease-out;
  transform-origin: left top;
  overflow: hidden;
  max-width: calc(100% - 80px);
  white-space: nowrap;
  text-overflow: ellipsis;
}
.blte-add-parking-mobile-passenger-selection__label-required {
  color: #c00;
}
.blte-add-parking-mobile-passenger-selection__label--hasLeadingIcon {
  left: 40px;
}
.blte-add-parking-mobile-passenger-selection__select {
  background-color: #fff;
  height: 48px;
  width: 100%;
  border-radius: 4px;
  border: none;
  outline: none;
  box-shadow: 0 0 0 1px #e1e1e1;
  color: gray;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  line-height: 26px;
  padding: 8px 40px 8px 8px;
  text-align: start;
}
.blte-add-parking-mobile-passenger-selection__select--hasLeadingIcon {
  padding-left: 40px;
}
.blte-add-parking-mobile-passenger-selection__select::placeholder {
  color: transparent;
}
.blte-add-parking-mobile-passenger-selection--error-inline {
  margin-bottom: 16px;
}
.blte-add-parking-mobile-passenger-selection--accessibility-focus .blte-add-parking-mobile-passenger-selection__leadingIcon,
.blte-add-parking-mobile-passenger-selection--has-focus .blte-add-parking-mobile-passenger-selection__leadingIcon {
  color: #000;
}
.blte-add-parking-mobile-passenger-selection--accessibility-focus .blte-add-parking-mobile-passenger-selection__label,
.blte-add-parking-mobile-passenger-selection--has-focus .blte-add-parking-mobile-passenger-selection__label {
  color: #094273;
}
.blte-add-parking-mobile-passenger-selection--accessibility-focus .blte-add-parking-mobile-passenger-selection__select,
.blte-add-parking-mobile-passenger-selection--has-focus .blte-add-parking-mobile-passenger-selection__select {
  color: #363636;
  box-shadow: 0 0 0 2px #094273;
}
.blte-add-parking-mobile-passenger-selection--has-value:not(.blte-add-parking-mobile-passenger-selection--has-error) .blte-add-parking-mobile-passenger-selection__leadingIcon {
  color: #000;
}
.blte-add-parking-mobile-passenger-selection--has-value:not(.blte-add-parking-mobile-passenger-selection--has-error) .blte-add-parking-mobile-passenger-selection__label {
  font-weight: 500;
  transform: translateY(-50%) scale(1);
  top: 0;
  font-size: 12px;
  opacity: 1;
  left: 8px !important;
}
.blte-add-parking-mobile-passenger-selection--has-value:not(.blte-add-parking-mobile-passenger-selection--has-error):not(.blte-add-parking-mobile-passenger-selection--accessibility-focus) .blte-add-parking-mobile-passenger-selection__select {
  color: #363636;
  box-shadow: 0 0 0 1px #e1e1e1;
}
.blte-add-parking-mobile-passenger-selection--has-value:not(.blte-add-parking-mobile-passenger-selection--has-focus):not(.blte-add-parking-mobile-passenger-selection--disabled) .blte-add-parking-mobile-passenger-selection__input,
.blte-add-parking-mobile-passenger-selection--has-value:not(.blte-add-parking-mobile-passenger-selection--has-focus):not(.blte-add-parking-mobile-passenger-selection--disabled) .blte-add-parking-mobile-passenger-selection__label {
  color: #363636;
}
.blte-add-parking-mobile-passenger-selection--has-error:not(.blte-add-parking-mobile-passenger-selection--disabled) .blte-add-parking-mobile-passenger-selection__label {
  color: #c00;
}
.blte-add-parking-mobile-passenger-selection--has-error:not(.blte-add-parking-mobile-passenger-selection--disabled) .blte-add-parking-mobile-passenger-selection__label:not(.blte-add-parking-mobile-passenger-selection--has-value) {
  color: #c00;
  font-weight: 500;
  transform: translateY(-50%) scale(1);
  top: 0;
  font-size: 12px;
  opacity: 1;
  left: 8px !important;
}
.blte-add-parking-mobile-passenger-selection--has-error:not(.blte-add-parking-mobile-passenger-selection--disabled) .blte-add-parking-mobile-passenger-selection__select {
  box-shadow: 0 0 0 1px #c00;
  color: #363636;
}
.blte-add-parking-mobile-passenger-selection--has-error:not(.blte-add-parking-mobile-passenger-selection--disabled) .blte-add-parking-mobile-passenger-selection__leadingIcon {
  color: #363636;
}
.blte-add-parking-mobile-passenger-selection--has-error:not(.blte-add-parking-mobile-passenger-selection--disabled).blte-add-parking-mobile-passenger-selection--has-focus .blte-add-parking-mobile-passenger-selection__select,
.blte-add-parking-mobile-passenger-selection--has-error:not(.blte-add-parking-mobile-passenger-selection--disabled).blte-add-parking-mobile-passenger-selection--accessibility-focus .blte-add-parking-mobile-passenger-selection__select {
  box-shadow: 0 0 0 2px #c00;
}
.blte-add-parking-mobile-passenger-selection--disabled {
  pointer-events: none;
  cursor: not-allowed;
  color: #a3a3a3;
}
.blte-add-parking-mobile-passenger-selection--disabled .blte-add-parking-mobile-passenger-selection__label,
.blte-add-parking-mobile-passenger-selection--disabled .blte-add-parking-mobile-passenger-selection__select,
.blte-add-parking-mobile-passenger-selection--disabled .blte-add-parking-mobile-passenger-selection__leadingIcon,
.blte-add-parking-mobile-passenger-selection--disabled .blte-add-parking-mobile-passenger-selection__error,
.blte-add-parking-mobile-passenger-selection--disabled .blte-add-parking-mobile-passenger-selection__label-required,
.blte-add-parking-mobile-passenger-selection--disabled .blte-add-parking-mobile-passenger-selection__helper-container {
  color: #a3a3a3;
}
.blte-add-parking-mobile-passenger-selection--disabled .blte-add-parking-mobile-passenger-selection__trailingIcon {
  color: #363636;
}
.blte-add-parking-mobile-passenger-selection--disabled .blte-add-parking-mobile-passenger-selection__select {
  box-shadow: 0 0 0 1px #e1e1e1;
}
.blte-add-parking-mobile-passenger-selection--disabled.blte-add-parking-mobile-passenger-selection--has-value:not(.blte-add-parking-mobile-passenger-selection--has-error) .blte-add-parking-mobile-passenger-selection__label,
.blte-add-parking-mobile-passenger-selection--disabled.blte-add-parking-mobile-passenger-selection--has-value:not(.blte-add-parking-mobile-passenger-selection--has-error) .blte-add-parking-mobile-passenger-selection__select,
.blte-add-parking-mobile-passenger-selection--disabled.blte-add-parking-mobile-passenger-selection--has-value:not(.blte-add-parking-mobile-passenger-selection--has-error) .blte-add-parking-mobile-passenger-selection__leadingIcon,
.blte-add-parking-mobile-passenger-selection--disabled:not(.blte-add-parking-mobile-passenger-selection--has-value) .blte-add-parking-mobile-passenger-selection__label,
.blte-add-parking-mobile-passenger-selection--disabled:not(.blte-add-parking-mobile-passenger-selection--has-value) .blte-add-parking-mobile-passenger-selection__select,
.blte-add-parking-mobile-passenger-selection--disabled:not(.blte-add-parking-mobile-passenger-selection--has-value) .blte-add-parking-mobile-passenger-selection__leadingIcon {
  color: #a3a3a3;
}
.blte-add-parking-mobile-passenger-selection--disabled.blte-add-parking-mobile-passenger-selection--has-value:not(.blte-add-parking-mobile-passenger-selection--has-error) .blte-add-parking-mobile-passenger-selection__trailingIcon,
.blte-add-parking-mobile-passenger-selection--disabled:not(.blte-add-parking-mobile-passenger-selection--has-value) .blte-add-parking-mobile-passenger-selection__trailingIcon {
  color: #a3a3a3;
}
.blte-add-parking-mobile-passenger-selection:hover:not(.blte-add-parking-mobile-passenger-selection--has-value):not(.blte-add-parking-mobile-passenger-selection--has-error) .blte-add-parking-mobile-passenger-selection__leadingIcon,
.blte-add-parking-mobile-passenger-selection--has-focus:not(.blte-add-parking-mobile-passenger-selection--has-value):not(.blte-add-parking-mobile-passenger-selection--has-error) .blte-add-parking-mobile-passenger-selection__leadingIcon {
  color: #000;
}
.blte-add-parking-mobile-passenger-selection__error {
  position: absolute;
  padding: 0 8px;
  width: 100%;
  gap: 16px;
  color: #c00;
}
