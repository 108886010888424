@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-booking-search__top-overlap {
  margin-top: -40px;
}
@media screen and (min-width: 768px) {
  .blte-booking-search__top-overlap {
    margin-top: -100px;
  }
}
