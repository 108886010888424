@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-hero {
  position: relative;
  display: flex;
  width: 100%;
  height: var(--image-height-mobile);
}
@media screen and (min-width: 576px) {
  .blte-hero {
    height: var(--image-height-tablet-sm);
  }
}
@media screen and (min-width: 768px) {
  .blte-hero {
    height: var(--image-height-tablet-lg);
  }
}
@media screen and (min-width: 1024px) {
  .blte-hero {
    height: var(--image-height-desktop);
  }
}
.blte-hero__image {
  width: 100%;
}
.blte-hero__image img,
.blte-hero__image picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .blte-hero__image img,
  .blte-hero__image picture {
    border-radius: 16px;
  }
}
.blte-hero__text {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
}
@media screen and (min-width: 576px) {
  .blte-hero__text {
    padding: 40px 64px;
  }
}
@media screen and (min-width: 768px) {
  .blte-hero__text {
    padding: 40px 80px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-hero__text {
    padding: 40px 80px;
  }
}
.blte-hero__text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: inherit;
  max-width: 800px;
}
.blte-hero__text-value {
  color: #fff;
  max-width: 800px;
}
.blte-hero__text-value-span {
  background-color: var(--text-background-color);
  color: var(--text-color);
}
.blte-hero__text-value-span--heading-h1 {
  font-size: 28px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-hero__text-value-span--heading-h1 {
    font-size: 42px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-hero__text-value-span--heading-h1 {
    font-size: 44px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-hero__text-value-span--heading-h2 {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-hero__text-value-span--heading-h2 {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-hero__text-value-span--heading-h2 {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-hero__text-value-span--heading-h3 {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-hero__text-value-span--heading-h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-hero__text-value-span--heading-h3 {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-hero__text-value > span {
  background-color: var(--text-background-color);
  color: var(--text-color);
  display: inline;
  white-space: break-spaces;
  padding: 0 8px;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.blte-hero__text-value > span br {
  content: "";
}
@media screen and (min-width: 576px) {
  .blte-hero__text-value > span {
    padding: 0 12px;
    line-height: 66px;
  }
}
@media screen and (min-width: 768px) {
  .blte-hero__text-value > span {
    padding: 0 16px;
    line-height: 66px;
  }
}
.blte-hero__text--alignment-center {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.blte-hero__text--alignment-left {
  justify-content: center;
  align-items: start;
  text-align: start;
}
.blte-hero__text--alignment-right {
  justify-content: center;
  align-items: end;
  text-align: end;
}
.blte-hero__text-subtitle {
  text-transform: none;
  padding-top: 8px;
}
@media screen and (min-width: 576px) {
  .blte-hero__text-subtitle {
    padding-top: 12px;
  }
}
.blte-hero__text-subtitle-span {
  background-color: var(--subtitle-background-color, transparent);
  color: var(--subtitle-color);
}
.blte-hero__text-subtitle-span--heading-h1 {
  font-size: 28px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-hero__text-subtitle-span--heading-h1 {
    font-size: 42px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-hero__text-subtitle-span--heading-h1 {
    font-size: 44px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-hero__text-subtitle-span--heading-h2 {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-hero__text-subtitle-span--heading-h2 {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-hero__text-subtitle-span--heading-h2 {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-hero__text-subtitle-span--heading-h3 {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-hero__text-subtitle-span--heading-h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-hero__text-subtitle-span--heading-h3 {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-hero__text-subtitle-span--heading-h4 {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-hero__text-subtitle-span--heading-h4 {
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-hero__text-subtitle-span--heading-h4 {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-hero__text-subtitle-span--heading-h5 {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-hero__text-subtitle-span--heading-h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: none;
  }
}
.blte-hero__text-subtitle-span--heading-h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: none;
}
.blte-hero__text-button {
  padding-top: 8px;
}
.blte-hero__text-button .blte-btn {
  width: fit-content;
  text-align: center;
}
@media screen and (min-width: 576px) {
  .blte-hero__text-button {
    padding-top: 12px;
  }
}
