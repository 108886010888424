@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.portal {
  position: static;
}
.blte-desktop-select__list {
  padding: 0;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border: none;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: -8px;
}
.blte-desktop-select__list--phoneNumberList {
  width: 300px !important;
  margin-left: 0 !important;
  margin-top: 5px !important;
}
.blte-desktop-select__listItems {
  flex: 1;
  max-height: 40vh;
  padding-inline-start: 0;
  overflow-y: auto;
}
.blte-desktop-select__listItems--phoneNumberList {
  width: 300px;
}
.blte-desktop-select__fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
.blte-desktop-select__fieldset--parking {
  width: 85%;
  margin-left: -15px;
}
.blte-desktop-select__legend {
  margin-bottom: 0;
  padding: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
}
.blte-desktop-select__search-country-code {
  appearance: none;
  width: 100%;
  padding: 6px 10px;
  gap: 8px;
  outline: none;
  border-radius: 4px;
  border: none;
  border-bottom: 1px solid #094273;
  box-shadow: 0 0 0 1px #094273;
  transition: 0.1s ease-out;
  display: flex;
  flex-wrap: wrap;
  line-height: 26px;
  text-overflow: ellipsis;
}
.blte-desktop-select__search-country-code.input-focused {
  background-color: #e8f2f1;
}
