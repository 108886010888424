@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-train-selection-body {
  padding-top: 60px;
}
@media screen and (min-width: 768px) {
  .blte-train-selection-body--overlay {
    margin-top: 80px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-train-selection-body--overlay {
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) {
  .blte-train-selection-body--overlay .blte-train-selection-body__incomplete--overlay {
    margin-top: 100px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-train-selection-body--overlay .blte-train-selection-body__incomplete--overlay {
    margin-top: 60px;
  }
}
.blte-train-selection-body__train-results {
  overflow: hidden;
}
.blte-train-selection-body__train-results--outbound-ticket-selected {
  animation: slide-up-and-down 1s;
}
.blte-train-selection-body__route-items--filtering {
  animation: slide-up-and-down 1s;
}
.blte-train-selection-body__details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .blte-train-selection-body__details {
    flex-direction: row;
  }
}
.blte-train-selection-body__fareCompare {
  display: none;
}
@media screen and (min-width: 768px) {
  .blte-train-selection-body__fareCompare {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .blte-train-selection-body__fareCompare > * {
    width: 50%;
  }
}
.blte-train-selection-body__travel-info {
  flex-direction: column;
}
.blte-train-selection-body__top-info {
  display: flex;
  justify-content: space-between;
}
.blte-train-selection-body__select-route {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-transform: none;
  color: #094273;
}
@media screen and (min-width: 576px) {
  .blte-train-selection-body__select-route {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: none;
  }
}
.blte-train-selection-body__edit-search {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  text-decoration-line: underline;
  color: var(--header-search-bar-link-color, #094273);
  outline: none;
}
.blte-train-selection-body__edit-search:focus-visible {
  border-radius: 2px;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px var(--header-search-bar-border-focus-color, #363636);
}
@media screen and (min-width: 768px) {
  .blte-train-selection-body__edit-search {
    display: none;
  }
}
.blte-train-selection-body__stations {
  display: flex;
  flex-flow: row wrap;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
}
.blte-train-selection-body__origin,
.blte-train-selection-body__destination {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  white-space: nowrap;
}
@media screen and (min-width: 576px) {
  .blte-train-selection-body__origin,
  .blte-train-selection-body__destination {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-train-selection-body__origin,
  .blte-train-selection-body__destination {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-train-selection-body__to-icon {
  color: #094273;
  font-size: 24px;
  margin: 0 18px;
}
.blte-train-selection-body__fare-content {
  display: flex;
  align-items: center;
  width: 100%;
}
.blte-train-selection-body__found-items {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  animation: fade-in 1s;
  color: #565656;
}
@media screen and (min-width: 576px) {
  .blte-train-selection-body__found-items {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-train-selection-body__found-items__wrapper {
  align-self: center;
}
.blte-train-selection-body__filters {
  display: flex;
  margin-top: 16px;
}
@media screen and (min-width: 768px) {
  .blte-train-selection-body__filters {
    margin-top: 0;
  }
}
.blte-train-selection-body__filters > * {
  width: 174px;
}
@media screen and (min-width: 768px) {
  .blte-train-selection-body__filters > * {
    width: 174px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-train-selection-body__filters > * {
    width: 320px;
  }
}
.blte-train-selection-body__departure-trip {
  display: none;
}
@media screen and (min-width: 768px) {
  .blte-train-selection-body__departure-trip {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }
}
.blte-train-selection-body__departure-trip-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-transform: none;
  animation: fade-in 1s;
  color: #094273;
  margin-bottom: 8px;
}
@media screen and (min-width: 576px) {
  .blte-train-selection-body__departure-trip-label {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: none;
  }
}
.blte-train-selection-body__departure-trip-wrapper {
  animation: slide-up 1s;
}
.blte-train-selection-body__found-wrapper {
  display: none;
}
@media screen and (min-width: 768px) {
  .blte-train-selection-body__found-wrapper {
    display: flex !important;
    align-items: center;
    min-height: 1px;
  }
}
.blte-train-selection-body__premium-filter {
  background-color: #fff;
  padding: 0 16px;
  border-radius: 20px;
  margin-left: 8px;
}
@media screen and (min-width: 768px) {
  .blte-train-selection-body__premium-filter {
    display: none;
  }
}
.blte-kiosk .blte-train-selection-body .blte-padding--horizontal-contained {
  padding: 0;
  width: 1160px;
  margin: 0 auto;
}
