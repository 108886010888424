@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-setting-entry-with-action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  margin-bottom: 16px;
  background: #fff;
  border-radius: 8px;
}
.blte-setting-entry-with-action__setting-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (min-width: 576px) {
  .blte-setting-entry-with-action__setting-wrapper {
    flex-direction: row;
    align-items: center;
  }
}
.blte-setting-entry-with-action__setting-label {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
}
@media screen and (min-width: 576px) {
  .blte-setting-entry-with-action__setting-label {
    min-width: 128px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-setting-entry-with-action__setting-label {
    min-width: 140px;
  }
}
.blte-setting-entry-with-action__setting-value {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-setting-entry-with-action__setting-value {
    margin-left: 24px;
  }
}
.blte-setting-entry-with-action__action-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 110px;
  padding: 0;
}
@media screen and (min-width: 576px) {
  .blte-setting-entry-with-action__action-button {
    align-items: flex-start;
  }
}
