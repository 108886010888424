@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-extras-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.blte-extras-modal__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 24px 40px;
  gap: 24px;
}
@media screen and (min-width: 576px) {
  .blte-extras-modal__content {
    padding: 32px 64px 40px;
    gap: 24px;
  }
}
@media screen and (min-width: 768px) {
  .blte-extras-modal__content {
    padding: 32px 40px;
  }
}
.blte-extras-modal__content-main {
  color: #363636;
  font-size: 18px;
  line-height: 26px;
}
@media screen and (min-width: 576px) {
  .blte-extras-modal__content-main {
    font-size: 20px;
    line-height: 28px;
  }
}
.blte-extras-modal__heading {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  order: 2;
}
.blte-extras-modal__info-cards {
  display: flex;
  align-self: stretch;
  gap: 24px;
  flex-flow: column wrap;
  gap: 8px;
  order: 4;
}
@media screen and (min-width: 768px) {
  .blte-extras-modal__info-cards {
    flex-flow: row nowrap;
    gap: 24px;
    order: 3;
  }
}
.blte-extras-modal__item-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  order: 3;
}
@media screen and (min-width: 768px) {
  .blte-extras-modal__item-list {
    order: 4;
  }
}
.blte-extras-modal__disclaimer {
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: none;
  order: 5;
}
.blte-extras-modal__error-description {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  padding-left: 10px;
}
