@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-past-trips {
  background: #f2f2f4;
  min-height: 800px;
}
.blte-past-trips__content {
  padding: 60px 0;
  padding: 40px 0;
}
@media screen and (min-width: 768px) {
  .blte-past-trips__content {
    padding: 60px 0;
  }
}
.blte-past-trips__header {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  display: none;
  color: #094273;
  margin-bottom: 16px;
}
@media screen and (min-width: 768px) {
  .blte-past-trips__header {
    display: block;
  }
}
.blte-past-trips__placeholders > div:first-child {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  margin-bottom: 16px;
}
.blte-past-trips__placeholders > div:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
@media screen and (min-width: 768px) {
  .blte-past-trips__placeholders > div:first-child {
    display: flex;
    width: 50px;
    height: 16px;
  }
}
