@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trips-tag {
  padding: 0 6px;
  border-radius: 4px;
}
.blte-trips-tag--variant-smart_standard {
  background-color: #ffdb00;
  color: #363636;
}
.blte-trips-tag--variant-smart_saver {
  background-color: #ffef8c;
}
.blte-trips-tag--variant-premium_standard {
  background-color: #363636;
  color: #fff;
}
.blte-trips-tag--variant-delayed {
  background-color: #ff7e00;
  color: #fff;
}
.blte-trips-tag--variant-updated {
  background-color: #ff7e00;
  color: #fff;
}
.blte-trips-tag--variant-cancelled {
  background-color: #c00;
  color: #fff;
}
.blte-trips-tag--variant-onTime {
  background-color: #137e00;
  color: #fff;
}
.blte-trips-tag--variant-completed {
  border: 1px solid #363636;
  color: #363636;
}
.blte-trips-tag--size-small {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-trips-tag--size-middle {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-trips-tag--size-large {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-trips-tag--size-large {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
