@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-kiosk-celebration-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.blte-kiosk-celebration-page__content {
  background: #fff;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blte-kiosk-celebration-page__celebration-banner,
.blte-kiosk-celebration-page__celebration-cta-list,
.blte-kiosk-celebration-page__banner {
  width: 1160px;
}
.blte-kiosk-celebration-page .blte-celebration-banner {
  border-radius: 8px;
}
