@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rti-wallet-pass {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  display: flex;
  width: 100%;
  padding: 6px 12px;
  border-radius: 4px;
}
.blte-rti-wallet-pass__image {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}
.blte-rti-wallet-pass__data {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.blte-rti-wallet-pass__info {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  column-gap: 8px;
}
.blte-rti-wallet-pass__details {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: flex-start;
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-rti-wallet-pass__details {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-rti-wallet-pass__stations-icon {
  padding: 0 6px;
}
.blte-rti-wallet-pass__remain-items {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
  width: fit-content;
}
.blte-rti-wallet-pass__checkbox-data {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-end;
}
.blte-rti-wallet-pass__checkbox-data .blte-checkbox {
  padding: 0;
}
.blte-rti-wallet-pass__checkbox-data .blte-checkbox__label {
  margin-left: 0;
}
.blte-rti-wallet-pass__checkbox-data > span {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #137e00;
  text-align: end;
}
.blte-rti-wallet-pass--disabled .blte-rti-wallet-pass__details,
.blte-rti-wallet-pass--disabled .blte-rti-wallet-pass__remain-items {
  color: #8d8d8d;
}
.blte-rti-wallet-pass--active {
  box-shadow: 0 0 0 1px #094273;
  background: rgba(242, 242, 244, 0.4);
}
.blte-rti-wallet-pass:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px #363636;
}
