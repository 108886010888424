@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-train-passes-from-to {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.blte-train-passes-from-to__row {
  display: grid;
  gap: 16px;
  grid-template: 1fr 1fr/none;
}
@media screen and (min-width: 1024px) {
  .blte-train-passes-from-to__row {
    grid-template: none/1fr 1fr;
  }
}
.blte-train-passes-from-to__subtitle {
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .blte-train-passes-from-to__subtitle {
    text-align: left;
  }
}
.blte-train-passes-from-to__price-update {
  font-size: 18px;
  color: #137e00;
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .blte-train-passes-from-to__price-update {
    text-align: left;
  }
}
