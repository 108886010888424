@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-payment-error-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blte-payment-error-modal__icon {
  color: #c00;
  font-size: 64px;
}
.blte-payment-error-modal__title {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-payment-error-modal__description {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  margin-top: 8px;
  text-align: center;
}
.blte-payment-error-modal__buttons {
  display: flex;
  padding: 0 8px;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  margin-top: 40px;
}
