@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passes-mobile-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 24px;
  gap: 8px;
  background: #fff;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.12));
  width: 100%;
  position: absolute;
  bottom: 0;
}
.blte-passes-mobile-footer__label {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  color: #363636;
}
.blte-passes-mobile-footer__price {
  font-weight: 450;
  font-size: 18px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #363636;
}
