@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-revert-provisional-changes__buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.blte-revert-provisional-changes__buttons--twoButtons > :first-child {
  margin-right: 12px;
  background-color: #e1e1e1;
}
.blte-revert-provisional-changes__buttons-unstyled {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.blte-revert-provisional-changes__buttons-unstyled > :first-child {
  margin-right: 12px;
}
.blte-revert-provisional-changes__text {
  margin-bottom: 16px;
  font-size: 18px;
}
.blte-revert-provisional-changes__text-group {
  margin-bottom: 16px;
  font-size: 18px;
  word-wrap: break-word;
}
.blte-revert-provisional-changes__icon {
  margin-bottom: 20px;
}
.blte-revert-provisional-changes__icon > :first-child {
  display: flex;
  margin: auto;
  color: #fefefe;
  background-color: #094273;
  outline: #094273;
}
