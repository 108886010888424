@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rti-step {
  padding: 47px 0 24px;
  background-color: #f2f2f4;
}
@media screen and (min-width: 768px) {
  .blte-rti-step {
    padding: 40px 0;
  }
}
.blte-rti-step .aem-Grid > .aem-GridColumn:first-child {
  padding-right: 24px;
}
@media screen and (min-width: 1024px) {
  .blte-rti-step .aem-Grid > .aem-GridColumn:first-child {
    padding-right: 40px;
  }
}
.blte-rti-step__cost-title {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
  padding-bottom: 16px;
  text-align: center;
}
@media screen and (min-width: 576px) {
  .blte-rti-step__cost-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-rti-step__cost-title {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-rti-step__update-trip-alert {
  padding-right: 0 !important;
  margin-bottom: 40px;
  margin: 0 24px 24px;
}
.blte-rti-step__travel-info {
  flex-direction: column;
  margin-bottom: 40px;
}
.blte-rti-step__travel-info-mobile {
  margin: 0 auto 24px 24px;
}
.blte-rti-step__mobile-timer {
  position: fixed;
  z-index: 9000;
  top: 55px;
  right: 22px;
}
@media screen and (min-width: 576px) {
  .blte-rti-step__mobile-timer {
    top: 88px;
  }
}
.blte-rti-step__tp-disabled-banner {
  position: fixed;
  left: 0;
  z-index: 50;
  width: 100%;
  text-align: center;
}
.blte-rti-step__tp-disabled-banner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 640px;
  max-width: calc(100vw - 40px);
  padding: 12px 24px;
  border: 2px solid #ffdb00;
  border-radius: 4px;
  background: #fffbe6;
  color: #363636;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1019607843);
  margin: 0 auto;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-rti-step__tp-disabled-banner-wrapper {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-rti-step__tp-disabled-banner-wrapper > i {
  font-size: 24px;
}
.blte-rti-step__tp-disabled-banner-text {
  width: 100%;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .blte-rti-step__tp-disabled-banner-text {
    text-align: left;
  }
}
.blte-rti-step__tp-disabled-banner-close:hover {
  cursor: pointer;
}
.blte-rti-step__discard-booking-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.blte-rti-step__discard-booking-buttons > :first-child {
  margin-right: 12px;
}
.blte-kiosk .blte-rti-step .blte-padding--horizontal-contained {
  padding: 0;
  width: 1160px;
  margin: 0 auto;
}
