@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-fare-compare-modal-v2 {
  display: flex;
  flex-direction: column;
}
.blte-fare-compare-modal-v2__header {
  text-align: center;
  position: sticky;
  top: 0;
  left: 0;
  min-height: 30px;
}
.blte-fare-compare-modal-v2__modal-mini-spacer {
  height: 30px;
}
.blte-fare-compare-modal-v2__icon-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  border-block: none;
  border-inline: none;
  border-color: transparent;
  padding: 0;
}
.blte-fare-compare-modal-v2__icon-wrapper:hover {
  cursor: pointer;
}
.blte-fare-compare-modal-v2__icon {
  color: #363636;
  font-size: 24px;
  line-height: 24px;
}
.blte-fare-compare-modal-v2__cell-content-wrapper {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  width: 100%;
  height: 100%;
  min-width: 20px;
  min-height: 20px;
}
.blte-fare-compare-modal-v2__cell-icon-wrapper {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.blte-fare-compare-modal-v2__cell-tooltip-wrapper {
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.blte-fare-compare-modal-v2__tooltip {
  border: 1px solid #565656;
  border-radius: 100%;
}
.blte-fare-compare-modal-v2__tooltip .blte-tooltip__content {
  width: 15px;
  height: 15px;
}
.blte-fare-compare-modal-v2__body {
  padding: 16px;
}
.blte-fare-compare-modal-v2__body p {
  margin: 0;
}
.blte-fare-compare-modal-v2__modal-notification {
  width: 100%;
  text-align: center;
  padding: 16px 0;
  background-color: #ffdb00;
  border-radius: 8px;
  border: 1px solid #f2f2f4;
  font-weight: 700;
}
