@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-trip-item-past {
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 8px;
  padding: 12px 8px 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: border-color 0.25s;
}
.blte-account-trip-item-past:hover .blte-account-trip-item-past__direction {
  color: #094273;
}
.blte-account-trip-item-past:focus,
.blte-account-trip-item-past:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px #363636;
}
@media screen and (min-width: 576px) {
  .blte-account-trip-item-past {
    padding: 12px 16px 12px 24px;
  }
}
.blte-account-trip-item-past:not(:last-child) {
  margin-bottom: 16px;
}
.blte-account-trip-item-past__content {
  padding-right: 16px;
}
.blte-account-trip-item-past__direction {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.blte-account-trip-item-past__from {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  letter-spacing: 0;
  margin-right: 8px;
}
@media screen and (min-width: 576px) {
  .blte-account-trip-item-past__from {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-account-trip-item-past__to {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  letter-spacing: 0;
  margin-left: 8px;
}
@media screen and (min-width: 576px) {
  .blte-account-trip-item-past__to {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-account-trip-item-past__direction-icon--type-arrow-double::after {
  font-size: 24px;
}
.blte-account-trip-item-past__direction-icon--type-arrow-from-to::after {
  font-size: 24px;
}
.blte-account-trip-item-past__arrow-wrapper {
  cursor: pointer;
}
.blte-account-trip-item-past__action-icon::after {
  font-size: 28px;
}
.blte-account-trip-item-past__cancelled {
  margin-left: 8px;
}
