@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-cancellation-detail {
  display: flex;
  justify-content: space-between;
}
.blte-cancellation-detail--isGray {
  color: #8d8d8d;
}
.blte-cancellation-detail--isBold {
  font-weight: 500;
}
.blte-cost-summary__title {
  padding-bottom: 0;
}
.blte-cost-summary_modification-fee-class {
  padding-left: 8px;
}
.blte-cost-summary_modification-fee-class span {
  font-size: 16px;
}
