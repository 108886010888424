@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: var(--tabs-no-headers-border-radius, 0 16px 16px);
}
.blte-tabs__buttons {
  background: #fff;
  position: relative;
  box-shadow: 0 -20px 20px rgba(0, 0, 0, 0.08);
  border-radius: 16px 16px 0 0;
  border: 2px solid #fff;
  display: flex;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-tabs__buttons {
    width: auto;
  }
}
.blte-tabs__button {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  position: relative;
  color: var(--tabs-text-color, #fff);
  background: var(--tabs-background-color, #094273);
  padding: 12px 24px;
  transition: all 0.3s;
  flex-grow: 1;
  justify-content: center;
}
.blte-tabs__button:first-child {
  border-top-left-radius: 16px;
}
.blte-tabs__button:last-child {
  border-top-right-radius: 16px;
}
.blte-tabs__button:not(:disabled):hover {
  background: var(--tabs-background-hover-color, #042c4f);
  color: var(--tabs-text-hover-color, #fff);
}
.blte-tabs__button:not(:disabled):focus,
.blte-tabs__button:not(:disabled):focus-visible {
  outline: none;
}
.blte-tabs__button:not(:disabled):focus-visible {
  box-shadow: 0 0 0 2px var(--tabs-border-focus-color, #363636), inset 0 0 0 2px #fff;
  z-index: 1;
}
.blte-tabs__button--active {
  background: var(--tabs-background-active-color, #fff);
  color: var(--tabs-text-active-color, #094273);
}
.blte-tabs__button--active:not(:disabled):hover {
  background: var(--tabs-background-active-color, #fff);
  color: var(--tabs-text-active-color, #094273);
}
.blte-tabs__button:disabled {
  cursor: auto;
}
.blte-tabs__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.blte-tabs__content {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  padding: 24px;
  margin-bottom: 4px;
  border-radius: var(--tabs-no-headers-border-radius, 0 0 16px 16px);
  background: #fff;
}
@media screen and (min-width: 768px) {
  .blte-tabs__content {
    border-radius: var(--tabs-no-headers-border-radius, 0 16px 16px);
  }
}
.blte-tabs__pills > * {
  margin: 0 16px 12px 0;
}
.blte-tabs__pills > *:last-child {
  margin-right: 0;
}
.blte-tabs__pills--footer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .blte-tabs__pills--footer {
    justify-content: flex-end;
  }
}
.blte-tabs__pills--footer > * {
  margin: 12px 16px 0 0;
}
.blte-tabs__pills--footer > *:first-child {
  margin-left: 0;
}
