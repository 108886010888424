@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passes-carousel {
  overflow: hidden;
  padding: 24px 12px 0;
  display: flex;
  width: 100%;
  background-color: #fff;
  height: 235px;
}
@media screen and (min-width: 576px) {
  .blte-passes-carousel {
    padding: 24px 0 0;
  }
}
.blte-passes-carousel__items {
  width: calc(100% - 48px);
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  position: relative;
  transition: transform 400ms ease 0s;
  user-select: none;
  max-width: 390px;
}
.blte-passes-carousel__items .blte-pass-card,
.blte-passes-carousel__items .blte-credit-pass {
  min-width: 120px;
}
.blte-passes-carousel__items > * {
  flex: 1 0 100%;
  margin-right: 12px;
}
@media screen and (min-width: 576px) {
  .blte-passes-carousel__items > * {
    margin-right: 40px;
  }
}
@media screen and (min-width: 576px) {
  .blte-passes-carousel__items {
    width: calc(100% - 208px);
  }
}
