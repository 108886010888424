@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rti-credit-card-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.blte-rti-credit-card-item__option {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 12px;
  justify-content: space-between;
  box-shadow: 0 0 0 1px #e1e1e1;
  border-radius: 4px;
}
.blte-rti-credit-card-item__option--active {
  box-shadow: 0 0 0 1px #094273;
  background: rgba(242, 242, 244, 0.4);
}
.blte-rti-credit-card-item__info {
  display: flex;
}
.blte-rti-credit-card-item--newCardActive {
  box-shadow: 0 0 0 1px #094273;
  border-radius: 4px;
}
.blte-rti-credit-card-item--newCardActive .blte-rti-credit-card-item__option {
  box-shadow: none;
  border-bottom: 1px solid #8d8d8d;
  border-radius: 4px 4px 0 0;
}
.blte-rti-credit-card-item__form {
  padding: 16px 12px;
  background: rgba(242, 242, 244, 0.4);
}
