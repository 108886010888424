@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-dashboardv2 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
}
@media screen and (min-width: 576px) {
  .blte-account-dashboardv2 {
    padding: 16px 64px;
  }
}
@media screen and (min-width: 768px) {
  .blte-account-dashboardv2 {
    gap: 24px;
    padding: 0;
  }
}
.blte-account-dashboardv2__row {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 16px;
}
@media screen and (min-width: 768px) {
  .blte-account-dashboardv2__row {
    gap: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-account-dashboardv2__row {
    flex-direction: row;
  }
}
.blte-account-dashboardv2__column {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}
@media screen and (min-width: 768px) {
  .blte-account-dashboardv2__column {
    gap: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-account-dashboardv2__column {
    width: 50%;
  }
}
.blte-account-dashboardv2__column-single {
  width: 100%;
}
.blte-account-dashboardv2__user-and-rewards {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
}
@media screen and (min-width: 576px) {
  .blte-account-dashboardv2__user-and-rewards {
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .blte-account-dashboardv2__user-and-rewards {
    gap: 24px;
  }
}
