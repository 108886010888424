@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-train-passes-card {
  display: grid;
  grid-template: 1fr min-content/none;
  border-radius: 6px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .blte-train-passes-card {
    border-radius: 0;
  }
}
@media screen and (min-width: 1024px) {
  .blte-train-passes-card {
    grid-template: none/1fr 1fr;
    border-radius: 6px;
    max-width: 100%;
    min-height: 573px;
  }
}
.blte-train-passes-card__grid-container {
  padding: 24px;
  background-color: var(--background-color, #fff);
}
@media screen and (min-width: 768px) {
  .blte-train-passes-card__grid-container {
    padding: 0;
    background-color: #fff;
  }
}
.blte-train-passes-card__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .blte-train-passes-card__form {
    align-items: flex-start;
  }
}
.blte-train-passes-card__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
}
.blte-train-passes-card__content-wrapper {
  background-color: var(--background-color, #fff);
}
.blte-train-passes-card__content {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  align-items: center;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .blte-train-passes-card__content {
    padding: 80px;
    gap: 26px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-train-passes-card__content {
    padding: 48px;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
}
.blte-train-passes-card__content,
.blte-train-passes-card__image {
  max-width: 656px;
}
@media screen and (min-width: 1024px) {
  .blte-train-passes-card__content,
  .blte-train-passes-card__image {
    max-width: 100%;
  }
}
.blte-train-passes-card__image {
  position: relative;
}
@media screen and (min-width: 768px) {
  .blte-train-passes-card__image {
    width: 100%;
    margin: 0 auto;
    padding: 0 80px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-train-passes-card__image {
    height: 100%;
    margin: initial;
    padding: 0;
  }
}
.blte-train-passes-card__image .blte-image {
  position: relative;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.blte-train-passes-card__backIconButton {
  border: none;
  background-color: inherit;
  color: #094273;
}
.blte-train-passes-card__icon {
  font-size: 50px;
}
.blte-train-passes-card__subtitle-text {
  font-weight: 500;
  font-size: 26px;
}
