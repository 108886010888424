@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rti-wallet-items {
  margin-bottom: 24px;
}
.blte-rti-wallet-items__passes {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
