@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-payment-request-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blte-payment-request-modal__icon {
  color: #094273;
  font-size: 64px;
}
.blte-payment-request-modal__title {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-payment-request-modal__description {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  margin-top: 8px;
  text-align: center;
}
