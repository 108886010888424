@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-auto-renew-payment {
  display: flex;
  flex-direction: column;
  color: #363636;
}
.blte-auto-renew-payment .blte-alert {
  margin-top: 24px;
}
.blte-auto-renew-payment .blte-alert__icon {
  background-color: #094273;
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
}
.blte-auto-renew-payment .blte-alert__icon::after {
  font-size: 13px;
  width: 100%;
  text-align: center;
}
