@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-alert {
  background-color: var(--alert-background-color, #e8f2f1);
  border-radius: 8px;
  padding: 8px;
  gap: 4px;
}
.blte-alert__icon {
  margin-right: 8px;
  margin-top: 2px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  background-color: var(--alert-icon-background-color, #e8f2f1);
  outline: var(--alert-icon-background-color, #e8f2f1) !important;
  color: var(--alert-icon-text-color, #fefefe);
}
.blte-alert__summary {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: inline-block;
  vertical-align: middle;
  color: var(--alert-summary-color, #363636);
}
.blte-alert__description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: block;
  color: var(--alert-description-color, #363636);
}
