@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-create-item__add-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.blte-create-item__add-item {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
}
.blte-create-item__add-btn {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  margin-left: 16px;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #ffdb00;
  border-radius: 50%;
}
.blte-create-item__add-btn:focus {
  outline: none;
}
.blte-create-item__add-btn:focus-visible {
  box-shadow: 0 0 0 2px #363636;
}
