@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-pass-card-empty {
  display: flex;
  max-width: 394px;
  justify-content: center;
}
.blte-pass-card-empty__content {
  width: 100%;
  padding: 0 40px;
  border: 2px dashed #ffdb00;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 334px;
  justify-content: center;
}
.blte-pass-card-empty__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  margin-top: 4px;
}
.blte-pass-card-empty__subtitle {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #8d8d8d;
  margin: 8px 0;
  text-align: center;
}
.blte-pass-card-empty__actions {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}
