@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-train-passes-categories__row {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  gap: 16px;
}
.blte-train-passes-categories__fields {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  align-items: center;
}
@media screen and (min-width: 1024px) {
  .blte-train-passes-categories__fields {
    align-items: flex-start;
    margin-top: 24px;
  }
}
.blte-train-passes-categories__learn-more {
  display: flex;
  margin-top: 16px;
  align-items: center;
  gap: 8px;
}
@media screen and (min-width: 1024px) {
  .blte-train-passes-categories__learn-more {
    margin-top: 8px;
  }
}
.blte-train-passes-categories__learn-more button {
  padding: 0;
}
.blte-train-passes-categories__subtitle {
  text-align: center;
}
@media screen and (min-width: 1024px) {
  .blte-train-passes-categories__subtitle {
    text-align: left;
  }
}
.blte-train-passes-categories__info-icon {
  display: flex;
}
