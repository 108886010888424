@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-passes-promo-code {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  width: 100%;
}
.blte-passes-promo-code__text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 450;
  text-decoration: underline;
  color: #094273;
}
.blte-passes-promo-code__text:hover {
  cursor: pointer;
}
.blte-passes-promo-code__promo-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 12px;
  height: 42px;
  border: 2px solid #137e00;
  border-radius: 8px;
}
.blte-passes-promo-code__promo-btn-code {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.blte-passes-promo-code__promo-btn-icon-left {
  line-height: 17.5px;
  font-size: 17.5px;
  color: #137e00;
}
.blte-passes-promo-code__promo-btn-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #137e00;
}
.blte-passes-promo-code__promo-btn-remove {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
.blte-passes-promo-code__promo-btn-remove:hover {
  cursor: pointer;
}
.blte-passes-promo-code__promo-btn-icon-right {
  line-height: 13.75px;
  font-size: 13.75px;
  color: #363636;
}
.blte-passes-promo-code__promo-btn-icon-right:hover {
  cursor: pointer;
}
.blte-passes-promo-code__promo-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  max-width: 225px;
  height: 42px;
}
.blte-passes-promo-code__promo-input-field input {
  height: 42px;
}
