@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-rewards-activity {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media screen and (min-width: 768px) {
  .blte-account-rewards-activity {
    gap: 24px;
  }
}
.blte-account-rewards-activity__user-account {
  padding: 8px 0 0;
}
@media screen and (min-width: 576px) {
  .blte-account-rewards-activity__user-account {
    padding: 16px 0 0;
  }
}
