@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rti-seat-selection-modal {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 0;
}
