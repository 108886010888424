@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-nativeSelect {
  position: absolute;
  inset: 0;
  width: 100%;
  opacity: 0;
}
