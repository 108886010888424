@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-auto-renew {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blte-auto-renew__icon {
  font-size: 24px;
}
.blte-auto-renew__left {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  color: #094273;
}
.blte-auto-renew__info {
  display: flex;
  align-items: center;
}
.blte-auto-renew__info-label {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  margin-left: 4px;
}
.blte-auto-renew__info-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
  margin: -8px 28px 0;
}
.blte-auto-renew__indicator {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  color: #363636;
  box-shadow: 0 0 0 1px #363636;
}
.blte-auto-renew__indicator--autoRenew {
  color: #137e00;
  box-shadow: 0 0 0 1px #137e00;
}
.blte-auto-renew--empty {
  pointer-events: none;
}
.blte-auto-renew--empty .blte-auto-renew__left,
.blte-auto-renew--empty .blte-auto-renew__indicator,
.blte-auto-renew--empty .blte-auto-renew__info-description {
  color: #8d8d8d;
}
.blte-auto-renew--empty .blte-auto-renew__indicator {
  box-shadow: 0 0 0 1px #8d8d8d;
}
