@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-content-tabs {
  width: 100%;
}
.blte-content-tabs__header {
  display: flex;
  justify-content: center;
}
.blte-content-tabs__header_item_wrapper {
  display: flex;
  padding: 4px;
  border-radius: 0;
  background-color: var(--tab-header-background-color, #e8f2f1);
  overflow-y: auto;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.blte-content-tabs__header_item_wrapper::-webkit-scrollbar {
  display: none;
}
@media screen and (min-width: 768px) {
  .blte-content-tabs__header_item_wrapper {
    border-radius: 40px;
    width: auto;
  }
}
.blte-content-tabs__header_item {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: none;
  flex: 1;
  border: none;
  border-radius: 40px;
  text-transform: uppercase;
  background-color: var(--tab-header-background-color, #e8f2f1);
  color: var(--tab-header-text-color, #565656);
  padding: 8px 40px;
  margin-right: 4px;
  white-space: nowrap;
  cursor: pointer;
}
.blte-content-tabs__header_item--active {
  background-color: var(--tab-header-background-active-color, #fff);
  color: var(--tab-header-text-active-color, #363636);
}
.blte-content-tabs__header_item--disabled {
  background-color: var(--tab-header-background-disabled-color, #f2f2f4);
  color: var(--tab-header-text-disabled-color, #8d8d8d);
}
.blte-content-tabs__header_item:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--tab-header-border-focus-color, #363636), 0 0 6px rgba(0, 0, 0, 0.25);
  color: var(--tab-header-text-focus-color, #363636);
}
.blte-content-tabs__header_item:last-child {
  margin-right: 0;
}
.blte-content-tabs__content_wrapper {
  display: flex;
  flex-direction: column;
}
.blte-content-tabs__content_wrapper .blte-content-tabs__content_item {
  display: block;
  opacity: 0;
  height: auto;
  transition: all var(--swap-duration, "0.3s");
}
.blte-content-tabs__content_wrapper .blte-content-tabs__visible {
  opacity: 1;
}
.blte-content-tabs__content_wrapper .blte-content-tabs__hidden {
  height: 0;
  display: none;
}
.blte-content-tabs__content_wrapper--edit-mode .blte-content-tabs__content_item {
  opacity: 1;
}
.blte-content-tabs__content_wrapper--edit-mode .blte-content-tabs__hidden {
  height: auto;
}
