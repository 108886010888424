@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-details-trips {
  margin-top: 32px;
  background: #fff;
  box-sizing: border-box;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-trips {
    margin-top: 40px;
    padding: 24px 64px;
  }
}
@media screen and (min-width: 768px) {
  .blte-trip-details-trips {
    padding: 0;
    background: none;
  }
}
.blte-trip-details-trips__placeholders {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.blte-trip-details-trips__placeholders > div:first-child {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  width: 130px;
  height: 24px;
  border-radius: 2px;
}
.blte-trip-details-trips__placeholders > div:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-trips__placeholders > div:first-child {
    height: 30px;
  }
}
.blte-trip-details-trips__placeholders > div:nth-child(2),
.blte-trip-details-trips__placeholders > div:nth-child(3) {
  background: #fff;
  border-radius: 8px;
  width: 100%;
  height: 130px;
  display: flex;
  margin-top: 16px;
  overflow: hidden;
  box-shadow: 0 0 0 1px #e1e1e1;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-trips__placeholders > div:nth-child(2),
  .blte-trip-details-trips__placeholders > div:nth-child(3) {
    box-shadow: none;
  }
}
.blte-trip-details-trips__placeholders > div:nth-child(2) > div:first-child,
.blte-trip-details-trips__placeholders > div:nth-child(3) > div:first-child {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  flex: 0 0 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.blte-trip-details-trips__placeholders > div:nth-child(2) > div:first-child::after,
.blte-trip-details-trips__placeholders > div:nth-child(3) > div:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-trips__placeholders > div:nth-child(2) > div:first-child,
  .blte-trip-details-trips__placeholders > div:nth-child(3) > div:first-child {
    flex: 0 0 130px;
  }
}
.blte-trip-details-trips__placeholders > div:nth-child(2) > div:first-child > div,
.blte-trip-details-trips__placeholders > div:nth-child(3) > div:first-child > div {
  background: #fff;
  border-radius: 2px;
  position: relative;
  z-index: 1;
}
.blte-trip-details-trips__placeholders > div:nth-child(2) > div:first-child > div:first-child,
.blte-trip-details-trips__placeholders > div:nth-child(3) > div:first-child > div:first-child {
  width: 48px;
  height: 24px;
}
.blte-trip-details-trips__placeholders > div:nth-child(2) > div:first-child > div:last-child,
.blte-trip-details-trips__placeholders > div:nth-child(3) > div:first-child > div:last-child {
  width: 30px;
  height: 16px;
  margin-top: 12px;
}
.blte-trip-details-trips__placeholders > div:nth-child(2) > div:last-child,
.blte-trip-details-trips__placeholders > div:nth-child(3) > div:last-child {
  flex: 1 0 auto;
  padding: 24px 12px;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-trips__placeholders > div:nth-child(2) > div:last-child,
  .blte-trip-details-trips__placeholders > div:nth-child(3) > div:last-child {
    padding: 24px 24px 40px;
  }
}
@media screen and (min-width: 768px) {
  .blte-trip-details-trips__placeholders > div:nth-child(2) > div:last-child,
  .blte-trip-details-trips__placeholders > div:nth-child(3) > div:last-child {
    padding: 24px 24px 40px;
  }
}
.blte-trip-details-trips__placeholders > div:nth-child(2) > div:last-child > div,
.blte-trip-details-trips__placeholders > div:nth-child(3) > div:last-child > div {
  background: #e1e1e1;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
}
.blte-trip-details-trips__placeholders > div:nth-child(2) > div:last-child > div::after,
.blte-trip-details-trips__placeholders > div:nth-child(3) > div:last-child > div::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #e1e1e1, #f2f2f4, #e1e1e1);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-trip-details-trips__placeholders > div:nth-child(2) > div:last-child > div:first-child,
.blte-trip-details-trips__placeholders > div:nth-child(3) > div:last-child > div:first-child {
  width: 100%;
  height: 24px;
}
@media screen and (min-width: 576px) {
  .blte-trip-details-trips__placeholders > div:nth-child(2) > div:last-child > div:first-child,
  .blte-trip-details-trips__placeholders > div:nth-child(3) > div:last-child > div:first-child {
    width: 75%;
  }
}
@media screen and (min-width: 768px) {
  .blte-trip-details-trips__placeholders > div:nth-child(2) > div:last-child > div:first-child,
  .blte-trip-details-trips__placeholders > div:nth-child(3) > div:last-child > div:first-child {
    width: 50%;
  }
}
.blte-trip-details-trips__placeholders > div:nth-child(2) > div:last-child > div:last-child,
.blte-trip-details-trips__placeholders > div:nth-child(3) > div:last-child > div:last-child {
  width: 25%;
  height: 16px;
  margin-top: 8px;
}
.blte-trip-details-trips__details > div:not(:first-child) {
  margin-top: 16px;
}
