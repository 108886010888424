@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-tooltip {
  font-size: 14px;
  color: var(--tooltip-content-text-color, #8d8d8d);
}
.blte-tooltip:hover {
  color: var(--tooltip-content-text-hover-color, #2e6a6e);
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}
.blte-tooltip__info-text {
  transition: all 0.3s ease-in-out;
  max-width: 320px;
  line-height: 20px;
  border-radius: 8px;
  color: var(--tooltip-info-text-color, #565656);
  background: var(--tooltip-info-background-color, #fff);
  padding: 4px 8px;
  box-shadow: 6px 4px 16px 0 rgba(0, 0, 0, 0.15);
}
