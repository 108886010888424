@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-manage-trips-form-container__required-field-label {
  margin: 0 0 15px;
}
.blte-manage-trips-form-container__title {
  display: block;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  margin: 0;
  color: inherit;
}
@media screen and (min-width: 576px) {
  .blte-manage-trips-form-container__title {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-manage-trips-form-container__description {
  display: block;
  margin: 0;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-manage-trips-form-container__description > a {
  color: #094273;
  padding-right: 4px;
}
.blte-manage-trips-form {
  display: flex;
  width: 100%;
}
.blte-manage-trips-form > form {
  width: 100%;
}
.blte-manage-trips-form__first-row {
  display: flex;
  padding: 16px 0;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .blte-manage-trips-form__first-row {
    flex-direction: row;
  }
}
.blte-manage-trips-form__first-row .blte-widget-input__wrapper {
  width: 100%;
  margin-bottom: 16px;
}
.blte-manage-trips-form__first-row .blte-widget-input__wrapper:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .blte-manage-trips-form__first-row .blte-widget-input__wrapper {
    width: 50%;
    margin-right: 16px;
    margin-bottom: 0;
  }
  .blte-manage-trips-form__first-row .blte-widget-input__wrapper:last-child {
    margin-right: 0;
  }
}
.blte-manage-trips-form__second-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.blte-manage-trips-form__content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 156px);
  padding: 0 24px;
  overflow: auto;
}
.blte-manage-trips-form__content .blte-widget-input {
  margin-top: 2px;
  width: 100%;
  margin-bottom: 16px;
}
.blte-manage-trips-form__content .blte-widget-input--has-error {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .blte-manage-trips-form__content .blte-widget-input {
    width: 50%;
    margin-right: 16px;
    margin-bottom: 0;
  }
  .blte-manage-trips-form__content .blte-widget-input:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
}
.blte-manage-trips-form__actions {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  padding: 16px 24px;
  background: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.15);
}
