@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-cost-summary-total {
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-cost-summary-total {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-cost-summary-total__wrapper,
.blte-cost-summary-total__content {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  cursor: pointer;
  flex-direction: column;
  padding: 24px;
  border-radius: 24px 24px 0 0;
  gap: 8px;
}
.blte-cost-summary-total__wrapper {
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.078);
  position: sticky;
  bottom: 0;
  padding: 15px 24px;
}
@media screen and (min-width: 576px) {
  .blte-cost-summary-total__wrapper {
    padding: 12px 64px;
  }
}
.blte-cost-summary-total__content {
  flex-direction: row;
  padding: 0;
}
.blte-cost-summary-total__wrapper-promo-code {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 8px;
}
.blte-cost-summary-total__wrapper-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.blte-cost-summary-total__arrow-up-icon {
  font-size: 24px;
  color: #363636;
}
.blte-cost-summary-total__arrow-up-button {
  border: none;
  background: inherit;
  margin-top: -6px;
}
.blte-cost-summary-total__content,
.blte-cost-summary-total__total {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-cost-summary-total__content,
  .blte-cost-summary-total__total {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-cost-summary-total__amount,
.blte-cost-summary-total__total {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  font-size: 20px;
}
@media screen and (min-width: 576px) {
  .blte-cost-summary-total__amount,
  .blte-cost-summary-total__total {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-rti-timer__wrapper {
  width: 156px;
  text-align: center;
  margin-top: 7px;
  background-color: #e8f2f1;
  padding: 4px 8px 5px;
  border-radius: 4px;
  height: fit-content;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}
@media screen and (min-width: 768px) {
  .blte-rti-timer__wrapper {
    box-shadow: none;
  }
}
@media screen and (min-width: 1024px) {
  .blte-rti-timer__wrapper {
    box-shadow: none;
  }
}
.blte-rti-timer__countdown {
  font-weight: 500;
}
.blte-rti-timer--session-expired {
  color: red;
}
.blte-cost-summary__title {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 16px;
}
@media screen and (min-width: 576px) {
  .blte-cost-summary__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-cost-summary__title {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-cost-summary__title {
    padding-bottom: 24px;
    text-align: left;
  }
}
.blte-cost-summary__title-wrapper {
  display: flex;
  justify-content: space-between;
}
.blte-cost-summary__help {
  text-align: center;
  padding-bottom: 8px;
}
.blte-cost-summary .blte-rti-trip-details-item {
  margin-bottom: 24px;
}
.blte-cost-summary .blte-rti-trip-details-item:last-of-type {
  margin-bottom: 24px;
}
.blte-cost-summary-detail__from-to {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
}
@media screen and (min-width: 576px) {
  .blte-cost-summary-detail__from-to {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-cost-summary-detail__name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
}
@media screen and (min-width: 576px) {
  .blte-cost-summary-detail__name {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-cost-summary-detail__arrow {
  padding: 0 8px;
}
.blte-cost-summary-sections__cancellation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .blte-cost-summary-sections__cancellation {
    flex-direction: row;
    padding: 0;
  }
}
.blte-cost-summary-sections__container {
  padding: 0 25px 25px;
  width: 100%;
  border: solid 1px #e1e1e1;
  border-radius: 10px;
  height: fit-content;
  min-height: 160px;
}
.blte-cost-summary-sections__container--select {
  background-color: rgba(230, 242, 242, 0.5019607843);
  border: solid 1px #2e6a6e;
}
.blte-cost-summary-sections__container--disabledClass {
  background-color: rgba(242, 242, 244, 0.5019607843);
  color: #a3a3a3;
}
@media screen and (min-width: 768px) {
  .blte-cost-summary-sections__container {
    width: 50%;
    height: 225px;
  }
}
.blte-cost-summary-sections__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e1e1e1;
}
.blte-cost-summary-sections__header p {
  padding: 0;
  margin: 0;
}
@media screen and (min-width: 1024px) {
  .blte-cost-summary-sections__header {
    font-size: 21px;
  }
}
.blte-cost-summary-sections__date-check {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.blte-cost-summary-sections__info-section {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  font-size: 1em;
  min-width: 75px;
}
.blte-cost-summary-sections__from-to-select {
  display: flex;
  align-items: start;
  gap: 40px;
}
.blte-cost-summary-sections__disabled-message {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e1e1e1;
  font-size: 15px;
  color: #363636;
}
@media screen and (min-width: 768px) {
  .blte-cost-summary-sections__disabled-message {
    margin-top: 0;
    padding-top: 1px;
    font-size: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-cost-summary-sections__disabled-message {
    margin-top: 5px;
    padding-top: 12px;
    font-size: 17px;
  }
}
.blte-cost-summary-sections__item {
  margin-top: 16px;
}
.blte-cost-summary-sections__from-to,
.blte-cost-summary-sections__name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
}
@media screen and (min-width: 576px) {
  .blte-cost-summary-sections__from-to,
  .blte-cost-summary-sections__name {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-cost-summary-sections__arrow {
  padding: 0 8px;
}
.blte-cost-summary-sections__cancelation-fee {
  display: flex;
  justify-content: space-between;
  margin: 0 0 0 30px;
  color: #8d8d8d;
}
.blte-cost-summary-sections__info-icon {
  margin-left: 10px;
}
.blte-cost-summary-sections__info-icon > :first-child {
  color: #8d8d8d;
  outline: 2px solid #8d8d8d;
}
.blte-cost-summary-detail-item {
  display: flex;
  justify-content: space-between;
}
.blte-cost-summary-detail-item__type {
  padding-left: 12px;
}
.blte-cost-summary-detail-item__no-padding-left {
  padding-left: 0;
}
.blte-cost-summary-detail-item__other {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  margin-top: 16px;
}
@media screen and (min-width: 576px) {
  .blte-cost-summary-detail-item__other {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-cost-summary-detail-item--isOther {
  color: #137e00;
  margin-top: 16px;
}
.blte-cost-summary-detail-item--isOther:nth-child(2) {
  margin-top: 0;
}
.blte-cost-summary-detail-item__trailingIcon {
  border: 0;
  background-color: inherit;
  margin-left: -3px;
  margin-right: -10px;
  cursor: pointer;
}
.blte-cost-summary-upgrade {
  padding-bottom: 0;
}
@media screen and (min-width: 768px) {
  .blte-cost-summary-upgrade {
    padding-bottom: 24px;
  }
}
.blte-cost-summary-upgrade__container {
  background-color: #fffbe6;
  padding: 12px;
}
.blte-cost-summary-upgrade__premium-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-cost-summary-upgrade__premium-title span {
  border: 2px #363636 solid;
  border-radius: 8px;
  margin: 0 4px;
  padding: 0 4px;
}
.blte-cost-summary-upgrade__content {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .blte-cost-summary-upgrade__content {
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
  }
}
.blte-cost-summary-upgrade__reasons {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  padding-bottom: 8px;
  padding-right: 4px;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .blte-cost-summary-upgrade__reasons {
    padding-bottom: 0;
  }
}
.blte-cost-summary-upgrade__reason {
  display: flex;
  align-items: flex-start;
  padding-top: 8px;
}
.blte-cost-summary-upgrade__ckeck-icon {
  margin: 1px 4px;
  font-size: 10px;
  padding: 0 5px;
  border-radius: 50%;
  color: #fff;
  background-color: #137e00;
}
.blte-cost-summary-upgrade__remove-container {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  background-color: #fffbe6;
  padding: 12px;
  display: flex;
  justify-content: space-between;
}
.blte-cost-summary-upgrade__remove {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  padding-left: 4px;
  overflow: visible;
  text-decoration-line: underline;
  color: var(--header-search-bar-link-color, #094273);
  outline: none;
}
.blte-cost-summary-content {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  overflow-x: hidden;
}
@media screen and (min-width: 576px) {
  .blte-cost-summary-content {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-cost-summary-content__direction {
  padding-bottom: 16px;
}
.blte-cost-summary-content__taxes {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.blte-cost-summary-content .blte-divider--alignment-horizontal {
  margin: 16px 0;
}
.blte-cost-summary-content__passes {
  display: flex;
  flex-direction: column;
}
.blte-cost-summary-content__pass-item {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #137e00;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
}
@media screen and (min-width: 576px) {
  .blte-cost-summary-content__pass-item {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-cost-summary-content__promo-content {
  max-width: 100%;
  justify-content: center;
  display: flex;
  margin: 16px auto;
  margin-bottom: 0;
  padding-bottom: 2px;
}
@media screen and (min-width: 768px) {
  .blte-cost-summary-content__promo-content {
    margin-top: 24px;
  }
}
.blte-cost-summary-content .blte-promo-code .blte-link-button {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-cost-summary-content .blte-promo-code .blte-link-button__label {
  border-bottom: 1px #094273 solid;
}
.blte-cost-summary-content .blte-promo-code .blte-input {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  margin-right: 8px;
}
@media screen and (min-width: 576px) {
  .blte-cost-summary-content .blte-promo-code .blte-input {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-cost-summary-content__total-returned {
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  margin: 10px 0;
}
@media screen and (min-width: 576px) {
  .blte-cost-summary-content__total-returned {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
