@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-fare-compare-buttons {
  display: inline-flex;
  align-items: stretch;
}
.blte-fare-compare-buttons--width-full {
  width: 100%;
}
.blte-fare-compare-buttons__button {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  flex: 1;
  padding: 8px 12px;
  margin-left: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  outline: none;
  border: none;
}
.blte-fare-compare-buttons__button:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #363636;
  border-radius: 4px;
}
.blte-fare-compare-buttons__button--variant-smart {
  background: #ffdb00;
  border-radius: 8px 0 0 8px;
  color: #363636;
}
.blte-fare-compare-buttons__button--variant-smart .blte-fare-compare-buttons__icons {
  background: rgba(255, 255, 255, 0.5);
}
.blte-fare-compare-buttons__button--variant-premium {
  background: #363636;
  border-radius: 0 8px 8px 0;
  color: #fff;
}
.blte-fare-compare-buttons__button--variant-premium .blte-fare-compare-buttons__icons {
  background: rgba(255, 255, 255, 0.1);
}
.blte-fare-compare-buttons__header {
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.blte-fare-compare-buttons__body {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  text-align: left;
  margin-top: 4px;
}
.blte-fare-compare-buttons__icons {
  padding: 2px 6px;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.blte-fare-compare-buttons__icons i {
  margin-right: 4px;
}
.blte-fare-compare-buttons__icons i:last-child {
  margin-right: 0;
}
.blte-fare-compare-buttons__icons span {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-kiosk .blte-fare-compare-buttons {
  padding: 0 16px;
}
