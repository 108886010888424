@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-seat-map-mobile-placeholder {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.blte-seat-map-mobile-placeholder__top {
  display: flex;
  width: 100%;
  padding: 8px 24px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
}
.blte-seat-map-mobile-placeholder__top > div {
  border-radius: 2px;
  background-color: #e1e1e1;
  width: 107px;
  height: 24px;
}
.blte-seat-map-mobile-placeholder__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 16px;
}
.blte-seat-map-mobile-placeholder__content > div {
  display: flex;
  align-self: center;
  height: 100%;
  width: 100%;
  background-color: #e1e1e1;
}
@media screen and (min-width: 576px) {
  .blte-seat-map-mobile-placeholder__content > div {
    width: 358px;
  }
}
.blte-seat-map-mobile-placeholder__footer {
  border-radius: 16px 16px 0 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
}
.blte-seat-map-mobile-placeholder__footer-first {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 24px 8px;
}
@media screen and (min-width: 576px) {
  .blte-seat-map-mobile-placeholder__footer-first {
    padding: 16px 64px 12px;
  }
}
.blte-seat-map-mobile-placeholder__footer-second {
  padding: 12px 24px;
  border-top: 1px solid #e1e1e1;
}
.blte-seat-map-mobile-placeholder__footer-second > div {
  border-radius: 2px;
  background-color: #e1e1e1;
  border-radius: 8px;
  height: 40px;
}
@media screen and (min-width: 576px) {
  .blte-seat-map-mobile-placeholder__footer-second {
    padding: 24px 64px;
  }
}
.blte-seat-map-mobile-placeholder__passenger {
  border-radius: 2px;
  background-color: #e1e1e1;
  width: 107px;
  height: 24px;
}
.blte-seat-map-mobile-placeholder__site-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
}
.blte-seat-map-mobile-placeholder__coach {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.blte-seat-map-mobile-placeholder__coach > * {
  border-radius: 2px;
  background-color: #e1e1e1;
  width: 44px;
  height: 24px;
}
.blte-seat-map-mobile-placeholder__direction {
  border-radius: 2px;
  background-color: #e1e1e1;
  width: 104px;
  height: 10px;
}
