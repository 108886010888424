.blte-header-date-carousel__prev::after,.blte-header-date-carousel__next::after {
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

}
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-header-date-carousel {
  display: flex;
  position: relative;
  user-select: none;
}
.blte-header-date-carousel__outer-wrapper {
  background: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  display: block;
}
.blte-header-date-carousel * {
  user-select: none;
}
.blte-header-date-carousel__day {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  border: none;
  color: var(--header-date-carousel-day-color, #363636);
  background-color: var(--header-date-carousel-day-background-color, #fff);
  padding: 12px 8px;
  width: 100%;
  text-align: center;
  position: relative;
  cursor: pointer;
}
.blte-header-date-carousel__day:hover {
  background-color: var(--header-date-carousel-day-background-hover-color, #fff);
}
.blte-header-date-carousel__day:focus-visible {
  outline: none;
  box-shadow: inset 0 0 0 2px var(--header-date-carousel-day-border-focus-color, #363636);
}
.blte-header-date-carousel__day--disabled {
  cursor: initial;
  background-color: #fefefe;
  color: #f2f2f4;
}
.blte-header-date-carousel__day--disabled .blte-header-date-carousel__price:hover {
  color: #f2f2f4;
}
.blte-header-date-carousel__date {
  animation: fade-in 1s;
}
.blte-header-date-carousel__price {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  animation: fade-in 1s;
}
@media screen and (min-width: 576px) {
  .blte-header-date-carousel__price {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-header-date-carousel__price:hover {
  color: var(--header-date-carousel-day-hover-color, #363636);
}
.blte-header-date-carousel__price--cheapest {
  color: var(--header-date-carousel-day-color-cheapest, #363636);
}
.blte-header-date-carousel__original-price {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #8d8d8d;
  text-decoration: line-through;
  text-decoration-thickness: 1px;
}
.blte-header-date-carousel__carousel-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
}
.blte-header-date-carousel__carousel {
  width: 100%;
  position: relative;
}
.blte-header-date-carousel__inner-carousel {
  width: 100%;
  overflow: hidden;
}
.blte-header-date-carousel__track {
  display: inline-flex;
  height: 100%;
  transition: transform 0.2s linear;
}
.blte-header-date-carousel__placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  min-height: 72px;
}
.blte-header-date-carousel__card-container {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
}
.blte-header-date-carousel__card-container--active .blte-header-date-carousel__day {
  background-color: var(--header-date-carousel-day-background-active-color, #fff);
  color: var(--header-date-carousel-day-active-color, #363636);
}
.blte-header-date-carousel__card-container--active .blte-header-date-carousel__day:before {
  content: "";
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: var(--header-date-carousel-day-underline-color, #ffdb00);
  bottom: 0;
}
.blte-header-date-carousel__prev {
  padding: 0;
}
.blte-header-date-carousel__prev::after {
  content: ICON_FONT_LOADER_IMAGE(ID4a74a83f9f9725e0371f9574b9c6d726);
}
@media screen and (min-width: 768px) {
  .blte-header-date-carousel__prev {
    padding: 0 24px 0 0;
  }
}
@media screen and (min-width: 1024px) {
  .blte-header-date-carousel__prev {
    padding: 0 40px 0 0;
  }
}
.blte-header-date-carousel__prev:disabled {
  opacity: 0.1;
}
.blte-header-date-carousel__next {
  padding: 0;
}
.blte-header-date-carousel__next::after {
  content: ICON_FONT_LOADER_IMAGE(IDdc0f790aa6274da32c0fd98eb2798059);
}
@media screen and (min-width: 768px) {
  .blte-header-date-carousel__next {
    padding: 0 0 0 24px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-header-date-carousel__next {
    padding: 0 0 0 40px;
  }
}
.blte-header-date-carousel__prev,
.blte-header-date-carousel__next {
  border: none;
  background: transparent;
  cursor: pointer;
  display: block;
  outline: 0;
}
.blte-header-date-carousel__prev:focus-visible,
.blte-header-date-carousel__next:focus-visible {
  box-shadow: inset 0 0 0 2px #363636;
}
.blte-header-date-carousel .show {
  opacity: 1;
}
.blte-header-date-carousel .hide {
  opacity: 0.1;
}
