@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-tickets-form-alert-banner {
  display: flex;
  align-items: center;
  gap: 6px;
  width: calc(100% + 48px);
  margin: 0 0 24px -24px;
  padding: 6px 16px;
  box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.1019607843);
}
@media screen and (min-width: 768px) {
  .blte-tickets-form-alert-banner {
    width: 100%;
    border-radius: 8px;
    margin: 0 24px 0 0;
    box-shadow: unset;
  }
}
.blte-tickets-form-alert-banner--hidden {
  box-shadow: unset;
  margin: unset;
}
@media screen and (min-width: 768px) {
  .blte-tickets-form-alert-banner--hidden {
    margin-right: 24px;
  }
}
.blte-tickets-form-alert-banner--variant-info {
  background: #e6f2f2;
}
.blte-tickets-form-alert-banner--variant-notice {
  background: #ffe5e5;
}
.blte-tickets-form-alert-banner--variant-warning {
  background: #fffbe6;
}
.blte-tickets-form-alert-banner--variant-promotion {
  background: #e7fae6;
}
.blte-tickets-form-alert-banner__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font-size: 16px;
}
.blte-tickets-form-alert-banner__text {
  color: #363636;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .blte-tickets-form-alert-banner {
    margin-left: 0;
    box-shadow: none;
  }
}
@media screen and (min-width: 0) and (max-width: 575px) {
  .blte-tickets-form-alert-banner {
    margin-left: 0;
    box-shadow: none;
  }
}
.blte-tickets-form-footnote {
  margin: 9px 0 0 2px;
  font-size: 14px;
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .blte-tickets-form-footnote {
    margin: -18px 0 9px 17px;
  }
}
@media screen and (min-width: 0) and (max-width: 575px) {
  .blte-tickets-form-footnote {
    margin: -18px 0 9px 17px;
  }
}
