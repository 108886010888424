@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rti-return-as-credit__passes {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.blte-rti-return-as-credit__disclaimer {
  margin-top: 16px;
}
