.blte-account-navigation-menus__navigation .blte-account-navigation-menus__navItem::after {
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

}
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-navigation-menus__navigation,
.blte-account-navigation-menus__subNavigation {
  background: #fff;
}
.blte-account-navigation-menus__navigation {
  padding: 16px 24px;
}
@media screen and (min-width: 576px) {
  .blte-account-navigation-menus__navigation {
    padding: 16px 64px;
  }
}
@media screen and (min-width: 768px) {
  .blte-account-navigation-menus__navigation {
    margin-top: 40px;
    padding: 0;
  }
}
.blte-account-navigation-menus__navigation--isEngagement {
  padding: 0 0 24px;
}
@media screen and (min-width: 768px) {
  .blte-account-navigation-menus__navigation--isEngagement {
    margin-top: 0;
    padding: 0s;
  }
}
.blte-account-navigation-menus__subNavigation {
  margin-top: 16px;
  padding: 16px 24px 24px;
}
@media screen and (min-width: 576px) {
  .blte-account-navigation-menus__subNavigation {
    padding: 16px 64px 24px;
  }
}
.blte-account-navigation-menus__subNavigation--isEngagement {
  margin-top: 32px;
  padding: 0 0 24px;
}
@media screen and (min-width: 768px) {
  .blte-account-navigation-menus__subNavigation {
    margin-top: 64px;
    padding: 0;
  }
}
.blte-account-navigation-menus__navItem {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  padding: 8px;
  display: flex;
  text-align: left;
  color: #363636;
  border-radius: 8px;
  width: 100%;
}
.blte-account-navigation-menus__navItem:focus-visible {
  outline: none;
  box-shadow: inset 0 0 0 2px #363636;
}
.blte-account-navigation-menus__navItem:hover {
  background: #e8f2f1;
}
.blte-account-navigation-menus__navItem--logout {
  color: #c00;
}
.blte-account-navigation-menus__navigation .blte-account-navigation-menus__navItem {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  position: relative;
}
@media screen and (min-width: 576px) {
  .blte-account-navigation-menus__navigation .blte-account-navigation-menus__navItem {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-account-navigation-menus__navigation .blte-account-navigation-menus__navItem::after {
  content: ICON_FONT_LOADER_IMAGE(IDdc0f790aa6274da32c0fd98eb2798059);
}
.blte-account-navigation-menus__navigation .blte-account-navigation-menus__navItem::after {
  position: absolute;
  right: 0;
  font-size: 28px;
}
@media screen and (min-width: 768px) {
  .blte-account-navigation-menus__navigation .blte-account-navigation-menus__navItem::after {
    content: "";
  }
}
.blte-account-navigation-menus__navigation .blte-account-navigation-menus__navItem--isEngagement::after {
  content: "";
}
.blte-account-navigation-menus__navigation .blte-account-navigation-menus__navItem--active {
  background: #e8f2f1;
}
.blte-account-navigation-menus__navigation .blte-account-navigation-menus__navItem > span {
  padding-left: 8px;
}
.blte-account-navigation-menus__navigation .blte-account-navigation-menus__navItem .blte-icon {
  font-size: 24px;
  height: 24px;
  width: 24px;
  line-height: 24px;
}
.blte-account-navigation-menus__navigation .blte-account-navigation-menus__navItem .blte-icon__card {
  line-height: 16px;
}
.blte-account-navigation-menus__subNavigation .blte-account-navigation-menus__navItem {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-account-navigation-menus__subNavigation .blte-account-navigation-menus__navItem > span {
  padding-left: 4px;
}
.blte-account-navigation-menus__subNavigation .blte-account-navigation-menus__navItem .blte-icon {
  width: 24px;
}
.blte-account-navigation-menus__subNavigation .blte-account-navigation-menus__navItem .blte-icon__ticket {
  font-size: 21px;
}
.blte-account-navigation-menus__subNavigation .blte-account-navigation-menus__navItem .blte-icon__bell {
  font-size: 18px;
}
.blte-account-navigation-menus__navigation--placeholders .blte-account-navigation-menus__navItem--active,
.blte-account-navigation-menus__subNavigation--placeholders .blte-account-navigation-menus__navItem--active {
  background: none;
}
.blte-account-navigation-menus__navigation--placeholders .blte-account-navigation-menus__navItem .blte-icon,
.blte-account-navigation-menus__subNavigation--placeholders .blte-account-navigation-menus__navItem .blte-icon {
  font-size: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  background: #f2f2f4;
  position: relative;
  overflow: hidden;
}
.blte-account-navigation-menus__navigation--placeholders .blte-account-navigation-menus__navItem .blte-icon::after,
.blte-account-navigation-menus__subNavigation--placeholders .blte-account-navigation-menus__navItem .blte-icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #f2f2f4, #fff, #f2f2f4);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-account-navigation-menus__navigation--placeholders .blte-account-navigation-menus__navItem span,
.blte-account-navigation-menus__subNavigation--placeholders .blte-account-navigation-menus__navItem span {
  font-size: 0;
  height: 16px;
  background: red;
  width: 50%;
  margin-left: 10px;
  background: #f2f2f4;
  position: relative;
  overflow: hidden;
}
.blte-account-navigation-menus__navigation--placeholders .blte-account-navigation-menus__navItem span::after,
.blte-account-navigation-menus__subNavigation--placeholders .blte-account-navigation-menus__navItem span::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #f2f2f4, #fff, #f2f2f4);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-account-navigation-menus__subNavigation--placeholders .blte-account-navigation-menus__navItem .blte-icon {
  width: 24px;
  height: 24px;
}
.blte-account-navigation-menus__subNavigation--placeholders .blte-account-navigation-menus__navItem span {
  height: 12px;
  width: 40%;
}
