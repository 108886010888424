@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-video-layer {
  display: flex;
  overflow: hidden;
}
.blte-video-layer__thumbnail {
  position: relative;
  width: 100%;
  aspect-ratio: var(--video-layer-thumbnail-aspect-ratio, 1.3333333333);
  height: auto;
  overflow: hidden;
}
.blte-video-layer__thumbnail > img {
  position: absolute;
  display: block;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
