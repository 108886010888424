@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-details-navigation-trip-not-modifiable-message__smart-saver-banner {
  display: none;
  margin-bottom: 25px;
  background-color: #f2f2f4;
  align-items: center;
  padding: 12px 10px;
  border-radius: 8px;
  gap: 8px;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-navigation-trip-not-modifiable-message__smart-saver-banner {
    display: flex;
  }
}
.blte-trip-details-navigation-trip-not-modifiable-message__smart-saver-banner-mobile {
  display: none;
}
@media screen and (max-width: 767px) {
  .blte-trip-details-navigation-trip-not-modifiable-message__smart-saver-banner-mobile {
    display: flex;
    margin-bottom: 25px;
    background-color: #f2f2f4;
    align-items: center;
    padding: 12px 10px;
    border-radius: 8px;
    gap: 8px;
    margin-top: 30px;
  }
}
.blte-trip-details-navigation-trip-not-modifiable-message__info-icon {
  color: #fff;
  background-color: #094273;
  padding: 4px 11px;
  border-radius: 32px;
  font-size: 12px;
}
