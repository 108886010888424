@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-global-header__bottom-nav {
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .blte-global-header__bottom-nav {
    margin: 12px 0 8px;
    border-top: none;
    flex-direction: row;
    padding: 0;
    width: unset;
    align-items: center;
  }
}
.blte-global-header__bottom-nav > div:nth-last-child(2) .blte-nav-item--size-large {
  margin-bottom: 0;
}
.blte-global-header__bottom-nav > div:last-of-type .blte-nav-item {
  margin-right: 0;
}
.blte-global-header__bottom-nav .blte-global-header__buy-tickets {
  margin-left: 16px;
  display: none;
}
@media screen and (min-width: 768px) {
  .blte-global-header__bottom-nav .blte-global-header__buy-tickets {
    display: flex;
  }
}
@media screen and (min-width: 1024px) {
  .blte-global-header__bottom-nav .blte-global-header__buy-tickets {
    margin-left: 24px;
  }
}
.blte-global-header__bottom-nav .blte-global-header__buy-tickets.blte-btn--size-medium {
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
}
@media screen and (min-width: 1024px) {
  .blte-global-header__bottom-nav .blte-global-header__buy-tickets.blte-btn--size-medium {
    padding: 8px 16px;
    font-size: 18px;
    line-height: 26px;
  }
}
.blte-global-header__bottom-nav .blte-global-header__add-to-cart {
  display: none;
}
@media screen and (min-width: 768px) {
  .blte-global-header__bottom-nav .blte-global-header__add-to-cart {
    display: block;
  }
}
