@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-price-item {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  outline: none;
  display: flex;
  width: 100%;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .blte-price-item--class-smart {
    background: linear-gradient(#fff, #ffdb00 90%);
  }
  .blte-price-item--class-premium {
    background: linear-gradient(#fff, #363636 90%);
  }
  .blte-price-item--class-smart.blte-price-item--selected {
    background: #ffdb00;
    border-bottom-left-radius: 0;
  }
  .blte-price-item--class-premium.blte-price-item--selected {
    background: #565656;
    color: #fff;
    border-bottom-right-radius: 0;
  }
  .blte-price-item--selected {
    z-index: 1;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  .blte-price-item:hover:not(.blte-price-item--disabled, .blte-price-item--selected) {
    z-index: 1;
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  .blte-price-item:focus-visible {
    box-shadow: 0 0 0 2px var(--pill-button-border-focus-color, #363636);
  }
}
.blte-price-item--loading {
  background: #fff;
}
.blte-price-item--disabled {
  cursor: auto;
}
.blte-price-item__placeholder {
  display: flex;
  flex-direction: column;
  padding: 16px;
}
.blte-price-item__placeholder--mobile {
  height: 100%;
  justify-content: center;
  background: #f2f2f4;
}
.blte-price-item-desktop {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  width: 100%;
}
.blte-price-item:not(.blte-price-item--selected) .blte-price-item-desktop {
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  .blte-price-item-desktop {
    height: calc(100% - 4px);
  }
}
.blte-price-item-desktop__priceWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.blte-price-item-desktop__no-seats {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  height: 100%;
  color: #a3a3a3;
  animation: fade-in 1s;
}
@media screen and (min-width: 576px) {
  .blte-price-item-desktop__no-seats {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-price-item-desktop--unavailable {
  height: 100%;
  border-bottom: 4px solid #e1e1e1;
}
.blte-price-item-desktop__price {
  align-items: center;
  display: flex;
  flex-flow: wrap;
  animation: fade-in 1s;
}
@media screen and (min-width: 768px) {
  .blte-price-item-desktop__price {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (min-width: 1024px) {
  .blte-price-item-desktop__price {
    align-items: center;
    flex-direction: row;
  }
}
.blte-price-item-desktop__fromLabel {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
.blte-price-item-desktop__fromLabel--isPremiumSelected {
  color: #fff;
}
.blte-price-item-desktop__bottom {
  animation: fade-in 1s;
}
.blte-price-item-desktop__displayedPrice {
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-transform: none;
  color: #094273;
}
@media screen and (min-width: 576px) {
  .blte-price-item-desktop__displayedPrice {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-price-item-desktop__displayedPrice {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 1024px) {
  .blte-price-item-desktop__displayedPrice {
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-price-item-desktop__displayedPrice--isPremiumSelected {
  color: #fff;
}
@media screen and (min-width: 1024px) {
  .blte-price-item-desktop__displayedPrice {
    padding-right: 4px;
  }
}
.blte-price-item-desktop__originalPrice {
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
  color: #565656;
  text-decoration: line-through;
  text-decoration-thickness: 1px;
}
@media screen and (min-width: 576px) {
  .blte-price-item-desktop__originalPrice {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-price-item-desktop__originalPrice--isPremiumSelected {
  color: #fff;
}
.blte-price-item-desktop__person {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  color: #363636;
}
.blte-price-item-desktop__person--isPremiumSelected {
  color: #fff;
}
.blte-price-item-desktop__tags {
  display: flex;
  margin-bottom: 4px;
}
.blte-price-item-desktop__tags > :first-child:nth-last-child(2) {
  margin-right: 4px;
}
.blte-price-item-desktop__seats {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  justify-content: flex-end;
  color: #c00;
}
.blte-price-item-mobile {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  animation: fade-in 1s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  border-radius: 0 16px 16px 0;
  align-items: flex-start;
  text-align: start;
  color: #363636;
}
@media screen and (min-width: 576px) {
  .blte-price-item-mobile {
    padding: 16px;
  }
}
@media screen and (min-width: 768px) {
  .blte-price-item-mobile {
    text-align: unset;
  }
}
.blte-price-item-mobile__no-seats {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  text-align: center;
  color: #565656;
}
.blte-price-item-mobile--unavailable {
  background-color: #e1e1e1;
}
.blte-price-item-mobile--class-smart {
  background-color: #ffdb00;
  color: #363636;
}
.blte-price-item-mobile--class-premium {
  background-color: #363636;
  color: #fff;
}
.blte-price-item-mobile__price {
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  animation: fade-in 1s;
}
@media screen and (min-width: 576px) {
  .blte-price-item-mobile__price {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-price-item-mobile__price {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 1024px) {
  .blte-price-item-mobile__price {
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-price-item-mobile__originalPrice {
  color: #565656;
  text-decoration: line-through;
  text-decoration-thickness: 1px;
}
.blte-price-item-mobile__originalPrice--isPremiumSelected {
  color: #fff;
}
