@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-page-placeholder__list-row {
  background: #fff;
  position: relative;
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px 12px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  height: 64px;
}
.blte-page-placeholder__list-row::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #fff, #f2f2f4, #fff);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
@media screen and (min-width: 576px) {
  .blte-page-placeholder__list-row {
    padding: 12px 12px 12px 16px;
  }
}
.blte-page-placeholder__list-row-content {
  width: 150px;
  height: 16px;
  background-color: #e1e1e1;
}
