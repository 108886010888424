.blte-trip-details-navigation-cancel-trip button::after {
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

}
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-details-navigation-cancel-trip {
  display: flex;
  align-items: center;
  color: #c00;
  margin-top: 20px;
}
.blte-trip-details-navigation-cancel-trip i {
  display: none;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-navigation-cancel-trip i {
    font-size: 20px;
    flex: 0 0 auto;
    display: block;
    margin-right: 12px;
  }
}
.blte-trip-details-navigation-cancel-trip button {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: #c00;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.blte-trip-details-navigation-cancel-trip button::after {
  content: ICON_FONT_LOADER_IMAGE(IDdc0f790aa6274da32c0fd98eb2798059);
}
.blte-trip-details-navigation-cancel-trip button:focus-visible {
  box-shadow: 0 0 0 2px #c00;
  outline: none;
  border-radius: 4px;
}
@media screen and (min-width: 768px) {
  .blte-trip-details-navigation-cancel-trip button {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
  .blte-trip-details-navigation-cancel-trip button::after {
    content: "";
  }
}
.blte-trip-details-navigation-cancel-trip--isCancellable {
  color: #a3a3a3;
}
.blte-trip-details-navigation-cancel-trip--isCancellable > button {
  color: #a3a3a3;
  cursor: auto;
}
