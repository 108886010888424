@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-teaser-v2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px 8px 0 0;
  text-decoration: none;
  position: relative;
  padding: var(--teaser-padding-value, inherit);
}
.blte-teaser-v2--addPadingToCard {
  padding-top: 16px;
  padding-bottom: 16px;
}
.blte-teaser-v2__icon {
  padding: var(--teaser-padding-value, inherit);
  background: var(--teaser-background-color);
  padding-bottom: 0;
  border-radius: 8px 8px 0 0;
}
.blte-teaser-v2__top-icon {
  margin-bottom: 16px;
}
.blte-teaser-v2__content {
  background-color: var(--teaser-background-color);
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  flex-grow: 1;
}
.blte-teaser-v2__content--addPadingToCard {
  padding: var(--teaser-padding-value, inherit);
  padding-top: 0 !important;
  gap: 16px;
}
.blte-teaser-v2__title {
  margin-bottom: 12px;
  color: var(--teaser-title-color, #363636);
}
.blte-teaser-v2__description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  overflow-wrap: anywhere;
  margin-bottom: 12px;
  color: var(--teaser-description-color, #363636);
}
@media screen and (min-width: 576px) {
  .blte-teaser-v2__description {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-teaser-v2__description:hover {
  cursor: pointer;
}
.blte-teaser-v2__image {
  position: relative;
  aspect-ratio: var(--teaser-image-aspect-ratio, 1.3333333333);
  width: auto;
  height: auto;
  max-height: 440px;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  z-index: 1;
}
.blte-teaser-v2__image--addPadingToCard {
  border-radius: 8px !important;
}
.blte-teaser-v2__image:hover {
  cursor: pointer;
}
.blte-teaser-v2__image > img,
.blte-teaser-v2__image > picture > img {
  pointer-events: none;
  display: block;
  inset: 0;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s;
  border-radius: 8px;
}
.blte-teaser-v2__image--imageAlignment-left {
  justify-content: flex-start;
}
.blte-teaser-v2__image--imageAlignment-left > picture {
  min-width: 50%;
}
.blte-teaser-v2__image--imageAlignment-left > img,
.blte-teaser-v2__image--imageAlignment-left > picture > img {
  width: 100%;
}
.blte-teaser-v2__image--imageAlignment-center {
  justify-content: center;
}
.blte-teaser-v2__image--imageAlignment-center > picture {
  min-width: 50%;
}
.blte-teaser-v2__image--imageAlignment-center > img,
.blte-teaser-v2__image--imageAlignment-center > picture > img {
  width: 100%;
}
.blte-teaser-v2__image--imageAlignment-right {
  justify-content: flex-end;
}
.blte-teaser-v2__image--imageAlignment-right > picture {
  min-width: 50%;
}
.blte-teaser-v2__image--imageAlignment-right > img,
.blte-teaser-v2__image--imageAlignment-right > picture > img {
  width: 100%;
}
.blte-teaser-v2__cta {
  margin-top: auto;
}
.blte-teaser-v2--clickable:hover .blte-teaser-v2__image > img,
.blte-teaser-v2--clickable:hover .blte-teaser-v2__image > picture > img {
  max-width: 100%;
  transform: scale(1.1);
}
.blte-teaser-v2--clickable:focus-visible {
  outline: none;
}
.blte-teaser-v2--clickable:focus-visible::before {
  content: "";
  position: absolute;
  inset: -2px;
  box-shadow: 0 0 0 2px var(--teaser-border-focus-color, #363636);
  border-radius: 8px 8px 0 0;
}
.blte-teaser-v2--rounded {
  border-radius: 8px;
}
.blte-teaser-v2--rounded .blte-teaser-v2__content {
  border-radius: 0 0 8px 8px;
  padding: var(--teaser-padding-value, 16px);
}
.blte-teaser-v2--rounded .blte-teaser-v2__image {
  border-radius: 8px 8px 0 0;
}
.blte-teaser-v2--rounded .blte-teaser-v2__image > img,
.blte-teaser-v2--rounded .blte-teaser-v2__image > picture > img {
  transition: transform 0.5s;
  border-radius: 8px 8px 0 0;
}
.blte-teaser-v2--rounded:focus-visible {
  outline: none;
}
.blte-teaser-v2--rounded:focus-visible::before {
  border-radius: 8px;
}
.blte-teaser-v2--alignment-center .blte-teaser-v2__content {
  display: flex;
  align-items: center;
  text-align: center;
}
.blte-teaser-v2--alignment-left .blte-teaser-v2__content {
  display: flex;
  align-items: start;
  text-align: left;
}
.blte-teaser-v2--alignment-right .blte-teaser-v2__content {
  display: flex;
  align-items: end;
  text-align: right;
}
.blte-teaser-v2__text-wrapper-alignment {
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  padding: 15px;
  flex-direction: column-reverse;
  align-items: inherit;
  max-width: 800px;
  justify-content: space-between;
}
@media screen and (min-width: 576px) {
  .blte-teaser-v2__text-wrapper-alignment {
    padding: 20px;
  }
}
@media screen and (min-width: 768px) {
  .blte-teaser-v2__text-wrapper-alignment {
    padding: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-teaser-v2__text-wrapper-alignment {
    padding: 15px;
  }
}
.blte-teaser-v2__text-wrapper-alignment--left {
  align-items: flex-start;
}
.blte-teaser-v2__text-wrapper-alignment--center {
  align-items: center;
}
.blte-teaser-v2__text-wrapper-alignment--right {
  align-items: flex-end;
}
.blte-teaser-v2__text {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: calc(var(--teaser-padding-value, 0px) + 15px);
}
@media screen and (min-width: 576px) {
  .blte-teaser-v2__text {
    padding: calc(var(--teaser-padding-value, 0px) + 20px);
  }
}
@media screen and (min-width: 768px) {
  .blte-teaser-v2__text {
    padding: calc(var(--teaser-padding-value, 0px) + 10px);
  }
}
@media screen and (min-width: 1024px) {
  .blte-teaser-v2__text {
    padding: calc(var(--teaser-padding-value, 0px) + 15px);
  }
}
.blte-teaser-v2__text-group {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  gap: 8px;
}
.blte-teaser-v2__text-wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: inherit;
  max-width: 800px;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}
.blte-teaser-v2__text-value {
  color: #fff;
  max-width: 800px;
  line-height: 15px !important;
  z-index: 3;
}
.blte-teaser-v2__text-value--imageTextAlignment {
  display: flex;
}
.blte-teaser-v2__text-value--imageTextAlignment-center {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.blte-teaser-v2__text-value--imageTextAlignment-left {
  justify-content: start;
  align-items: start;
  text-align: start;
}
.blte-teaser-v2__text-value--imageTextAlignment-right {
  justify-content: end;
  align-items: end;
  text-align: end;
}
.blte-teaser-v2__text-value--imageTextAlignment2 {
  display: flex;
}
.blte-teaser-v2__text-value--imageTextAlignment2-center {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.blte-teaser-v2__text-value--imageTextAlignment2-left {
  justify-content: start;
  align-items: start;
  text-align: start;
}
.blte-teaser-v2__text-value--imageTextAlignment2-right {
  justify-content: end;
  align-items: end;
  text-align: end;
}
.blte-teaser-v2__text-value--imageTextAlignment3 {
  display: flex;
}
.blte-teaser-v2__text-value--imageTextAlignment3-center {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.blte-teaser-v2__text-value--imageTextAlignment3-left {
  justify-content: start;
  align-items: start;
  text-align: start;
}
.blte-teaser-v2__text-value--imageTextAlignment3-right {
  justify-content: end;
  align-items: end;
  text-align: end;
}
.blte-teaser-v2__text-value-span {
  color: var(--text-color);
}
.blte-teaser-v2__text-value-span--heading-h1 {
  font-size: 28px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-teaser-v2__text-value-span--heading-h1 {
    font-size: 42px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-teaser-v2__text-value-span--heading-h1 {
    font-size: 44px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-teaser-v2__text-value-span--heading-h2 {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-teaser-v2__text-value-span--heading-h2 {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-teaser-v2__text-value-span--heading-h2 {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-teaser-v2__text-value-span--heading-h3 {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-teaser-v2__text-value-span--heading-h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-teaser-v2__text-value-span--heading-h3 {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-teaser-v2__text-value-span--heading-h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-teaser-v2__text-value-span--heading-h4 {
    font-size: 22px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-teaser-v2__text-value-span--heading-h5 {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-teaser-v2__text-value-span--heading-h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: none;
  }
}
.blte-teaser-v2__text-value-span--heading-h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: none;
}
.blte-teaser-v2__text-value > span {
  display: inline;
  white-space: break-spaces;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.blte-teaser-v2__text-value > span br {
  content: "";
}
.blte-teaser-v2__text--imageTextAlignment-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--teaser-background-color);
}
.blte-teaser-v2__text--imageTextAlignment-left {
  display: flex;
  justify-content: center;
  align-items: start;
  text-align: start;
  background-color: var(--teaser-background-color);
}
.blte-teaser-v2__text--imageTextAlignment-right {
  display: flex;
  justify-content: center;
  align-items: end;
  text-align: end;
  background-color: var(--teaser-background-color);
}
.blte-teaser-v2__image-button {
  display: flex;
  z-index: 3;
}
.blte-teaser-v2__cta-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  min-height: 50px;
}
.blte-teaser-v2__primaryButtonCta {
  width: 100%;
  background-color: var(--teaser-primary-cta-background-color-color);
  border: 1px solid var(--teaser-primary-cta-border-color-color);
}
.blte-teaser-v2__secondaryButtonCta {
  width: 100%;
  background-color: var(--teaser-secondary-cta-background-color-color);
  border: 1px solid var(--teaser-secondary-cta-border-color-color);
}
