@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-alertWithButton {
  background-color: var(--alert-background-color, #ffdb00);
  border-radius: 8px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
.blte-alertWithButton__icon {
  margin-right: 8px;
  margin-top: 2px;
  display: flex;
}
.blte-alertWithButton__description {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  margin-top: auto;
  margin-left: 8px;
  padding-right: 40px;
  color: var(--alert-description-color, #363636);
}
.blte-alertWithButton__button {
  background-color: var(--alert-background-color, #ffdb00);
  border: none;
}
