@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-trip-details-error-banner__error-banner {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #fae6e6;
  margin-bottom: 12px;
  gap: 10px;
}
.blte-trip-details-error-banner__error-banner i {
  font-size: 20px;
  color: #c00;
}
.blte-trip-details-error-banner__error-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
.blte-trip-details-error-banner__error-description {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
