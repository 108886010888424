@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-nav-item {
  list-style: none;
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 16px;
}
.blte-nav-item__submenu {
  transition: max-height 0.3s linear;
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  left: 0;
  min-width: 100%;
  width: max-content;
  max-height: 0;
  z-index: 3;
}
.blte-nav-item__submenu .blte-menu-item {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-nav-item__submenu .blte-menu-item {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-nav-item__submenu .blte-menu-item__link {
  padding: 12px 16px;
}
.blte-nav-item__submenu--expanded {
  transition:
    opacity 0.3s,
    max-height 0.3s,
    padding-left 0.3s,
    padding-top 0.3s,
    padding-bottom 0.3s;
  opacity: 1;
  max-height: 500px;
  overflow: hidden;
}
.blte-nav-item__submenu-item {
  display: none;
}
.blte-nav-item__submenu-item--expanded {
  display: block;
}
.blte-nav-item .blte-nav-item-header {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-content: start;
  width: 100%;
  outline: none;
  text-decoration: none;
  color: var(--nav-item-text-color, #363636);
  background: var(--nav-item-background-color, #fff);
  padding: 4px;
}
.blte-nav-item .blte-nav-item-header .blte-nav-item-header__leading-content {
  z-index: 2;
}
.blte-nav-item .blte-nav-item-header:hover {
  color: var(--nav-item-text-hover-color, #363636);
  background: var(--nav-item-background-hover-color, #fff);
}
.blte-nav-item .blte-nav-item-header:focus-visible {
  border-radius: 2px;
  outline: none;
  color: var(--nav-item-text-focus-color, #363636);
  background: var(--nav-item-background-focus-color, #fff);
  box-shadow: 0 0 0 2px var(--nav-item-background-focus-color, #fff), 0 0 0 4px var(--nav-item-border-focus-color, #363636);
}
.blte-nav-item--size-small {
  padding: 4px;
}
.blte-nav-item--size-small .blte-nav-item-header__leading-content {
  display: flex;
  align-items: center;
}
.blte-nav-item--size-small .blte-nav-item-header__leading-icon {
  display: flex;
  margin-right: 4px;
}
.blte-nav-item--size-small .blte-nav-item-header__label {
  display: flex;
  text-transform: capitalize;
}
.blte-nav-item--isResponsive.blte-nav-item--size-large {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-nav-item--isResponsive.blte-nav-item--size-large {
    font-size: 22px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-nav-item--isResponsive.blte-nav-item--size-large .blte-nav-item-header__leading-icon {
  display: none;
}
.blte-nav-item--isResponsive.blte-nav-item--size-small {
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-nav-item--isResponsive.blte-nav-item--size-small {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-nav-item--isResponsive {
    width: auto;
    margin: 0;
  }
  .blte-nav-item--isResponsive .blte-nav-item__submenu {
    min-width: calc(100% + 16px);
    position: absolute;
    margin-left: -8px;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
  }
  .blte-nav-item--isResponsive.blte-nav-item--size-large {
    font-size: 16px;
    font-weight: 450;
    line-height: 24px;
    letter-spacing: 0;
    text-transform: none;
    width: auto;
    margin-right: 16px;
  }
}
@media screen and (min-width: 768px) and (min-width: 576px) {
  .blte-nav-item--isResponsive.blte-nav-item--size-large {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-nav-item--isResponsive.blte-nav-item--size-large:focus-within .blte-nav-item__submenu {
    transition:
      opacity 0.3s,
      max-height 0.3s,
      padding-left 0.3s,
      padding-top 0.3s,
      padding-bottom 0.3s;
    opacity: 1;
    max-height: 500px;
  }
  .blte-nav-item--isResponsive.blte-nav-item--size-large:focus-within .blte-nav-item__submenu.blte-nav-item__submenu--expanded {
    transition:
      opacity 0.3s,
      max-height 0.3s,
      padding-left 0.3s,
      padding-top 0.3s,
      padding-bottom 0.3s;
    opacity: 1;
    max-height: 500px;
  }
  .blte-nav-item--isResponsive.blte-nav-item--size-large:hover .blte-nav-item-header:before {
    z-index: 1;
    bottom: 9px;
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    height: 4px;
    background-color: var(--nav-item-underline-hover-color, #ffdb00);
  }
  .blte-nav-item--isResponsive.blte-nav-item--size-large .blte-nav-item-header__trailing-icon {
    display: none;
  }
}
@media screen and (min-width: 768px) and (min-width: 1024px) {
  .blte-nav-item--isResponsive.blte-nav-item--size-large {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
    margin-right: 24px;
  }
}
@media screen and (min-width: 768px) and (min-width: 1024px) and (min-width: 576px) {
  .blte-nav-item--isResponsive.blte-nav-item--size-large {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-nav-item--isResponsive.blte-nav-item--size-small {
    font-size: 14px;
    font-weight: 450;
    line-height: 20px;
    letter-spacing: 0;
    text-transform: none;
    margin-right: 16px;
    width: auto;
    padding: 0;
  }
  .blte-nav-item--isResponsive.blte-nav-item--size-small:focus-within .blte-nav-item__submenu {
    transition:
      opacity 0.3s,
      max-height 0.3s,
      padding-left 0.3s,
      padding-top 0.3s,
      padding-bottom 0.3s;
    opacity: 1;
    max-height: 500px;
  }
  .blte-nav-item--isResponsive.blte-nav-item--size-small:focus-within .blte-nav-item__submenu.blte-nav-item__submenu--expanded {
    transition:
      opacity 0.3s,
      max-height 0.3s,
      padding-left 0.3s,
      padding-top 0.3s,
      padding-bottom 0.3s;
    opacity: 1;
    max-height: 500px;
  }
  .blte-nav-item--isResponsive.blte-nav-item--size-small .blte-nav-item-header--expanded .blte-nav-item-header__label {
    text-decoration: underline;
  }
}
@media screen and (min-width: 1024px) {
  .blte-nav-item--isResponsive.blte-nav-item--size-large {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
    margin-right: 24px;
  }
}
@media screen and (min-width: 1024px) and (min-width: 576px) {
  .blte-nav-item--isResponsive.blte-nav-item--size-large {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-nav-item--isDesktop {
  width: auto;
  margin: 0;
}
.blte-nav-item--isDesktop .blte-nav-item__submenu {
  min-width: calc(100% + 16px);
  position: absolute;
  margin-left: -8px;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
}
.blte-nav-item--isDesktop.blte-nav-item--size-large {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  width: auto;
  margin-right: 16px;
}
@media screen and (min-width: 576px) {
  .blte-nav-item--isDesktop.blte-nav-item--size-large {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-nav-item--isDesktop.blte-nav-item--size-large:focus-within .blte-nav-item__submenu {
  transition:
    opacity 0.3s,
    max-height 0.3s,
    padding-left 0.3s,
    padding-top 0.3s,
    padding-bottom 0.3s;
  opacity: 1;
  max-height: 500px;
}
.blte-nav-item--isDesktop.blte-nav-item--size-large:focus-within .blte-nav-item__submenu.blte-nav-item__submenu--expanded {
  transition:
    opacity 0.3s,
    max-height 0.3s,
    padding-left 0.3s,
    padding-top 0.3s,
    padding-bottom 0.3s;
  opacity: 1;
  max-height: 500px;
}
.blte-nav-item--isDesktop.blte-nav-item--size-large:hover .blte-nav-item-header:before {
  z-index: 1;
  bottom: 9px;
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  height: 4px;
  background-color: var(--nav-item-underline-hover-color, #ffdb00);
}
.blte-nav-item--isDesktop.blte-nav-item--size-large .blte-nav-item-header__trailing-icon {
  display: none;
}
@media screen and (min-width: 1024px) {
  .blte-nav-item--isDesktop.blte-nav-item--size-large {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
    margin-right: 24px;
  }
}
@media screen and (min-width: 1024px) and (min-width: 576px) {
  .blte-nav-item--isDesktop.blte-nav-item--size-large {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-nav-item--isDesktop.blte-nav-item--size-small {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: none;
  margin-right: 16px;
  width: auto;
  padding: 0;
}
.blte-nav-item--isDesktop.blte-nav-item--size-small:focus-within .blte-nav-item__submenu {
  transition:
    opacity 0.3s,
    max-height 0.3s,
    padding-left 0.3s,
    padding-top 0.3s,
    padding-bottom 0.3s;
  opacity: 1;
  max-height: 500px;
}
.blte-nav-item--isDesktop.blte-nav-item--size-small:focus-within .blte-nav-item__submenu.blte-nav-item__submenu--expanded {
  transition:
    opacity 0.3s,
    max-height 0.3s,
    padding-left 0.3s,
    padding-top 0.3s,
    padding-bottom 0.3s;
  opacity: 1;
  max-height: 500px;
}
.blte-nav-item--isDesktop.blte-nav-item--size-small .blte-nav-item-header--expanded .blte-nav-item-header__label {
  text-decoration: underline;
}
@media screen and (min-width: 1024px) {
  .blte-nav-item--isDesktop.blte-nav-item--size-large {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
    margin-right: 24px;
  }
}
@media screen and (min-width: 1024px) and (min-width: 576px) {
  .blte-nav-item--isDesktop.blte-nav-item--size-large {
    font-size: 20px;
    font-weight: 450;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: none;
  }
}
