@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rti-trip-details-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 16px;
}
.blte-rti-trip-details-item:last-of-type {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .blte-rti-trip-details-item {
    margin-top: 0;
  }
}
.blte-rti-trip-details-item__info {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.blte-rti-trip-details-item__stations {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  padding-bottom: 6px;
  display: flex;
}
@media screen and (min-width: 576px) {
  .blte-rti-trip-details-item__stations {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-rti-trip-details-item__details {
  display: flex;
  flex-direction: row;
}
.blte-rti-trip-details-item__from {
  margin-right: 4px;
}
.blte-rti-trip-details-item__to {
  margin-left: 4px;
}
.blte-rti-trip-details-item__dates {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  flex-flow: row wrap;
}
@media screen and (min-width: 576px) {
  .blte-rti-trip-details-item__dates {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-rti-trip-details-item__smart_saver {
  background-color: #ffef8c;
  color: #363636;
}
.blte-rti-trip-details-item__smart_standard {
  background-color: #ffdb00;
  color: #363636;
}
.blte-rti-trip-details-item__premium_standard {
  background-color: #363636;
  color: #fff;
}
.blte-rti-trip-details-item__smart_standard,
.blte-rti-trip-details-item__premium_standard,
.blte-rti-trip-details-item__smart_saver {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  text-transform: capitalize;
  width: fit-content;
  height: fit-content;
  padding: 0 4px;
  border-radius: 4px;
  margin-right: 16px;
  margin-bottom: 0;
  text-align: center;
}
.blte-rti-trip-details-item__departureDate {
  margin-right: 16px;
}
.blte-rti-trip-details-item__edit {
  display: none;
}
@media screen and (min-width: 576px) {
  .blte-rti-trip-details-item__edit {
    display: flex;
  }
}
