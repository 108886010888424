@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-empty-trips {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px 8px;
}
.blte-empty-trips__empty-trip {
  color: #a3a3a3;
  display: block;
  width: unset;
  height: unset;
  margin-bottom: 24px;
  line-height: 10px;
}
.blte-empty-trips__empty-trip::after {
  font-size: 83.5px;
}
.blte-empty-trips__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 4px;
}
@media screen and (min-width: 576px) {
  .blte-empty-trips__title {
    font-size: 22px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-empty-trips__description {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 24px;
}
@media screen and (min-width: 576px) {
  .blte-empty-trips__description {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-empty-trips__action {
  margin-bottom: 16px;
}
.blte-empty-trips__link {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-empty-trips__link {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-empty-trips__heading--variant-upcoming {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-empty-trips__heading--variant-upcoming {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-empty-trips__heading--variant-upcoming {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-empty-trips__heading--variant-past {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: none;
}
@media screen and (min-width: 576px) {
  .blte-empty-trips__heading--variant-past {
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-empty-trips__heading--variant-past {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0;
    text-transform: none;
  }
}
