@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-account-info-tiles-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media screen and (min-width: 768px) {
  .blte-account-info-tiles-container {
    gap: 24px;
  }
}
.blte-account-info-tiles-container__title {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
}
.blte-account-info-tiles-container__row {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 16px;
}
@media screen and (min-width: 768px) {
  .blte-account-info-tiles-container__row {
    display: flex;
    gap: 24px;
  }
}
.blte-account-info-tiles-container__column {
  padding: 16px 24px 16px 16px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  gap: 16px;
}
@media screen and (min-width: 768px) {
  .blte-account-info-tiles-container__column {
    width: 50%;
  }
}
.blte-account-info-tiles-container__tile-image {
  max-width: none;
  width: 48px;
  height: 48px;
}
.blte-account-info-tiles-container__text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.blte-account-info-tiles-container__tile-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}
