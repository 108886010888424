@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-newsletter-form-v2-success-message__form-submission-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 70px 20px;
}
@media (min-width: 1025px) {
  .blte-newsletter-form-v2-success-message__form-submission-message {
    width: 455px;
    padding: 130px 50px;
    max-height: 372px;
  }
}
.blte-newsletter-form-v2-success-message__title {
  font-size: 25px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
}
.blte-newsletter-form-v2-success-message__subtitle {
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
}
.blte-newsletter-form-v2-success-message__button {
  padding-top: 30px;
}
