@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-alert-banner {
  background-color: var(--alert-banner-background-color, #2e6a6e);
  width: 100%;
}
.blte-alert-banner__container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 16px 16px 16px 24px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}
@media screen and (min-width: 576px) {
  .blte-alert-banner__container {
    padding-left: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-alert-banner__container {
    padding-left: 64px;
  }
}
.blte-alert-banner__content {
  display: flex;
  align-items: start;
  flex: 1;
  flex-flow: row wrap;
  column-gap: 16px;
  padding-right: 8px;
}
@media screen and (min-width: 768px) {
  .blte-alert-banner__content {
    flex-direction: row;
    align-items: center;
  }
}
.blte-alert-banner__content--alignment-left {
  align-content: start;
  justify-content: start;
  text-align: start;
  align-items: start;
}
.blte-alert-banner__content--alignment-center {
  align-content: center;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.blte-alert-banner__content--alignment-right {
  align-content: end;
  justify-content: end;
  text-align: end;
  align-items: end;
}
.blte-alert-banner__leading-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  color: var(--alert-banner-icon-color, #363636);
}
.blte-alert-banner__text-content {
  display: flex;
}
.blte-alert-banner__text {
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  letter-spacing: 0;
  text-transform: none;
  color: var(--alert-banner-text-color, #363636);
}
@media screen and (min-width: 576px) {
  .blte-alert-banner__text {
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-alert-banner__text a {
  text-decoration: none;
  color: var(--alert-banner-link-text-color, #363636);
}
.blte-alert-banner__text a:focus-visible {
  border-radius: 4px;
  outline: none;
  box-shadow: 0 0 0 2px var(--alert-banner-link-border-focus-color, #363636);
}
.blte-alert-banner__buttons {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  flex-direction: row;
}
.blte-alert-banner__buttons > :first-child {
  padding-left: 0;
}
@media screen and (min-width: 768px) {
  .blte-alert-banner__buttons > :first-child {
    padding-left: 16px;
  }
}
.blte-alert-banner__alert-button {
  padding-top: 8px;
  padding-left: 16px;
}
@media screen and (min-width: 768px) {
  .blte-alert-banner__alert-button {
    padding-top: 0;
  }
}
.blte-alert-banner__close {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  display: flex;
  align-self: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 20px;
  color: var(--alert-banner-close-button-icon-color, #363636);
}
.blte-alert-banner__close:focus-visible {
  outline: none;
  border-radius: 4px;
  box-shadow: 0 0 0 2px var(--alert-banner-close-button-border-focus-color, #363636);
}
.blte-alert-banner--hidden {
  display: none;
}
