@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-monthly-parking-passes {
  width: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  .blte-monthly-parking-passes {
    flex-direction: row;
    width: 100%;
  }
}
.blte-monthly-parking-passes__image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  width: 100%;
  background-color: var(--background-color);
  padding: 40px 24px 24px;
}
@media screen and (min-width: 768px) {
  .blte-monthly-parking-passes__image {
    padding: 110px 64px;
  }
}
@media screen and (min-width: 1024px) {
  .blte-monthly-parking-passes__image {
    padding: 0 0 0 48px;
  }
}
.blte-monthly-parking-passes__img {
  object-fit: cover;
}
.blte-monthly-parking-passes__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--background-color);
  padding: 48px;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .blte-monthly-parking-passes__content {
    padding: 42px 0;
    align-items: center;
  }
}
@media screen and (min-width: 1024px) {
  .blte-monthly-parking-passes__content {
    padding: 48px;
    align-items: flex-start;
  }
}
.blte-monthly-parking-passes .blte-input__label,
.blte-monthly-parking-passes .blte-select__label {
  top: 24px;
}
.blte-monthly-parking-passes .blte-input__helper-container {
  margin-top: 4px;
  position: relative;
}
.blte-monthly-parking-passes .blte-input:focus-within .blte-input__label,
.blte-monthly-parking-passes .blte-input:focus-within .blte-select__label,
.blte-monthly-parking-passes .blte-input--has-error .blte-input__label,
.blte-monthly-parking-passes .blte-input--has-error .blte-select__label,
.blte-monthly-parking-passes .blte-input--has-value .blte-input__label,
.blte-monthly-parking-passes .blte-input--has-value .blte-select__label,
.blte-monthly-parking-passes .blte-select:focus-within .blte-input__label,
.blte-monthly-parking-passes .blte-select:focus-within .blte-select__label,
.blte-monthly-parking-passes .blte-select--has-error .blte-input__label,
.blte-monthly-parking-passes .blte-select--has-error .blte-select__label,
.blte-monthly-parking-passes .blte-select--has-value .blte-input__label,
.blte-monthly-parking-passes .blte-select--has-value .blte-select__label {
  background-color: transparent;
  padding-bottom: 2px;
}
.blte-monthly-parking-passes .blte-input:focus-within .blte-input__label::before,
.blte-monthly-parking-passes .blte-input:focus-within .blte-select__label::before,
.blte-monthly-parking-passes .blte-input--has-error .blte-input__label::before,
.blte-monthly-parking-passes .blte-input--has-error .blte-select__label::before,
.blte-monthly-parking-passes .blte-input--has-value .blte-input__label::before,
.blte-monthly-parking-passes .blte-input--has-value .blte-select__label::before,
.blte-monthly-parking-passes .blte-select:focus-within .blte-input__label::before,
.blte-monthly-parking-passes .blte-select:focus-within .blte-select__label::before,
.blte-monthly-parking-passes .blte-select--has-error .blte-input__label::before,
.blte-monthly-parking-passes .blte-select--has-error .blte-select__label::before,
.blte-monthly-parking-passes .blte-select--has-value .blte-input__label::before,
.blte-monthly-parking-passes .blte-select--has-value .blte-select__label::before {
  position: absolute;
  z-index: -1;
  content: "";
  background-color: #fff;
  height: 50%;
  bottom: 2px;
  left: 0;
  right: 0;
}
.blte-monthly-parking-passes .blte-input--has-error .blte-input__label,
.blte-monthly-parking-passes .blte-input--has-error .blte-select__label,
.blte-monthly-parking-passes .blte-input--has-value .blte-input__label,
.blte-monthly-parking-passes .blte-input--has-value .blte-select__label,
.blte-monthly-parking-passes .blte-select--has-error .blte-input__label,
.blte-monthly-parking-passes .blte-select--has-error .blte-select__label,
.blte-monthly-parking-passes .blte-select--has-value .blte-input__label,
.blte-monthly-parking-passes .blte-select--has-value .blte-select__label {
  top: 0;
  padding-bottom: 0;
}
.blte-monthly-parking-passes .blte-input--has-error .blte-input__label::before,
.blte-monthly-parking-passes .blte-input--has-error .blte-select__label::before,
.blte-monthly-parking-passes .blte-input--has-value .blte-input__label::before,
.blte-monthly-parking-passes .blte-input--has-value .blte-select__label::before,
.blte-monthly-parking-passes .blte-select--has-error .blte-input__label::before,
.blte-monthly-parking-passes .blte-select--has-error .blte-select__label::before,
.blte-monthly-parking-passes .blte-select--has-value .blte-input__label::before,
.blte-monthly-parking-passes .blte-select--has-value .blte-select__label::before {
  top: 1px;
  bottom: 1px;
}
.blte-monthly-parking-passes .blte-input--has-error:focus-within .blte-input__label,
.blte-monthly-parking-passes .blte-input--has-error:focus-within .blte-select__label,
.blte-monthly-parking-passes .blte-input--has-value:focus-within .blte-input__label,
.blte-monthly-parking-passes .blte-input--has-value:focus-within .blte-select__label,
.blte-monthly-parking-passes .blte-select--has-error:focus-within .blte-input__label,
.blte-monthly-parking-passes .blte-select--has-error:focus-within .blte-select__label,
.blte-monthly-parking-passes .blte-select--has-value:focus-within .blte-input__label,
.blte-monthly-parking-passes .blte-select--has-value:focus-within .blte-select__label {
  padding-bottom: 2px;
}
.blte-monthly-parking-passes .blte-input--has-error:focus-within .blte-input__label::before,
.blte-monthly-parking-passes .blte-input--has-error:focus-within .blte-select__label::before,
.blte-monthly-parking-passes .blte-input--has-value:focus-within .blte-input__label::before,
.blte-monthly-parking-passes .blte-input--has-value:focus-within .blte-select__label::before,
.blte-monthly-parking-passes .blte-select--has-error:focus-within .blte-input__label::before,
.blte-monthly-parking-passes .blte-select--has-error:focus-within .blte-select__label::before,
.blte-monthly-parking-passes .blte-select--has-value:focus-within .blte-input__label::before,
.blte-monthly-parking-passes .blte-select--has-value:focus-within .blte-select__label::before {
  bottom: 2px;
}
.blte-monthly-parking-passes .blte-input__input--hasTrailingIcon,
.blte-monthly-parking-passes .blte-select__input--hasTrailingIcon {
  padding-right: 8px;
}
.blte-monthly-parking-passes .blte-input__trailingIcon {
  top: 24px;
}
.blte-monthly-parking-passes .blte-input:focus-within .blte-select:focus-within .blte-input__input,
.blte-monthly-parking-passes .blte-input:focus-within .blte-select:focus-within .blte-select__input {
  border: 1px solid transparent;
  box-shadow: 0 0 0 2px #094273;
}
