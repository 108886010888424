.blte-teasers-list__desktop-carousel-button .slick-prev::after,.blte-teasers-list__desktop-carousel-button .slick-next::after {
    font-family: 'icon-font';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

}
@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-teasers-list {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  box-sizing: content-box;
  background-color: var(--teaser-list-background-color);
}
.blte-teasers-list__content {
  max-width: 1400px;
  margin: 0 auto;
}
.blte-teasers-list__content--variant-stack {
  width: 100%;
}
.blte-teasers-list__content--variant-stack .blte-teasers-list__items {
  flex-wrap: wrap;
}
.blte-teasers-list__content--variant-stack .blte-teasers-list__items > * {
  padding: 24px 0 0;
}
@media screen and (min-width: 576px) {
  .blte-teasers-list__content--variant-stack .blte-teasers-list__items > * {
    padding: 40px 0 0;
  }
}
@media screen and (min-width: 768px) {
  .blte-teasers-list__content--variant-stack .blte-teasers-list__items > * {
    padding: 0;
    margin: 0 24px 0 0;
  }
  .blte-teasers-list__content--variant-stack .blte-teasers-list__items > *:last-child {
    margin: 0;
  }
}
@media screen and (min-width: 1024px) {
  .blte-teasers-list__content--variant-stack .blte-teasers-list__items > * {
    padding: 0;
    margin: 0 40px 0 0;
  }
}
.blte-teasers-list__content--variant-carousel {
  width: 100%;
  overflow: hidden;
  padding: 0 24px;
}
@media screen and (min-width: 576px) {
  .blte-teasers-list__content--variant-carousel {
    padding: 0 64px;
  }
}
@media screen and (min-width: 768px) {
  .blte-teasers-list__content--variant-carousel {
    padding: 0;
  }
}
.blte-teasers-list__content--variant-carousel .blte-teasers-list__items-wrapper {
  padding: 40px 10px 0;
}
.blte-teasers-list__content--variant-carousel .blte-teasers-list__items {
  position: relative;
  transition: transform 400ms ease 0s;
  user-select: none;
}
.blte-teasers-list__content--variant-carousel .blte-teasers-list__items > * {
  flex: 1 0 100%;
  padding-right: 16px;
}
.blte-teasers-list__content--variant-carousel .blte-teasers-list__items > *:last-child {
  padding-right: 0;
}
@media screen and (min-width: 576px) {
  .blte-teasers-list__content--variant-carousel .blte-teasers-list__items > * {
    padding-right: 24px;
  }
  .blte-teasers-list__content--variant-carousel .blte-teasers-list__items > *:last-child {
    padding-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .blte-teasers-list__content--variant-carousel .blte-teasers-list__items > * {
    flex: none;
    padding: 0;
    margin: 0 24px 0 0;
  }
  .blte-teasers-list__content--variant-carousel .blte-teasers-list__items > *:last-child {
    margin: 0;
  }
}
@media screen and (min-width: 1024px) {
  .blte-teasers-list__content--variant-carousel .blte-teasers-list__items > * {
    padding: 0;
    margin: 0 40px 0 0;
  }
}
@media screen and (min-width: 1024px) {
  .blte-teasers-list__content--width-narrow {
    width: 83.3333333333%;
  }
}
.blte-teasers-list__content--alignment-left .blte-teasers-list__top,
.blte-teasers-list__content--alignment-left .blte-teasers-list__top-eyebrow,
.blte-teasers-list__content--alignment-left .blte-teasers-list__top-title,
.blte-teasers-list__content--alignment-left .blte-teasers-list__bottom {
  text-align: start;
  align-items: start;
  justify-content: start;
}
.blte-teasers-list__content--alignment-center .blte-teasers-list__top,
.blte-teasers-list__content--alignment-center .blte-teasers-list__top-eyebrow,
.blte-teasers-list__content--alignment-center .blte-teasers-list__top-title,
.blte-teasers-list__content--alignment-center .blte-teasers-list__bottom {
  text-align: center;
  align-items: center;
  justify-content: center;
}
.blte-teasers-list__content--alignment-right .blte-teasers-list__top,
.blte-teasers-list__content--alignment-right .blte-teasers-list__top-eyebrow,
.blte-teasers-list__content--alignment-right .blte-teasers-list__top-title,
.blte-teasers-list__content--alignment-right .blte-teasers-list__bottom {
  text-align: end;
  align-items: end;
  justify-content: end;
}
.blte-teasers-list__top {
  width: 100%;
  display: flex;
}
.blte-teasers-list__top-eyebrow {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: none;
  color: var(--teaser-list-eyebrow-color, #363636);
}
.blte-teasers-list__top-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
  text-transform: none;
  color: var(--teaser-list-title-color, #363636);
  background-color: var(--teaser-list-title-background-color);
  margin-top: 4px;
}
@media screen and (min-width: 576px) {
  .blte-teasers-list__top-title {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0;
    text-transform: none;
  }
}
@media screen and (min-width: 768px) {
  .blte-teasers-list__top-title {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0;
    text-transform: none;
  }
}
.blte-teasers-list__top-content {
  display: flex;
  width: 100%;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .blte-teasers-list__top-content {
    max-width: 66.6666666667%;
  }
}
@media screen and (min-width: 1024px) {
  .blte-teasers-list__top-content {
    max-width: 50%;
  }
}
.blte-teasers-list__items-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .blte-teasers-list__items-wrapper {
    padding: 48px 0 0;
  }
  .blte-teasers-list__items-wrapper > * {
    margin-bottom: 24px;
  }
  .blte-teasers-list__items-wrapper > *:last-child {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1024px) {
  .blte-teasers-list__items-wrapper > * {
    margin-bottom: 40px;
  }
  .blte-teasers-list__items-wrapper > *:last-child {
    margin-bottom: 0;
  }
}
.blte-teasers-list__items {
  display: flex;
  width: 100%;
  padding-left: 0;
}
.blte-teasers-list__items > .blte-teaser-v2 {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .blte-teasers-list__items > .blte-teaser-v2 {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .blte-teasers-list__items--length-2 > .blte-teaser-v2 {
    width: calc((100% - 24px) / 2);
  }
}
@media screen and (min-width: 1024px) {
  .blte-teasers-list__items--length-2 > .blte-teaser-v2 {
    width: calc((100% - 40px) / 2);
  }
}
@media screen and (min-width: 768px) {
  .blte-teasers-list__items--length-3 > .blte-teaser-v2 {
    width: calc((100% - 48px) / 3);
  }
}
@media screen and (min-width: 1024px) {
  .blte-teasers-list__items--length-3 > .blte-teaser-v2 {
    width: calc((100% - 80px) / 3);
  }
}
@media screen and (min-width: 768px) {
  .blte-teasers-list__items--length-4 > .blte-teaser-v2 {
    width: calc((100% - 72px) / 4);
  }
}
@media screen and (min-width: 1024px) {
  .blte-teasers-list__items--length-4 > .blte-teaser-v2 {
    width: calc((100% - 120px) / 4);
  }
}
.blte-teasers-list__items--carousel {
  width: calc(100% - 120px);
  margin-left: auto;
  margin-right: auto;
}
.blte-teasers-list__bottom {
  display: flex;
  padding-top: 24px;
}
@media screen and (min-width: 576px) {
  .blte-teasers-list__bottom {
    padding-top: 40px;
  }
}
@media screen and (min-width: 768px) {
  .blte-teasers-list__bottom {
    padding-top: 48px;
  }
}
.blte-teasers-list__desktop-carousel-button {
  position: absolute;
  display: flex;
  align-self: stretch;
  align-items: center;
  z-index: 2;
  top: 0;
  background: #fff;
  height: 100%;
  width: 60px;
}
.blte-teasers-list__desktop-carousel-button--width-narrow {
  width: calc(8.3333333333% + 64px);
}
.blte-teasers-list__desktop-carousel-button--prev {
  left: 0;
  justify-content: start;
}
.blte-teasers-list__desktop-carousel-button--next {
  right: 0;
  justify-content: end;
}
.blte-teasers-list__desktop-carousel-button .slick-prev,
.blte-teasers-list__desktop-carousel-button .slick-next {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  font-size: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #094273;
  color: #094273;
}
.blte-teasers-list__desktop-carousel-button .slick-prev::after,
.blte-teasers-list__desktop-carousel-button .slick-next::after {
  width: 32px;
  height: 32px;
}
.blte-teasers-list__desktop-carousel-button .slick-prev:hover,
.blte-teasers-list__desktop-carousel-button .slick-next:hover {
  background: #094273;
  color: #fff;
}
.blte-teasers-list__desktop-carousel-button .slick-prev:focus-visible,
.blte-teasers-list__desktop-carousel-button .slick-next:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px #363636;
  color: #363636;
}
.blte-teasers-list__desktop-carousel-button .slick-prev:disabled,
.blte-teasers-list__desktop-carousel-button .slick-next:disabled {
  box-shadow: none;
  cursor: unset;
  background: #f2f2f4;
  color: #e1e1e1;
}
.blte-teasers-list__desktop-carousel-button .slick-prev:disabled:focus,
.blte-teasers-list__desktop-carousel-button .slick-next:disabled:focus {
  box-shadow: none;
}
.blte-teasers-list__desktop-carousel-button .slick-prev:disabled:hover,
.blte-teasers-list__desktop-carousel-button .slick-next:disabled:hover {
  background: #f2f2f4;
  color: #e1e1e1;
}
.blte-teasers-list__desktop-carousel-button .slick-prev::after {
  content: ICON_FONT_LOADER_IMAGE(ID4a74a83f9f9725e0371f9574b9c6d726);
}
.blte-teasers-list__desktop-carousel-button .slick-prev::after {
  font-size: 23px;
  line-height: 32px;
}
.blte-teasers-list__desktop-carousel-button .slick-next {
  right: 0;
}
.blte-teasers-list__desktop-carousel-button .slick-next::after {
  content: ICON_FONT_LOADER_IMAGE(IDdc0f790aa6274da32c0fd98eb2798059);
}
.blte-teasers-list__desktop-carousel-button .slick-next::after {
  font-size: 26px;
  line-height: 32px;
}
