@keyframes placeholder-gradient {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}
.blte-rewards-summary {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  border-radius: 8px;
  padding: 16px;
}
.blte-rewards-summary__title-date-container {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .blte-rewards-summary__title-date-container {
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }
}
.blte-rewards-summary__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}
.blte-rewards-summary__membership {
  margin-left: 0;
}
@media screen and (min-width: 768px) {
  .blte-rewards-summary__membership {
    margin-left: auto;
  }
}
.blte-rewards-summary__membership--placeholder {
  font-size: 0;
  width: 30%;
  min-width: 150px;
  height: 24px;
  border-radius: 2px;
  background: #f2f2f4;
  position: relative;
  overflow: hidden;
}
.blte-rewards-summary__membership--placeholder::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #f2f2f4, #fff, #f2f2f4);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
.blte-rewards-summary__date {
  color: #8d8d8d;
  white-space: nowrap;
}
.blte-rewards-summary__tracker-card-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
@media screen and (min-width: 1024px) {
  .blte-rewards-summary__tracker-card-container {
    flex-direction: row;
  }
}
@media screen and (min-width: 1024px) {
  .blte-rewards-summary__card-container {
    width: 100%;
    max-width: 370px;
  }
}
.blte-rewards-summary__expanded-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.blte-rewards-summary__expanded-qr-code-img {
  max-width: none;
  width: min-content;
  align-self: center;
}
.blte-rewards-summary__buttons-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.blte-rewards-summary__button-container--placeholder {
  width: 100%;
  height: 36px;
  border-radius: 2px;
  background: #f2f2f4;
  position: relative;
  overflow: hidden;
}
.blte-rewards-summary__button-container--placeholder::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(90deg, #f2f2f4, #fff, #f2f2f4);
  animation: placeholder-gradient 1s infinite ease-in-out;
}
@media screen and (min-width: 768px) {
  .blte-rewards-summary__button-container--placeholder {
    width: 160px;
  }
}
.blte-rewards-summary__action-button {
  width: 100% !important;
}
@media screen and (min-width: 768px) {
  .blte-rewards-summary__action-button {
    width: max-content !important;
    padding: 4px 32px !important;
  }
}
